<cg-olb-headline
  [type]="'h2'"
  [title]="automaticDataTransmition?.title | transloco"
  [subtitle]="'licensePlate.gdvDataTransmission.infoDialog' | transloco"
></cg-olb-headline>

<div class="automatic-data-transmission" [formGroup]="form" data-cy="gdv-part-radio-button-group">
  @if (automaticDataTransmition) {
    <cg-radio-button-group
      formControlName="fetchGdv"
      [content]="automaticDataTransmition?.radioButton"
    ></cg-radio-button-group>
  }
  @if (form.controls.fetchGdv.touched && form.controls.fetchGdv.invalid) {
    <div class="error-message">
      <cg-error-message
        [class]="'error-message'"
        [errorMessage]="'licensePlate.gdvDataTransmission.errorMessage' | transloco"
      >
      </cg-error-message>
    </div>
  }
</div>

<div class="agb-link">
  <cg-link [content]="additionalOptions.firstLink"></cg-link>
</div>
