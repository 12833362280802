@if (protectConfig$ | async) {
  <section class="vaps-offer">
    <ng-template #master>
      <cg-olb-headline class="mb-4 block t:mb-6" [title]="translationTexts.title"></cg-olb-headline>

      <div class="vaps-offer--subheadline flex items-center justify-between pt-content-mobile w:pt-content-desktop">
        <cg-headline type="h2" styleType="h4" [content]="translationTexts.subtitle"></cg-headline>
        <cg-info-button class="block [&>button>div]:pr-0" [detailContent]="detailContentVapsOffer"></cg-info-button>
      </div>

      <form [formGroup]="form">
        <cg-vaps-offer-grid [content]="content"></cg-vaps-offer-grid>
      </form>
    </ng-template>

    <cg-split-view [master]="master" [showBranding]="true"></cg-split-view>

    <cg-olb-footer
      class="split-view--footer"
      [disableBackBtn]="hideBtns"
      [disableForwardBtn]="hideBtns"
      [forwardBtnContent]="forwardBtnContent"
      (backwardBtnClicked)="goBack()"
      (forwardBtnClicked)="goForward()"
    ></cg-olb-footer>
  </section>
}
<ng-template #detailContentVapsOffer>
  <cg-olb-headline class="[&>*]:!mt-0" [title]="'vapsOffer.info.title' | transloco"></cg-olb-headline>
  <cg-paragraph
    class="text-base font-bold leading-6 text-cg-anthracite"
    [content]="{ text: 'vapsOffer.info.list.title' | transloco, ngTemplate: 'cgParagraph' }"
  ></cg-paragraph>

  <ul class="mt-2 list-disc pl-4 text-cg-anthracite">
    <li>{{ "vapsOffer.info.list.bulletpoint1" | transloco }}</li>
    <li>{{ "vapsOffer.info.list.bulletpoint2" | transloco }}</li>
    <li>{{ "vapsOffer.info.list.bulletpoint3" | transloco }}</li>
  </ul>

  <cg-olb-headline class="mt-6 block [&>*]:!mt-0" [title]="'vapsOffer.info.bosch.title' | transloco"></cg-olb-headline>
  <cg-paragraph
    class="text-base font-bold leading-6 text-cg-anthracite"
    [content]="{ text: 'vapsOffer.info.bosch.list.title' | transloco, ngTemplate: 'cgParagraph' }"
  ></cg-paragraph>

  <ul class="mt-2 list-disc pl-4 text-cg-anthracite">
    <li [innerHTML]="'vapsOffer.info.bosch.list.bulletpoint1' | transloco"></li>
    <li [innerHTML]="'vapsOffer.info.bosch.list.bulletpoint2' | transloco"></li>
    <li [innerHTML]="'vapsOffer.info.bosch.list.bulletpoint3' | transloco"></li>
    <li [innerHTML]="'vapsOffer.info.bosch.list.bulletpoint4' | transloco"></li>
    <li [innerHTML]="'vapsOffer.info.bosch.list.bulletpoint5' | transloco"></li>
  </ul>

  <cg-picture class="ml-auto mt-2 block w-fit" [content]="boschLogo"></cg-picture>
</ng-template>
