import { DestroyRef, inject, Injectable } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Actions, ofType } from "@ngrx/effects";
import { take, tap } from "rxjs";
import { NewAppointmentActions, NewAppointmentFacade, ProcessFacade } from "@cg/olb/state";
import { TranslocoService } from "@jsverse/transloco";
import { addWeeks, endOfISOWeek, format, isSameWeek, startOfISOWeek, subDays } from "date-fns";
import { de } from "date-fns/locale";
import { EnvironmentService } from "@cg/environments";
import { NewAppointmentData, ProcessId } from "@cg/shared";

@Injectable({
  providedIn: "root"
})
export class NewAppointmentTileHelperService {
  public destroyRef = inject(DestroyRef);

  public constructor(
    private readonly translocoService: TranslocoService,
    private readonly appointmentFacade: NewAppointmentFacade,
    private readonly processFacade: ProcessFacade,
    private readonly actions$: Actions,
    private readonly environmentService: EnvironmentService
  ) {}

  public get activeVapsOffer(): boolean {
    return this.environmentService.env.features.vaps.active;
  }

  public getWeekTitle({ customerAppointmentStart }: NewAppointmentData) {
    const today = new Date(Date.now());
    const appointmentDate = new Date(customerAppointmentStart);

    if (isSameWeek(today, appointmentDate)) {
      return this.translocoService.translate("newAppointment.appointmentSelection.tabTitle.thisWeek");
    } else if (isSameWeek(addWeeks(today, 1), appointmentDate)) {
      return this.translocoService.translate("newAppointment.appointmentSelection.tabTitle.nextWeek");
    } else {
      const startOfFirstWeek = startOfISOWeek(appointmentDate);
      const endOfFirstWeek = subDays(endOfISOWeek(appointmentDate), 1);

      const startDate = format(startOfFirstWeek, "dd. LLL.", { locale: de });
      const endDate = format(endOfFirstWeek, "dd. LLL.", { locale: de });

      return `${startDate} - ${endDate}`;
    }
  }

  public bookAppointment(appointment: NewAppointmentData): void {
    this.actions$
      .pipe(
        ofType(NewAppointmentActions.confirmAppointmentSuccess),
        take(1),
        tap(() => {
          const exitId: ProcessId = this.activeVapsOffer ? "vaps-offer" : "customer-contact";

          this.processFacade.goForward(exitId);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();

    this.appointmentFacade.setSelectedAppointment(appointment.appointmentId, appointment.serviceCenterId);
    this.appointmentFacade.confirmAppointment();
  }
}
