import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SpinnerMode } from "@cg/spinner";
import add from "date-fns/add";
import endOfDay from "date-fns/endOfDay";
import { ApiService } from "@cg/core/api";
import {
  AppointmentsRequest,
  AppointmentsResponse,
  ConfirmAppointmentRequest,
  JobType,
  RequiredService,
  ServiceCentersRequest,
  ServiceCentersResponse
} from "@cg/shared";
import { NewAppointmentCleanUpService } from "./new-appointment-clean-up.service";

export const FIRST_APPOINTMENT_IN_MIN = 30;
@Injectable({
  providedIn: "root"
})
export class NewAppointmentService {
  // Inject of clean up service is required once, for it to be instantiated.
  public constructor(
    private readonly apiService: ApiService,
    private readonly cleanUpService: NewAppointmentCleanUpService
  ) {}

  // TODO rework with injects
  // private readonly apiService = inject(ApiService);
  // private readonly cleanUpService = inject(NewAppointmentCleanUpService);

  public getServiceCenters(
    payload: ServiceCentersRequest,
    requiredService: RequiredService
  ): Observable<ServiceCentersResponse> {
    if (RequiredService.REPLACE === requiredService) {
      return this.getServiceCentersReplace(payload);
    } else if (RequiredService.REPAIR === requiredService) {
      return this.getServiceCentersRepair(payload);
    }
  }

  public getServiceCentersRepair(payload: ServiceCentersRequest): Observable<ServiceCentersResponse> {
    return this.apiService.get(
      "frontendProxy",
      `/sc/repair/${payload.latitude}/${payload.longitude}/${payload.damageCount}`,
      {
        spinnerMode: SpinnerMode.ALWAYS
      }
    );
  }

  public getServiceCentersReplace(payload: ServiceCentersRequest): Observable<ServiceCentersResponse> {
    return this.apiService.post(
      "frontendProxy",
      `/sc/replace/${payload.latitude}/${payload.longitude}`,
      { itemNumbers: payload.itemNumbers },
      {
        spinnerMode: SpinnerMode.ALWAYS
      }
    );
  }

  public getAppointments(payload: AppointmentsRequest, spinnerMode?: SpinnerMode): Observable<AppointmentsResponse> {
    return this.apiService.post("frontendProxy", "/appointments", payload, {
      spinnerMode: spinnerMode ?? SpinnerMode.ALWAYS
    });
  }

  public confirmAppointment(payload: ConfirmAppointmentRequest): Observable<void> {
    return this.apiService.post("frontendProxy", `/appointment/confirmation/${payload.customerCaseId}`, payload);
  }

  /**
   * For a repair or replace job, the earliest timeRangeBegin should start with the current time, otherwise at the end of the day.
   */
  public getTimeRangeBeginFor(date: Date, jobType: JobType, initial: boolean = true): string {
    return (jobType === "REPAIR" || jobType === "REPLACE") && initial
      ? add(date, { minutes: FIRST_APPOINTMENT_IN_MIN }).toISOString()
      : endOfDay(date).toISOString();
  }
}
