import { AsyncPipe, NgClass } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  input,
  OnInit,
  Output
} from "@angular/core";
import { takeUntilDestroyed, toSignal } from "@angular/core/rxjs-interop";
import { ControlContainer, FormGroup, FormGroupDirective, ReactiveFormsModule } from "@angular/forms";
import { combineLatest, Observable } from "rxjs";
import { distinctUntilChanged, filter, map, startWith } from "rxjs/operators";
import { NewAppointmentFacade, UiLoadingState } from "@cg/olb/state";
import { ConsentServiceKeys, UserCentricsService } from "@cg/consent-management";
import { GoogleMapsService } from "@cg/core/services";
import { AddFormControls } from "@cg/core/types";
import { clearIconWhite } from "@cg/icon";
import { AppointmentData, AppointmentSearchForm } from "@cg/shared";
import { NewAppointmentFilterComponent } from "../new-appointment-filter/new-appointment-filter.component";
import { NewAppointmentSearchLocationInputComponent } from "../new-appointment-search-location-input/new-appointment-search-location-input.component";

// Feature.NEW_APPOINTMENT_TILE
@Component({
  selector: "cg-new-appointment-search",
  templateUrl: "./new-appointment-search.component.html",
  styleUrls: ["./new-appointment-search.component.scss"],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    AsyncPipe,
    ReactiveFormsModule,
    NewAppointmentFilterComponent,
    NewAppointmentSearchLocationInputComponent
  ]
})
export class NewAppointmentSearchComponent implements OnInit {
  private readonly userCentricsService = inject(UserCentricsService);
  private readonly parent = inject(FormGroupDirective);
  private readonly appointmentFacade = inject(NewAppointmentFacade);

  public readonly skipFormWithExitId = input.required<(exitId: string) => void>();
  public selectedServiceCenterIds = toSignal(this.appointmentFacade.selectedServiceCenterIds$);
  public offeredServiceCenters = toSignal(this.appointmentFacade.offeredServiceCenters$);

  // TODO as out signal?
  @Output()
  public searchClicked = new EventEmitter();

  public destroyRef = inject(DestroyRef);
  public form: FormGroup<AddFormControls<AppointmentSearchForm>>;
  public isInitialState$: Observable<boolean>;
  public clearIcon = clearIconWhite;
  public appointmentData: AppointmentData;

  public get searchInput(): string {
    return this.form.controls.searchServiceCenterInput.value;
  }

  public get isFormTouchedProgrammatically(): boolean {
    return this.form.pristine && !this.form.dirty;
  }

  public ngOnInit(): void {
    this.form = this.parent.form as FormGroup<AddFormControls<AppointmentSearchForm>>;

    this.userCentricsService.activateService<GoogleMapsService>(ConsentServiceKeys.googleMaps);
    this.initDisplayMode();

    this.setFormValues();
  }

  public setFormValues(): void {
    this.appointmentFacade.formattedAddress$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((formattedAddress: string) => !!formattedAddress),
        distinctUntilChanged()
      )
      .subscribe((formattedAddress: string) => {
        const oldValue = this.form.controls.searchServiceCenterInput.value;

        if (oldValue && oldValue !== formattedAddress) {
          this.form.markAsUntouched();
        }

        this.form.controls.searchServiceCenterInput.setValue(formattedAddress);
        this.form.markAsPristine({ onlySelf: true });
      });

    this.appointmentFacade.selectedAppointmentId$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        distinctUntilChanged(),
        filter((id: string) => this.form.controls.selectedAppointmentId.value !== id)
      )
      .subscribe((appointmentId: string) => {
        this.form.controls.selectedAppointmentId.setValue(appointmentId);
        this.form.markAsPristine({ onlySelf: true });
      });
  }

  public filterHasChanged() {
    this.form.markAsUntouched();
  }

  public search() {
    this.searchClicked.emit();
  }

  private initDisplayMode() {
    this.isInitialState$ = combineLatest([
      this.appointmentFacade.loadingState$,
      this.appointmentFacade.formattedAddress$
    ]).pipe(
      map(
        ([loadingState, formattedAddress]: [UiLoadingState, string]) =>
          loadingState !== UiLoadingState.LOADING_SERVICE_CENTERS && !formattedAddress
      ),
      startWith(true)
    );
  }
}
