<form class="flex flex-col" [formGroup]="form" [ngClass]="{ 'min-h-[82svh]': this.isDesktop() && searchClicked() }">
  <cg-new-appointment-search
    id="appointment-search-started"
    class="sticky -top-[1px]"
    [ngClass]="{
      'z-[60] -mx-4 shadow-xl [&>div>form>cg-new-appointment-filter>div]:rounded-none': searchIsSticky(),
      't:-mx-6 [&>div>form>cg-new-appointment-filter>div]:border-none [&_[data-cy=appointment-filter-icon]]:rounded-none':
        searchIsSticky() && !this.isDesktop(),
      'w-screen w:part-[input-elements-wrapper]:!px-0 [&>div>form>cg-new-appointment-filter>div]:border-none [&_[data-cy=appointment-filter-icon]]:rounded-none':
        searchIsSticky() && this.isDesktop(),
      '[&>div>form>cg-new-appointment-filter>div]:rounded-2xl': !searchIsSticky() && this.isDesktop(),
      '!mx-auto h-[44svh]': !searchClicked() && this.isDesktop(),
      '!ml-0': hideBtns && this.isDesktop(),
      '!z-30': hideBtns && !this.isDesktop(),
      'min-w-[480px]': this.isDesktop(),
      'z-[60]': !this.isDesktop()
    }"
    [skipFormWithExitId]="skipFormWithExitId.bind(this)"
    (searchClicked)="search()"
  ></cg-new-appointment-search>

  @if (combinedAppointmentLocations(); as locations) {
    @if (searchClicked()) {
      <div class="mt-10 flex flex-col gap-4">
        @if (earliestLocationWithAppointment) {
          <cg-new-appointment-earliest-appointment-card
            class="mb-4"
            [ngClass]="{
              't:!order-1': this.isDesktop() && (selectedAllServiceCenters() || earliestInSelectedAppointments()),
              't:order-3 t:mb-8': this.isDesktop()
            }"
            [locationWithAppointment]="earliestLocationWithAppointment"
          ></cg-new-appointment-earliest-appointment-card>
        }
        @if (hasAnyAppointments() && appointmentsLoaded()) {
          @for (selectedLocation of locations.selected; track getCardId(i, selectedLocation); let i = $index) {
            <cg-new-appointment-select-card
              data-cy="appointment-select-card"
              [ngClass]="{ 't:order-1': this.isDesktop() }"
              [appointments]="selectedLocation.appointments"
              [expanded]="currentlyExpanded.has(i)"
              [serviceCenter]="selectedLocation.serviceCenter"
              [limitDate]="limitDate"
              (accordionToggled)="toggleCurrentlyExpanded(i)"
            ></cg-new-appointment-select-card>
          }
          @if (shouldDisplayMobileService() === false && !selectedAllServiceCenters()) {
            <div class="mt-12" [ngClass]="{ 't:order-2': this.isDesktop() }">
              <div [ngClass]="{ 't:mx-auto t:mb-12 t:text-center': this.isDesktop() }">
                @if (hasSelectedAppointments()) {
                  <cg-olb-headline
                    class="part-[headline]:!text-lg"
                    [ngClass]="{ 't:part-[headline]:!text-2xl': this.isDesktop() }"
                    [title]="'newAppointment.ealierAppointments.title' | transloco"
                  ></cg-olb-headline>
                } @else {
                  <cg-olb-headline
                    class="part-[headline]:!text-lg"
                    [ngClass]="{ 't:part-[headline]:!text-2xl': this.isDesktop() }"
                    [title]="'newAppointment.emptyAppointments.title' | transloco"
                  ></cg-olb-headline>
                }
                <ng-template #emptyAppointments>
                  <cg-olb-headline
                    class="part-[headline]:!text-lg"
                    [ngClass]="{ 't:part-[headline]:!text-2xl': this.isDesktop() }"
                    [title]="'newAppointment.emptyAppointments.title' | transloco"
                  ></cg-olb-headline>
                </ng-template>
              </div>
            </div>
            @if (locations.earlier.length === 0) {
              <div class="mt-2 flex flex-row items-center gap-2" [ngClass]="{ 't:order-3': this.isDesktop() }">
                <div
                  class="box-border rounded-full border border-black bg-cg-yellow"
                  [ngClass]="{
                    'size-2 t:size-4 t:border-2': this.isDesktop(),
                    'h-2 w-2': !this.isDesktop()
                  }"
                ></div>
                <span class="text-[13px]" [ngClass]="{ 't:text-base': this.isDesktop() }">{{
                  "newAppointment.ealierAppointments.noAppointments" | transloco
                }}</span>
              </div>
            }
            @if (locations.selected.length; as scLength) {
              <div class="flex flex-col gap-4" [ngClass]="{ 't:order-4': this.isDesktop() }">
                @for (earlierLocations of locations.earlier; track getCardId(i, earlierLocations); let i = $index) {
                  <cg-new-appointment-select-card
                    [ngClass]="{ 't:order-4': this.isDesktop() }"
                    [appointments]="earlierLocations.appointments"
                    [expanded]="currentlyExpanded.has(i + scLength)"
                    [serviceCenter]="earlierLocations.serviceCenter"
                    [limitDate]="limitDate"
                    (accordionToggled)="toggleCurrentlyExpanded(i + scLength)"
                  ></cg-new-appointment-select-card>
                }
              </div>
            }
          }
        } @else if (appointmentsLoaded()) {
          <cg-new-appointment-no-appointments></cg-new-appointment-no-appointments>
        }
      </div>
    }
  }

  @if (shouldDisplayMobileService()) {
    <cg-new-appointment-mobile-service-view class="mt-12 block"></cg-new-appointment-mobile-service-view>
  }

  @if (this.isDesktop()) {
    <button
      type="button"
      class="pointer flex items-center gap-4"
      [ngClass]="{
        'mt-12': searchClicked(),
        'mt-4': !searchClicked()
      }"
      (click)="goBack()"
    >
      <cg-icon [content]="backIcon" class="size-4 rotate-180 transform"></cg-icon>
      <span class="font-roboto-condensed font-bold text-cg-anthracite">{{ "newAppointment.btnBack" | transloco }}</span>
    </button>
  }
</form>
