import { provideEffects } from "@ngrx/effects";
import type { EnvironmentProviders, Provider } from "@angular/core";
import { AppointmentEffects } from "./effects/appointment.effects";
import { AppointmentTrackingEffects } from "./effects/appointment-tracking.effects";
import { ChannelSwitchEffects } from "./effects/channel-switch.effects";
import { ChannelSwitchTrackingEffects } from "./effects/channel-switch-tracking.effects";
import { ContactDataEffects } from "./effects/contact-data.effects";
import { ContextEffects } from "./effects/context.effects";
import { CustomerCaseEffects } from "./effects/customer-case.effects";
import { CustomerCaseTrackingEffects } from "./effects/customer-case-tracking.effects";
import { DamageEffects } from "./effects/damage.effects";
import { DamageTrackingEffects } from "./effects/damage-tracking.effects";
import { GdvEffects } from "./effects/gdv.effects";
import { InsuranceEffects } from "./effects/insurance.effects";
import { InsuranceTrackingEffects } from "./effects/insurance-tracking.effects";
import { LicensePlateTrackingEffects } from "./effects/license-plate-tracking.effects";
import { NewAppointmentEffects } from "./effects/new-appointment.effects";
import { NewAppointmentTrackingEffects } from "./effects/new-appointment-tracking.effects";
import { ProcessEffects } from "./effects/process.effects";
import { ProcessTrackingEffects } from "./effects/process-tracking.effects";
import { ProductEffects } from "./effects/product.effects";
import { VapsTrackingEffects } from "./effects/vaps-tracking.effects";

export function provideOlbEffects(): Provider[] | EnvironmentProviders[] {
  return [
    provideEffects([
      CustomerCaseEffects,
      CustomerCaseTrackingEffects,
      ContextEffects,
      ProcessEffects,
      InsuranceEffects,
      AppointmentEffects,
      NewAppointmentEffects,
      ProductEffects,
      ContactDataEffects,
      DamageEffects,
      GdvEffects,
      ChannelSwitchEffects,
      ChannelSwitchTrackingEffects,
      AppointmentTrackingEffects,
      NewAppointmentTrackingEffects,
      DamageTrackingEffects,
      ProcessTrackingEffects,
      InsuranceTrackingEffects,
      LicensePlateTrackingEffects,
      VapsTrackingEffects
    ])
  ];
}
