import { ChangeDetectionStrategy, Component, inject, input } from "@angular/core";
import { FormGroupDirective, ReactiveFormsModule } from "@angular/forms";
import { TranslocoPipe } from "@jsverse/transloco";
import { KeyboardNavigationDirective, KeyboardNavigationRootDirective } from "@cg/shared";
import { VapsOfferGridItem } from "../../interfaces/vaps-offer-grid-item.interface";
import { VapsOfferGridItemComponent } from "../vaps-offer-grid-item/vaps-offer-grid-item.component";

@Component({
  selector: "cg-vaps-offer-grid",
  templateUrl: "./vaps-offer-grid.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    VapsOfferGridItemComponent,
    ReactiveFormsModule,
    TranslocoPipe,
    KeyboardNavigationRootDirective,
    KeyboardNavigationDirective
  ]
})
export class VapsOfferGridComponent {
  public readonly formGroupDirective = inject(FormGroupDirective);

  public content = input.required<VapsOfferGridItem[]>();
}
