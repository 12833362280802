import { CommonModule } from "@angular/common";
import { Component, inject } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { NewAppointmentFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { HeadlineComponent, HeadlineType } from "@cg/core/ui";
import { BrandingComponent, BreakpointService } from "@cg/shared";

@Component({
  selector: "cg-new-appointment-header",
  standalone: true,
  imports: [CommonModule, BrandingComponent, HeadlineComponent, TranslocoPipe],
  templateUrl: "./new-appointment-header.component.html"
})
export class NewAppointmentHeaderComponent {
  protected readonly breakpointService = inject(BreakpointService);
  private readonly appointmentFacade = inject(NewAppointmentFacade);

  public isDesktop = toSignal(this.breakpointService.isDesktop$);
  public searchClicked = toSignal(this.appointmentFacade.searchClicked$);
  protected readonly HeadlineType = HeadlineType;
}
