import { DatePipe, NgClass, NgTemplateOutlet, SlicePipe } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  input,
  Input,
  output,
  QueryList,
  signal,
  TemplateRef,
  ViewChildren,
  WritableSignal
} from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { firstValueFrom } from "rxjs";
import { NewAppointmentFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { getDay } from "date-fns";
import { Icon } from "@cg/content-api/typescript-interfaces";
import { IconComponent, ParagraphComponent } from "@cg/core/ui";
import { accordionIconUp, busyScIcon } from "@cg/icon";
import { StaticMapComponent } from "@cg/olb/shared";
import {
  BreakpointService,
  ExpansionPanelComponent,
  NewAppointmentData,
  OpeningHour,
  OverlayService,
  ServiceCenterData,
  Tab,
  TabsComponent
} from "@cg/shared";
import { NewAppointmentTileHelperService } from "../../services/new-appointment-tile-helper.service";
import { getOpeningHour } from "../../utils/opening-hour.util";
import { NewAppointmentAllScAppointmentsDialogComponent } from "../new-appointment-all-sc-appointments-dialog/new-appointment-all-sc-appointments-dialog.component";
import { NewAppointmentCircleComponent } from "../new-appointment-circle/new-appointment-circle.component";
import { NewAppointmentDesktopDetailComponent } from "../new-appointment-desktop-detail/new-appointment-desktop-detail.component";
import { NewAppointmentDistanceLabelComponent } from "../new-appointment-distance-label/new-appointment-distance-label.component";
import { NewAppointmentSelectionGridComponent } from "../new-appointment-selection-grid/new-appointment-selection-grid.component";

// Feature.NEW_APPOINTMENT_TILE
@Component({
  selector: "cg-new-appointment-select-card",
  templateUrl: "./new-appointment-select-card.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    DatePipe,
    SlicePipe,
    NgTemplateOutlet,
    TranslocoPipe,
    ExpansionPanelComponent,
    IconComponent,
    TabsComponent,
    NewAppointmentSelectionGridComponent,
    NewAppointmentCircleComponent,
    NewAppointmentDistanceLabelComponent,
    NewAppointmentDesktopDetailComponent,
    ParagraphComponent,
    StaticMapComponent
  ]
})
export class NewAppointmentSelectCardComponent implements AfterViewInit {
  private readonly overlayService = inject(OverlayService);
  private readonly newAppointmentTitleService = inject(NewAppointmentTileHelperService);
  private readonly breakpointService = inject(BreakpointService);
  private readonly appointmentFacade = inject(NewAppointmentFacade);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly elementRef = inject(ElementRef);

  public readonly isMobile = toSignal(this.breakpointService.isMobile$);

  public readonly serviceCenter = input.required<ServiceCenterData>();

  public readonly expanded = input<boolean>(false);
  public readonly limitDate = input.required<Date>();

  public readonly accordionToggled = output<void>();

  @ViewChildren("weekTab")
  public weekTab: QueryList<TemplateRef<unknown>>;

  public firstWeekTitle: string;

  public busyScIcon: Icon = busyScIcon;
  public accordionIconUp: Icon = accordionIconUp;

  public tabs: Tab[] = [];

  public firstAppointment: NewAppointmentData;
  public allAppointments: WritableSignal<NewAppointmentData[][]> = signal([]);

  public maxHeight: string;

  public maxAppointsPerDay: number;
  public selectedAppointmentId: string;
  public selectedAppointment: NewAppointmentData;
  public openingHour: OpeningHour;

  private hasAppointments = false;

  @Input()
  public set appointments(appointments: NewAppointmentData[][]) {
    this.hasAppointments = appointments.length !== 0;

    if (!this.hasAppointments) {
      return;
    }

    this.allAppointments.set(appointments);

    this.firstAppointment = appointments[0][0];
    this.maxAppointsPerDay = this.calcMaxItemPerDay();

    const gridItemHeight = 60;
    const otherElementsHeight = 210;
    const detailHeight = 400;
    this.maxHeight = this.maxAppointsPerDay * gridItemHeight + otherElementsHeight + detailHeight + "px";
    this.firstWeekTitle = this.getTabTitle(appointments[0][0]);
  }

  public ngAfterViewInit(): void {
    this.initTabs();
  }

  public emitAccordionToggle(): void {
    this.accordionToggled.emit();
  }

  public openAllAppointments(selectedSc: ServiceCenterData): void {
    if (!this.isMobile()) {
      return;
    }

    this.overlayService.open(NewAppointmentAllScAppointmentsDialogComponent, {
      selectedSc: signal(selectedSc),
      offeredAppointments: this.allAppointments
    });
  }

  public getToday() {
    return new Date();
  }

  private initTabs(): void {
    this.tabs = [];

    if (!this.hasAppointments) {
      return;
    }

    this.tabs = this.isMobile()
      ? [{ header: this.firstWeekTitle, template: this.weekTab.get(0) }]
      : this.allAppointments().map((appointments: NewAppointmentData[], index: number) => {
          const firstAppointment = appointments[0];
          const title = this.getTabTitle(firstAppointment);
          return { header: title, template: this.weekTab.get(index) };
        });

    this.cdr.detectChanges();
  }

  public async selectedAppointmentIdChanged(appointmentId: string): Promise<void> {
    if (this.selectedAppointmentId === appointmentId) {
      this.deselectAppointment();
      this.appointmentFacade.clearSelectedAppointmentIds();
      return;
    }

    this.selectedAppointmentId = appointmentId;
    this.appointmentFacade.setSelectedAppointment(appointmentId, this.serviceCenter().serviceCenterId);
    this.selectedAppointment = this.allAppointments()
      .flat()
      .find((appointment: NewAppointmentData) => appointment.appointmentId === this.selectedAppointmentId);

    const availableServiceCenters = await firstValueFrom(this.appointmentFacade.offeredServiceCenters$);
    const selectedServiceCenter = availableServiceCenters.find(
      (serviceCenter: ServiceCenterData) => serviceCenter.serviceCenterId === this.serviceCenter().serviceCenterId
    );

    this.openingHour = getOpeningHour(this.selectedAppointment, selectedServiceCenter);

    this.scrollToAppointmentDetails();
  }

  public deselectAppointment(): void {
    this.selectedAppointmentId = null;
    this.selectedAppointment = null;
    this.openingHour = null;
  }

  private getTabTitle(firstAppointmentOfWeek: NewAppointmentData): string {
    return this.newAppointmentTitleService.getWeekTitle(firstAppointmentOfWeek);
  }

  private calcMaxItemPerDay() {
    let maxAppointsPerWeeks = 1;
    for (const week of this.allAppointments()) {
      const dayMap = new Map<number, number>();

      for (const app of week) {
        const dayOfWeek = getDay(new Date(app.customerAppointmentStart));

        if (dayMap.has(dayOfWeek)) {
          const count = dayMap.get(dayOfWeek);
          dayMap.set(dayOfWeek, count + 1);

          if (maxAppointsPerWeeks < count + 1) {
            maxAppointsPerWeeks = count + 1;
          }
        } else {
          dayMap.set(dayOfWeek, 1);
        }
      }
    }

    return maxAppointsPerWeeks;
  }

  private scrollToAppointmentDetails(): void {
    const appointmentSelectCard = this.elementRef.nativeElement.querySelector("#appointmentSelectCard");
    const appointmentTile: HTMLElement = document.querySelector("[data-process-id='appointment']");
    const appointmentFilter = document.querySelector("#appointment-filter");
    const filterHeight = appointmentFilter?.getBoundingClientRect().height ?? 0;

    const top = appointmentTile.offsetTop + appointmentSelectCard.offsetTop - filterHeight;

    window.scrollTo({ top, behavior: "smooth" });
  }
}
