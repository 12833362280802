import { damageSizeBigIcon, damageSizeSmallIcon } from "@cg/icon";
import { DamageSize } from "@cg/shared";
import type { DamageAssessmentContent } from "../../../interfaces/damage-assessment-content";

export const damageSizeTileContent: DamageAssessmentContent = {
  controlName: "damageSize",
  a11y: {
    legend: "damageSize.buttons.a11y.legend"
  },
  buttons: [
    {
      title: "damageSize.buttons.small",
      picture: damageSizeSmallIcon,
      radio: {
        id: "damage-size-small",
        value: DamageSize.SMALL
      }
    },
    {
      title: "damageSize.buttons.big",
      picture: damageSizeBigIcon,
      radio: {
        id: "damage-size-big",
        value: DamageSize.BIG
      }
    }
  ]
};
