import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, filter, map, mergeMap, take, tap, withLatestFrom } from "rxjs/operators";
import {
  AppointmentFacade,
  AppointmentUserSearchLocationState,
  CustomerCaseFacade,
  DamageFacade,
  NewAppointmentActions,
  NewAppointmentFacade
} from "@cg/olb/state";
import { NewAppointmentService } from "@cg/olb/tiles";
import { errorToString } from "@cg/core/utils";
import { damageChipCountToGmCount } from "@cg/olb/shared";
import {
  AppointmentsRequest,
  AppointmentsResponse,
  ChosenProduct,
  ConfirmAppointmentRequest,
  CustomerCase,
  DamageChipCount,
  NewAppointmentData,
  RequiredService,
  ServiceCenterData,
  ServiceCentersRequest,
  ServiceCentersResponse
} from "@cg/shared";

@Injectable()
export class NewAppointmentEffects {
  private readonly actions$ = inject(Actions);
  private readonly appointmentService = inject(NewAppointmentService);
  private readonly appointmentFacade = inject(NewAppointmentFacade);
  private readonly oldAppointmentFacade = inject(AppointmentFacade);
  private readonly damageFacade = inject(DamageFacade);
  private readonly customerCaseFacade = inject(CustomerCaseFacade);

  public getServiceCenters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewAppointmentActions.getServiceCenters),
      withLatestFrom(
        this.appointmentFacade.searchLocation$,
        this.damageFacade.damageChipCount$,
        this.damageFacade.requiredService$
      ),
      this.waitForProductId(),
      mergeMap(
        ([_, searchLocation, damageCount, requiredService, itemNumbers]: [
          Action,
          AppointmentUserSearchLocationState,
          DamageChipCount,
          RequiredService,
          string[]
        ]) => {
          const payload: ServiceCentersRequest = {
            latitude: searchLocation.lat,
            longitude: searchLocation.lng,
            damageCount: damageChipCountToGmCount(damageCount),
            itemNumbers: itemNumbers ?? []
          };
          return this.appointmentService.getServiceCenters(payload, requiredService).pipe(
            map((response: ServiceCentersResponse) =>
              NewAppointmentActions.getServiceCentersSuccess({
                payload: { response: response, serviceCenterSearchFormattedAddress: searchLocation.formattedAddress }
              })
            ),
            catchError((error: Error) =>
              of(NewAppointmentActions.getServiceCentersFailure({ error: errorToString(error) }))
            )
          );
        }
      )
    )
  );

  public getAppointments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewAppointmentActions.getAppointments),
      withLatestFrom(this.appointmentFacade.offeredServiceCenters$, this.customerCaseFacade.customerCaseId$),
      this.waitForProductId(),
      mergeMap(([_, serviceCenters, customerCaseId, itemNumbers]: [Action, ServiceCenterData[], string, string[]]) => {
        const serviceCenterIds: string[] = serviceCenters.map(
          (serviceCenter: ServiceCenterData) => serviceCenter.serviceCenterId
        );
        const payload: AppointmentsRequest = {
          serviceCenters: serviceCenterIds,
          itemNumbers,
          searchDate: new Date(Date.now()),
          customerCaseId
        };
        return this.appointmentService.getAppointments(payload).pipe(
          map((response: AppointmentsResponse) => NewAppointmentActions.getAppointmentsSuccess({ payload: response })),
          catchError((error: Error) =>
            of(NewAppointmentActions.getAppointmentsFailure({ error: errorToString(error) }))
          )
        );
      })
    )
  );

  public confirmAppointment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewAppointmentActions.confirmAppointment),
      withLatestFrom(this.appointmentFacade.selectedAppointment$, this.customerCaseFacade.customerCaseId$),
      map(
        ([_, selectedAppointment, customerCaseId]: [Action, NewAppointmentData, string]) =>
          ({
            ...selectedAppointment,
            customerCaseId
          }) as ConfirmAppointmentRequest
      ),
      mergeMap((payload: ConfirmAppointmentRequest) =>
        this.appointmentService.confirmAppointment(payload).pipe(
          map(() =>
            NewAppointmentActions.confirmAppointmentSuccess({
              payload: { appointmentId: payload.appointmentId, serviceCenterId: payload.serviceCenterId }
            })
          ),
          catchError((error: Error) =>
            of(NewAppointmentActions.confirmAppointmentFailure({ error: errorToString(error) }))
          )
        )
      )
    )
  );

  public confirmAppointmentSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NewAppointmentActions.confirmAppointmentSuccess), // TODO Clarify, if confirmed appointment is always already available - reducer before effect!?
        withLatestFrom(
          this.appointmentFacade.confirmedAppointment$,
          this.appointmentFacade.confirmedAppointmentServiceCenter$,
          this.appointmentFacade.isCalibration$
        ),
        tap(([_, appointment, serviceCenter, calibration]: [Action, NewAppointmentData, ServiceCenterData, boolean]) =>
          this.oldAppointmentFacade.patchNewAppointmentDataToOldState(
            serviceCenter.address,
            appointment.customerAppointmentStart,
            calibration
          )
        )
      ),
    { dispatch: false }
  );

  private waitForProductId<T>() {
    return (source: Observable<T>) =>
      source.pipe(
        mergeMap((values: T) =>
          this.customerCaseFacade.customerCase$.pipe(
            filter((cc: CustomerCase) => !!cc && !!cc.shoppingCartEntries?.length),
            take(1),
            map((customerCase: CustomerCase) => {
              if (Array.isArray(values)) {
                const itemIdsArray: string[] = customerCase.shoppingCartEntries.map(
                  (item: ChosenProduct) => item.itemNumber
                );
                return [...values, itemIdsArray];
              } else {
                throw new Error("waitForProductId is only for observables with arrays");
              }
            })
          )
        )
      );
  }
}
