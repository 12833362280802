<section
  class="insurance-type-container"
  [ngClass]="{ 'insurance-type-container-mobile': !!insuranceType }"
  [@slideUp]="slideUpAfterSpinnerGone$ | async"
  (@slideUp.done)="slideUpAnimationDone($event)"
>
  <ng-template #master>
    <form class="insurance-type-form">
      <cg-olb-headline class="mb-4 block" [title]="'insuranceType.title' | transloco"></cg-olb-headline>

      <div class="insurance-type--type-selection">
        @if (hasError) {
          <div>
            <cg-error-message
              [class]="'error-message'"
              [errorMessage]="'insuranceType.errors.errorMessage' | transloco"
            >
            </cg-error-message>
          </div>
        }

        <div class="flex flex-col gap-2">
          <cg-fake-dropdown
            id="insurance-type-insurance-provider"
            data-cy="insurance-type-selection"
            [placeholder]="
              insuranceType
                ? ('insuranceType.buttons.' + insuranceType.toLowerCase() | transloco)
                : 'Versicherungsart auswählen'
            "
            (keyup.enter)="onClicked('insurance-type-selection')"
            (keyup.space)="onClicked('insurance-type-selection')"
            (click)="onClicked('insurance-type-selection')"
            (press)="onClicked('insurance-type-selection')"
          ></cg-fake-dropdown>
          <cg-fake-dropdown
            [class.hidden]="insuranceType !== 'FULLY'"
            [placeholder]="selectedInsurance ? (selectedInsuranceName$() | async) : 'Versicherung auswählen'"
            (keyup.enter)="onClicked('insurances-selection')"
            (keyup.space)="onClicked('insurances-selection')"
            (press)="onClicked('insurances-selection')"
          ></cg-fake-dropdown>
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template #detail>
    <cg-insurance-type-detail [insuranceType]="insuranceType"></cg-insurance-type-detail>
  </ng-template>

  <cg-split-view [master]="master" [detail]="detail"></cg-split-view>
  <cg-olb-footer
    class="split-view--footer"
    [disableBackBtn]="hideBtns"
    [disableForwardBtn]="hideBtns"
    (backwardBtnClicked)="goBack()"
    (forwardBtnClicked)="goForwardBtnClicked()"
  ></cg-olb-footer>
</section>
