/* eslint-disable sonarjs/no-duplicate-string */

import { ChangeDetectionStrategy, Component, computed, inject, input, Signal } from "@angular/core";
import { TranslocoPipe } from "@jsverse/transloco";
import { HeadlineType } from "@cg/core/ui";
import { OlbHeadlineComponent } from "@cg/olb/shared";
import { DrawerComponent, NewAppointmentData, ServiceCenterData } from "@cg/shared";
import { AppointmentsPerWeek } from "../../interfaces/appointments-per-week.interface";
import { NewAppointmentTileHelperService } from "../../services/new-appointment-tile-helper.service";
import { NewAppointmentAllScAppointmentsWeekComponent } from "../new-appointment-all-sc-appointments-week/new-appointment-all-sc-appointments-week.component";

// Feature.NEW_APPOINTMENT_TILE
@Component({
  selector: "cg-new-appointment-all-sc-appointments-dialog",
  templateUrl: "./new-appointment-all-sc-appointments-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoPipe, DrawerComponent, OlbHeadlineComponent, NewAppointmentAllScAppointmentsWeekComponent]
})
export class NewAppointmentAllScAppointmentsDialogComponent {
  private readonly newAppointmentTitleService = inject(NewAppointmentTileHelperService);

  public readonly headlineType = HeadlineType;

  public selectedSc = input<ServiceCenterData>();
  public offeredAppointments = input<NewAppointmentData[][]>();

  public appointmentsPerWeek: Signal<AppointmentsPerWeek[]> = computed(() =>
    this.offeredAppointments().map((appointments: NewAppointmentData[]): AppointmentsPerWeek => {
      if (appointments?.length > 0) {
        const firstAppointment = appointments[0];
        return { title: this.newAppointmentTitleService.getWeekTitle(firstAppointment), appointments: appointments };
      }
    })
  );

  public location = computed<string>(() => {
    const { subLocality, city } = this.selectedSc().address;
    if (!subLocality) {
      return city;
    }

    return `${city} - ${subLocality}`;
  });
}
