import { ChangeDetectionStrategy, Component, input, InputSignal } from "@angular/core";

// Feature.NEW_APPOINTMENT_TILE
@Component({
  selector: "cg-new-appointment-circle",
  templateUrl: "./new-appointment-circle.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class NewAppointmentCircleComponent {
  public selected: InputSignal<boolean> = input<boolean>(false);
}
