import { parseISO } from "date-fns";
import type { AdvancedSchedulerWeekDay } from "@cg/core/types";
import type { NewAppointmentData, OpeningHour, ServiceCenterData } from "@cg/shared";

function convertNumberToDayAdvancedScheduler(day: number): string {
  const advancedSchedulerWeekDays: AdvancedSchedulerWeekDay[] = [
    "SUNDAY",
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY"
  ];

  return advancedSchedulerWeekDays[day];
}

function getOpeningHoursForDate(date: Date, serviceCenter: ServiceCenterData): OpeningHour {
  return serviceCenter.openingHours
    ? serviceCenter.openingHours.find(
        (openingHour: OpeningHour) => openingHour.day === convertNumberToDayAdvancedScheduler(date.getDay())
      )
    : null;
}

export function openingHoursStripTrailingMinutes(hours: OpeningHour): OpeningHour {
  const hoursCopy = { ...hours };

  for (const key in hoursCopy) {
    if (key !== "day" && hoursCopy[key]?.length > 5) {
      hoursCopy[key] = hoursCopy[key].slice(0, 5);
    }
  }

  return hoursCopy;
}

export function getOpeningHour(appointment: NewAppointmentData, serviceCenter: ServiceCenterData): OpeningHour {
  if (!serviceCenter) {
    return;
  }

  const date = parseISO(appointment.customerAppointmentStart);

  const openingHour = { ...getOpeningHoursForDate(date, serviceCenter) };
  return openingHoursStripTrailingMinutes(openingHour);
}
