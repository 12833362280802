<div class="flex h-full flex-col">
  <cg-headline
    class="part-[headline]:!m-h1 part-[headline]:!font-roboto-condensed part-[headline]:!text-[26px] part-[headline]:!font-bold part-[headline]:!leading-8 part-[headline]:t:!m-h1-tablet part-[headline]:t:!text-[30px] part-[headline]:t:!leading-10"
    [content]="'appointment.appointmentNoSelected.texts.title' | transloco"
  ></cg-headline>
  <div class="flex flex-1 items-center justify-center">
    <div class="flex flex-col justify-center">
      <cg-icon class="m-auto mb-8 block h-[72px] w-[72px]" [content]="icon"></cg-icon>
      <cg-paragraph [content]="{ text: (text$ | async | transloco), ngTemplate: 'cgParagraph' }"></cg-paragraph>
    </div>
  </div>
</div>
