import { animate, state, style, transition, trigger } from "@angular/animations";
import { AsyncPipe, DatePipe, NgClass } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Observable } from "rxjs";
import { filter, skip } from "rxjs/operators";
import { TranslocoPipe } from "@jsverse/transloco";
import { IconComponent } from "@cg/core/ui";
import { accordionIconDown } from "@cg/icon";
import {
  AppointmentData,
  ExpansionPanelComponent,
  RadioButtonGroup,
  RadioButtonGroupComponent,
  RequiredService,
  SameDayAppointments
} from "@cg/shared";
import { AppointmentListService } from "../../services/appointment-list/appointment-list.service";

@Component({
  selector: "cg-appointment-list-item",
  templateUrl: "./appointment-list-item.component.html",
  styleUrls: ["./appointment-list-item.component.scss"],
  animations: [
    trigger("animationOpenClose", [
      state("false", style({ background: "#f1f1f1", color: "#555" })),
      state("true", style({ background: "none", color: "$cg-anthracite" })),
      transition("true <=> false", animate("500ms ease-out"))
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    DatePipe,
    AsyncPipe,
    TranslocoPipe,
    RadioButtonGroupComponent,
    IconComponent,
    ExpansionPanelComponent
  ]
})
export class AppointmentListItemComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() public appointmentData: AppointmentData;
  @Input() public sameDayAppointments: SameDayAppointments;
  @Input() public isExpanded = false;
  @Input() public disabled = false;
  @Input() public showAppointmentDetails = true;
  @Input() public requiredService: RequiredService;
  @Input() public selectedAppointmentId: string;
  @Output() public selectedAppointmentChange = new EventEmitter<string>();

  @ViewChild("radioGroup", { static: false }) public radioGroup: RadioButtonGroupComponent<string>;

  public get isMoreThanOneAppointment(): boolean {
    return this.appointmentCount > 1;
  }

  public get expandedPanelTitleDay(): string {
    return this.appointmentListService.getExpandedPanelTitleDay(this.sameDayAppointments.day);
  }

  public get expandedPanelTitleDate$(): Observable<string> {
    return this.appointmentListService.getExpandedPanelTitleDate(this.sameDayAppointments.day);
  }

  public get showNumberOfAppointments(): boolean {
    return this.appointmentListService.shouldShowNumberOfAppointments(this.sameDayAppointments, this.requiredService);
  }

  public get appointmentCount(): number {
    return this.sameDayAppointments.appointments.length;
  }

  public destroyRef = inject(DestroyRef);
  public radioGroupContent: RadioButtonGroup;
  public icon = accordionIconDown;

  public constructor(
    public appointmentListService: AppointmentListService,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    if (this.sameDayAppointments) {
      this.radioGroupContent = {
        controlName: "appointments",
        a11y: {
          legend: "appointment.appointmentList.a11y.legend"
        },
        buttons: this.appointmentListService.createRadioButtonsFromAppointments(this.sameDayAppointments)
      };
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ("disabled" in changes) {
      this.radioGroup?.setDisabledState(changes.disabled?.currentValue);
    }

    if ("selectedAppointmentId" in changes) {
      this.radioGroup?.writeValue(changes.selectedAppointmentId.currentValue);
    }
  }

  public ngAfterViewInit() {
    this.resetRadioGroupOnDeclinedAppointment();
    this.setPreselectedAppointment();
    this.cdr.markForCheck();
  }

  public onSelectedValueChanged(appointmentId: string) {
    this.selectedAppointmentChange.emit(appointmentId);
  }

  private resetRadioGroupOnDeclinedAppointment(): void {
    this.appointmentData.appointmentId$
      .pipe(
        skip(1),
        filter((appointmentId: string) => !appointmentId),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((_: string) => {
        this.radioGroup?.writeValue(undefined);
        this.cdr.markForCheck();
      });
  }

  private setPreselectedAppointment() {
    if (this.selectedAppointmentId) {
      this.radioGroup.writeValue(this.selectedAppointmentId);
    }
  }
}
