import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  Inject,
  Input,
  OnInit
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { BehaviorSubject } from "rxjs";
import { filter, take } from "rxjs/operators";
import { addClickHandler, IconComponent } from "@cg/core/ui";
import { IS_SERVER_PLATFORM } from "@cg/core/utils";
import { newTeslaAppointmentMap, teslaAppointmentMap } from "@cg/icon";
import { AppointmentData, AvailableServiceCenter } from "@cg/shared";
import { teslaScToMapModel } from "../../model/tesla-sc-to-map-model";

@Component({
  selector: "cg-tesla-appointment-map",
  templateUrl: "./tesla-appointment-map.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconComponent]
})
export class TeslaAppointmentMapComponent implements OnInit {
  @Input() public appointmentData: AppointmentData;
  @Input() public useNewMap: boolean;
  public destroyRef = inject(DestroyRef);
  public readonly map = teslaAppointmentMap;
  public readonly newMap = newTeslaAppointmentMap;
  public serviceCenterMap: Map<string, string>;
  public mapReady$ = new BehaviorSubject<boolean>(false);
  public mapLoaded = false;

  public constructor(
    private cdr: ChangeDetectorRef,
    @Inject(IS_SERVER_PLATFORM) private readonly isServer: boolean,
    private readonly elementRef: ElementRef
  ) {}

  public ngOnInit(): void {
    this.setupMutationObserver();

    this.appointmentData.availableServiceCenters$
      .pipe(
        filter((availableServiceCenters: AvailableServiceCenter[]) => !!availableServiceCenters),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((availableServiceCenters: AvailableServiceCenter[]) => {
        this.serviceCenterMap = new Map<string, string>();
        availableServiceCenters.forEach((sc: AvailableServiceCenter) => {
          const scId = sc.serviceCenter;
          this.serviceCenterMap.set(teslaScToMapModel[scId], scId);
        });

        this.mapLoaded = true;
        this.mapReady$.next(true);
      });
  }

  public registerServiceCenterClickHandler() {
    if (this.isServer) {
      return;
    }

    setTimeout(() => {
      this.serviceCenterMap.forEach((value: string, key: string) => {
        addClickHandler(key, this.createClickHandler(value));
      });
    }, 300);
  }

  public createClickHandler(scId: string): () => void {
    return () => {
      this.appointmentData.setSelectedServiceCenterIds([scId]);
      this.cdr.markForCheck();
    };
  }

  private setupMutationObserver() {
    const observer = new MutationObserver((mutations: MutationRecord[]) => {
      mutations.forEach((mutation: MutationRecord) => {
        if (mutation.addedNodes.length > 0 && this.mapLoaded) {
          this.mapReady$.next(true);
        }
      });
    });

    observer.observe(this.elementRef.nativeElement.querySelector("cg-icon"), {
      childList: true
    });

    this.mapReady$
      .pipe(
        filter((ready: boolean) => ready),
        take(1),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.registerServiceCenterClickHandler();
        observer.disconnect();
      });
  }
}
