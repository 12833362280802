import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Inject,
  OnInit
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { combineLatest, Observable, of } from "rxjs";
import { filter, map, mergeMap, take, tap } from "rxjs/operators";
import { ProcessFacade, ProductFacade } from "@cg/olb/state";
import { TranslocoPipe, TranslocoService } from "@jsverse/transloco";
import { TrackingService } from "@cg/analytics";
import { Icon, Paragraph } from "@cg/content-api/typescript-interfaces";
import { IconComponent, ParagraphComponent } from "@cg/core/ui";
import {
  arrowsIcon,
  experiencedProfessionalsIcon,
  likeIcon,
  windscreenAvailableIcon,
  windscreenQualityIcon
} from "@cg/icon";
import {
  CarIdentifiedAbTestExitIds,
  OLB_PROCESS_FLOW_MODEL,
  OlbForwardComponent,
  OlbHeadlineComponent,
  ProcessFlow,
  Product,
  ReplacedScreensStatistic,
  ScrollService
} from "@cg/olb/shared";
import { Button, CtalinkComponent, SplitViewComponent } from "@cg/shared";
import { ExitNodeResolverService } from "../../services/exit-node-resolver.service";
import { BaseDirective } from "../core/directives/base/base.directive";
import { changeCarLink } from "./models/change-car-link.model";

@Component({
  selector: "cg-car-identified",
  templateUrl: "./car-identified.component.html",
  styleUrls: ["./car-identified.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoPipe,
    IconComponent,
    OlbHeadlineComponent,
    CtalinkComponent,
    ParagraphComponent,
    OlbForwardComponent,
    SplitViewComponent
  ]
})
export class CarIdentifiedComponent extends BaseDirective<void> implements OnInit {
  public readonly likeIcon = likeIcon;
  public readonly forwardBtnContent: Button = {
    id: "forward-button",
    text: "carIdentified.forwardButton",
    iconPosition: "right",
    icon: arrowsIcon
  };

  public changeCarLink = {
    ...changeCarLink,
    link: {
      ...changeCarLink.link,
      text: "carIdentified.link",
      title: "carIdentified.link"
    }
  };

  public changeCarLinkFallback = {
    ...changeCarLink,
    link: {
      ...changeCarLink.link,
      text: "carIdentified.linkFallback",
      title: "carIdentified.linkFallback"
    }
  };

  public destroyRef = inject(DestroyRef);
  public infoList: { icon: Icon; paragraph: Paragraph }[];
  public replacedScreensStatistic: ReplacedScreensStatistic;
  public showScreenAvailableFlag = true;

  public brand: string;
  public model: string;

  public slideUpAfterSpinnerGone$: Observable<string>;

  // eslint-disable-next-line max-params
  public constructor(
    protected cdr: ChangeDetectorRef,
    protected processFacade: ProcessFacade,
    protected exitNodeResolver: ExitNodeResolverService,
    protected trackingService: TrackingService,
    protected scrollService: ScrollService,
    @Inject(OLB_PROCESS_FLOW_MODEL) processFlow: ProcessFlow,
    private readonly productFacade: ProductFacade,
    private readonly translocoService: TranslocoService
  ) {
    super(cdr, processFacade, exitNodeResolver, trackingService, scrollService, processFlow);
  }

  public async ngOnInit(): Promise<void> {
    await super.ngOnInit();

    this.createInfoList();

    this.productFacade.products$
      .pipe(
        filter((products: Product[]) => products?.length > 0),
        takeUntilDestroyed(this.destroyRef),
        take(1),
        map((products: Product[]) => products[0]),
        tap((product: Product) => {
          this.brand = product.brand;
          this.model = product.model;
          this.productFacade.loadReplacedScreensStatistic(this.brand);
        }),
        mergeMap(() => this.productFacade.replacedScreensStatistic$.pipe(takeUntilDestroyed(this.destroyRef)))
      )
      .subscribe((screensStatistic: ReplacedScreensStatistic) => {
        this.replacedScreensStatistic = screensStatistic;
        this.createInfoList();
      });
  }

  public initFormGroup(): void {}

  public setFormValues(): void {}

  public saveForm(): void {}

  public getExitIdForSavedForm(): Observable<CarIdentifiedAbTestExitIds> {
    return of("appointment");
  }

  public changeCar($event: Event) {
    $event.preventDefault();
    this.processFacade.changeCar();
  }

  public hideAvailabilityFlag(): void {
    this.showScreenAvailableFlag = false;
  }

  public getConcatBrandAndModelString(): string {
    if (this.brand && this.model) {
      return `${this.brand}, ${this.model}`;
    } else if (this.brand) {
      return this.brand;
    } else if (this.model) {
      return this.model;
    } else {
      return "";
    }
  }

  private createInfoList(): void {
    const textKeyFirstPart = "carIdentified.listElements";
    const ngTemplate = "cgParagraph";

    combineLatest([
      this.brand && this.replacedScreensStatistic && !this.replacedScreensStatistic.fallback
        ? this.translocoService.selectTranslate(`${textKeyFirstPart}.elementOne`, {
            brand: this.brand,
            replacedScreens: this.replacedScreensStatistic.replacements.toLocaleString("de-DE"),
            year: this.replacedScreensStatistic.year
          })
        : this.translocoService.selectTranslate(`${textKeyFirstPart}.elementOneFallback`),
      this.brand
        ? this.translocoService.selectTranslate(`${textKeyFirstPart}.elementTwo`, { brand: this.brand })
        : this.translocoService.selectTranslate(`${textKeyFirstPart}.elementTwoFallback`),
      this.brand
        ? this.translocoService.selectTranslate(`${textKeyFirstPart}.elementThree`, { brand: this.brand })
        : this.translocoService.selectTranslate(`${textKeyFirstPart}.elementThreeFallback`)
    ])
      .pipe(take(1))
      .subscribe(([text1, text2, text3]: [string, string, string]) => {
        this.infoList = [
          { icon: windscreenAvailableIcon, paragraph: { text: text1, ngTemplate } },
          { icon: experiencedProfessionalsIcon, paragraph: { text: text2, ngTemplate } },
          { icon: windscreenQualityIcon, paragraph: { text: text3, ngTemplate } }
        ];
        this.cdr.markForCheck();
      });
  }
}
