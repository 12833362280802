import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject, input, OnInit, signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { DamageFacade, ProcessFacade } from "@cg/olb/state";
import { IconComponent, ParagraphComponent } from "@cg/core/ui";
import { accordionIconUp, mobileServiceIcon } from "@cg/icon";
import { CtaTwoLinesComponent, ExpansionPanelComponent, OverlayService, RequiredService } from "@cg/shared";
import { AppointmentsPerWeek } from "../../interfaces/appointments-per-week.interface";
import { NewAppointmentCircleComponent } from "../new-appointment-circle/new-appointment-circle.component";
import { NewAppointmentSelectionGridComponent } from "../new-appointment-selection-grid/new-appointment-selection-grid.component";

// Feature.NEW_APPOINTMENT_TILE
@Component({
  selector: "cg-new-appointment-all-sc-appointments-week",
  templateUrl: "./new-appointment-all-sc-appointments-week.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    ExpansionPanelComponent,
    ParagraphComponent,
    NewAppointmentCircleComponent,
    IconComponent,
    NewAppointmentSelectionGridComponent,
    CtaTwoLinesComponent
  ]
})
export class NewAppointmentAllScAppointmentsWeekComponent implements OnInit {
  private readonly processFacade = inject(ProcessFacade);
  private readonly damageFacade = inject(DamageFacade);
  private readonly overlayService = inject(OverlayService);

  public readonly accordionIcon = accordionIconUp;
  public readonly serviceIcon = mobileServiceIcon;
  public readonly RequiredService = RequiredService;

  public readonly requiredService = toSignal(this.damageFacade.requiredService$);
  public readonly expanded = signal<boolean>(false);

  public readonly isInitiallyExpanded = input<boolean>(false);
  public readonly item = input<AppointmentsPerWeek>();
  public readonly isLastItem = input<boolean>(false);
  public readonly isDisplayedInAllScDialog = input<boolean>(false);

  public ngOnInit(): void {
    this.expanded.set(this.isInitiallyExpanded());
  }

  public toggleExpanded(): void {
    this.expanded.update((val: boolean) => !val);
  }

  public mobileServiceClicked(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    this.overlayService.close();

    this.processFacade.setChannelSwitchReason("MOBILE_SERVICE");
    this.processFacade.goForward("channel-switch");
  }
}
