import { DatePipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject, input } from "@angular/core";
import { TranslocoPipe } from "@jsverse/transloco";
import { IconComponent, ParagraphComponent } from "@cg/core/ui";
import { appointmentInfoIcon, calendarIcon, clockIcon } from "@cg/icon";
import { OlbHeadlineComponent } from "@cg/olb/shared";
import { BaseButtonComponent, NewAppointmentData, OpeningHour } from "@cg/shared";
import { NewAppointmentTileHelperService } from "../../services/new-appointment-tile-helper.service";
import { NewAppointmentCarBringingScheduleTextComponent } from "../new-appointment-car-bringing-schedule-text/new-appointment-car-bringing-schedule-text.component";
import { NewAppointmentTimeComponent } from "../new-appointment-time/new-appointment-time.component";

// Feature.NEW_APPOINTMENT_TILE_DESKTOP
@Component({
  selector: "cg-new-appointment-desktop-detail",
  templateUrl: "./new-appointment-desktop-detail.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DatePipe,
    TranslocoPipe,
    IconComponent,
    ParagraphComponent,
    OlbHeadlineComponent,
    BaseButtonComponent,
    NewAppointmentTimeComponent,
    NewAppointmentCarBringingScheduleTextComponent
  ]
})
export class NewAppointmentDesktopDetailComponent {
  private readonly newAppointmentTitleService = inject(NewAppointmentTileHelperService);

  public appointment = input.required<NewAppointmentData>();
  public openHour = input.required<OpeningHour>();

  public readonly calendarIcon = calendarIcon;
  public readonly clockIcon = clockIcon;
  public readonly infoIcon = appointmentInfoIcon;

  public bookAppointment(): void {
    this.newAppointmentTitleService.bookAppointment(this.appointment());
  }
}
