import { Inject, Injectable, Type } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { map } from "rxjs/operators";
import { Feature, FeatureToggleFacade } from "@cg/feature-toggle";
import { OptimizelyService } from "@cg/analytics";
import {
  ConditionalComponentMappingEntry,
  ConditionalComponentResolverMapping,
  ConditionalFeatureComponentMapping,
  ConditionalOptimizelyComponentMapping,
  OLB_PROCESS_FLOW_CONDITIONAL_TILE_MAPPING
} from "@cg/olb/shared";
import { OptimizelyExperiment } from "@cg/core/enums";

@Injectable()
export class ConditionalComponentResolverService {
  public constructor(
    @Inject(OLB_PROCESS_FLOW_CONDITIONAL_TILE_MAPPING)
    private conditionalComponentMapping: ConditionalComponentResolverMapping,
    private readonly optimizelyService: OptimizelyService,
    private readonly featureToggleFacade: FeatureToggleFacade
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async getConditionalComponent(processId: string): Promise<Type<unknown> | undefined> {
    const conditionalMappingEntry: ConditionalComponentMappingEntry = this.conditionalComponentMapping[processId];

    if (!conditionalMappingEntry) {
      return undefined;
    }

    let component: Type<unknown> | undefined;

    if (conditionalMappingEntry.optimizely) {
      component = await this._getOptimizelyComponent(conditionalMappingEntry.optimizely);
    } else if (conditionalMappingEntry.feature) {
      component = await this._getFeatureComponent(conditionalMappingEntry.feature);
    }

    return component;
  }

  private async _getOptimizelyComponent(conditionalEntry: ConditionalOptimizelyComponentMapping) {
    // eslint-disable-next-line guard-for-in
    for (const experiment in conditionalEntry) {
      const variationOfAbTest = await firstValueFrom(
        this.optimizelyService
          .getActiveVariationIdWithIndex$(experiment as OptimizelyExperiment)
          .pipe(
            map((result: { variationId: string; index: number }) =>
              conditionalEntry[experiment].variations.find(
                (variation: { variationId: string; index: number }) => variation.variationId === result.variationId
              )
            )
          )
      );

      if (variationOfAbTest) {
        return variationOfAbTest?.component;
      }
    }
  }

  private async _getFeatureComponent(conditionalEntry: ConditionalFeatureComponentMapping) {
    // eslint-disable-next-line guard-for-in
    for (const feature in conditionalEntry) {
      const isFeatureActive = await firstValueFrom(this.featureToggleFacade.isFeatureActive$(feature as Feature));

      if (isFeatureActive) {
        return conditionalEntry[feature].component;
      }
    }
  }
}
