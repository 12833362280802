import { DOCUMENT, NgClass } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  DestroyRef,
  effect,
  ElementRef,
  inject,
  OnDestroy,
  OnInit,
  signal,
  Signal,
  untracked,
  viewChild
} from "@angular/core";
import { takeUntilDestroyed, toSignal } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";
import { DamageFacade, NewAppointmentFacade, ProcessFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { endOfISOWeek } from "date-fns";
import addWeeks from "date-fns/addWeeks";
import { AnalyticsEventContainerComponent, OptimizelyFeEvent } from "@cg/analytics";
import { AddFormControls } from "@cg/core/types";
import { IconComponent } from "@cg/core/ui";
import { arrowsIcon, phoneContactIcon } from "@cg/icon";
import { OlbHeadlineComponent, ScrollService } from "@cg/olb/shared";
import {
  AppointmentSearchForm,
  BreakpointService,
  NewAppointmentData,
  ProcessId,
  RequiredService,
  ServiceCenterData
} from "@cg/shared";
import { ExitNodeResolverService } from "../../../services/exit-node-resolver.service";
import { BaseDirective } from "../../core/directives/base/base.directive";
import { NewAppointmentEarliestAppointmentCardComponent } from "../components/new-appointment-earliest-appointment-card/new-appointment-earliest-appointment-card.component";
import { NewAppointmentMobileServiceViewComponent } from "../components/new-appointment-mobile-service-view/new-appointment-mobile-service-view.component";
import { NewAppointmentNoAppointmentsComponent } from "../components/new-appointment-no-appointments/new-appointment-no-appointments.component";
import { NewAppointmentSearchComponent } from "../components/new-appointment-search/new-appointment-search.component";
import { NewAppointmentSelectCardComponent } from "../components/new-appointment-select-card/new-appointment-select-card.component";
import { GroupedLocationWithAppointments } from "../interfaces/grouped-location-with-appointments.interface";
import { LocationWithAppointment } from "../interfaces/location-with-appointment.interface";
import { LocationWithAppointments } from "../interfaces/location-with-appointments.interface";
import { NewAppointmentTileDataService } from "../services/new-appointment-tile-data.service";
import { NewAppointmentTileSearchbarService } from "../services/new-appointment-tile-searchbar.service";

@Component({
  selector: "cg-new-appointment-tile",
  templateUrl: "./new-appointment-tile.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    TranslocoPipe,
    ReactiveFormsModule,
    IconComponent,
    NewAppointmentNoAppointmentsComponent,
    OlbHeadlineComponent,
    NewAppointmentMobileServiceViewComponent,
    NewAppointmentSelectCardComponent,
    NewAppointmentEarliestAppointmentCardComponent,
    NewAppointmentSearchComponent
  ],
  standalone: true
})
export class NewAppointmentTileComponent
  extends BaseDirective<AddFormControls<AppointmentSearchForm>>
  implements OnInit, AfterViewInit, OnDestroy
{
  protected readonly cdr = inject(ChangeDetectorRef);
  protected readonly processFacade = inject(ProcessFacade);
  protected readonly exitNodeResolver = inject(ExitNodeResolverService);
  protected readonly scrollService = inject(ScrollService);
  protected readonly breakpointService = inject(BreakpointService);
  private readonly appointmentFacade = inject(NewAppointmentFacade);
  private readonly damageFacade = inject(DamageFacade);
  private readonly document = inject(DOCUMENT);
  private readonly searchbarService = inject(NewAppointmentTileSearchbarService);
  private readonly appointmentDataService = inject(NewAppointmentTileDataService);
  public destroyRef = inject(DestroyRef);

  public requiredService = toSignal(this.damageFacade.requiredService$);

  public offeredServiceCenters = toSignal(this.appointmentFacade.offeredServiceCenters$);
  public offeredAppointments = toSignal(this.appointmentFacade.offeredAppointments$);
  public selectedServiceCenterIds = toSignal(this.appointmentFacade.selectedServiceCenterIds$);

  public offeredAppointmentSearchLocation = toSignal(this.appointmentFacade.offeredAppointmentsSearchLocation$);
  public searchLocation = toSignal(this.appointmentFacade.searchLocation$);
  public searchClicked = toSignal(this.appointmentFacade.searchClicked$);
  public formattedAddress = toSignal(this.appointmentFacade.formattedAddress$);
  public firstAppointmentSearch = toSignal(this.appointmentFacade.firstAppointmentSearch$);

  public loadingState = toSignal(this.appointmentFacade.loadingState$);
  public isDesktop = toSignal(this.breakpointService.isDesktop$);
  public searchIsSticky = signal(false);

  public readonly limitDate = endOfISOWeek(addWeeks(new Date(), 6));
  public currentlyExpanded = new Set<number>();
  public backIcon = arrowsIcon;
  public scrollAction: number;

  public readonly EMPTY_COMBINED_APPOINTMENT_LOCATION = { selected: [], earlier: [] };
  public combinedAppointmentLocations = signal<GroupedLocationWithAppointments>(
    this.EMPTY_COMBINED_APPOINTMENT_LOCATION
  );
  public earliestLocationWithAppointment: LocationWithAppointment;

  public earliestInSelectedAppointments = this.appointmentDataService.earliestInSelectedAppointments;

  public searchComponent = viewChild(NewAppointmentSearchComponent, { read: ElementRef });
  protected readonly phoneContactIcon = phoneContactIcon;
  private observer: IntersectionObserver;
  private isSingleScInRadius = false;

  public shouldDisplayMobileService: Signal<boolean> = computed(
    () =>
      untracked(this.requiredService) === RequiredService.REPLACE &&
      this.combinedAppointmentLocations().earlier.length === 0 &&
      this.combinedAppointmentLocations().selected.length === 1 &&
      this.combinedAppointmentLocations().selected[0].appointments.length === 1 &&
      untracked(this.searchClicked)
  );

  public hasSelectedAppointments: Signal<boolean> = computed(() =>
    this.combinedAppointmentLocations()
      .selected.map((location: LocationWithAppointments): boolean => location.appointments.length !== 0)
      .some((hasAppointmentsA: boolean) => hasAppointmentsA)
  );

  public appointmentsLoaded: Signal<boolean> = computed(() => this.loadingState().valueOf() >= 4);

  public hasAnyAppointments: Signal<boolean> = computed(
    () => this.hasSelectedAppointments() || this.combinedAppointmentLocations().earlier.length > 0
  );

  public selectedAllServiceCenters: Signal<boolean> = computed(
    () => this.selectedServiceCenterIds()?.length === this.offeredServiceCenters()?.length
  );

  #resetSearchClickedEffect = effect(
    () => {
      // Trigger formattedAddress
      this.formattedAddress();

      untracked(() => {
        this.appointmentFacade.setSearchClicked(false);
        this.searchIsSticky.set(false);
      });
    },
    { allowSignalWrites: true }
  );

  #trackSelectedBranchesEffect = effect(
    () => {
      if (this.selectedServiceCenterIds().length > 0 && !!untracked(this.combinedAppointmentLocations)) {
        const eventLabel =
          this.selectedServiceCenterIds().length === untracked(this.offeredServiceCenters).length
            ? "all-branches"
            : this.selectedServiceCenterIds().length === 1
              ? "location"
              : `${this.selectedServiceCenterIds().length}-branches`;

        this.trackingService.trackEvent({
          event: "ga-event",
          eventCategory: "olb",
          eventAction: untracked(this.firstAppointmentSearch)
            ? "appointment-filter-initial"
            : "appointment-filter-change",
          eventLabel
        });
      }
    },
    { allowSignalWrites: true }
  );

  #appointmentLocationsEffect = effect(
    () => {
      if (Object.entries(this.offeredAppointments()).length) {
        const combinedAppointments = this.appointmentDataService.mapToCombinedAppointmentLocations([
          this.selectedServiceCenterIds(),
          this.offeredAppointments(),
          untracked(this.offeredServiceCenters)
        ]);

        if (untracked(this.isDesktop)) {
          this.earliestLocationWithAppointment = this.appointmentDataService.getEarliestLocationWithAppointment(
            combinedAppointments.selected,
            this.selectedServiceCenterIds()
          );
        } else {
          this.earliestLocationWithAppointment = this.appointmentDataService.getEarliestLocationWithAppointment(
            combinedAppointments.selected
          );
        }
        this.combinedAppointmentLocations.set(combinedAppointments);
      } else {
        this.earliestLocationWithAppointment = null;
        this.combinedAppointmentLocations.set(this.EMPTY_COMBINED_APPOINTMENT_LOCATION);
      }
      this.notifyOptimizelyLoadingDone();
    },
    { allowSignalWrites: true }
  );

  #singleScInRadiusCheckEffect = effect(
    () => {
      if (this.offeredServiceCenters()?.length === 1) {
        this.isSingleScInRadius = true;
        this.appointmentFacade.setSelectedServiceCenterIds(
          this.offeredServiceCenters().map(({ serviceCenterId }: ServiceCenterData) => serviceCenterId)
        );
      } else {
        this.isSingleScInRadius = false;
      }
    },
    { allowSignalWrites: true }
  );

  #resettingCurrentlyExpandedServiceCenterEffect = effect(
    () => {
      this.expandFirstWithAppointments();
      if (
        this.combinedAppointmentLocations().earlier.length === 0 &&
        this.combinedAppointmentLocations().selected.length === 0
      ) {
        this.scrollAction = this.scrollTo("appointment", this.scrollAction);
      } else {
        this.scrollAction = this.scrollTo("appointment-search-started", this.scrollAction, 70);
      }
    },
    { allowSignalWrites: true }
  );

  #setSearchClickedEffect = effect(
    () => {
      if (this.selectedServiceCenterIds().length > 0 && !this.isSingleScInRadius) {
        this.appointmentFacade.setSearchClicked(true);
      }
    },
    { allowSignalWrites: true }
  );

  #searchClickedEffect = effect(
    () => {
      if (!this.searchClicked()) {
        this.searchbarService.resetSearchBarMargin(this.searchComponent());
        this.searchIsSticky.set(false);
        this.cdr.markForCheck();
      }
    },
    { allowSignalWrites: true }
  );

  public async ngOnInit(): Promise<void> {
    this.scrollToSearch();
    await super.ngOnInit();
  }

  public ngAfterViewInit(): void {
    this.initStickyObserver();
    this.initProcessFlowStickyHandling();
  }

  public ngOnDestroy(): void {
    this.appointmentFacade.setSearchClicked(false);
    this.observer?.disconnect();
    window.onresize = null;
  }

  public initFormGroup(): void {
    this.form = new FormGroup<AddFormControls<AppointmentSearchForm>>({
      searchServiceCenterInput: new FormControl<string>("", Validators.required),
      selectedAppointmentId: new FormControl<string>("", Validators.required)
    });
  }

  public setFormValues(): void {
    // TODO: maybe implement this later for save and restore or mycarglass
  }

  /**
   * Method is not used in this component and is only implemented to satisfy the interface.
   *
   * Processforward is handled in the `clickOk` method in the `new-appointment-detail` component.
   **/
  public getExitIdForSavedForm(): Observable<string> {
    return of("");
  }

  public saveForm(): void {}

  public search(): void {
    const oldSearchedAddress = this.offeredAppointmentSearchLocation();
    const newSearchAddress = this.formattedAddress();
    if (newSearchAddress !== null && oldSearchedAddress !== newSearchAddress) {
      this.appointmentFacade.setSearchClicked(true);
      this.searchbarService.handleSearchbarPosition(this.searchComponent(), this.searchIsSticky());

      this.appointmentFacade.getAppointments();
    }
  }

  public toggleCurrentlyExpanded(emittingCard: number): void {
    if (this.currentlyExpanded.has(emittingCard)) {
      this.currentlyExpanded.delete(emittingCard);
    } else {
      this.currentlyExpanded.add(emittingCard);
    }
  }

  public getCardId(_index: number, { serviceCenter, appointments }: LocationWithAppointments): string {
    return `${serviceCenter.serviceCenterId}_${appointments.length}`;
  }

  private notifyOptimizelyLoadingDone(): void {
    this.document
      .querySelector(`#${AnalyticsEventContainerComponent.ANALYTICS_EVENT_CONTAINER_ID}`)
      .dispatchEvent(new CustomEvent(OptimizelyFeEvent.APPOINTMENT_TILE_LOADED));
  }

  private scrollTo(elementId: ProcessId | string, scrollAction?: number, offset?: number): number {
    if (scrollAction) {
      clearTimeout(scrollAction);
    }

    if (this.currentlyExpanded.size === 0) {
      return;
    }

    return window.setTimeout(() => this.scrollService.scrollToTileId(elementId as ProcessId, offset), 600);
  }

  private expandFirstWithAppointments(): void {
    this.currentlyExpanded = new Set<number>();
    const selectedAppointmentLocations = this.combinedAppointmentLocations()?.selected ?? [];

    let firstWithAppointments = selectedAppointmentLocations.findIndex(
      ({ appointments }: { appointments: NewAppointmentData[][] }) => appointments.length
    );
    if (firstWithAppointments === -1 && selectedAppointmentLocations.length > 0) {
      firstWithAppointments = 0;
    }
    this.currentlyExpanded.add(firstWithAppointments);
  }

  private initStickyObserver(): void {
    this.observer = new IntersectionObserver(
      ([e]: [IntersectionObserverEntry]) => {
        const isSticky = e.intersectionRatio < 1 && e.boundingClientRect.bottom < screen.height;

        if (isSticky !== this.searchIsSticky() && this.searchClicked()) {
          this.searchIsSticky.set(isSticky && this.processId === this.currentProcessId);
          this.searchbarService.handleSearchbarPosition(this.searchComponent(), this.searchIsSticky());
          this.cdr.markForCheck();
        }
      },
      { threshold: [1] }
    );
    this.observer.observe(this.searchComponent().nativeElement);
  }

  private initProcessFlowStickyHandling(): void {
    this.processFacade.currentProcessId$
      .pipe(
        tap((processId: ProcessId) => {
          const { y } = this.searchComponent().nativeElement.getBoundingClientRect();
          this.searchIsSticky.set(processId === this.processId && y <= 0);
          this.searchbarService.handleSearchbarPosition(this.searchComponent(), this.searchIsSticky());
          this.cdr.detectChanges();
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private scrollToSearch(): void {
    this.scrollService.scrollToTileId("appointment");
  }
}
