<div class="flex flex-col">
  <form [formGroup]="form">
    @if (displayMode === DisplayMode.INITIAL) {
      <cg-headline
        class="mt-3 block part-[headline]:!font-roboto-condensed part-[headline]:!text-[26px] part-[headline]:!font-bold part-[headline]:!leading-8 part-[headline]:t:!text-[30px] part-[headline]:t:!leading-10"
        [content]="'appointment.appointmentSearch.texts.title' | transloco"
      ></cg-headline>
    }

    <div class="sticky top-0 z-[2] mb-4 block rounded-[32px] bg-white pt-4 t:mb-8">
      @if (displayMode === DisplayMode.INITIAL) {
        <cg-olb-search-for-sc-input
          class="appointment-search--input"
          [appointmentData]="appointmentData"
        ></cg-olb-search-for-sc-input>
      }
      @if (displayMode !== DisplayMode.INITIAL && !appointmentData.isScSelectDisabled) {
        <cg-appointment-filter
          id="appointment-list"
          [isFormTouchedProgrammatically]="isFormTouchedProgrammatically"
          [appointmentData]="appointmentData"
          (filterChanged)="filterChanged()"
        ></cg-appointment-filter>
      }
      @if (appointmentData.isScSelectDisabled) {
        <cg-appointment-filter-static
          id="appointment-list-static"
          [appointmentData]="appointmentData"
        ></cg-appointment-filter-static>
      }
    </div>

    @if ((hasAppointments$ | async) === false && displayMode === DisplayMode.PENDING) {
      <div class="flex justify-center">
        <cg-loading-spinner></cg-loading-spinner>
      </div>
    }
    @if ((hasAppointments$ | async) === false && displayMode !== DisplayMode.PENDING) {
      <div class="flex flex-col items-center justify-center p-8 d:p-6">
        @if (displayMode === DisplayMode.INITIAL && (appointmentData.config | async)?.busy) {
          <cg-icon class="mb-4 block h-[72px] w-[72px]" [content]="busyIcon"></cg-icon>
          <cg-paragraph
            class="max-w-[556px] text-center [&>p:is(a)]:text-cg-anthracite [&>p:is(a)]:underline"
            [content]="{ text: 'appointment.appointmentSearch.texts.busyInfo' | transloco, ngTemplate: 'cgParagraph' }"
          ></cg-paragraph>
        } @else {
          <cg-icon class="appointment-search--icon h-[72px] w-[72px]" [content]="icon"></cg-icon>
          <cg-paragraph
            class="max-w-[556px] text-center [&>p:is(a)]:text-cg-anthracite [&>p:is(a)]:underline"
            [content]="{
              text:
                text
                | transloco
                  : {
                      phone: PhoneNumber.CS_NO_APPOINTMENT_AVAILABLE,
                      plainPhone
                    },
              ngTemplate: 'cgParagraph'
            }"
          ></cg-paragraph>
        }
        @if (displayMode === DisplayMode.NO_APPOINTMENTS) {
          <cg-paragraph class="appointment-search--paragraph">
            {{ "appointment.appointmentSearch.texts.callUsStart" | transloco }}
            <a (click)="tapToCall($event)">{{ PhoneNumber.CS_NO_APPOINTMENT_AVAILABLE }}</a>
            {{ "appointment.appointmentSearch.texts.callUsEnd" | transloco }}
          </cg-paragraph>
        }
        @if (showMobileService) {
          <cg-ctalink
            class="mt-4 block"
            [content]="mobileServiceCta"
            (clickedFn)="goToMobileService($event)"
          ></cg-ctalink>
        }
      </div>
    }
    @if (hasAppointments$ | async) {
      <cg-appointment-list
        class="-mt-10.5 relative z-[1] block part-[appointment-list]:overflow-auto t:-mt-[58px]"
        [ngClass]="{ error: hasAppointmentError }"
        [appointmentData]="appointmentData"
        [formControlName]="'selectedAppointmentId'"
        (mobileService)="goToMobileService($event)"
        (goForwardWithoutAppointment)="onGoForwardWithoutAppointment($event)"
        (listScrolled)="listScrolled()"
      ></cg-appointment-list>
    }
  </form>

  @if (isBookingWithoutAppointmentAllowed) {
    <cg-cta-two-lines
      class="mt-8 block"
      [title]="noAppointmentCtaContent.title"
      [subtitle]="noAppointmentCtaContent.subtitle"
      [icon]="noAppointmentCtaContent.icon"
      (click)="onGoForwardWithoutAppointment($event)"
      data-cy="forwardWithoutAppointment"
    >
    </cg-cta-two-lines>
  }
</div>
