import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, computed, forwardRef, inject, input } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import {
  ControlValueAccessor,
  FormGroup,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule
} from "@angular/forms";
import { HeadlineComponent, ParagraphComponent, ResponsiveBgDirective } from "@cg/cgx-ui/core";
import { BadgeComponent } from "@cg/core/ui";
import { VapsOfferGridItem } from "../../interfaces/vaps-offer-grid-item.interface";

@Component({
  selector: "cg-vaps-offer-grid-item",
  templateUrl: "./vaps-offer-grid-item.component.html",
  styleUrl: "./vaps-offer-grid-item.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, BadgeComponent, HeadlineComponent, ParagraphComponent, ResponsiveBgDirective, ReactiveFormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => VapsOfferGridItemComponent),
      multi: true
    }
  ]
})
export class VapsOfferGridItemComponent implements ControlValueAccessor {
  private readonly parentFormGroup = inject(FormGroupDirective);

  public content = input.required<VapsOfferGridItem>();

  public form: FormGroup = this.parentFormGroup.form;
  public readonly isChecked = computed(() => this.choiceInputValue() === this.content().value);

  private readonly choiceInputValue = toSignal(this.form.controls.choice.valueChanges);

  public onChange = (_value: string) => {};
  public onTouched = () => {};

  public handleItemClick() {
    this.writeValue(this.content().value);
  }

  public writeValue(value: string): void {
    this.form.patchValue({ choice: value });
    this.onChange(value);
  }

  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState?(_isDisabled: boolean): void {}
}
