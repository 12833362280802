<fieldset>
  <legend class="sr-only">{{ "vapsOffer.packages.a11y.legend" | transloco }}</legend>
  <div [formGroup]="formGroupDirective.form" class="flex w-full flex-col gap-4 cgxT:flex-row" cgKeyboardNavigationRoot>
    @for (item of content(); track item.title) {
      <cg-vaps-offer-grid-item
        #gridItem
        class="w-full"
        tabindex="0"
        [content]="item"
        cgKeyboardNavigation
        [dataFocusIndex]="$index"
        [dataCount]="content().length"
        (keyup.space)="gridItem.handleItemClick()"
      ></cg-vaps-offer-grid-item>
    }
  </div>
</fieldset>
