import { GetGdv } from "@cg/shared";
import type { AutomaticDataTransmition } from "@cg/shared";

export const licensePlateAutomaticDataTransmission: AutomaticDataTransmition = {
  radioButton: {
    controlName: "automaticDataTransmission",
    a11y: {
      legend: "licensePlate.gdvDataTransmission.buttons.a11y.legend"
    },
    buttons: [
      {
        title: "licensePlate.gdvDataTransmission.buttons.yes",
        radio: { id: "license-plate-gdv-yes", value: GetGdv.TRANSMISSION }
      },
      {
        title: "licensePlate.gdvDataTransmission.buttons.no",
        radio: { id: "license-plate-gdv-no", value: GetGdv.NO_TRANSMISSION }
      }
    ]
  },
  title: "licensePlate.gdvDataTransmission.title"
};
