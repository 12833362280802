@if (contentCardsCreated) {
  <section>
    <ng-template #master>
      <div class="flex max-w-[776px] flex-col">
        <cg-olb-headline type="h1" data-cy="summary-title" [title]="'summary.title' | transloco"></cg-olb-headline>
        <cg-paragraph [content]="{ text: 'summary.subTitle' | transloco }"></cg-paragraph>
        <div class="mt-4 grid grid-cols-1 gap-x-8 d:mt-6 d:grid-cols-2 d:gap-x-[72px]">
          @for (card of cards; track getUniqueTrackingId(card)) {
            <cg-content-card
              class="border-t border-solid border-gray-300"
              [attr.data-cy]="card.id"
              data-cs-mask
              [content]="card"
            ></cg-content-card>
          }
        </div>
        <div class="mt-0 border-t border-gray-300 d:mt-6">
          <div class="grid grid-cols-1 gap-x-8 d:grid-cols-2 d:gap-x-[72px]">
            @for (card of appointmentCards; track card.id) {
              <cg-content-card
                data-cs-mask
                [content]="card"
                [attr.data-cy]="card.id"
                [contentUnderSublineTemplateRef]="card.id === 'appointmentTimeInfoCard' ? appointmentInfoText : null"
              ></cg-content-card>
            }
          </div>
        </div>

        @if (isOpportunityFunnel) {
          <cg-toast-message
            class="part-[toast-wrapper]:!p-2 [&>div>div>div>cg-icon>mat-icon>svg>path:nth-of-type(1)]:fill-black [&>div>div>div>cg-icon>mat-icon>svg>path:nth-of-type(2)]:stroke-white [&>div>div>div>cg-icon>mat-icon>svg>path:nth-of-type(3)]:stroke-white [&>div>div>div>cg-icon>mat-icon>svg>path]:fill-black [&>div>div>div>cg-icon>mat-icon>svg>path]:stroke-transparent"
            [content]="toastMessage"
          ></cg-toast-message>
        }
      </div>
    </ng-template>

    <cg-split-view [master]="master"></cg-split-view>

    <div
      class="summary--footer sticky -bottom-[1px] z-[2] overflow-hidden rounded-lg rounded-tl-none rounded-tr-none bg-cg-white shadow-[0px_7px_8px_-1px_rgba(0,0,0,0.16)] d:!static"
    >
      <div class="px-4 pb-4 w:px-15 w:pb-10">
        <cg-paragraph
          class="block part-[text]:x-[text-[13px],leading-5]"
          [ngClass]="{ 'part-[text]:x-[my-6]': !isOpportunityFunnel, 'part-[text]:x-[mt-2,mb-6]': isOpportunityFunnel }"
          [content]="{ text: consentText }"
        ></cg-paragraph>
        <cg-olb-forward
          class="block w-full part-[button]:w-full d:w-fit d:part-[button]:w-auto"
          (click)="goForward()"
          [content]="forwardBtnContent"
          data-cy="btn-next"
        ></cg-olb-forward>
      </div>
    </div>
  </section>

  <ng-template #appointmentInfoText>
    <div class="flex flex-col d:flex-row">
      <div>
        <cg-appointment-date-info></cg-appointment-date-info>

        @if (!isOpportunityFunnel && requiredService === "REPLACE" && !isCalibration) {
          <cg-car-bringing-schedule-text></cg-car-bringing-schedule-text>
        }
      </div>
      <cg-richtext
        class="block pt-3 d:hidden"
        [content]="{
          text: serviceCenterText
        }"
      ></cg-richtext>
    </div>
  </ng-template>
}
