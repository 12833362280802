import { DatePipe, NgClass, NgStyle, SlicePipe } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  inject,
  input,
  model,
  OnInit,
  output,
  signal
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { NewAppointmentFacade } from "@cg/olb/state";
import { addDays, getDay, startOfWeek } from "date-fns";
import { BreakpointService, NewAppointmentData, OverlayService } from "@cg/shared";
import { NewAppointmentDetailComponent } from "../new-appointment-detail/new-appointment-detail.component";

// Feature.NEW_APPOINTMENT_TILE
@Component({
  selector: "cg-new-appointment-selection-grid",
  templateUrl: "./new-appointment-selection-grid.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, DatePipe, SlicePipe, NgStyle]
})
export class NewAppointmentSelectionGridComponent implements OnInit {
  private readonly overlayService = inject(OverlayService);
  private readonly appointmentFacade = inject(NewAppointmentFacade);
  private readonly breakpointService = inject(BreakpointService);
  private readonly destroyRef = inject(DestroyRef);

  public appointments = input.required<NewAppointmentData[]>();
  public isDisplayedInAllScDialog = input<boolean>(false);

  public selectedAppointmentId = model<string>(null);

  public selectedAppointmentIdChanged = output<string>();

  public weekDays = computed<Date[]>(() => {
    const firstDay = startOfWeek(new Date(this.appointments()[0].customerAppointmentStart), { weekStartsOn: 1 });
    let days = [firstDay];

    for (let i = 0; i < 5; i++) {
      days = [...days, addDays(days[days.length - 1], 1)];
    }

    return days;
  });

  public appointmentGridItems = computed<NewAppointmentData[][]>(() => {
    const appointmentsPerDay = this.appointments().reduce(
      (acc: NewAppointmentData[][], appointment: NewAppointmentData) => {
        // -1 because getDay returns 0 for sunday
        const day = getDay(new Date(appointment.customerAppointmentStart)) - 1;

        if (day < 0) {
          // prevent crash, when mock data returns sundays
          console.warn("There were appointments on sunday. Those are ignored in UI!");
          return acc;
        }

        acc[day] = [...acc[day], appointment];
        return acc;
      },
      Array.from({ length: 6 }, () => []) as NewAppointmentData[][]
    );

    let maxLength = 0;
    appointmentsPerDay.forEach((day: NewAppointmentData[]) => {
      if (day.length > maxLength) {
        maxLength = day.length;
      }
    });

    // fill up the days with empty slots to get a grid layout
    const appointmentsPerDayFilled: NewAppointmentData[][] = appointmentsPerDay.map((day: NewAppointmentData[]) => {
      const emptySlots: NewAppointmentData[] = Array.from({ length: maxLength - day.length }, () => null);
      return [...day, ...emptySlots];
    });

    // reassemble the array to get the correct grid layout (days as columns)
    let result: NewAppointmentData[][] = [];
    for (let i = 0; i < maxLength; i++) {
      result = [...result, appointmentsPerDayFilled.map((day: NewAppointmentData[]) => day[i])];
    }

    return result;
  });

  public maxAppointmentsPerDay = 0;
  public isMobile = false;

  public ngOnInit(): void {
    this.breakpointService.isMobile$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isMobile: boolean) => {
      this.isMobile = isMobile;
    });
  }

  public bookAppointment(appointmentId: string, serviceCenterId: string): void {
    this.overlayService.close();
    this.appointmentFacade.setSelectedAppointment(appointmentId, serviceCenterId);
    this.overlayService.open(NewAppointmentDetailComponent, {
      isParentAllScDialog: signal(this.isDisplayedInAllScDialog())
    });
  }

  public selectAppointment(appointmentId: string): void {
    this.selectedAppointmentId.set(appointmentId);

    this.selectedAppointmentIdChanged?.emit(appointmentId);
  }
}
