/// <reference types="@types/googlemaps" />

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  input
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ControlContainer, FormGroupDirective } from "@angular/forms";
import { filter, take, tap } from "rxjs/operators";
import { NewAppointmentFacade } from "@cg/olb/state";
import { Icon } from "@cg/content-api/typescript-interfaces";
import { IS_BROWSER_PLATFORM } from "@cg/core/utils";
import { inputClearIcon, searchIcon } from "@cg/icon";
import {
  AppointmentCoordinates,
  ComponentOverarchingChangeDetectionService,
  GooglePlacesInputComponent,
  LocationAutocompleteResult
} from "@cg/shared";

@Component({
  selector: "cg-new-appointment-search-location-input",
  templateUrl: "./new-appointment-search-location-input.component.html",
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [GooglePlacesInputComponent]
})
export class NewAppointmentSearchLocationInputComponent implements AfterViewInit {
  private readonly destroyRef = inject(DestroyRef);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly cdrService = inject(ComponentOverarchingChangeDetectionService);
  private readonly isBrowser: boolean = inject(IS_BROWSER_PLATFORM);
  private readonly appointmentFacade = inject(NewAppointmentFacade);

  public icon = input<Icon>(searchIcon);
  public clearIcon = input<Icon>(inputClearIcon);

  public ngAfterViewInit(): void {
    if (!this.isBrowser) {
      return;
    }

    this.initListenToExternalChanges();
  }

  public handleAutocompleteResult({ lat, lng, address, locality }: LocationAutocompleteResult) {
    this.appointmentFacade.searchLocation$
      .pipe(
        take(1),
        filter((position: AppointmentCoordinates) => position.lat !== lat && position.lng !== lng),
        tap(() => {
          this.appointmentFacade.setSearchLocation({ lat, lng, formattedAddress: address, locality });
          this.appointmentFacade.getServiceCenters();
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private initListenToExternalChanges(): void {
    this.cdrService.changeDetectionRequest$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.cdr.markForCheck());
  }
}
