import { AsyncPipe, NgClass, NgTemplateOutlet } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { BehaviorSubject, combineLatest, Observable, of, Subject } from "rxjs";
import { map, withLatestFrom } from "rxjs/operators";
import { AppointmentFacade, CustomerCaseFacade, DamageFacade, ProcessFacade } from "@cg/olb/state";
import { ResumeFacade } from "@cg/resume-core";
import { TranslocoPipe } from "@jsverse/transloco";
import { TrackingService } from "@cg/analytics";
import { AppointmentDetailComponent } from "@cg/appointment-ui";
import { AddFormControls } from "@cg/core/types";
import { PLACEHOLDER } from "@cg/core/ui";
import { EnvironmentService } from "@cg/environments";
import {
  ChannelSwitchReason,
  ChooseServiceCenterExitIds,
  OLB_PROCESS_FLOW_MODEL,
  OlbFooterComponent,
  OlbHeadlineComponent,
  ProcessFlow,
  ScrollService
} from "@cg/olb/shared";
import {
  Appointment,
  AppointmentData,
  AptModel,
  AvailableServiceCenter,
  BrandingComponent,
  BreakpointService,
  JobStatus,
  SetAppointmentPayload,
  SplitViewComponent
} from "@cg/shared";
import { ExitNodeResolverService } from "../../services/exit-node-resolver.service";
import { BaseDirective } from "../core/directives/base/base.directive";
import { TeslaAppointmentMapComponent } from "./components/tesla-appointment-map/tesla-appointment-map.component";
import { TeslaAppointmentSearchComponent } from "./components/tesla-appointment-search/tesla-appointment-search.component";
import { TeslaAppointmentForm } from "./interfaces/tesla-appointment-form.interface";

@Component({
  selector: "cg-tesla-appointment",
  templateUrl: "./tesla-appointment.component.html",
  styleUrls: ["./tesla-appointment.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    NgTemplateOutlet,
    AsyncPipe,
    TranslocoPipe,
    ReactiveFormsModule,
    AppointmentDetailComponent,
    TeslaAppointmentMapComponent,
    OlbHeadlineComponent,
    BrandingComponent,
    TeslaAppointmentSearchComponent,
    SplitViewComponent,
    OlbFooterComponent
  ]
})
export class TeslaAppointmentComponent extends BaseDirective<AddFormControls<TeslaAppointmentForm>> implements OnInit {
  public appointmentData: AppointmentData;
  public savedEvent = new Subject<boolean>();
  public count: string;
  public numberOfScs: number;
  @ViewChild("teslaAppointmentSearch") public teslaSearch: TeslaAppointmentSearchComponent;

  // eslint-disable-next-line max-params
  public constructor(
    protected readonly cdr: ChangeDetectorRef,
    protected readonly processFacade: ProcessFacade,
    protected readonly exitNodeResolver: ExitNodeResolverService,
    protected readonly scrollService: ScrollService,
    protected readonly trackingService: TrackingService,
    private readonly appointmentFacade: AppointmentFacade,
    private readonly damageFacade: DamageFacade,
    private readonly customerCaseFacade: CustomerCaseFacade,
    private readonly resumeFacade: ResumeFacade,
    private readonly breakpointService: BreakpointService,
    private readonly environmentService: EnvironmentService,
    @Inject(OLB_PROCESS_FLOW_MODEL) processFlow: ProcessFlow
  ) {
    super(cdr, processFacade, exitNodeResolver, trackingService, scrollService, processFlow);
    this.setAppointmentData();
  }

  public get activeVapsOffer(): boolean {
    return this.environmentService.env.features.vaps.active;
  }

  public async ngOnInit() {
    await super.ngOnInit();

    this.appointmentFacade.getServiceCenters({
      aptModel: AptModel.TESLA,
      timerangeBegin: new Date().toISOString()
    });

    combineLatest([this.appointmentData.appointmentId$, this.appointmentData.selectedServiceCenterIds$])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([appointmentId, serviceCenterIds]: [string, string[]]) => {
        this.form.controls.scId.setValue(serviceCenterIds?.[0] ?? null);
        this.form.controls.selectedAppointmentId.setValue(appointmentId);

        this.cdr.markForCheck();
        this.cdr.detectChanges();
      });

    this.appointmentData.availableServiceCenters$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((serviceCenters: AvailableServiceCenter[]) => {
        this.numberOfScs = serviceCenters?.length ?? 16;
        this.cdr.markForCheck();
      });
  }

  public get isWidescreen$(): Observable<boolean> {
    return this.breakpointService.isWidescreen$;
  }

  public get isScSelected(): boolean {
    return (
      this.form.controls.scId.value === "" ||
      this.form.controls.scId.value === PLACEHOLDER ||
      this.form.controls.scId.value === null
    );
  }

  public initFormGroup(): void {
    this.form = new FormGroup({
      selectedAppointmentId: new FormControl<string>("", Validators.required),
      scId: new FormControl<string>("", Validators.required)
    });
  }

  public saveForm(): void {
    this.savedEvent.next(true);
  }

  public forwardBtnClicked(): void {
    this.checkFormErrors();
    this.goForward();
  }

  public checkFormErrors(): void {
    if (this.form.controls.scId.value === "" || this.form.controls.scId.value === PLACEHOLDER) {
      this.form.controls.scId.setValue(null);
    }

    if (this.form.controls.selectedAppointmentId.invalid) {
      this.form.controls.selectedAppointmentId.setValue(null);
    }
  }

  public setAppointmentData(): void {
    this.appointmentData = {
      appointmentId$: this.appointmentFacade.appointmentId$,
      availableAppointments$: this.appointmentFacade.availableAppointments$,
      selectedServiceCenter$: this.appointmentFacade.selectedServiceCenter$,
      selectedServiceCenterIds$: this.appointmentFacade.selectedServiceCenterIds$,
      availableServiceCenters$: this.appointmentFacade.availableServiceCenters$,
      isCalibration$: this.appointmentFacade.isCalibration$,
      requiredService$: this.damageFacade.requiredService$,
      formattedAddress$: this.appointmentFacade.formattedAddress$,
      locality$: this.appointmentFacade.locality$,
      position$: this.appointmentFacade.position$,
      mobileServiceAvailable$: this.appointmentFacade.mobileServiceAvailable$,
      hasAdverseBuyAppointments$: this.appointmentFacade.hasAdverseBuyAppointments$,
      confirmed$: this.appointmentFacade.confirmed$,
      appointmentNextLoading$: this.appointmentFacade.appointmentNextLoading$,
      nextLoadingLimitReached$: this.appointmentFacade.nextLoadingLimitReached$,
      isLoading$: this.appointmentFacade.isLoading$,
      customerCaseId$: this.customerCaseFacade.customerCaseId$,
      damageChipCount$: this.damageFacade.damageChipCount$,
      reloadAppointments$: new BehaviorSubject<boolean>(false),
      resumeResponse$: this.resumeFacade.resumeResponse$,
      setStatus: (status: JobStatus) => {
        this.appointmentFacade.setStatus(status);
      },
      resetStateForForm: () => {
        this.appointmentFacade.resetStateForForm();
      },
      confirmAppointment: (appointment: SetAppointmentPayload) => {
        this.appointmentFacade.confirmAppointment(appointment);
      },
      setChannelSwitchReason: (reason: ChannelSwitchReason) => {
        this.processFacade.setChannelSwitchReason(reason);
      },
      setAppointmentId: (appointmentId: string) => {
        this.appointmentFacade.setAppointmentId(appointmentId);
      },
      setSelectedServiceCenterIds: (serviceCenterIds: string[]) => {
        this.appointmentFacade.setSelectedServiceCenterIds(serviceCenterIds);
      },
      clearAndRefetchAppointments: (serviceCenterId: string) => {
        this.appointmentFacade.fetchTeslaAppointments(serviceCenterId);
      },
      setNextLoadingLimitReached: (limitReached: boolean) => {
        this.appointmentFacade.setNextLoadingLimitReached(limitReached);
      },
      setMobileServiceAvailable: (isAvailable: boolean) => {
        this.appointmentFacade.setMobileServiceAvailable(isAvailable);
      },
      getNextAppointments: (date: string, aptModel: AptModel = AptModel.TESLA) => {
        this.appointmentFacade.getNextAppointments(date, aptModel);
      },
      saveAutocompleteResult: (lat: number, lng: number, address: string, locality: string) => {
        this.appointmentFacade.saveAutocompleteResult({ lat, lng, address, locality });
      },
      reloadAppointments: () => {
        this.appointmentFacade.reloadAppointments();
      }
    };
  }

  public getExitIdForSavedForm(): Observable<ChooseServiceCenterExitIds> {
    if (this.activeVapsOffer) {
      return of("vapsOffer");
    }

    return of("emailQuery");
  }

  public setFormValues(): void {}

  public get hasSelectedAppointment$(): Observable<boolean> {
    return this.appointmentData.appointmentId$.pipe(
      withLatestFrom(this.appointmentData.availableAppointments$),
      map(
        ([appointmentId, availableAppointments]: [string, Appointment[]]) =>
          !!availableAppointments?.find((appointment: Appointment) => appointment.appointmentId === appointmentId)
      ),
      takeUntilDestroyed(this.destroyRef)
    );
  }
}
