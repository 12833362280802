<section>
  <ng-template #master>
    @if (form) {
      <form [formGroup]="form">
        <cg-olb-headline
          class="relative mb-[18px] block part-[headline]:t-only:!text-2xl"
          [title]="'damagesSizeMultipleChips.title' | transloco"
        ></cg-olb-headline>
        <div class="relative flex h-full flex-col items-start d:flex-row">
          <div class="mb-4 w-full bg-cg-gray-light-70 text-center d:mb-0 d:w-1/2">
            <div class="flex flex-col items-center justify-center gap-4 p-2 t:flex-row">
              <cg-icon class="h-36 w-52" [content]="picture"></cg-icon>
              <span class="t:-ml-8">
                {{ "damagesSizeMultipleChips.imageLabel" | transloco }}
              </span>
            </div>
          </div>
          <cg-radio-button-group
            class="flex w-full d:ml-6 d:!w-1/2 [&_.radio-button-headline]:!border-none"
            [content]="radioButtonGroup"
            [formControlName]="radioButtonGroup.controlName"
            (selectedValueChanged)="onSelectedValueChanged()"
          ></cg-radio-button-group>
        </div>
      </form>
    }
  </ng-template>

  <cg-split-view [master]="master"></cg-split-view>
</section>
