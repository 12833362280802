import { AsyncPipe, NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, computed, inject, signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { combineLatest } from "rxjs";
import { take } from "rxjs/operators";
import { OLB_CONFIG, OlbConfiguration } from "@cg/olb/configuration";
import { DamageFacade, InsuranceFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { IconComponent, ParagraphComponent } from "@cg/core/ui";
import { arrowRedIcon } from "@cg/icon";
import { InsuranceType, OlbHeadlineComponent } from "@cg/olb/shared";
import { BaseButtonComponent, BreakpointService, CtaComponent, RequiredService } from "@cg/shared";

@Component({
  selector: "cg-qualtrics-teaser",
  templateUrl: "./qualtrics-teaser.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    AsyncPipe,
    TranslocoPipe,
    BaseButtonComponent,
    OlbHeadlineComponent,
    ParagraphComponent,
    CtaComponent,
    IconComponent
  ]
})
export class QualtricsTeaserComponent {
  private readonly breakpointService = inject(BreakpointService);
  private readonly insuranceFacade = inject(InsuranceFacade);
  private readonly damageFacade = inject(DamageFacade);
  private readonly olbConfig: OlbConfiguration = inject(OLB_CONFIG);

  public readonly pinnedByIntersectionRatio = signal<boolean>(false);

  public readonly isPinned = computed<boolean>(() => (this.isDesktop() ? false : this.pinnedByIntersectionRatio()));

  public readonly arrowRedIcon = arrowRedIcon;

  private readonly isDesktop = toSignal(this.breakpointService.isDesktop$);
  private readonly QUALTRICS_LINK: string = "https://belron.eu.qualtrics.com/jfe/form/SV_4G9iMnsonW7R0kC";

  public linkClicked(event: Event) {
    event.stopPropagation();
    event.preventDefault();

    combineLatest([this.insuranceFacade.type$, this.damageFacade.requiredService$, this.breakpointService.isTouch$])
      .pipe(take(1))
      .subscribe(([insuranceType, requiredService, isTouch]: [InsuranceType, RequiredService, boolean]) => {
        const urlParams = new URLSearchParams();
        urlParams.append("entryChannel", this.olbConfig.entryChannel.toLowerCase());
        urlParams.append("insuranceType", insuranceType.toLowerCase());
        urlParams.append("requiredService", requiredService.toLowerCase());
        urlParams.append("viewport", isTouch ? "mobile" : "desktop");
        urlParams.append("abTestVariation", sessionStorage.getItem("qualtrics_abTest_variationId") || "null");

        window.open(`${this.QUALTRICS_LINK}?${urlParams.toString()}`, "_blank");
      });
  }
}
