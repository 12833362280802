<cg-drawer [content]="content" [headline]="headline" [showCloseIcon]="true" [buttons]="buttons"> </cg-drawer>

<ng-template #headline>
  <cg-olb-headline
    [type]="headlineType.h1"
    [title]="'newAppointment.scSelectDialog.title' | transloco: { city: locality$ | async }"
  ></cg-olb-headline>
  <cg-paragraph>{{ "newAppointment.multiSelect.selectAll.subtitle" | transloco }}</cg-paragraph>
</ng-template>

<ng-template #content>
  <form [formGroup]="form">
    <cg-new-appointment-multi-select
      formControlName="selectedSc"
      [items]="offeredServiceCenters()"
    ></cg-new-appointment-multi-select>
  </form>
</ng-template>

<ng-template #buttons>
  <div class="flex w-full flex-row gap-4">
    <button
      cg-base-button
      class="w-full part-[icon-text-wrapper]:px-4"
      [btnText]="'newAppointment.scSelectDialog.buttons.ok' | transloco"
      (click)="clickedOk()"
    ></button>
  </div>
</ng-template>
