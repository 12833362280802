import { AsyncPipe, DatePipe, NgClass } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ReactiveFormsModule } from "@angular/forms";
import { combineLatest, Observable } from "rxjs";
import { filter, map, take, tap, withLatestFrom } from "rxjs/operators";
import { Feature, FeatureToggleFacade } from "@cg/feature-toggle";
import { OLB_CONFIG, OlbConfiguration } from "@cg/olb/configuration";
import {
  AppointmentFacade,
  ContactDataFacade,
  CustomerCaseFacade,
  DamageFacade,
  GdvFacade,
  ProcessFacade,
  ProductFacade
} from "@cg/olb/state";
import { appointmentElements, AppointmentElementsInterface } from "@cg/translation";
import { TranslocoPipe } from "@jsverse/transloco";
import { CookieService } from "ngx-cookie-service";
import { OptimizelyActiveEvents, OptimizelyService, TrackingService } from "@cg/analytics";
import {
  AppointmentDateInfoComponent,
  AppointmentService,
  CarBringingScheduleTextTextComponent
} from "@cg/appointment-ui";
import { SharedFacade } from "@cg/carglass-shared-state";
import { List } from "@cg/content-api/typescript-interfaces";
import { FakeDropdownComponent, IconComponent, ParagraphComponent } from "@cg/core/ui";
import { ABTest } from "@cg/core/utils";
import { EnvironmentService } from "@cg/environments";
import { successIcon } from "@cg/icon";
import {
  ExitIds,
  isDirectResumeFn,
  isTesla,
  OlbHeadlineComponent,
  Product,
  ScrollService,
  VAPsPackage
} from "@cg/olb/shared";
import {
  AdditionalProduct,
  Appointment,
  BrandingComponent,
  CccPhoneInfoComponent,
  Ctalink,
  CtalinkComponent,
  ListComponent,
  Lpn,
  PhoneNumber,
  RequiredService
} from "@cg/shared";
import { OptimizelyExperiment } from "@cg/core/enums";
import { ExitNodeResolverService } from "../../services/exit-node-resolver.service";
import { BaseDirective } from "../core/directives/base/base.directive";
import { QualtricsTeaserComponent } from "./components/qualtrics-teaser/qualtrics-teaser.component";
import { WiperBookingComponent } from "./components/wiper-booking/wiper-booking.component";
import { AppointmentSummary } from "./interfaces/appointment-summary.interface";
import { appointmentLinks } from "./models/appointment-links.model";

@ABTest(OptimizelyExperiment.OLB_CONFIRMATION_CONTENT_RELEVANCY)
@Component({
  selector: "cg-appointment-confirmation",
  templateUrl: "./appointment-confirmation.component.html",
  styleUrls: ["./appointment-confirmation.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    AsyncPipe,
    TranslocoPipe,
    ReactiveFormsModule,
    BrandingComponent,
    IconComponent,
    OlbHeadlineComponent,
    ParagraphComponent,
    CarBringingScheduleTextTextComponent,
    CtalinkComponent,
    ListComponent,
    CccPhoneInfoComponent,
    QualtricsTeaserComponent,
    AppointmentDateInfoComponent,
    QualtricsTeaserComponent,
    FakeDropdownComponent,
    WiperBookingComponent
  ],
  providers: [DatePipe]
})
export class AppointmentConfirmationComponent extends BaseDirective<null> implements OnInit, AfterViewInit, OnDestroy {
  protected readonly cdr = inject(ChangeDetectorRef);
  protected readonly processFacade = inject(ProcessFacade);
  protected readonly exitNodeResolver = inject(ExitNodeResolverService);
  protected readonly scrollService = inject(ScrollService);
  protected readonly trackingService = inject(TrackingService);
  private readonly damageFacade = inject(DamageFacade);
  private readonly contactDataFacade = inject(ContactDataFacade);
  private readonly appointmentFacade = inject(AppointmentFacade);
  private readonly gdvFacade = inject(GdvFacade);
  private readonly datePipe = inject(DatePipe);
  private readonly appointmentService = inject(AppointmentService);
  private readonly optimizelyService = inject(OptimizelyService);
  private readonly sharedFacade = inject(SharedFacade);
  private readonly customerCaseFacade = inject(CustomerCaseFacade);
  private readonly cookieService = inject(CookieService);
  private readonly _olbConfig: OlbConfiguration = inject(OLB_CONFIG);
  private readonly productFacade = inject(ProductFacade);
  private readonly featureToggleFacade = inject(FeatureToggleFacade);
  private readonly environmentService = inject(EnvironmentService);

  @Input()
  public readonly phoneNumber: PhoneNumber = PhoneNumber.APPOINTMENT;

  @ViewChild("qualtricsTeaser", { static: false, read: QualtricsTeaserComponent })
  public qualtricsTeaser: QualtricsTeaserComponent;

  @ViewChild("qualtricsTeaser", { static: false, read: ElementRef })
  public qualtricsTeaserEl: ElementRef;
  public readonly showCompanyData = this.environmentService.env.features.showCompanyDataOnAppointmentConfirmation;
  public readonly successIcon = successIcon;
  public appointment: Appointment;
  public appointmentSummary: AppointmentSummary = null;
  public appointmentLinks: { saveToCalendarCtaLink: Ctalink; navigateWithGoogleMaps: Ctalink } = appointmentLinks;
  public appointmentElements: AppointmentElementsInterface = appointmentElements;
  public requiredService: RequiredService;
  public isCalibration: boolean;
  public showDamageDate$: Observable<boolean>;
  public documents$: Observable<List>;
  public requiredServiceIsReplace$: Observable<boolean>;
  public isDirectResume = false;
  public driverHeadingTextKey = "";
  public isTesla = false;
  public teslaModel = "";
  public isTouch: boolean;
  public bookedProtect$ = this.customerCaseFacade.protectProduct$;
  public bookedWiper$ = this.customerCaseFacade.wiperProduct$;
  public vapsPackage$ = this.customerCaseFacade.vapsPackage$;

  public isConfirmationContentRelevancyTestActive = false;
  public showDetailsWasClicked = false;
  public featureFlagWiperActive$ = this.featureToggleFacade.isFeatureActive$(Feature.WIPER_OFFER_ON_CONFIRMATION);

  public showWiperBooking$ = this.featureFlagWiperActive$.pipe(
    withLatestFrom(this.vapsPackage$),
    map(([wiperActive, vapsPackage]: [boolean, VAPsPackage]) => wiperActive && vapsPackage !== VAPsPackage.PREMIUM)
  );

  private _intersectionObserver: IntersectionObserver;

  public async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.isDirectResume = isDirectResumeFn(this._olbConfig.entryChannel);

    this.showDamageDate$ = this.gdvFacade.useNoDate$.pipe(
      take(1),
      map((useNoDate: boolean) => !useNoDate && !this.isDirectResume)
    );

    const firstPart = "appointmentConfirmation.texts";
    this.driverHeadingTextKey = this.isDirectResume ? `${firstPart}.driverHukDirectResume` : `${firstPart}.driver`;

    this.trackVAPsBooking();
    this.setRequiredServiceStatus();
    this.createAppointmentSummary();
    this.processFacade.setUrlToNavigateAfterExit(null);
    this.processFacade.exitOlb();
    this.trackOptimizelyEvents();
    this.cookieService.delete("resumeId");
    this.checkForTesla();

    this.documents$ = this.showDamageDate$.pipe(
      take(1),
      map((showDamageDate: boolean) =>
        showDamageDate ? appointmentElements.mandatoryDocuments : appointmentElements.mandatoryDocumentsNoDamageDate
      )
    );

    this.optimizelyService
      .isVariationOfExperimentActive(OptimizelyExperiment.OLB_CONFIRMATION_CONTENT_RELEVANCY)
      .subscribe((active: boolean) => {
        this.isConfirmationContentRelevancyTestActive = active;
        this.cdr.detectChanges();
      });
  }

  public ngAfterViewInit(): void {
    this._intersectionObserver = new IntersectionObserver(
      ([e]: [IntersectionObserverEntry]) => {
        this.qualtricsTeaser.pinnedByIntersectionRatio.set(e.intersectionRatio < 1);
      },
      { threshold: [1] }
    );

    this._intersectionObserver.observe(this.qualtricsTeaserEl.nativeElement);
  }

  public ngOnDestroy() {
    this._intersectionObserver?.disconnect();
  }

  private trackOptimizelyEvents(): void {
    combineLatest([
      this.optimizelyService.isVariationOfExperimentActive(OptimizelyExperiment.LPN_ON_STAGE),
      this.sharedFacade.lpn$
    ])
      .pipe(
        filter(
          ([isExperimentActive, lpn]: [boolean, Lpn]) =>
            !!isExperimentActive && lpn?.region !== null && lpn?.letters !== null
        ),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.optimizelyService.trackEvent(OptimizelyActiveEvents.APPOINTMENT_CONFIRMATION_LPN_ON_STAGE);
      });

    this.optimizelyService
      .isVariationOfExperimentActive(OptimizelyExperiment.BUSY_BANNER)
      .pipe(
        filter((isActive: boolean) => !!isActive),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.optimizelyService.trackEvent(OptimizelyActiveEvents.APPOINTMENT_CONFIRMATION_BUSY_BANNER);
      });

    this.optimizelyService.isOptimizelyInitialized$
      .pipe(
        take(1),
        filter((v: boolean) => v),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.optimizelyService.trackEvent(OptimizelyActiveEvents.APPOINTMENT_CONFIRMATION);
      });

    this.optimizelyService
      .isVariationOfExperimentActive(OptimizelyExperiment.OLB_CONFIRMATION_CONTENT_RELEVANCY)
      .pipe(
        filter((isActive: boolean) => !!isActive),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.optimizelyService.trackEvent(OptimizelyActiveEvents.OLB_CONFIRMATION_CONTENT_RELEVANCY);
      });
  }

  private trackVAPsBooking(): void {
    this.bookedProtect$
      .pipe(
        take(1),
        filter((bookedProtect: boolean) => bookedProtect),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => this.customerCaseFacade.bookVAPSuccess(AdditionalProduct.PROTECT));

    this.bookedWiper$
      .pipe(
        take(1),
        filter((bookedWiper: boolean) => bookedWiper),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => this.customerCaseFacade.bookVAPSuccess(AdditionalProduct.WIPER));

    combineLatest([this.vapsPackage$, this.bookedProtect$, this.bookedWiper$])
      .pipe(
        filter(
          ([_vapsPackage, bookedProtect, bookedWiper]: [VAPsPackage, boolean, boolean]) =>
            !!bookedProtect || !!bookedWiper
        ),
        take(1),
        map(([vapsPackage, _bookedProtect, _bookedWiper]: [VAPsPackage, boolean, boolean]) => vapsPackage),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((vapsPackage: VAPsPackage) =>
        this.trackingService.trackEvent({
          eventCategory: "olb",
          eventAction: "vaps-package-booking",
          eventLabel: vapsPackage ?? "single"
        })
      );
  }

  public getExitIdForSavedForm(): Observable<ExitIds> | undefined {
    return undefined;
  }

  public initFormGroup(): void {}

  public saveForm(): void {}

  public setFormValues(): void {}

  public setRequiredServiceStatus(): void {
    this.damageFacade.requiredService$
      .pipe(
        filter((requiredService: RequiredService) => !!requiredService),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((requiredService: RequiredService) => (this.requiredService = requiredService));
  }

  public createAppointmentSummary(): void {
    combineLatest([
      this.appointmentFacade.currentAppointment$,
      this.appointmentFacade.isCalibration$,
      this.damageFacade.rawLpn$,
      this.damageFacade.damageDate$,
      this.contactDataFacade.driverFirstname$,
      this.contactDataFacade.driverLastname$,
      this.contactDataFacade.driverStreet$,
      this.contactDataFacade.driverZip$,
      this.contactDataFacade.driverCity$,
      this.contactDataFacade.email$,
      this.contactDataFacade.mobile$,
      this.contactDataFacade.insuranceHolderCompany$,
      this.contactDataFacade.insuranceHolderFirstname$,
      this.contactDataFacade.insuranceHolderLastname$,
      this.contactDataFacade.insuranceHolderStreet$,
      this.contactDataFacade.insuranceHolderZip$,
      this.contactDataFacade.insuranceHolderCity$,
      this.contactDataFacade.insuranceHolderPhone$
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        ([
          appointment,
          isCalibration,
          lpn,
          damageDate,
          driverFirstName,
          driverLastName,
          driverStreet,
          driverZip,
          driverCity,
          email,
          phone,
          insuranceHolderCompany,
          insuranceHolderFirstname,
          insuranceHolderLastname,
          insuranceHolderStreet,
          insuranceHolderZip,
          insuranceHolderCity,
          insuranceHolderPhone
        ]: [
          Appointment,
          boolean,
          string,
          string,
          string,
          string,
          string,
          string,
          string,
          string,
          string,
          string,
          string,
          string,
          string,
          string,
          string,
          string
        ]) => {
          this.appointment = appointment;
          this.isCalibration = isCalibration;
          this.appointmentSummary = {
            appointmentDay: this.datePipe.transform(appointment.customerAppointmentStart, "EEEE"),
            appointmentDate: this.datePipe.transform(appointment.customerAppointmentStart, "dd.MM.yyyy"),
            appointmentTime: this.datePipe.transform(appointment.customerAppointmentStart, "HH:mm"),
            serviceCenterStreet: appointment.street,
            serviceCenterZip: appointment.postalCode,
            serviceCenterCity: appointment.city,
            lpn: lpn.toUpperCase(),
            damageDate: this.formatDamageDate(damageDate),
            driverFirstName,
            driverLastName,
            driverStreet,
            driverZip,
            driverCity,
            email,
            phone,
            insuranceHolderCompany,
            insuranceHolderFirstname,
            insuranceHolderLastname,
            insuranceHolderStreet,
            insuranceHolderZip,
            insuranceHolderCity,
            insuranceHolderPhone
          };
          this.appointmentLinks.saveToCalendarCtaLink.link.href =
            this.appointmentService.buildCalendarHref(appointment);
          this.appointmentLinks.navigateWithGoogleMaps.link.href = this.appointmentService.buildGoogleMapsRouteHref(
            appointment.street,
            appointment.city,
            false
          );
        }
      );
  }

  public createServiceCenterAddressInfo(): string {
    return `Carglass<sup>®</sup>-Service-Center<br>${this.appointmentSummary.serviceCenterStreet}<br>${this.appointmentSummary.serviceCenterZip} ${this.appointmentSummary.serviceCenterCity}`;
  }

  public createDriverContactInfo(): string {
    if (
      !this.appointmentSummary.driverStreet &&
      !this.appointmentSummary.driverZip &&
      !this.appointmentSummary.driverCity
    ) {
      return `${this.appointmentSummary.driverFirstName} ${this.appointmentSummary.driverLastName}`;
    }

    return `${this.appointmentSummary.driverFirstName} ${this.appointmentSummary.driverLastName}<br>${this.appointmentSummary.driverStreet}<br>${this.appointmentSummary.driverZip} ${this.appointmentSummary.driverCity}`;
  }

  public createCompanyContactInfo(): string {
    const contactInfo = `${this.appointmentSummary.insuranceHolderCompany}<br>${this.appointmentSummary.insuranceHolderFirstname} ${this.appointmentSummary.insuranceHolderLastname}<br>${this.appointmentSummary.insuranceHolderStreet}<br>${this.appointmentSummary.insuranceHolderZip} ${this.appointmentSummary.insuranceHolderCity}`;

    if (this.appointmentSummary.insuranceHolderPhone) {
      return `${contactInfo}<br>${this.appointmentSummary.insuranceHolderPhone}`;
    }

    return contactInfo;
  }

  public checkForTesla() {
    this.productFacade.products$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((products: Product[]) => products?.length > 0),
        take(1),
        tap((products: Product[]) => {
          this.isTesla = isTesla(products);

          if (this.isTesla) {
            this.teslaModel = products[0].model;
          }

          this.cdr.detectChanges();
        })
      )
      .subscribe();
  }

  public showAppointmentDetails(): void {
    this.showDetailsWasClicked = true;
  }

  private formatDamageDate(damageDate: string): string {
    if (!damageDate) {
      return "";
    }

    // avoid current browser locale tampering with the date new using new Date() string parsing
    const [year, month, day] = damageDate.split("-");
    return `${day}.${month}.${year}`;
  }
}
