<ng-container [formGroup]="form">
  <ng-container>
    <cg-olb-headline
      title="{{ 'vehicleIdentificationNumber.vin.headline' | transloco }}"
      type="h2"
      styleType="h3"
    ></cg-olb-headline>

    <cg-paragraph
      class="mb-4 block"
      [content]="{ text: 'vehicleIdentificationNumber.subHeadline' | transloco }"
    ></cg-paragraph>

    <div class="m-image max-w-content">
      <cg-picture [content]="vinPicture" [shouldTranslateAlt]="true"></cg-picture>
    </div>
  </ng-container>
  <div class="flex w-full max-w-content flex-col text-xs">
    <div class="mb-4 flex flex-1 flex-row d:mb-2">
      <div class="mr-2 w-full max-w-[472px] d:mr-4 d:w-auto">
        <cg-text-input [content]="input.vin" [formControlName]="'vin'"></cg-text-input>
      </div>
      <div class="block w-16">
        <cg-text-input
          class="block w-16 part-[input]:!pr-4 part-[input]:uppercase"
          [content]="input.checksum"
          [formControlName]="'checksum'"
          [doneIcon]="noIcon"
          [errorIcon]="noIcon"
          [specificInputType]="InputType.NUMBERS_ONLY"
        ></cg-text-input>
      </div>
    </div>
  </div>

  @if (hasVinGroupError || hasChecksumError) {
    <cg-error-message [errorMessage]="'vehicleIdentificationNumber.vin.errorMessage' | transloco"> </cg-error-message>
  }

  <div>
    <cg-olb-headline
      class="headline m-h3 block [&>h3]:!text-[13px] [&>h3]:text-cg-anthracite-light-30"
      [type]="'h3'"
      [title]="'vehicleIdentificationNumber.hint.title' | transloco"
    ></cg-olb-headline>
    <cg-list
      class="text-[13px] text-cg-anthracite-light-30 [&>ul>li:not(:last-child)]:mb-1 [&>ul>li:not(:last-child)]:d:-mb-0.5 [&>ul>li>cg-icon>mat-icon>svg_#Path]:fill-cg-anthracite-light-30 [&>ul>li>cg-icon]:pr-0"
      [content]="hintList"
      [shouldTranslateItem]="true"
    ></cg-list>
  </div>
</ng-container>
