<div
  class="flex w-full cursor-pointer flex-col bg-cg-gray-light-80 px-4 py-3 transition-all duration-300"
  [ngClass]="{
    'xs:-mx-6 t:max-d:-mx-12 -mx-8 w-screen !bg-white/95 shadow-[0px_4px_8px_8px_rgba(0,0,0,0.25)]': isPinned()
  }"
  (click)="linkClicked($event)"
>
  <div class="t:max-d:mx-2 flex place-items-center justify-between gap-2 d:justify-center d:gap-6">
    <cg-paragraph
      class="part-[text]:text-[13px] d:part-[text]:text-base"
      [content]="'qualtricsTeaser.title' | transloco"
    ></cg-paragraph>
    <button
      class="hidden d:block [&>div>span]:whitespace-nowrap [&>div>span]:text-[13px] [&>div]:h-8 [&>div]:px-3"
      cg-base-button
      [btnText]="'qualtricsTeaser.btnText' | transloco"
      (click)="linkClicked($event)"
    ></button>
    <cg-icon class="size-6 d:!hidden" [content]="arrowRedIcon"></cg-icon>
  </div>
</div>
