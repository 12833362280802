<section class="license-plate-container">
  <cg-split-view [master]="master"></cg-split-view>
</section>

<ng-template #master>
  <div class="license-plate">
    <cg-olb-headline
      class="license-plate--headline mb-4 t:mb-6"
      [title]="'opportunityFunnelLicensePlate.title' | transloco"
    ></cg-olb-headline>

    <form [formGroup]="form">
      <div class="mb-4 t:mb-6">
        <cg-olb-headline
          class="license-plate--lpn-title mb-2 [&>h3]:!mb-0 [&>p]:!mt-0 [&>p]:mt-0 [&>p]:!text-[13px]"
          [title]="'opportunityFunnelLicensePlate.lpnTitle' | transloco"
          [subtitle]="'opportunityFunnelLicensePlate.lpnSubtitle' | transloco"
          type="h3"
        ></cg-olb-headline>

        <cg-lpn-input></cg-lpn-input>
      </div>
    </form>

    <div class="license-plate--ssl-agb-wrapper">
      <div class="license-plate--ssl-info">
        <cg-icon class="license-plate--ssl-info-icon h-6 w-6" [content]="sslIcon"></cg-icon>
        <cg-paragraph class="license-plate--ssl-info-text">{{
          "opportunityFunnelLicensePlate.sslText" | transloco
        }}</cg-paragraph>
      </div>
      <div>
        <cg-link class="license-plate--agb-link" [content]="agbLink"></cg-link>
      </div>
    </div>

    <button
      cg-base-button
      [btnText]="'opportunityFunnelLicensePlate.ctaTitle' | transloco"
      class="mt-4 w-full part-[icon-text-wrapper]:px-4 t:mt-6 t:w-fit"
      (click)="goForward()"
      data-cy="btn-next"
    ></button>
  </div>
</ng-template>
