<cg-expansion-panel
  [contentTemplate]="weekContent"
  [expanded]="expanded()"
  [titleTemplate]="weekTitleTemplate"
></cg-expansion-panel>

<ng-template #weekTitleTemplate>
  <div
    class="relative flex items-center justify-between gap-2 border-b border-b-cg-anthracite-light-80 px-4 py-3"
    (click)="toggleExpanded()"
  >
    @if (expanded()) {
      <div class="absolute bottom-0 left-0 h-1 w-full bg-cg-yellow"></div>
    }
    <cg-paragraph class="font-bold [&>p]:text-[13px]">{{ item().title }}</cg-paragraph>
    <div class="flex flex-row gap-2.5">
      @if (!expanded()) {
        <cg-new-appointment-circle class="[&>div]:h-6 [&>div]:w-6 [&>div]:border-0 [&>div]:bg-cg-yellow">
          <span class="font-roboto-condensed font-bold text-cg-black">{{ item().appointments.length }}</span>
        </cg-new-appointment-circle>
      }
      <cg-icon
        class="h-6 w-6 origin-center transform transition-transform duration-200"
        [content]="accordionIcon"
        [ngClass]="{ 'rotate-180': !expanded() }"
      ></cg-icon>
    </div>
  </div>
</ng-template>

<ng-template #weekContent>
  <div class="flex flex-col gap-0.5">
    <cg-new-appointment-selection-grid
      class="mb-6 mt-4"
      [appointments]="item().appointments"
      [isDisplayedInAllScDialog]="isDisplayedInAllScDialog()"
    ></cg-new-appointment-selection-grid>

    @if (isLastItem() && requiredService() === RequiredService.REPLACE) {
      <cg-cta-two-lines
        data-cy="mobile-service"
        subtitle="appointment.appointmentList.ctas.mobileServiceSubtitle"
        title="appointment.appointmentList.ctas.mobileServiceTitle"
        [icon]="serviceIcon"
        (click)="mobileServiceClicked($event)"
      >
      </cg-cta-two-lines>
    }
  </div>
</ng-template>
