import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, Signal } from "@angular/core";
import { takeUntilDestroyed, toSignal } from "@angular/core/rxjs-interop";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { take } from "rxjs/operators";
import { NewAppointmentFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { HeadlineType, ParagraphComponent } from "@cg/core/ui";
import { OlbHeadlineComponent } from "@cg/olb/shared";
import { BaseButtonComponent, DrawerComponent, OverlayService, ServiceCenterData } from "@cg/shared";
import { NewAppointmentMultiSelectComponent } from "../new-appointment-multi-select/new-appointment-multi-select.component";

// Feature.NEW_APPOINTMENT_TILE
@Component({
  selector: "cg-new-appointment-sc-select-dialog",
  templateUrl: "./new-appointment-sc-select-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    TranslocoPipe,
    ReactiveFormsModule,
    DrawerComponent,
    OlbHeadlineComponent,
    NewAppointmentMultiSelectComponent,
    BaseButtonComponent,
    ParagraphComponent
  ]
})
export class NewAppointmentScSelectDialogComponent implements OnInit {
  private readonly overlayService = inject(OverlayService);
  private readonly appointmentFacade = inject(NewAppointmentFacade);
  private readonly fb = inject(FormBuilder);
  private readonly destroyRef = inject(DestroyRef);

  public readonly offeredServiceCenters: Signal<ServiceCenterData[]> = toSignal(
    this.appointmentFacade.offeredServiceCenters$
  );

  public readonly headlineType = HeadlineType;
  public form: FormGroup<{ selectedSc: FormControl<string[]> }>;
  public locality$ = this.appointmentFacade.locality$;

  public ngOnInit(): void {
    this.initForm();
    this.initDefaultSelectAllIds();
  }

  public clickedOk(): void {
    let selectedIds = this.form.controls.selectedSc.value;

    if (selectedIds === null || selectedIds.length === 0) {
      selectedIds = this.offeredServiceCenters().map((sc: ServiceCenterData): string => sc.serviceCenterId);
    }

    this.appointmentFacade.setSelectedServiceCenterIds(selectedIds);

    this.overlayService.close();
  }

  private initForm() {
    this.form = this.fb.group({
      selectedSc: []
    });
  }

  private initDefaultSelectAllIds() {
    this.appointmentFacade.selectedServiceCenterIds$
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe((scIds: string[]) => {
        this.form.controls.selectedSc.setValue(scIds);
      });
  }
}
