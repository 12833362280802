import type { Picture } from "@cg/content-api/typescript-interfaces";

export const generateEconomyPackagePictureModel = (assetPath: string): Picture => ({
  sizes: [
    {
      srcset: `${assetPath}/olb/vaps-offer/no-carglass-protect-mobile.webp`,
      media: "xs"
    },
    {
      srcset: `${assetPath}/olb/vaps-offer/no-carglass-protect-mobile.webp`,
      media: "sm"
    },
    {
      srcset: `${assetPath}/olb/vaps-offer/no-carglass-protect-desktop.webp`,
      media: "md"
    },
    {
      srcset: `${assetPath}/olb/vaps-offer/no-carglass-protect-desktop.webp`,
      media: "lg"
    },
    {
      srcset: `${assetPath}/olb/vaps-offer/no-carglass-protect-desktop.webp`,
      media: "xl"
    }
  ],
  source: `${assetPath}/olb/vaps-offer/no-carglass-protect-desktop.webp`,
  alt: "Economy package",
  ngTemplate: "cgPicture"
});

export const generateStandardPackagePictureModel = (assetPath: string): Picture => ({
  sizes: [
    {
      srcset: `${assetPath}/olb/vaps-offer/carglass-protect-mobile.webp`,
      media: "xs"
    },
    {
      srcset: `${assetPath}/olb/vaps-offer/carglass-protect-mobile.webp`,
      media: "sm"
    },
    {
      srcset: `${assetPath}/olb/vaps-offer/carglass-protect-desktop.webp`,
      media: "md"
    },
    {
      srcset: `${assetPath}/olb/vaps-offer/carglass-protect-desktop.webp`,
      media: "lg"
    },
    {
      srcset: `${assetPath}/olb/vaps-offer/carglass-protect-desktop.webp`,
      media: "xl"
    }
  ],
  source: `${assetPath}/olb/vaps-offer/carglass-protect-desktop.webp`,
  alt: "Standard package",
  ngTemplate: "cgPicture"
});

export const generatePremiumPackagePictureModel = (assetPath: string): Picture => ({
  sizes: [
    {
      srcset: `${assetPath}/olb/vaps-offer/carglass-protect-and-wiper-mobile.webp`,
      media: "xs"
    },
    {
      srcset: `${assetPath}/olb/vaps-offer/carglass-protect-and-wiper-mobile.webp`,
      media: "sm"
    },
    {
      srcset: `${assetPath}/olb/vaps-offer/carglass-protect-and-wiper.webp`,
      media: "md"
    },
    {
      srcset: `${assetPath}/olb/vaps-offer/carglass-protect-and-wiper.webp`,
      media: "lg"
    },
    {
      srcset: `${assetPath}/olb/vaps-offer/carglass-protect-and-wiper.webp`,
      media: "xl"
    }
  ],
  source: `${assetPath}/olb/vaps-offer/carglass-protect-and-wiper.webp`,
  alt: "Premium package",
  ngTemplate: "cgPicture"
});
