<section>
  <ng-template #master>
    <cg-new-appointment-header></cg-new-appointment-header>
    <cg-new-appointment-tile [processMetadata]="processMetadata" />
  </ng-template>

  <cg-split-view
    class="[&>div>div]:p-0"
    [ngClass]="{
      'with-pin h-[80dvh]': this.isDesktop() && !searchClicked(),
      'no-pin': this.isDesktop() && searchClicked(),
      'bg-map block w-full': this.isDesktop()
    }"
    [drawerTrigger]="false"
    [master]="master"
    [overlayBackwardText]="'newAppointment.appointmentDrawer.buttons.back' | transloco"
    [overlayForwardText]="'newAppointment.appointmentDrawer.buttons.forward' | transloco"
    [showBackground]="false"
    [showBranding]="false"
  ></cg-split-view>
</section>
