import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { ProcessFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { HeadlineType, IconComponent, ParagraphComponent } from "@cg/core/ui";
import { mobileServiceIcon, phoneContactIcon } from "@cg/icon";
import { OlbHeadlineComponent } from "@cg/olb/shared";
import { CtaTwoLinesComponent, FormatTelLinkPipe, PhoneNumber, SafeContentPipe } from "@cg/shared";

// Feature.NEW_APPOINTMENT_TILE
@Component({
  selector: "cg-new-appointment-mobile-service-view",
  templateUrl: "./new-appointment-mobile-service-view.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [FormatTelLinkPipe],
  imports: [
    SafeContentPipe,
    TranslocoPipe,
    OlbHeadlineComponent,
    ParagraphComponent,
    CtaTwoLinesComponent,
    IconComponent
  ]
})
export class NewAppointmentMobileServiceViewComponent {
  private readonly processFacade = inject(ProcessFacade);
  private readonly telPipe = inject(FormatTelLinkPipe);

  public phoneIcon = phoneContactIcon;
  public serviceIcon = mobileServiceIcon;
  public readonly headlineType = HeadlineType;
  public PhoneNumber = PhoneNumber;

  public mobileServiceClicked(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    this.processFacade.setChannelSwitchReason("MOBILE_SERVICE");
    this.processFacade.goForward("channel-switch");
  }

  public callLinkClicked(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    const phoneUri = this.telPipe.transform(PhoneNumber.CS_NO_APPOINTMENT_AVAILABLE);
    window.open(phoneUri, "_self");
  }
}
