import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { Observable, of } from "rxjs";
import { NewAppointmentFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { AddFormControls } from "@cg/core/types";
import { AppointmentSearchForm, BreakpointService, SplitViewComponent } from "@cg/shared";
import { BaseDirective } from "../../core/directives/base/base.directive";
import { NewAppointmentHeaderComponent } from "../components/new-appointment-header/new-appointment-header.component";
import { NewAppointmentTileComponent } from "../new-appointment-tile/new-appointment-tile.component";

// Feature.NEW_APPOINTMENT_TILE
@Component({
  selector: "cg-new-appointment",
  templateUrl: "./new-appointment.component.html",
  styleUrl: "./new-appointment.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, TranslocoPipe, SplitViewComponent, NewAppointmentTileComponent, NewAppointmentHeaderComponent],
  standalone: true
})
export class NewAppointmentComponent
  extends BaseDirective<AddFormControls<AppointmentSearchForm>>
  implements OnInit, OnDestroy
{
  protected readonly breakpointService = inject(BreakpointService);
  private readonly appointmentFacade = inject(NewAppointmentFacade);

  public isDesktop = toSignal(this.breakpointService.isDesktop$);
  public searchClicked = toSignal(this.appointmentFacade.searchClicked$);

  public async ngOnInit(): Promise<void> {
    await super.ngOnInit();
  }

  public initFormGroup(): void {}

  public setFormValues(): void {
    // TODO: maybe implement this later for save and restore or mycarglass
  }

  public getExitIdForSavedForm(): Observable<string> {
    return of("");
  }

  public saveForm(): void {}
}
