import { NgClass } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  DestroyRef,
  effect,
  inject,
  input,
  OnInit,
  output,
  signal,
  Signal,
  viewChild
} from "@angular/core";
import { takeUntilDestroyed, toSignal } from "@angular/core/rxjs-interop";
import { FormGroup, ReactiveFormsModule, UntypedFormControl } from "@angular/forms";
import { combineLatest, map, take, withLatestFrom } from "rxjs";
import { NewAppointmentFacade } from "@cg/olb/state";
import { TranslocoPipe, TranslocoService } from "@jsverse/transloco";
import { Icon } from "@cg/content-api/typescript-interfaces";
import { AddFormControls } from "@cg/core/types";
import { FakeDropdownComponent, IconComponent, PLACEHOLDER } from "@cg/core/ui";
import { accordionIconDown, calendarIcon, clearIconWhite, filterIcon, pinFillIcon, searchIcon } from "@cg/icon";
import {
  BaseButtonComponent,
  BreakpointService,
  OlbSearchForScInputComponent,
  OverlayService,
  ServiceCenterData,
  ServiceCenterSelectionLabelComponent
} from "@cg/shared";
import { NewAppointmentScSelectDialogComponent } from "../new-appointment-sc-select-dialog/new-appointment-sc-select-dialog.component";
import { NewAppointmentSearchLocationInputComponent } from "../new-appointment-search-location-input/new-appointment-search-location-input.component";
import { NewAppointmentFilterForm } from "./interfaces/new-appointment-filter-form.interface";

// Feature.NEW_APPOINTMENT_TILE
@Component({
  selector: "cg-new-appointment-filter",
  templateUrl: "./new-appointment-filter.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    TranslocoPipe,
    BaseButtonComponent,
    ReactiveFormsModule,
    IconComponent,
    ServiceCenterSelectionLabelComponent,
    FakeDropdownComponent,
    NewAppointmentSearchLocationInputComponent
  ]
})
export class NewAppointmentFilterComponent implements OnInit, AfterViewInit {
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly overlayService = inject(OverlayService);
  private readonly translocoService = inject(TranslocoService);
  private readonly breakPointService = inject(BreakpointService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly appointmentFacade = inject(NewAppointmentFacade);

  public readonly filterChanged = output<void>();
  public readonly searchClicked = output<void>();

  public olbSearchForScInputComponent = viewChild(OlbSearchForScInputComponent);

  public readonly isFormTouchedProgrammatically = input.required<boolean>();
  public readonly isScSelectDisabled = input<boolean>(false); // is always false for create appointment, for edit appointment (currently with old appointment tile) it is true if the required service is replace
  public readonly showChevron = input.required<boolean>();
  public readonly showDropDownChevron = input<boolean>(true);

  public readonly locality = toSignal(this.appointmentFacade.locality$);
  public readonly selectedServiceCenterIds = toSignal(this.appointmentFacade.selectedServiceCenterIds$);
  public readonly offeredServiceCenters = toSignal(this.appointmentFacade.offeredServiceCenters$);
  public readonly formattedAddress = toSignal(this.appointmentFacade.formattedAddress$);

  public readonly pinIcon: Icon = pinFillIcon;
  public readonly filterIcon: Icon = filterIcon;
  public readonly arrowIcon: Icon = accordionIconDown;
  public readonly searchIcon: Icon = searchIcon;
  public readonly calendarIcon: Icon = calendarIcon;
  public readonly clearIcon: Icon = clearIconWhite;

  public readonly expanded = signal(false);
  public readonly isTablet = toSignal(
    this.breakPointService.isMobile$.pipe(
      takeUntilDestroyed(this.destroyRef),
      map((isMobile: boolean) => !isMobile)
    )
  );
  public form: FormGroup<AddFormControls<NewAppointmentFilterForm>>;

  public selectionText: Signal<string> = computed(() => {
    if (
      !this.selectedServiceCenterIds() ||
      !this.offeredServiceCenters() ||
      this.selectedServiceCenterIds().length === 0
    ) {
      return this.translocoService.translate("newAppointment.appointmentFilter.selectionTexts.none");
    }

    const count = this.selectedServiceCenterIds().length;

    if (count === this.offeredServiceCenters().length && this.offeredServiceCenters().length > 1) {
      return this.translocoService.translate("newAppointment.appointmentFilter.selectionTexts.all");
    } else if (count > 1) {
      return this.translocoService.translate("newAppointment.appointmentFilter.selectionTexts.multiple", { count });
    } else if (count === 1) {
      const { city, subLocality = null } = this.offeredServiceCenters().find(
        (sc: ServiceCenterData) => sc.serviceCenterId === this.selectedServiceCenterIds()[0]
      ).address;
      return subLocality ? `${city} - ${subLocality}` : city;
    }
  });

  public serviceCenterCount: Signal<number> = computed(() =>
    this.offeredServiceCenters() ? this.offeredServiceCenters().length : 0
  );

  public distance: Signal<number> = computed(() => {
    const furthestServiceCenterDistance: number = this.offeredServiceCenters().length
      ? this.offeredServiceCenters().at(this.offeredServiceCenters().length - 1).distance
      : 0;
    return Math.ceil(furthestServiceCenterDistance / 10) * 10;
  });

  public get hasSelection(): boolean {
    return this.countSelectedServiceCenter > 0;
  }

  public get countSelectedServiceCenter(): number {
    const value = this.form.controls.scIds.value;
    return !!value && value[0] !== PLACEHOLDER ? this.form.controls.scIds.value.length : 0;
  }

  public constructor() {
    effect(
      () => {
        this.filterChanged?.emit();

        if (this.offeredServiceCenters()?.length > 0) {
          this.form.controls.scIds.setValue(this.selectedServiceCenterIds());
        }

        if (this.formattedAddress() === null) {
          this.olbSearchForScInputComponent().skipAutocompleteOneTime = true;
        }

        this.form.controls.searchServiceCenterInput.setValue(this.formattedAddress());

        this.cdr.detectChanges();
      },
      { allowSignalWrites: true }
    );
  }

  public ngOnInit(): void {
    this.form = new FormGroup<AddFormControls<NewAppointmentFilterForm>>({
      scIds: new UntypedFormControl([]),
      searchServiceCenterInput: new UntypedFormControl("")
    });

    this.expandFilter();
  }

  public ngAfterViewInit(): void {
    if (
      !!this.olbSearchForScInputComponent() &&
      !!this.form.controls.searchServiceCenterInput.value &&
      this.isFormTouchedProgrammatically()
    ) {
      this.olbSearchForScInputComponent().getPlacePredictions();
    }
  }

  public expandFilter(): void {
    if (this.isScSelectDisabled()) {
      // is always false for create appointment, for edit appointment (currently with old appointment tile) it is true if the required service is replace
      return;
    }
    this.expanded.set(true);
  }

  public collapseFilter(): void {
    this.expanded.set(false);
  }

  public search(event?: Event): void {
    event?.stopPropagation();
    event?.preventDefault();

    combineLatest([this.appointmentFacade.offeredServiceCenters$, this.appointmentFacade.selectedServiceCenterIds$])
      .pipe(take(1))
      .subscribe(([availableServiceCenters, selectedServiceCenterIds]: [ServiceCenterData[], string[]]) => {
        if (!selectedServiceCenterIds || selectedServiceCenterIds.length === 0) {
          // Still required to catch empty drop down or special cases
          this.appointmentFacade.setSelectedServiceCenterIds(
            availableServiceCenters?.map((sc: ServiceCenterData) => sc.serviceCenterId) ?? []
          );
        }

        this.collapseFilter();
        this.searchClicked.emit();
      });
  }

  public showScSelectionDialog(): void {
    this.appointmentFacade.offeredServiceCenters$
      .pipe(take(1))
      .subscribe((offeredServiceCenters: ServiceCenterData[]) => {
        if (offeredServiceCenters?.length <= 1) {
          return;
        }

        this.overlayService.open(NewAppointmentScSelectDialogComponent);

        this.overlayService.afterClosed$
          .pipe(take(1), withLatestFrom(this.appointmentFacade.selectedServiceCenterIds$))
          .subscribe(([_, selectedServiceCenterIds]: [boolean, string[]]) => {
            if (selectedServiceCenterIds?.length > 0) {
              this.collapseFilter();
              this.searchClicked.emit();
            }
          });
      });
  }
}
