/* eslint-disable sonarjs/no-duplicate-string */
import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { filter, tap, withLatestFrom } from "rxjs/operators";
import { DamageFacade, NewAppointmentActions, NewAppointmentFacade, ProcessFacade } from "@cg/olb/state";
import differenceInDays from "date-fns/differenceInDays";
import { TrackingEvent, TrackingService } from "@cg/analytics";
import { isOpportunityFunnel } from "@cg/core/utils";
import {
  ConfirmAppointmentResponse,
  NewAppointmentData,
  ProcessMetadata,
  RequiredService,
  ServiceCenterData
} from "@cg/shared";

@Injectable()
export class NewAppointmentTrackingEffects {
  private readonly actions$ = inject(Actions);
  private readonly trackingService = inject(TrackingService);
  private readonly appointmentFacade = inject(NewAppointmentFacade);
  private readonly damageFacade = inject(DamageFacade);
  private readonly processFacade = inject(ProcessFacade);

  private initialDistanceOffered: number;

  public appointmentSelectionStepScSearchTracking$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NewAppointmentActions.getServiceCentersSuccess),
        withLatestFrom(this.appointmentFacade.firstServiceCenterSearch$),
        filter(([_, firstSearch]: [{ payload }, boolean]) => firstSearch),
        tap(() => {
          this.trackingService.trackEvent({
            event: "ga-event",
            eventCategory: "olb",
            eventAction: "appointment-selection-step",
            eventLabel: "sc-search"
          });

          this.appointmentFacade.setFirstServiceCenterSearch();
        })
      ),
    { dispatch: false }
  );

  public appointmentSelectionAppointmentSearchStepTracking$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NewAppointmentActions.getAppointmentsSuccess),
        withLatestFrom(this.appointmentFacade.firstAppointmentSearch$),
        filter(([_, firstOffer]: [{ payload }, boolean]) => firstOffer),
        tap(() => {
          this.trackingService.trackEvent({
            event: "ga-event",
            eventCategory: "olb",
            eventAction: "appointment-selection-step",
            eventLabel: "appointment-search"
          });

          this.appointmentFacade.setFirstAppointmentSearch();
        })
      ),
    { dispatch: false }
  );

  public appointmentResultTracking$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NewAppointmentActions.getAppointmentsSuccess),
        withLatestFrom(
          this.appointmentFacade.locality$,
          this.appointmentFacade.offeredServiceCenters$,
          this.appointmentFacade.offeredAppointments$
        ),
        tap(
          ([_, formattedAddress, offeredServiceCenters, offeredAppointments]: [
            { payload },
            string,
            ServiceCenterData[],
            Record<string, NewAppointmentData[][]>
          ]) => {
            this.trackAppointmentResult(formattedAddress, offeredServiceCenters, offeredAppointments);
          }
        )
      ),
    { dispatch: false }
  );

  public appointmentTypeTracking$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NewAppointmentActions.getAppointmentsSuccess),
        withLatestFrom(this.appointmentFacade.isCalibration$, this.damageFacade.requiredService$),
        tap(([_, isCalibration, requiredService]: [{ payload }, boolean, RequiredService]) => {
          this.trackingService.trackEvent({
            event: "ga-event",
            eventCategory: "olb",
            eventAction: "appointment-type",
            eventLabel: this.determineAppointmentSelectionEventLabel(isCalibration, requiredService)
          });
        })
      ),
    { dispatch: false }
  );

  public currentAppointmentTracking$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NewAppointmentActions.confirmAppointmentSuccess),
        withLatestFrom(
          this.appointmentFacade.offeredServiceCenters$,
          this.appointmentFacade.offeredAppointments$,
          this.appointmentFacade.isCalibration$,
          this.damageFacade.requiredService$,
          this.processFacade.processMetaData$
        ),
        tap(
          ([{ payload }, offeredServiceCenters, offeredAppointment, isCalibration, requiredService, processMetaData]: [
            { payload: ConfirmAppointmentResponse },
            ServiceCenterData[],
            Record<string, NewAppointmentData[][]>,
            boolean,
            RequiredService,
            ProcessMetadata[]
          ]) => {
            this.trackConfirmedAppointment(
              offeredServiceCenters,
              payload,
              offeredAppointment,
              processMetaData,
              isCalibration,
              requiredService
            );
          }
        )
      ),
    { dispatch: false }
  );

  private trackConfirmedAppointment(
    offeredServiceCenters: ServiceCenterData[],
    payload: ConfirmAppointmentResponse,
    offeredAppointment: Record<string, NewAppointmentData[][]>,
    processMetaData: ProcessMetadata[],
    isCalibration: boolean,
    requiredService: RequiredService
  ) {
    const selectedServiceCenter = offeredServiceCenters.find(
      (serviceCenter: ServiceCenterData) => serviceCenter.serviceCenterId === payload.serviceCenterId
    );
    const serviceCenterAddress = selectedServiceCenter.address;

    const selectedAppointment =
      Object.values(offeredAppointment)
        ?.flat()
        ?.flat()
        .find((appointment: NewAppointmentData) => appointment.appointmentId === payload.appointmentId) ?? null;

    const today = new Date(Date.now());
    today.setHours(0, 0, 0, 0);
    const dateAppointment = new Date(selectedAppointment.availabilityPeriodStart);
    dateAppointment.setHours(0, 0, 0, 0);

    const daysUntil = differenceInDays(dateAppointment, today);

    if (isOpportunityFunnel(processMetaData)) {
      return;
    }

    this.trackingService.trackEvent({
      event: "ga-event",
      eventCategory: "olb",
      eventAction: "appointment-selection",
      eventLabel: this.determineAppointmentSelectionEventLabel(isCalibration, requiredService),
      appointment: {
        "days-until": daysUntil,
        city: serviceCenterAddress.city,
        region: serviceCenterAddress.state,
        distance: selectedServiceCenter.distance,
        branch: {
          address: `${serviceCenterAddress.street}, ${serviceCenterAddress.postalCode} ${serviceCenterAddress.city}`,
          id: payload.serviceCenterId
        }
      }
    });
  }

  private trackAppointmentResult(
    formattedAddress: string,
    branches: ServiceCenterData[],
    appointments: Record<string, NewAppointmentData[][]>
  ) {
    const searchCity = this.formatSearchCityForAnalytics(formattedAddress);
    let eventLabel = "result";

    if (!branches || branches.length === 0) {
      eventLabel = "no-branches";
    } else if (this.isNoAppointmentOffered(appointments)) {
      eventLabel = "no-appointment";
    }

    let appointment = null;

    if (eventLabel === "result") {
      const today = new Date(Date.now());
      today.setHours(0, 0, 0, 0);
      const firstAppointment = Object.values(appointments).at(0).at(0).at(0);
      const firstDate = new Date(firstAppointment.customerAppointmentStart);
      firstDate.setHours(0, 0, 0, 0);

      const numberOfAppointments = Object.values(appointments).flat().flat().length;
      const daysUntil = differenceInDays(firstDate, today);
      const nearestDistance = this.getNearestDistance(branches, appointments);

      if (!this.initialDistanceOffered) {
        this.initialDistanceOffered = nearestDistance;
      }

      appointment = {
        "first-appointment-available-days-until": daysUntil,
        "available-appointments": numberOfAppointments,
        "search-city": searchCity,
        "initial-distance-offered": this.initialDistanceOffered,
        "distance-offered": nearestDistance
      };
    } else {
      appointment = {
        "search-city": searchCity
      };
    }

    const payload: Partial<TrackingEvent> = {
      event: "ga-event",
      eventCategory: "olb",
      eventAction: "appointment-result",
      eventLabel
    };

    // eslint-disable-next-line @typescript-eslint/no-extra-parens
    this.trackingService.trackEvent({ ...payload, ...(appointment && { appointment }) });
  }

  private isNoAppointmentOffered(appointments: Record<string, NewAppointmentData[][]>) {
    return (
      !appointments || Object.keys(appointments).length === 0 || Object.values(appointments).flat().flat().length === 0
    );
  }

  private getNearestDistance(branches: ServiceCenterData[], appointments: Record<string, NewAppointmentData[][]>) {
    const BIG_INITIAL_DISTANCE = 1000000;
    return branches.reduce((acc: number, curr: ServiceCenterData) => {
      if (curr.distance < acc) {
        const scHasAppointments = Object.entries(appointments).find(
          ([key, value]: [string, NewAppointmentData[][]]) => key === curr.serviceCenterId && value.length
        );
        if (scHasAppointments) {
          return curr.distance;
        }
      }

      return acc;
    }, BIG_INITIAL_DISTANCE);
  }

  private formatSearchCityForAnalytics(address: string | null): string {
    if (!address) {
      return null;
    }
    const city = address.split(",").splice(-2);
    city[0] = city[0].replace(/\d{5} /, "");
    return city.join(",").trim();
  }

  private determineAppointmentSelectionEventLabel(isCalibration: boolean, requiredService: RequiredService): string {
    if (isCalibration) {
      return "replace-adas";
    } else if (!isCalibration && requiredService === RequiredService.REPLACE) {
      return "replace-non-adas";
    }

    return "repair";
  }
}
