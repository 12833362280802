import { inject, Injectable, signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { isBefore } from "date-fns";
import { BreakpointService, NewAppointmentData, ServiceCenterData } from "@cg/shared";
import { GroupedLocationWithAppointments } from "../interfaces/grouped-location-with-appointments.interface";
import { LocationWithAppointment } from "../interfaces/location-with-appointment.interface";
import { LocationWithAppointments } from "../interfaces/location-with-appointments.interface";

@Injectable({
  providedIn: "root"
})
export class NewAppointmentTileDataService {
  private readonly breakpointService = inject(BreakpointService);

  private readonly EMPTY_COMBINED_APPOINTMENT_LOCATION = { selected: [], earlier: [] };

  public earliestInSelectedAppointments = signal(false);
  private isDesktop = toSignal(this.breakpointService.isDesktop$);

  public mapToCombinedAppointmentLocations([selectedCenterIds, offeredAppointments, offeredServiceCenters]: [
    string[],
    Record<string, NewAppointmentData[][]>,
    ServiceCenterData[]
  ]): GroupedLocationWithAppointments {
    if (selectedCenterIds.length === 0) {
      return this.EMPTY_COMBINED_APPOINTMENT_LOCATION;
    }

    const allLocationWithAppointments: LocationWithAppointments[] = this.mapLocationsWithAppointments(
      offeredAppointments,
      offeredServiceCenters
    );

    const sortedLocationsWithAppointment = this.groupBySelectionStatus(allLocationWithAppointments, selectedCenterIds);

    const earliestSelectedAppointment = this.getEarliestAppointment(sortedLocationsWithAppointment.selected);
    if (!this.hasSelectedSpecificServiceCenters(offeredServiceCenters, selectedCenterIds)) {
      this.filterSelectedLocationsWithAppointmentsWhenTheresAtLeastOneAppointmentAtAll(sortedLocationsWithAppointment);
    }
    if (earliestSelectedAppointment) {
      const earliestSelectedTime = new Date(earliestSelectedAppointment.customerAppointmentStart);

      this.filterEarliestAppointmentsBefore(sortedLocationsWithAppointment, earliestSelectedTime);
    }

    return this.sortByDistance(sortedLocationsWithAppointment);
  }

  public getEarliestLocationWithAppointment(
    locationsWithAppointments: LocationWithAppointments[],
    selectedServiceCenterIds?: string[]
  ): LocationWithAppointment {
    if (!locationsWithAppointments || locationsWithAppointments.length === 0) {
      return null;
    }

    if (!this.isDesktop() && locationsWithAppointments.length === 1) {
      return null;
    }

    let earliestAppointmentId: string | null;
    if (this.isDesktop()) {
      const earliestAppointment = this.getEarliestAppointment(locationsWithAppointments);

      if (!earliestAppointment) {
        return null;
      }

      this.earliestInSelectedAppointments.set(selectedServiceCenterIds.includes(earliestAppointment.serviceCenterId));

      earliestAppointmentId = earliestAppointment.appointmentId;
    } else {
      earliestAppointmentId = this.getEarliestAppointment(locationsWithAppointments)?.appointmentId;
    }

    if (!earliestAppointmentId) {
      return null;
    }

    const earliestAppointmentWithLocation = locationsWithAppointments
      .filter(({ appointments }: { appointments: NewAppointmentData[][] }) => appointments.length)
      .find((loc: LocationWithAppointments) => loc.appointments[0][0].appointmentId === earliestAppointmentId);

    return {
      serviceCenter: earliestAppointmentWithLocation.serviceCenter,
      appointment: earliestAppointmentWithLocation.appointments[0][0]
    };
  }

  private getEarliestAppointment(locations: LocationWithAppointments[]): NewAppointmentData {
    if (!locations || locations.length === 0) {
      return null;
    }

    const reducedLocations = locations.reduce(
      (locationA: LocationWithAppointments, locationB: LocationWithAppointments) => {
        if (locationA.appointments.length === 0) {
          return locationB;
        }

        if (locationB.appointments.length === 0) {
          return locationA;
        }

        const aTime: number = new Date(locationA.appointments[0][0].customerAppointmentStart).getTime();
        const bTime: number = new Date(locationB.appointments[0][0].customerAppointmentStart).getTime();

        if (aTime < bTime) {
          return locationA;
        } else if (aTime > bTime) {
          return locationB;
        } else {
          return locationA.serviceCenter.distance < locationB.serviceCenter.distance ? locationA : locationB;
        }
      }
    );

    return reducedLocations.appointments.length ? reducedLocations?.appointments[0][0] : null;
  }

  private sortByDistance(
    sortedLocationsWithAppointment: GroupedLocationWithAppointments
  ): GroupedLocationWithAppointments {
    const distanceSorter = (locationA: LocationWithAppointments, locationB: LocationWithAppointments) =>
      locationA.serviceCenter.distance - locationB.serviceCenter.distance;
    sortedLocationsWithAppointment.selected.sort(distanceSorter); // TODO check side effects of sort here!
    sortedLocationsWithAppointment.earlier.sort(distanceSorter); // TODO check side effects of sort here!
    return sortedLocationsWithAppointment;
  }

  private filterEarliestAppointmentsBefore(
    sortedLocationsWithAppointment: GroupedLocationWithAppointments,
    beforeDate: Date
  ): void {
    sortedLocationsWithAppointment.earlier = sortedLocationsWithAppointment.earlier.filter(
      (appointment: LocationWithAppointments) =>
        isBefore(new Date(appointment.appointments[0][0].customerAppointmentStart), beforeDate)
    );
  }

  private groupBySelectionStatus(
    allLocationWithAppointments: LocationWithAppointments[],
    selectedCenterIds: string[]
  ): GroupedLocationWithAppointments {
    return allLocationWithAppointments.reduce(
      (groupedLocations: GroupedLocationWithAppointments, location: LocationWithAppointments) => {
        if (selectedCenterIds.includes(location.serviceCenter.serviceCenterId)) {
          groupedLocations.selected.push(location);
        } else if (location.appointments.length > 0) {
          groupedLocations.earlier.push(location);
        }

        return groupedLocations;
      },
      { selected: [], earlier: [] } as GroupedLocationWithAppointments
    );
  }

  private mapLocationsWithAppointments(
    offeredAppointments: Record<string, NewAppointmentData[][]>,
    serviceCenters: ServiceCenterData[]
  ): LocationWithAppointments[] {
    return serviceCenters.map((serviceCenter: ServiceCenterData): LocationWithAppointments => {
      const appointments: NewAppointmentData[][] = offeredAppointments[serviceCenter.serviceCenterId] ?? [];
      return {
        serviceCenter,
        appointments
      };
    });
  }

  private filterSelectedLocationsWithAppointmentsWhenTheresAtLeastOneAppointmentAtAll(
    combinedAppointmentLocations: GroupedLocationWithAppointments
  ): void {
    const filteredSelectedLocations = combinedAppointmentLocations.selected.filter(
      (location: LocationWithAppointments) => location.appointments.length > 0
    );
    if (filteredSelectedLocations.length > 0) {
      combinedAppointmentLocations.selected = filteredSelectedLocations;
    }
  }

  private hasSelectedSpecificServiceCenters(
    offeredServiceCenters: ServiceCenterData[],
    selectedCenterIds: string[]
  ): boolean {
    return offeredServiceCenters.length !== selectedCenterIds.length;
  }
}
