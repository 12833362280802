{
  "olbSearchForScInput": {
    "placeholder": "PLZ oder Adresse",
    "error": "Bitte geben Sie Ihre PLZ oder Adresse ein"
  },
  "newAppointment": {
    "title": "Wo soll der Termin stattfinden?",
    "btnBack": "Zurück",
    "appointmentSelection": {
      "noAppointments": {
        "title": "Wir haben bis zum {{date}} keine freien Termine",
        "text": "Bitte wählen Sie ein anderes Service-Center."
      },
      "allAppointments": "Alle Termine",
      "tabTitle": {
        "thisWeek": "Diese Woche",
        "nextWeek": "Nächste Woche"
      }
    },
    "appointmentDrawer": {
      "buttons": {
        "back": "Abbrechen",
        "forward": "Termin übernehmen"
      }
    },
    "appointmentFilter": {
      "button": {
        "findAppointment": "Verfügbare Termine anzeigen",
        "findAppointmentTablet": "Termine"
      },
      "selectionTexts": {
        "none": "Service-Center wählen",
        "multiple": "{{ count }} Service-Center gewählt",
        "all": "Alle Service-Center gewählt"
      }
    },
    "multiSelect": {
      "selectAll": {
        "title": "Alle {{ count }} Service-Center",
        "subtitle": "Wählen Sie die Service-Center aus, die für Sie infrage kommen."
      }
    },
    "scSelectDialog": {
      "title": "Service-Center in <mark class=\"bg-cg-yellow text-black px-0.5\">{{city}}</mark>",
      "buttons": {
        "reset": "Zurücksetzen",
        "ok": "Termin finden"
      }
    },
    "allScAppointmentsDialog": {
      "title": "Alle Termine in <mark class=\"bg-cg-yellow text-black px-0.5\">{{location}}</mark>"
    },
    "appointmentDetail": {
      "title": "Ihre Terminauswahl",
      "texts": {
        "date": "Datum",
        "clock": "Uhr",
        "appointment": "Ihr Termin",
        "flexiblePickUpTimes": "Flexible Bring- & Holzeiten",
        "repair": {
          "picture": {
            "alt": "Monteur bei der Kalibrierung"
          }
        },
        "replaceWithAdas": {
          "explaination": "Da Ihr Fahrzeug mit Sicherheitssystemen (wie zum Beispiel einem Spurhalteassistenten) ausgestattet ist, kalibrieren wir beim Austausch die Kamera hinter Ihrer Scheibe mit unserer Laser-gestützten Messtechnik.",
          "picture": {
            "alt": "Monteur beim Austausch der Scheibe"
          }
        }
      },
      "buttons": {
        "ok": "Termin auswählen",
        "cancel": "Terminauswahl ändern"
      }
    },
    "appointmentDesktopDetail": {
      "title": "Termindetails",
      "date": "Datum",
      "yourAppointment": "Ihr Termin",
      "buttonBooking": "Termin auswählen"
    },
    "emptyAppointments": {
      "title": "<mark class='px-1 py-1 bg-cg-yellow'>Freie Termine</mark> in einem anderen Service-Center in Ihrer Nähe"
    },
    "ealierAppointments": {
      "title": "<mark class='px-1 py-1 bg-cg-yellow'>Frühere Termine</mark> in einem anderen Service-Center in Ihrer Nähe",
      "noAppointments": "Keine früheren Termine verfügbar"
    },
    "earliestAppointmentCard": {
      "badge": "frühester Termin",
      "button": "Termin auswählen",
      "buttonMobile": "Wählen"
    },
    "selectCard": {
      "overbooked": "<b>Keine Termine</b> bis {{date}}",
      "startingFrom": "ab <b>{{day}}</b> {{date}}"
    },
    "oneAppointmentView": {
      "title": "Nächster Termin am"
    },
    "mobileServiceView": {
      "title": "Sie möchten früher einen Termin?",
      "phone": "Jetzt <span class='underline'>{{phone}}</span> kostenfrei anrufen.",
      "ctas": {
        "mobileServiceTitle": "Kein passender Termin?",
        "mobileServiceSubtitle": "Wir kommen auch zu Ihnen."
      },
      "questions": {
        "title": "Ihr Carglass<sup>®</sup> Service"
      }
    },
    "noAppointments": {
      "title": "In Ihrem Umkreis gibt es leider kein Service-Center mit freien Terminen.",
      "paragraph": "Bitte kontaktieren Sie uns telefonisch, um gemeinsam mit Ihnen eine Lösung für Ihr Anliegen zu finden.",
      "serviceTitle": "Ihr Carglass<sup>®</sup> Service",
      "serviceParagraph": "Jetzt <a href=\"tel:{{plainPhone}}\" class=\"underline\">{{phone}}</a> kostenfrei anrufen."
    }
  },
  "carBringingScheduleText": {
    "repair": {
      "repairChipCount1": "Bitte bringen Sie Ihr Fahrzeug bis spätestens {{customerAppointmentFrom}} zu uns. Der Steinschlag ist innerhalb von 30 bis 45 Minuten repariert. Gerne können Sie die Wartezeit bei einem Kaffee überbrücken.",
      "repairChipCount2": "Bitte bringen Sie Ihr Fahrzeug bis spätestens {{customerAppointmentFrom}} zu uns. Die Steinschläge sind in ca. 45 bis 60 Minuten repariert. Gerne können Sie die Wartezeit bei einem Kaffee überbrücken.",
      "repairChipCount3": "Bitte bringen Sie Ihr Fahrzeug bis spätestens {{customerAppointmentFrom}} zu uns. Die Steinschläge sind in ca. 60 bis 90 Minuten repariert. Gerne können Sie die Wartezeit bei einem Kaffee überbrücken."
    },
    "replace": {
      "replaceWithAdas": "Für den Scheibenwechsel benötigen wir das Fahrzeug den ganzen Tag. Bitte bringen Sie das Fahrzeug morgens zu uns. Wir benachrichtigen Sie sobald es wieder fahrbereit ist.",
      "replaceWithoutAdas": {
        "differentStarttime": "Sie können Ihr Fahrzeug ganz einfach zwischen {{serviceCenterOpenFrom}} und {{customerAppointmentFrom}} Uhr abgeben. Wir informieren Sie, sobald Ihr Auto fertig ist.",
        "equalStarttime": "Bitte bringen Sie Ihr Fahrzeug um {{serviceCenterOpenFrom}} Uhr. Wir informieren Sie, sobald Ihr Auto fertig ist."
      }
    }
  },
  "appointmentDateInfo": {
    "repair": {
      "appointmentInfoRepairChipCount1": "<strong>{{appointmentDay}}, den {{appointmentDate}} um {{appointmentTime}} Uhr.</strong><br>Der Steinschlag ist innerhalb von 30 bis 45 Minuten repariert.",
      "appointmentInfoRepairChipCount2": "<strong>{{appointmentDay}}, den {{appointmentDate}} um {{appointmentTime}} Uhr.</strong><br>Die Steinschläge sind innerhalb von 45 bis 60 Minuten repariert.",
      "appointmentInfoRepairChipCount3": "<strong>{{appointmentDay}}, den {{appointmentDate}} um {{appointmentTime}} Uhr.</strong><br>Die Steinschläge sind innerhalb von 60 bis 90 Minuten repariert."
    },
    "replace": {
      "appointmentInfoReplace": "<strong>{{appointmentDay}}, den {{appointmentDate}} um {{appointmentTime}} Uhr.</strong><br><strong>Bitte bringen Sie das Auto morgens zu uns.</strong><br>Wir benachrichtigen Sie, sobald es wieder fahrbereit ist.",
      "appointmentInfoReplaceHalfDay": "<strong>{{appointmentDay}}, den {{appointmentDate}} um {{appointmentTime}} Uhr.</strong>"
    },
    "opportunityFunnel": {
      "text": "<strong>{{desiredAppointmentDay}}, den {{desiredAppointmentDate}} – </strong>{{desiredPeriod}}",
      "period": {
        "morning": "vormittags",
        "afternoon": "nachmittags"
      }
    }
  },
  "appointment": {
    "appointmentFilter": {
      "a11y": {
        "label": "Auswahl Service-Center"
      },
      "placeholder": {
        "serviceCenter": "Alle Service-Center"
      },
      "texts": {
        "count": "<strong class='text-cg-anthracite'>{{ count }} Service-Center</strong> im Umkreis von {{distance}} km",
        "selectedCount": "<strong class='text-cg-anthracite'>{{selected}} von {{count}}</strong> Service-Center gewählt"
      },
      "button": {
        "findAppointment": "Termin finden"
      }
    },
    "appointmentList": {
      "a11y": {
        "legend": "Terminauswahl"
      },
      "texts": {
        "noAppointment": "Kein Termin mehr zum Anzeigen",
        "appointmentLimit": "Wir können Termine nur bis max. 3 Wochen vorausplanen."
      },
      "ctas": {
        "mobileServiceTitle": "Kein passender Termin?",
        "mobileServiceSubtitle": "Wir kommen auch zu Ihnen."
      },
      "errors": {
        "noAppointmentSelected": "Bitte wählen Sie einen Termin aus."
      }
    },
    "appointmentListItem": {
      "texts": {
        "till": "bis",
        "oclock": "Uhr",
        "appointment": "{{count}} {count, plural, one {Termin} other {Termine}}",
        "appointmentCountText": "Heute nur noch {{ count }} {count, plural, one {Termin} other {Termine}} frei",
        "weekdayTitle": "den {{dateString}}",
        "today": "Heute"
      }
    },
    "appointmentNoSelected": {
      "texts": {
        "title": "Ihre Terminauswahl",
        "chooseAppointment": "Bitte wählen Sie einen Termin aus.",
        "choosePlace": "Bitte erst einen <strong>Wunschort</strong> angeben."
      }
    },
    "appointmentSearch": {
      "texts": {
        "title": "Wo soll der Termin stattfinden?",
        "callUsStart": "Bitte rufen Sie uns kostenfrei unter",
        "callUsEnd": "an, damit wir für Sie eine passende Lösung finden.",
        "searchInfo": "Wir suchen im Umkreis Ihres <strong>Wunschortes</strong> nach Service-Centern.",
        "noScs": "Leider konnten wir in Ihrer Nähe kein Service-Center finden.<br/><br/><strong>Ihre  Möglichkeiten:</strong><br/><br/> Sie wählen einen alternativen Wunschort.<br/><strong>oder<strong><br/>Unser mobiler Autoglas-Service kommt zu Ihnen.",
        "noScsWithoutMobileService": "<strong>Kein Service-Center am Wunschort</strong><br/><br/>Bitte rufen Sie uns kostenfrei unter <a href=\"tel:{{plainPhone}}\"><strong>{{phone}}</strong></a> an, damit wir für Sie eine passende Lösung finden.",
        "noAppointment": "In den kommenden drei Wochen ist kein Termin frei. <br/><br/>",
        "noAppointmentForSc": "<strong>Kein freier Termin in dem gewählten Service-Center verfügbar.</strong><br/>In den kommenden 3 Wochen ist kein Termin frei.<br/>Bitte <strong>wählen</strong> Sie ein <strong>anderes Service-Center.</strong>",
        "busyInfo": "Wir sind beschäftigter als gewöhnlich. <br><strong>Sichern Sie sich einen der wenigen Termine.</strong>"
      },
      "ctas": {
        "mobileServiceTitle": "Zum mobilen Service",
        "noAppointmentTitle": "Weiter ohne Termin",
        "noAppointmentSubtitle": "Wir setzen uns mit Ihrem Kunden in Verbindung."
      }
    },
    "appointmentTime": {
      "texts": {
        "appointmentStart": "Termin von",
        "bringFrom": "Bringen ab",
        "bring": "Bringen",
        "closed": "Geschlossen",
        "pickup": "Abholen bis"
      }
    },
    "nextButton": {
      "title": "Weitere Termine laden"
    },
    "appointmentDetail": {
      "title": "Ihre Terminauswahl",
      "texts": {
        "date": "Datum",
        "clock": "Uhr",
        "appointment": "Ihr Termin",
        "flexiblePickUpTimes": "Flexible Bring- & Holzeiten",
        "repair": {
          "picture": {
            "alt": "Monteur bei der Kalibrierung"
          }
        },
        "replaceWithAdas": {
          "explaination": "Da Ihr Fahrzeug mit Sicherheitssystemen (wie zum Beispiel einem Spurhalteassistenten) ausgestattet ist, kalibrieren wir beim Austausch die Kamera hinter Ihrer Scheibe mit unserer Laser-gestützten Messtechnik.",
          "picture": {
            "alt": "Monteur beim Austausch der Scheibe"
          }
        }
      }
    },
    "resumeInfo": {
      "title": "Ihr Termin wurde schon vergeben",
      "copy": "Bitte wählen Sie einen neuen aus."
    }
  },
  "appointmentAlreadyExists": {
    "texts": {
      "title": "Sie haben bereits einen Termin",
      "subtitle": "Für das Kennzeichen <strong>{{lpn}}</strong> ist bereits ein Termin in unserem System erfasst.",
      "detailTitle": "Sie möchten Details zu Ihrem Termin einsehen oder ändern?",
      "detailParagraph": "Alle Details zu Ihrem Termin können Sie im Carglass<sup>®</sup> Kundenportal einsehen und ändern. Loggen Sie sich dazu mit Ihrem Kennzeichen und Ihrer E-Mail-Adresse ein.",
      "telTitle": "Haben Sie Fragen? Rufen Sie uns kostenlos an."
    },
    "ctas": {
      "customerPortal": "Zum Carglass<sup>®</sup> Kundenportal"
    }
  },
  "appointmentConfirmation": {
    "texts": {
      "title": "Ihr Termin ist bestätigt.",
      "mailConfirmation": "Eine Auftragsbestätigung finden Sie in Ihrem Postfach.",
      "appointment": "Ihr Termin findet statt am",
      "appointmentPlace": "Ihr Termin findet hier statt:",
      "appointmentNeccessary": "Folgendes wird zum Termin benötigt:",
      "protectFrontWindow": "Auftragen der Protect Scheibenversiegelung",
      "wiperBooked": "Austausch Frontscheibenwischer",
      "repairFrontWindow": "Reparatur der Frontscheibe",
      "replaceFrontWindow": "Austausch der Frontscheibe",
      "wiperFrontWindow": "Inklusive Bosch-Frontscheibenwischer",
      "lpn": "Kennzeichen:",
      "damageDate": "Schadensdatum:",
      "company": "Angaben zur Firma:",
      "driver": "Angaben zum Fahrer:",
      "driverHukDirectResume": "Angaben zum Versicherungsnehmer:",
      "contactData": "Kontaktdaten:",
      "description": "Auftragsbeschreibung:",
      "insuranceDocuments": "Die letzte Beitragsrechnung oder die aktuelle Police Ihrer Versicherung",
      "vehicleRegistration": "Ihr Fahrzeugschein",
      "damageDateList": "Das Datum, wann der Schaden entstanden ist",
      "adas": {
        "title": "So bereiten Sie Ihr Fahrzeug für den Termin vor",
        "fuel": "Ihr Auto ist vollgetankt",
        "tyrePressure": "Ihr Reifendruck ist optimal",
        "explanation": "<strong>Warum?</strong><br/> Bei einem Scheibentausch müssen wir Ihre Fahrerassistenzsysteme kalibrieren. Dazu muss das Fahrzeug im Einzelfall bewegt werden."
      },
      "tesla": {
        "title": "So bereiten Sie Ihr Tesla {{model}} für den Termin vor:",
        "soc": "Ihr Auto ist ausreichend geladen",
        "tirePressure": "Der Reifendruck ist optimal",
        "software": "Die <strong>neuste Software ist installiert</strong>",
        "explanation": "<strong>Warum?</strong><br/> Bei einem Scheibentausch <strong>müssen</strong> wir die <strong>Fahrerassistenzsysteme neu kalibrieren,</strong> dafür <strong>muss die aktuellste Software installiert</strong> sein. Zusätzlich wird das Fahrzeug im Einzelfall bewegt."
      }
    },
    "ctas": {
      "calendar": "In Ihrem Kalender speichern",
      "route": "Route planen"
    }
  },
  "appointmentAdverseBuyInfoText": {
    "info": {
      "ctas": {
        "question": "Kann ich mit einem Glasschaden bis zum Termin weiter fahren?"
      }
    },
    "details": {
      "texts": {
        "safetyTitle": "Sicherheit",
        "safety": "Es ist wichtig, dass Sie Ihre Scheibe reparieren lassen, um die Stabilität Ihrer Karosserie wiederherzustellen und damit für Ihre Sicherheit zu sorgen. Zwar wird Ihre Scheibe nicht in tausend Einzelteile zerspringen, da sie aus Verbundsicherheitsglas besteht, dennoch sollten Sie zeitnah einen Termin bei Carglass<sup>®</sup> vereinbaren!",
        "temperatureDifferencesTitle": "Temperaturunterschiede",
        "temperatureDifferences": "Wir empfehlen Ihnen, die Klimaanlage nicht direkt auf die Windschutzscheibe zu richten. Dadurch kann ein Steinschlag reißen oder ein entstandener Riss größer werden. Sollte das dennoch passieren, wird die Windschutzscheibe nicht zersplittern oder ins Fahrzeug fallen. Wir empfehlen Ihnen: Vereinbaren Sie zeitnah einen Termin bei Carglass<sup>®</sup>!",
        "policeCheckTitle": "Polizeikontrolle",
        "policeCheck": "Sollten Sie in eine Polizeikontrolle kommen, können Sie die Carglass<sup>®</sup>-Terminbestätigung als Nachweis vorzeigen, dass Sie bereits einen Termin bei Carglass<sup>®</sup> vereinbart haben, um die Scheibe austauschen zu lassen. Die Bestätigung erhalten Sie nach Terminvereinbarung per SMS oder E-Mail."
      }
    },
    "callback": {
      "info": "<strong>Wünschen Sie einen früheren Termin?</strong><br/> <span>Wir rufen Sie gerne zurück, um eine passende Lösung für Sie zu finden.</span>",
      "ctas": {
        "callback": "Zurückrufen lassen"
      }
    }
  },
  "openingHours": {
    "closed": "Geschlossen",
    "isOpen": "<strong id='titleIsOpen'>Jetzt erreichbar</strong> bis {{to}}&nbsp;Uhr",
    "isOpenSoon": "Heute {{from}}–{{to}}&nbsp;Uhr",
    "openOnMonday": "Montag ab {{from}}&nbsp;Uhr",
    "openOnTomorrow": "<strong>{{dayFirstTwoChars}} {{from}} bis {{to}}&nbsp;Uhr</strong>",
    "fromTo": "{{from}}–{{to}}&nbsp;Uhr"
  },
  "carIdentified": {
    "headline": "Wir haben Ihre <span class='text-cg-green'>{{brand}}-Scheibe</span> gefunden",
    "headlineFallback": "Wir haben Ihre Scheibe gefunden",
    "link": "ändern",
    "linkFallback": "Fahrzeug ändern",
    "forwardButton": "Scheibe & Termin sichern",
    "listElements": {
      "elementOne": "<strong>{{replacedScreens}} {{brand}}-Scheiben</strong> hat Carglass<sup>®</sup> allein in {{year}} bei {{brand}} Fahrzeugen erfolgreich ausgetauscht.",
      "elementOneFallback": "Originalqualität mit 30 Jahren Carglass<sup>®</sup>-Garantie.",
      "elementTwo": "Bei Carglass<sup>®</sup> tauschen <strong>erfahrene Profis</strong> die Scheibe  Ihres {{brand}} aus und stellen die elektronischen Extras wieder fachgerecht ein.",
      "elementTwoFallback": "Bei Carglass<sup>®</sup> tauschen <strong>erfahrene Profis</strong> die defekte Scheibe aus und stellen die elektronischen Extras wieder fachgerecht ein.",
      "elementThree": "Die <strong>Hersteller- und Händlergarantien</strong> für Ihren {{brand}} bleiben voll gültig.",
      "elementThreeFallback": "Die <strong>Hersteller- und Händlergarantien</strong> für Ihr Fahrzeug bleiben voll gültig."
    }
  },
  "channelSwitch": {
    "base": {
      "texts": {
        "noWaitingTime": "Aktuell keine Wartezeit. Wir rufen Sie innerhalb weniger Augenblicke zurück.",
        "minutesWaitingTime": "Unsere Rückrufzeit beträgt derzeit <span style=\"color:{{olbGreen}}\">ca. {{minutes}} Minuten</span>",
        "secondsWaitingTime": "Unsere Rückrufzeit beträgt derzeit <span style=\"color:{{olbGreen}}\">ca. {{seconds}} Sekunden</span>"
      }
    },
    "callFailure": {
      "text": "Im Moment können wir Sie leider nicht anrufen. Es gibt ein technisches Problem. Wir bitten Sie, dies zu entschuldigen. \n\n Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut oder rufen Sie unsere Hotline an."
    },
    "selfCall": {
      "title": "So erreichen Sie uns",
      "paragraph": "Ihr Anruf ist kostenfrei – es fallen keine Gebühren an.",
      "countrywide": "Deutschlandweit",
      "worldwide": "<span class=\"font-bold\">Weltweit</span> - Sie sind im Ausland?",
      "openingHoursTitle": "Öffnungszeiten"
    },
    "callTimeout": {
      "title": "Entschuldigung, wir haben zur Zeit eine hohe Auslastung.",
      "closeWindow": {
        "title": "Sie können dieses Fenster nun schließen.",
        "description": "Sie müssen nichts weiter unternehmen."
      },
      "callback": {
        "title": "Wir rufen Sie umgehend zurück.",
        "description": "Wir haben Ihren Rückrufwunsch erhalten und der nächste freie Mitarbeiter wird Sie anrufen."
      }
    },
    "callbackTime": "Unsere Rückrufzeit beträgt derzeit <span class=\"text-cg-green font-bold whitespace-nowrap\">ca. {{ time }} {{ unit }}</span>",
    "callSuccess": {
      "title": "Vielen Dank für das Telefonat.",
      "text": "Wir senden Ihnen eine Zusammenfassung zu Ihrem Auftrag per E-Mail und/oder SMS zu."
    },
    "connectedToAgent": {
      "title": "Sie werden in wenigen Augenblicken mit unseren Autoglas-Spezialisten verbunden."
    },
    "countdown": {
      "title": "Unsere Autoglas-Spezialisten rufen Sie in den nächsten:",
      "minutes": "Minuten an",
      "seconds": "Sekunden an"
    },
    "fallback": {
      "title": "Wir rufen Sie schnellstmöglich zurück."
    },
    "fallbackSuccess": {
      "title": "Wir haben Ihren Rückrufwunsch erhalten.",
      "text": "Unsere Autoglasspezialist/innen werden sich umgehend bei Ihnen melden. <br /> Für einen einfachen Ablauf halten Sie bitte Ihren Fahrzeugschein bereit. <br /> Wir freuen uns auf das Telefonat mit Ihnen."
    },
    "fallbackSuccessOpportunityFunnel": {
      "title": "Bei Rückfragen melden wir uns noch einmal bei Ihnen.",
      "text": "Sollten wir noch Informationen von Ihnen brauchen, werden Sie <strong>morgen</strong> telefonisch von uns kontaktiert."
    },
    "models": {
      "callMeBack": {
        "text": "Jetzt anrufen lassen",
        "secondText": "Anrufen lassen"
      },
      "checkbox": {
        "text": "Ich bin einverstanden, dass Carglass<sup>®</sup> mich unter der angegebenen Mobilnummer zur Beratung hinsichtlich meines Glasschadens sowie zur Vereinbarung eines Termins einmalig kontaktiert."
      },
      "customerCallCenter": {
        "text": "Jetzt 08001003362 anrufen"
      },
      "customerCallUsBack": {
        "title": "Sie sind im Ausland? <strong>00800-88088221</strong>"
      },
      "navigateToHome": {
        "text": "Fertig"
      },
      "phoneNumber": {
        "error": "Bitte geben Sie eine gültige Mobilnummer innerhalb Deutschlands an",
        "placeholder": "Ihre Mobilnummer"
      },
      "general": {
        "title": "<p class=\"text-cg-green\">Wir rufen Sie an.</p> Carglass<sup>®</sup>-Rückrufservice",
        "paragraph": "Gerne helfen wir Ihnen persönlich mit Ihrem Anliegen weiter.",
        "kfzTitle": "Wie lautet das KFZ-Kennzeichen?",
        "fallback": {
          "title": "Wie lautet das KFZ-Kennzeichen?"
        },
        "forward": "Zurückrufen lassen",
        "selfCall": "Carglass<sup>®</sup> <span class=\"underline\">Service anrufen</span>"
      },
      "reason": {
        "link": {
          "text": "Sie können auch unsere Hotline anrufen"
        },
        "paidByDamagingParty": {
          "header": "Um die Kostenübernahme zu klären, helfen wir Ihnen lieber persönlich weiter."
        },
        "multipleWindows": {
          "header": "Bei mehreren beschädigten Scheiben helfen wir Ihnen gerne persönlich weiter."
        },
        "mobileService": {
          "header": "Um den Mobilen Service reibungslos zu planen, helfen wir Ihnen lieber persönlich weiter."
        },
        "productNotFound": {
          "header": "Sie haben es fast geschafft! Unsere Autoglas-Spezialist/innen rufen Sie persönlich an, um die richtige Scheibe für Ihr Fahrzeug zu ermitteln."
        },
        "sunroof": {
          "header": "Bei Dachfenstern helfen wir Ihnen lieber persönlich weiter."
        },
        "vehicleNotFound": {
          "header": "Zu Ihrem Fahrzeug konnten wir keine eindeutige Scheibe finden. Wir helfen Ihnen lieber persönlich weiter."
        },
        "specialVehicle": {
          "header": "Bei LKW, Wohnmobilen und Baufahrzeugen helfen wir Ihnen lieber persönlich weiter."
        },
        "sideOrRear": {
          "header": "Bei beschädigten oder eingeschlagenen Seiten- und Heckscheiben helfen wir Ihnen gerne persönlich weiter."
        },
        "lateAppointments": {
          "header": "Wir helfen Ihnen gerne persönlich weiter und rufen Sie umgehend zurück."
        },
        "uninsured": {
          "header": "Sie sind haftpflichtversichert?"
        },
        "commentNotice": "Gibt es noch wichtige Hinweise für Carglass® zu berücksichtigen?"
      }
    },
    "list": [
      "Wir können Ihnen bei der telefonischen Beratung bis zu <mark class=\"p-1 bg-cg-yellow font-bold whitespace-nowrap\">20% Rabatt</mark> anbieten."
    ],
    "lpn": {
      "headline": "KFZ-Kennzeichen eingeben",
      "paragraph": "Damit Sie schneller zu Ihrem Termin kommen, benötigen wir Ihr Kennzeichen."
    },
    "unit": {
      "minute": "Min.",
      "minutes": "Min.",
      "seconds": "Sek."
    }
  },
  "channelSwitchOpportunityFunnel": {
    "base": {
      "texts": {
        "noWaitingTime": "Aktuell keine Wartezeit. Wir rufen Sie innerhalb weniger Augenblicke zurück.",
        "minutesWaitingTime": "Unsere Rückrufzeit beträgt derzeit <span style=\"color:{{olbGreen}}\">ca. {{minutes}} Minuten</span>",
        "secondsWaitingTime": "Unsere Rückrufzeit beträgt derzeit <span style=\"color:{{olbGreen}}\">ca. {{seconds}} Sekunden</span>"
      },
      "title": "Wir rufen Sie an.",
      "subTitle": "Carglass<sup>®</sup>-Rückrufservice",
      "paragraph": "Gerne helfen wir Ihnen persönlich mit Ihrem Anliegen weiter."
    },
    "callFailure": {
      "text": "Im Moment können wir Sie leider nicht anrufen. Es gibt ein technisches Problem. Wir bitten Sie, dies zu entschuldigen. \n\n Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut oder rufen Sie unsere Hotline an."
    },
    "callTimeout": {
      "title": "Entschuldigung, wir haben zur Zeit eine hohe Auslastung.",
      "closeWindow": {
        "title": "Sie können dieses Fenster nun schließen.",
        "description": "Sie müssen nichts weiter unternehmen."
      },
      "callback": {
        "title": "Wir rufen Sie umgehend zurück.",
        "description": "Wir haben Ihren Rückrufwunsch erhalten und der nächste freie Mitarbeiter wird Sie anrufen."
      }
    },
    "callSuccess": {
      "text": "Wir senden Ihnen eine Zusammenfassung zu Ihrem Auftrag per E-Mail und/oder SMS zu."
    },
    "fallback": {
      "title": "Wir rufen Sie schnellstmöglich <span class='text-cg-green'>am nächsten Werktag</span> zurück."
    },
    "fallbackSuccess": {
      "text": "Unsere Autoglasspezialist/innen werden sich umgehend bei Ihnen melden. <br /> Für einen einfachen Ablauf halten Sie bitte Ihren Fahrzeugschein bereit. <br /> Wir freuen uns auf das Telefonat mit Ihnen."
    },
    "models": {
      "callMeBack": {
        "text": "Rückruf buchen",
        "secondText": "Rückruf buchen"
      },
      "checkbox": {
        "text": "Ich bin einverstanden, dass Carglass<sup>®</sup> mich unter der angegebenen Telefonnummer zur Beratung hinsichtlich meines Glasschadens, sowie zur Vereinbarung eines Termins einmalig kontaktiert."
      },
      "customerCallCenter": {
        "text": "Jetzt 08001003362 anrufen"
      },
      "customerCallUsBack": {
        "title": "Sie sind im Ausland? <strong>00800-88088221</strong>"
      },
      "navigateToHome": {
        "text": "Fertig"
      },
      "phoneNumber": {
        "error": "Bitte geben Sie eine gültige Mobilnummer innerhalb Deutschlands an",
        "placeholder": "Ihre Mobilnummer"
      },
      "reason": {
        "link": {
          "text": "Sie können auch unsere Hotline anrufen"
        }
      }
    },
    "lpn": {
      "headline": "KFZ-Kennzeichen eingeben",
      "subHeadline": "(inkl H- und E-Kennzeichen)"
    }
  },
  "chatInsuranceType": {
    "buttons": {
      "a11y": {
        "legend": "Auswahl ob Kasko versichert oder nicht"
      },
      "fully": "Ja, mein Auto ist kaskoversichert",
      "notFully": "Nein",
      "unknown": "Ich weiß es nicht"
    }
  },
  "contactForm": {
    "subject": {
      "a11y": {
        "label": "Betreff wählen"
      },
      "title": "Wie können wir Ihnen helfen?",
      "placeholder": "Betreff wählen*",
      "errorMessage": "Treffen Sie bitte eine Auswahl, bevor Sie fortfahren können."
    },
    "subjectType": {
      "move-appointment": "Terminverschiebung",
      "cancel-appointment": "Terminstornierung",
      "repair-replace": "Fragen zur Scheibenreparatur bzw. Scheibenaustausch",
      "general": "Allgemeine Anfragen",
      "complaint-feedback": "Beschwerden oder Feedback",
      "vaps": "Anfragen zu Sonderleistungen",
      "technical-problems": "Technische Probleme mit der Website oder dem Buchungssystem",
      "guarantee-warranty": "Informationen zu Garantien und Gewährleistungen",
      "bill-payment": "Fragen zu Rechnung und Zahlung",
      "call-back": "Bitte um Rückruf",
      "others": "Keines der aufgeführten Themen"
    },
    "message": {
      "title": "Ihre Nachricht an uns",
      "placeholder": "Was möchten Sie uns mitteilen?*",
      "errorMessage": "Bitte geben Sie eine Nachricht an"
    },
    "contact": {
      "title": "Wie erreichen wir Sie?"
    },
    "inputs": {
      "firstName": {
        "placeholder": "Vorname"
      },
      "lastName": {
        "placeholder": "Nachname",
        "placeholderRequired": "Nachname*",
        "errorMessage": "Bitte geben Sie einen Nachnamen an"
      },
      "email": {
        "placeholder": "E-Mail",
        "placeholderRequired": "E-Mail*",
        "errorMessage": "Bitte geben Sie eine korrekte E-Mail-Adresse an"
      },
      "mobile": {
        "placeholder": "Mobilnummer",
        "placeholderRequired": "Mobilnummer*",
        "errorMessage": "Bitte geben Sie eine korrekte Mobilnummer an"
      }
    },
    "lpn": {
      "title": "Wie lautet Ihr Kennzeichen?",
      "errorMessage": "Geben Sie bitte ein gültiges Kennzeichen ein, bevor Sie fortfahren können."
    },
    "privacy": "Wir verwenden Ihre Angaben zur Beantwortung Ihrer Anfrage.<br/> Weitere Informationen finden Sie in unseren <a href='{{privacyUrl}}' target='_blank' class='underline'>Datenschutzbestimmungen</a>.",
    "phoneContact": {
      "title": "Oder rufen Sie uns an",
      "subtitle": "Auch am Telefon sind wir gern für Sie da.",
      "countrywide": "Deutschlandweit",
      "worldwide": "Weltweit",
      "worldwideTeam": "Internationales Team"
    }
  },
  "costCheck": {
    "title": "Carglass<sup>®</sup> Kostencheck",
    "repair": "Reparatur",
    "replace": "Austausch*",
    "insurance": {
      "title": "Versicherung",
      "type": "Haben Sie eine Kaskoversicherung (Voll-/Teilkasko)?",
      "buttons": {
        "a11y": {
          "legend": "Auswahl ob Kasko versichert oder nicht"
        },
        "yes": "Ja (ich bin Kasko versichert)",
        "no": "Nein"
      },
      "name": "Wie heißt Ihre Versicherung?",
      "placeholder": "Bitte wählen Sie Ihre Versicherung aus"
    },
    "withInsurance": {
      "title": "Die Versicherung übernimmt die Kosten für Kasko-Versicherte",
      "repair": {
        "title": "Ihre Scheibenreparatur bei Carglass<sup>®</sup>:",
        "description": "Anfallende Kosten trägt vollständig Ihre Versicherung."
      },
      "replace": {
        "title": "Ihr Scheibenaustausch bei Carglass<sup>®</sup>:",
        "subtitle": "+ Ihre Selbstbeteiligung:",
        "price": "meist 150 €",
        "description": "Die Hauptkosten trägt Ihre Versicherung. Ihr Kostenanteil hängt von der vereinbarten Selbstbeteiligung ab. Meist sind das 150 Euro."
      }
    },
    "withoutInsurance": {
      "priceList": {
        "repair": {
          "oneStone": "1 Steinschlag: {{price}} Euro inkl. MwSt.",
          "twoStones": "2 oder 3 Steinschläge: {{price}} Euro inkl. MwSt."
        },
        "replace": {
          "frontWithoutAssistant": "Windschutzscheiben ohne Fahrerassistenzsystem ab {{price}} Euro inkl. MwSt.",
          "frontWithAssistant": "Windschutzscheiben mit Fahrerassistenzsystem ab {{price}} Euro inkl. MwSt.",
          "side": "Seitenscheiben ab {{price}} Euro inkl. MwSt.",
          "rear": "Heckscheiben ab {{price}} Euro inkl. MwSt.",
          "emergency": "Notverglasung: {{price}} Euro inkl. MwSt."
        }
      },
      "note": "*Die genauen Kosten hängen von Fahrzeugtyp und Ausstattung ab (z.B. beheizbare Scheibe, Fahrerassistenzsysteme)"
    },
    "customerRating": {
      "title": "Ihre Vorteile bei Carglass<sup>®</sup>",
      "subtitle": "42.490 Kunden bewerten uns mit sehr gut",
      "placeholder": "Trustpilot"
    },
    "usp": {
      "cost": "In der Regel kostenlos",
      "time": "Innerhalb eines Tages fertig",
      "guarantee": "30 Jahre Garantie auf Reparatur",
      "insurance": "Ihr <strong>Schadenfreiheitsrabatt</strong> bleibt in jedem Fall <strong>unberührt</strong>."
    },
    "cta": {
      "title": "Schaden prüfen und Termin vereinbaren",
      "subtitle": "In nur 5 Minuten zum Termin.",
      "button": "Jetzt Schaden prüfen"
    }
  },
  "customerAddress": {
    "title": "Wer sind Sie?",
    "subtitle": "Bitte geben Sie Ihre persönlichen Daten ein. Der Versicherungsnehmer des beschädigten Fahrzeugs ist Auftraggeber für die Reparatur.",
    "subtitleIOM": "Bitte geben Sie hier die Kontaktdaten der Person an, auf die das Auto versichert ist <strong>(Versicherungsnehmer/in)</strong>.",
    "insuranceHolder": "Angaben zum Versicherungsnehmer",
    "driverInformation": "Der Versicherungsnehmer des beschädigten Fahrzeugs ist Auftraggeber für die Reparatur.",
    "radioButtons": {
      "policyHolder": {
        "a11y": {
          "legend": "Auswahl ob Versicherungsnehmer oder nicht"
        },
        "yes": "Ich bin der Versicherungsnehmer",
        "no": "Ich bin nicht der Versicherungsnehmer"
      }
    },
    "ctas": {
      "terms": "Bitte nehmen Sie unsere AGB zur Kenntnis.",
      "privacy": "Bitte nehmen Sie unsere Datenschutzbestimmungen zur Kenntnis."
    },
    "country": {
      "a11y": {
        "label": "Auswahl Land"
      },
      "label": "Land"
    },
    "countries": {
      "de": "Deutschland",
      "be": "Belgien",
      "lu": "Luxemburg",
      "dk": "Dänemark",
      "ee": "Estland",
      "fr": "Frankreich",
      "it": "Italien",
      "hr": "Kroatien",
      "nl": "Niederlande",
      "no": "Norwegen",
      "at": "Österreich",
      "pt": "Portugal",
      "se": "Schweden",
      "ch": "Schweiz",
      "sl": "Slowenien",
      "es": "Spanien",
      "tr": "Türkei",
      "gb": "UK",
      "hu": "Ungarn"
    },
    "inputs": {
      "firstname": {
        "placeholder": "Vorname",
        "error": "Bitte geben Sie einen Vornamen an"
      },
      "lastname": {
        "placeholder": "Nachname",
        "error": "Bitte geben Sie einen Nachnamen an"
      },
      "street": {
        "placeholder": "Straße und Hausnummer",
        "error": "Bitte geben Sie eine Straße und Hausnummer an"
      },
      "zip": {
        "placeholder": "PLZ",
        "error": "Bitte geben Sie eine PLZ an"
      },
      "city": {
        "placeholder": "Ort",
        "error": "Bitte geben Sie einen Ort an"
      },
      "company": {
        "placeholder": "Firma",
        "error": "Bitte geben Sie eine Firma an"
      },
      "phone": {
        "placeholder": "Telefon",
        "error": "Bitte geben Sie eine Mobilnummer an"
      }
    }
  },
  "newCustomerAddress": {
    "radioButtons": {
      "taxDeductible": {
        "a11y": {
          "legend": "Vorsteuerabzugsberechtigt ja oder nein"
        },
        "yes": "ja",
        "no": "nein"
      }
    },
    "title": "Auf wen ist das Auto versichert?",
    "subtitle": "Bitte geben Sie hier die Kontaktdaten der Person oder Firma an, auf die das Auto versichert ist <strong>(Versicherungsnehmer/in).</strong>",
    "tabPrivate": "Privatperson",
    "tabCompany": "Firma",
    "differentContact": "abweichender Ansprechpartner",
    "taxDeductible": "Vorsteuerabzugsberechtigt",
    "country": {
      "a11y": {
        "label": "Auswahl Land"
      },
      "label": "Land"
    },
    "companyName": {
      "placeholder": "Firma",
      "errorMessage": "Bitte geben Sie einen Firmennamen ein"
    },
    "firstName": {
      "placeholder": "Vorname",
      "errorMessage": "Bitte geben Sie einen Vornamen ein"
    },
    "lastName": {
      "placeholder": "Nachname",
      "errorMessage": "Bitte geben Sie einen Nachnamen ein"
    },
    "street": {
      "placeholder": "Straße und Hausnummer",
      "errorMessage": "Bitte geben Sie eine Straße und Hausnummer an"
    },
    "zip": {
      "placeholder": "PLZ",
      "errorMessage": "Bitte geben Sie eine PLZ an"
    },
    "city": {
      "placeholder": "Ort",
      "errorMessage": "Bitte geben Sie einen Ort an"
    },
    "phone": {
      "placeholder": "Mobilnummer",
      "errorMessage": "Bitte geben Sie eine gültige Mobilnummer ein"
    },
    "alternativePhone": {
      "placeholder": "Alternative Mobilnummer (optional)",
      "errorMessage": "Bitte geben Sie eine gültige Mobilnummer ein"
    },
    "email": {
      "placeholder": "E-Mail",
      "errorMessage": "Bitte geben Sie eine gültige E-Mail Adresse ein"
    },
    "contactPerson": "Ansprechpartner",
    "otherContactPerson": {
      "title": "abweichender Ansprechpartner",
      "description": "Bitte geben Sie hier die persönlichen Kontaktdaten des Ansprechpartners bzw. Fahrers an, der zum Termin kommen wird."
    },
    "infoText": "Wir empfehlen, eine <strong>E-Mail Adresse anzugeben,</strong> damit der Kunde <strong>MeinCarglass</strong><sup>®</sup> nutzen und selbst seinen Termin vereinbaren/ändern kann."
  },
  "customerContact": {
    "title": "Wie können wir Sie erreichen?",
    "subHeadline": "Informationen rund um Ihren Auftrag erhalten Sie per <strong>E-Mail und SMS</strong>. Bei Rückfragen zu Ihrem Termin melden wir uns telefonisch.",
    "subHeadlineEarlyCustomerDataTestAllData": "Damit wir Ihren Termin buchen und Sie erreichen können, benötigen wir nun Ihre Kontaktdaten.",
    "subHeadlineEarlyCustomerDataTestMailOnly": "Damit wir Ihren Termin buchen und Sie erreichen können, benötigen wir nun Ihre E-Mail-Adresse.",
    "agreement": "Mit Klick auf <strong>Weiter</strong> erkläre ich mich damit einverstanden, dass ich per E-Mail und Telefon/SMS <strong>ausschließlich</strong> zu meinem Auftrag informiert werde.",
    "agreementEarlyCustomerDataTestAllData": "Die obigen Kontaktdaten werden wir ausschließlich dafür nutzen, um per E-Mail oder Telefon/SMS mit Ihnen bezüglich Ihrer Anfrage Kontakt aufzunehmen.",
    "agreementEarlyCustomerDataTestMailOnly": "Die obige E-Mail-Adresse werden wir ausschließlich verwenden, um mit Ihnen bezüglich Ihrer Anfrage Kontakt aufzunehmen.",
    "inputs": {
      "email": {
        "placeholder": "Ihre E-Mail-Adresse",
        "errorMessage": "Bitte geben Sie eine korrekte E-Mail-Adresse an"
      },
      "mobile": {
        "placeholder": "Mobilnummer",
        "errorMessage": "Bitte geben Sie eine korrekte Mobilnummer an"
      },
      "landline": {
        "placeholder": "Festnetznummer",
        "errorMessage": "Bitte geben Sie eine korrekte Festnetznummer an"
      }
    },
    "ctas": {
      "privacy": "Unsere Datenschutzbestimmungen"
    },
    "nextButton": {
      "finish": "Termin buchen",
      "default": "Weiter"
    }
  },
  "editCustomerContact": {
    "title": "Kontaktdaten ändern"
  },
  "overlayFooter": {
    "footerButtons": {
      "abort": "Abbrechen",
      "save": "Speichern"
    }
  },
  "damageLocation": {
    "title": "In welchem Bereich befindet sich der Steinschlag?",
    "buttons": {
      "a11y": {
        "legend": "Auswahl Steinschlagbereich"
      },
      "edge": "1. Weniger als 10 cm vom Rand",
      "view": "2. Fahrersichtfeld (über dem Lenkrad)",
      "other": "3. In einem anderen Bereich"
    }
  },
  "damageLocationMultipleChips": {
    "title": "Sind alle Steinschläge im grauen Bereich?",
    "buttons": {
      "a11y": {
        "legend": "Auswahl Steinschlagbereich"
      },
      "yes": "Ja, alle Steinschläge sind im grauen Bereich.",
      "no": "Nein, nicht alle Steinschläge sind im grauen Bereich."
    }
  },
  "damageSize": {
    "title": "Wie groß ist der Steinschlag?",
    "buttons": {
      "a11y": {
        "legend": "Auswahl Schadensgröße"
      },
      "small": "Kleiner 2,- € Münze",
      "big": "Größer 2,- € Münze"
    }
  },
  "damageChipCount": {
    "title": "Wie viele Steinschläge sind vorhanden?",
    "buttons": {
      "a11y": {
        "legend": "Auswahl Anzahl Steinschläge"
      },
      "two": "2 Steinschläge",
      "three": "3 Steinschläge",
      "moreThanThree": "Mehr als drei"
    }
  },
  "damagesSizeMultipleChips": {
    "title": "Ist ein Schaden größer als eine zwei Euro Münze?",
    "imageLabel": "Zwei Euro Münze",
    "buttons": {
      "a11y": {
        "legend": "Auswahl Schadensgröße"
      },
      "no": "Alle sind kleiner",
      "yes": "Einer ist größer"
    }
  },
  "damageType": {
    "title": "Welcher Schaden liegt vor?",
    "buttons": {
      "1to3": "1 - 3 Steinschläge",
      "3OrMore": "Mehr als 3",
      "crack": "Riss/Eingeschlagen"
    }
  },
  "damageTypeMultipleChips": {
    "title": "Welcher Schaden liegt vor?",
    "buttons": {
      "a11y": {
        "legend": "Auswahl Schadensart"
      },
      "1": "1 Steinschlag",
      "moreThan1": "Mehr als einer",
      "crack": "Riss/Eingeschlagen"
    }
  },
  "easinessOfProcess": {
    "headline": "Buchen Sie Ihren Termin in 5 Minuten",
    "subHeadline": "Sie nennen uns Ihren Schaden, wir kümmern uns um den Rest.",
    "customWelcome": {
      "genericHeadline": "Willkommen",
      "headline": "Hallo {{name}}",
      "subHeadline": "Buchen Sie Ihren Termin in 5 Minuten"
    }
  },
  "damageWindow": {
    "buttons": {
      "a11y": {
        "legend": "Auswahl beschädigte Scheibe"
      },
      "front": "Frontscheibe",
      "rear": "Heckscheibe",
      "roof": "Dachfenster",
      "side": "Seitenscheibe"
    },
    "specialOptions": {
      "multipleWindows": "Mehrere Scheiben beschädigt",
      "specialVehicle": "Wohnmobile / LKW / Sonderfahrzeuge"
    },
    "moreOptions": "Weitere Optionen",
    "title": "Welche Scheibe ist beschädigt?",
    "toastTitle": "Wir sind weiterhin in allen Service-Centern für Sie da."
  },
  "damageAssessmentResult": {
    "titleReplace": "Wir tauschen Ihre Scheibe aus",
    "titleRepair": "Wir reparieren Ihre Scheibe",
    "subTitle": "Schaden erfolgreich erfasst.",
    "prefixDialog": {
      "titleReplace": "Scheibenaustausch",
      "titleRepair": "Scheibenreparatur",
      "textCrack": "Wir sind gesetzlich dazu verpflichtet Ihre Scheibe auszutauschen, wenn sie einen Riss aufweist.<br/><br/>Wie lange der Austausch Ihrer Windschutzscheibe genau dauert, hängt maßgeblich vom Fahrzeugtyp und der Ausstattung ab. In den nächsten Schritten der Online-Buchung erfahren Sie mehr über die Dauer des Termins.",
      "textMajor": "Wir tauschen Ihre Scheibe aus, wenn diese mehr als drei Steinschläge aufweist.<br/><br/>Wie lange der Austausch Ihrer Windschutzscheibe genau dauert, hängt maßgeblich vom Fahrzeugtyp und der Ausstattung ab. In den nächsten Schritten der Online-Buchung erfahren Sie mehr über die Dauer des Termins.",
      "textSmallDamage": "Ihr Steinschlag kann repariert werden.<br/><br/>Unsere patentierte und TÜV-zertifizierte Glass Medic<sup>®</sup> Technologie gilt als innovative, starke und langlebige Steinschlag-Reparatur.<br/><br/>Damit Sie schnell wieder sicher unterwegs sind, sollten unsere Experten den Steinschlag zeitnah prüfen und Ihre Windschutzscheibe reparieren. In den nächsten Schritten der Online-Buchung können Sie ganz bequem einen Termin in Ihrer Nähe buchen.",
      "textBigDamage": "Wir tauschen Ihre Scheibe aus, da der Schaden größer als eine Zwei-Euro-Münze ist.<br/><br/>Wie lange der Austausch Ihrer Windschutzscheibe genau dauert, hängt maßgeblich vom Fahrzeugtyp und der Ausstattung ab. In den nächsten Schritten der Online-Buchung erfahren Sie mehr über die Dauer des Termins.",
      "textEdge": "Wir tauschen Ihre Scheibe aus, da der Schaden weniger als zehn Zentimeter vom Rand entfernt ist. Es besteht die Möglichkeit, dass die Windschutzscheibe reißt und wir müssen sie austauschen.<br/><br/>Wie lange der Austausch Ihrer Windschutzscheibe genau dauert, hängt maßgeblich vom Fahrzeugtyp und der Ausstattung ab. In den nächsten Schritten der Online-Buchung erfahren Sie mehr über die Dauer des Termins.",
      "textView": "Wir tauschen Ihre Scheibe aus, da sich der Schaden im Fahrersichtfeld befindet. Die Reparatur ist in diesem Fall gesetzlich verboten.<br/><br/>Wie lange der Austausch Ihrer Windschutzscheibe genau dauert, hängt maßgeblich vom Fahrzeugtyp und der Ausstattung ab. In den nächsten Schritten der Online-Buchung erfahren Sie mehr über die Dauer des Termins.",
      "titleOriginalWindow": "Scheiben in Originalqualität",
      "textOriginalWindow": "Beim Austausch von Windschutzscheiben verbauen wir natürlich nur Scheiben in Originalqualität. Diese erfüllen sämtliche internationale Vorschriften für Sicherheitsglas (ECE-Regelung 43) und sind absolut gleichwertig mit den Originalscheiben der PKW-Hersteller."
    }
  },
  "summary": {
    "title": "Fast geschafft",
    "subTitle": "Bitte kontrollieren Sie Ihre Angaben auf Richtigkeit.",
    "damageDescription": {
      "title": "Schadenserfassung",
      "front": "Frontscheibe",
      "replace": "Austausch",
      "repair": "Reparatur"
    },
    "branch": "Ihr Service-Center",
    "desiredTime": "Termin",
    "insurance": {
      "title": "Versicherung",
      "insuranceType": {
        "unknown": "Nicht bekannt",
        "full": "Vollkasko</br>{{insuranceName}}"
      }
    },
    "car": "Fahrzeug",
    "confirmation": {
      "consent": "Durch Klicken auf \"{{buttonText}}\" stimmen Sie unseren <a href='{{privacyUrl}}' target='_blank' id='privacy-policy-link'>Datenschutzbestimmungen</a> zu.",
      "buttonTextDefault": "Termin buchen",
      "buttonTextOpportunityFunnel": "Terminanfrage senden"
    },
    "appointment": {
      "appointmentHeadline": "Termin"
    },
    "contactData": "Kontaktdetails",
    "customerData": "Versicherungsnehmer",
    "additionalProduct": {
      "title": "Zusatz-Service",
      "description": "<strong>Carglass<sup>®</sup> {{product}}</strong> - für mehr Sicherheit</br>{{price}} €"
    },
    "opportunityFunnel": {
      "toastMessage": "Ihr <strong>Terminwunsch</strong> wird von uns noch <strong>telefonisch</strong> oder per <strong>E-Mail bestätigt</strong>.",
      "damageDescription": {
        "side": "Seitenscheibe",
        "multipleSide": "+2 Seitenscheiben",
        "rear": "Heckscheibe",
        "multipleRear": "+2 Heckscheiben",
        "front": "Frontscheibe"
      },
      "appointment": {
        "desiredAppointmentHeadline": "Terminanfrage"
      },
      "callTime": {
        "title": "Telefonisch erreichbar",
        "now": "Sofort",
        "later": "Morgen"
      },
      "period": {
        "morning": "vormittags",
        "afternoon": "nachmittags"
      }
    }
  },
  "insuranceType": {
    "title": "Wie ist das Auto versichert?",
    "subHeadline": "Wählen Sie Ihre Versicherung aus. Wir klären die Details mit Ihrer Versicherung.",
    "errors": {
      "errorMessage": "Treffen Sie bitte eine Auswahl, bevor Sie fortfahren können."
    },
    "buttons": {
      "a11y": {
        "legend": "Auswahl der Versicherungsart"
      },
      "fully": "Vollkasko / Teilkasko",
      "fullyYes": "Ja",
      "fullyNo": "Nein",
      "liability": "Nur Haftpflicht",
      "unknown": "Ich weiß es nicht",
      "paid_by_perpetrator": "Verursacher zahlt den Schaden",
      "none": "Ohne Versicherung"
    },
    "insuranceQuestion": "Wie heißt Ihre Versicherung?",
    "insuranceTypeOptions": {
      "title": "Versicherungsart"
    },
    "insuranceSelection": {
      "title": "Versicherung auswählen",
      "titleAbTest": "Bei welcher Versicherung?",
      "searchPlaceholder": "Versicherung suchen",
      "optionsHeadline": "Alphabetisch sortiert",
      "topOptionsHeadline": "Die häufigsten Versicherungen",
      "noMatchText": "Leider konnten wir unter Ihrem Suchbegriff keine Versicherung finden.",
      "fuzzyResultHeadline": "Meinten Sie vielleicht",
      "fuzzyEnhancementHeadline": "Ähnliche Ergebnisse",
      "footerButtonLabel": "Meine Versicherung ist nicht vorhanden",
      "placeholder": "Bitte Versicherer auswählen"
    },
    "insuranceSelectionPlaceholder": "Bitte Versicherer auswählen",
    "additionalOptions": "Weitere Optionen",
    "ctas": {
      "paidByPerpetrator": "Schaden wird vom Verursacher gezahlt.",
      "carIsNotInsured": "Mein Auto ist nicht versichert."
    },
    "detail": {
      "replace": {
        "fully": {
          "headline": "Ihr Fahrzeug ist voll- oder teilkasko versichert. Sehr gut.",
          "headlineAbTest": "Für Kasko-Versicherte übernimmt die Versicherung die Kosten",
          "subheadline": "Die Hauptkosten für den Austausch trägt Ihre Versicherung. Ihr persönlicher Kostenanteil hängt von der vereinbarten Selbstbeteiligung ab.",
          "description": "Ihr bestehender Schadenfreiheitsrabatt bleibt in jedem Fall unberührt.",
          "description1AbTest": "Wir klären die Details mit Ihrer Versicherung.",
          "description2AbTest": "Die Hauptkosten für den Austausch trägt Ihre Versicherung. Ihr persönlicher Kostenanteil hängt von der vereinbarten Selbstbeteiligung ab.",
          "description3AbTest": "Ihr bestehender Schadenfreiheitsrabatt bleibt in jedem Fall unberührt."
        },
        "liability": {
          "headline": "Ihr Fahrzeug ist Haftpflicht versichert.",
          "headlineAbTest": "Bei einer Haftpflichtversicherung helfen wir Ihnen persönlich weiter.",
          "description": "Im nächsten Schritt werden wir mit Ihnen die passende Scheibe bestimmen und Ihnen ein individuelles Angebot machen. Klicken Sie bitte auf \"Weiter\".",
          "subheadline": "Gerne beheben wir den Schaden an Ihrer Scheibe. Sie tragen die Kosten selbst.",
          "description1AbTest": "Windschutzscheiben unterscheiden sich nach Fahrzeugtyp und Klasse, daher machen wir Ihnen telefonisch ein individuelles Angebot."
        },
        "unknown": {
          "headline": "Versicherung nicht bekannt. Kein Problem.",
          "headlineAbTest": "Versicherung nicht bekannt? Kein Problem.",
          "subheadline": "In den nächsten Schritten können wir Ihren Versicherungsstatus (falls gewünscht) auch automatisiert herausfinden.",
          "description": "Gerne klären wir mit Ihnen auch vor Ort im Service-Center, ob für Sie Kosten anfallen.",
          "description1AbTest": "In den nächsten Schritten können wir Ihren Versicherungsstatus (falls gewünscht) auch automatisiert herausfinden.",
          "description2AbTest": "Gerne klären wir mit Ihnen auch vor Ort im Service-Center, ob für Sie Kosten anfallen."
        },
        "unselected": {
          "headline": "Entsprechend Ihrer Auswahl überprüfen wir, ob die Kosten von Ihrer Versicherung übernommen werden."
        },
        "paid_by_perpetrator": {
          "headlineAbTest": "Wir helfen Ihnen persönlich weiter.",
          "description1AbTest": "Um die Kostenübernahme zu klären helfen wir Ihnen persönlich weiter."
        },
        "none": {
          "headlineAbTest": "Wir helfen Ihnen persönlich weiter.",
          "description1AbTest": "Windschutzscheiben unterscheiden sich nach Fahrzeugtyp und Klasse, daher machen wir Ihnen telefonisch ein individuelles Angebot."
        }
      },
      "repair": {
        "fully": {
          "headline": "Ihr Fahrzeug ist voll- oder teilkasko versichert. Sehr gut.",
          "headlineAbTest": "Für Kasko-Versicherte übernimmt die Versicherung die Kosten",
          "subheadline": "Ihre Kosten werden beinahe von allen Versicherungen vollständig übernommen.",
          "description": "Ihr bestehender Schadenfreiheitsrabatt bleibt in jedem Fall unberührt.",
          "description1AbTest": "Wir klären die Details mit Ihrer Versicherung.",
          "description2AbTest": "Ihr bestehender Schadenfreiheitsrabatt bleibt in jedem Fall unberührt."
        },
        "liability": {
          "headline": "Ihr Fahrzeug ist Haftpflicht versichert.",
          "headlineAbTest": "Ihre Kfz-Haftpflicht übernimmt die Kosten nicht.",
          "subheadline": "Gerne beheben wir den Schaden an Ihrer Scheibe. Sie tragen die Kosten selbst.",
          "description1AbTest": "Gerne beheben wir den Schaden an Ihrer Scheibe. Sie tragen die Kosten selbst.",
          "description2AbTest": "Die Kosten für die Reparatur eines Steinschlages betragen {{price}} Euro inkl. MwSt.. Die Reparatur von zwei oder drei Steinschlägen kostet {{priceMoreChips}} Euro inkl. MwSt..",
          "description": "Die Kosten für die Reparatur eines Steinschlages betragen {{price}} Euro inkl. MwSt.. Die Reparatur von zwei oder drei Steinschlägen kostet {{priceMoreChips}} Euro inkl. MwSt.."
        },
        "unknown": {
          "headline": "Versicherung nicht bekannt. Kein Problem.",
          "headlineAbTest": "Versicherung nicht bekannt? Kein Problem.",
          "subheadline": "In den nächsten Schritten können wir Ihren Versicherungsstatus (falls gewünscht) auch automatisiert herausfinden.",
          "description": "Gerne klären wir mit Ihnen auch vor Ort im Service-Center, ob für Sie Kosten anfallen.",
          "description1AbTest": "In den nächsten Schritten können wir Ihren Versicherungsstatus (falls gewünscht) auch automatisiert herausfinden.",
          "description2AbTest": "Gerne klären wir mit Ihnen auch vor Ort im Service-Center, ob für Sie Kosten anfallen."
        },
        "unselected": {
          "headline": "Entsprechend Ihrer Auswahl überprüfen wir, ob die Kosten von Ihrer Versicherung übernommen werden."
        },
        "paid_by_perpetrator": {
          "headlineAbTest": "Wir helfen Ihnen persönlich weiter.",
          "description1AbTest": "Gerne beheben wir den Schaden an Ihrer Scheibe. Sie tragen die Kosten selbst.",
          "description2AbTest": "Die Kosten für die Reparatur eines Steinschlages betragen {{price}} Euro inkl. MwSt.. Die Reparatur von zwei oder drei Steinschlägen kostet {{priceMoreChips}} Euro inkl. MwSt.."
        },
        "none": {
          "headlineAbTest": "Das Auto ist nicht versichert.",
          "description1AbTest": "Gerne beheben wir den Schaden an Ihrer Scheibe. Sie tragen die Kosten selbst.",
          "description2AbTest": "Die Kosten für die Reparatur eines Steinschlages betragen {{price}} Euro inkl. MwSt.. Die Reparatur von zwei oder drei Steinschlägen kostet {{priceMoreChips}} Euro inkl. MwSt.."
        }
      }
    }
  },
  "insuranceCoveredMessage": {
    "headline": "<span class='text-cg-green'>Gute Nachricht</span> – für Kasko-Versicherte übernimmt die Versicherung die Kosten",
    "subHeadline": "Das bedeutet für Sie:",
    "items": {
      "text1": "<strong>Die Versicherung übernimmt die Hauptkosten für Ihre neue Scheibe.</strong> Je nach vereinbarter Selbstbeteiligung variiert Ihr Kostenanteil.",
      "text2": "<strong>Ihr Schadenfreiheitsrabatt bleibt unberührt.</strong>",
      "text3": "<strong>Die Versicherung übernimmt die Kosten für die Reparatur.</strong>"
    }
  },
  "insurancePartnerMessage": {
    "headline": "<span class='text-cg-green'>Prima</span> – die <mark class='block d:inline max-w-full w-fit px-[5px] bg-cg-yellow text-cg-black whitespace-nowrap truncate overflow-hidden'>{{insuranceName}}</mark> ist unser Vertragspartner",
    "headlineOptional": "<span class='text-cg-green'>Prima</span> – wir kümmern uns um den Rest",
    "subHeadline": "Gut zu wissen:",
    "items": {
      "text1": "<strong>Sie müssen nichts tun</strong> – wir kümmern uns um die Schadensabwicklung und Kommunikation mit Ihrer Versicherung.",
      "text2": "<strong>Jedes Carglass<sup>®</sup> Service-Center</strong> ist für den Austausch <strong>autorisiert.</strong>",
      "text3": "<strong>Jedes Carglass<sup>®</sup> Service-Center</strong> ist für die Reparatur <strong>autorisiert.</strong>"
    },
    "partnerSubHeadline": "Unser starker Partner"
  },
  "insuranceDeclinedMessage": {
    "repair": {
      "liability": {
        "headline": "Ihre KFZ-Haftpflicht übernimmt die Kosten leider nicht – <span class='text-cg-green'>wir helfen Ihnen</span>",
        "subHeadline": "Das bedeutet für Sie:",
        "items": {
          "text1": "<strong>Unsere Experten beheben den Schaden selbstverständlich gerne für Sie.</strong> Ganz schnell und unkompliziert.",
          "text2": "<strong>Sie tragen die Kosten selbst, aber das zum fairen Preis.</strong><br>Die Kosten für die Reparatur betragen: <br>1 Steinschlag {{priceGm1}} EUR*. <br>2-3 Steinschläge {{priceGm2_3}} EUR*.<br><br><span class='text-cg-anthracite-light-30'>*inkl. MwSt.</span>"
        }
      },
      "unknown": {
        "headline": "Ihnen ist die Versicherung nicht bekannt – <span class='text-cg-green'>wir helfen Ihnen</span>",
        "description": "Im nächsten Schritt können wir Ihren <strong>Versicherungsstatus</strong> auch <strong>automatisiert herausfinden</strong>. (optional)"
      },
      "none": {
        "headline": "Ihr Auto ist nicht versichert – <span class='text-cg-green'>wir helfen Ihnen</span>",
        "subHeadline": "Das bedeutet für Sie:",
        "items": {
          "text1": "<strong>Unsere Experten beheben den Schaden selbstverständlich gerne für Sie.</strong> Ganz schnell und unkompliziert.",
          "text2": "<strong>Sie tragen die Kosten selbst, aber das zum fairen Preis.</strong><br>Die Kosten für die Reparatur betragen: <br>1 Steinschlag {{priceGm1}} EUR*. <br>2-3 Steinschläge {{priceGm2_3}} EUR*.<br><br><span class='text-cg-anthracite-light-30'>*inkl. MwSt.</span>"
        }
      },
      "paidbyperpetrator": {
        "headline": "Der Verursacher zahlt den Schaden – <span class='text-cg-green'>wir helfen Ihnen</span>",
        "description": "Wir klären telefonisch gerne mit Ihnen gemeinsam die Kostenübernahme. Lassen Sie sich jetzt einfach zurückrufen und einen Termin geben.",
        "items": {
          "text1": "<strong>Ein Berater steht jetzt telefonisch für Sie bereit.</strong>"
        }
      }
    },
    "replace": {
      "liability": {
        "headline": "Nur Haftpflicht versichert – <span class='text-cg-green'>wir helfen Ihnen</span>",
        "description": "Sie erhalten telefonisch ein  unverbindliches Angebot. Denn je nach Fahrzeugtyp und Klasse können die Preise stark variieren.",
        "items": {
          "text1": "<strong>Auf Ihre neue Scheibe erhalten Sie telefonisch bis zu <mark class='px-[5px] bg-cg-yellow text-cg-black whitespace-nowrap'>20% Extra-Rabatt</mark></strong>"
        }
      },
      "unknown": {
        "headline": "Ihnen ist die Versicherung nicht bekannt – <span class='text-cg-green'>wir helfen Ihnen</span>",
        "description": "Im nächsten Schritt können wir Ihren <strong>Versicherungsstatus</strong> auch <strong>automatisiert herausfinden</strong>. (optional)"
      },
      "none": {
        "headline": "Ihr Auto ist nicht versichert – <span class='text-cg-green'>wir helfen Ihnen</span>",
        "description": "Sie erhalten telefonisch ein  unverbindliches Angebot. Denn je nach Fahrzeugtyp und Klasse können die Preise stark variieren.",
        "items": {
          "text1": "<strong>Auf Ihre neue Scheibe erhalten Sie telefonisch bis zu <mark class='px-[5px] bg-cg-yellow text-cg-black whitespace-nowrap'>20% Extra-Rabatt</mark></strong>"
        }
      },
      "paidbyperpetrator": {
        "headline": "Der Verursacher zahlt den Schaden – <span class='text-cg-green'>wir helfen Ihnen</span>",
        "description": "Wir klären telefonisch gerne mit Ihnen gemeinsam die Kostenübernahme. Lassen Sie sich jetzt einfach zurückrufen und einen Termin geben.",
        "items": {
          "text1": "<strong>Ein Berater steht jetzt telefonisch für Sie bereit.</strong>"
        }
      }
    },
    "footerButton": "Jetzt zurückrufen lassen"
  },
  "licensePlate": {
    "title": "Mit Ihrem Kennzeichen geht’s schneller.",
    "subHeadline": "Die folgenden Angaben benötigen wir für die Terminvereinbarung und zur Abklärung der Details mit Ihrer Versicherung.",
    "salutation": {
      "radioButtons": {
        "a11y": {
          "legend": "Anrede"
        },
        "woman": "Frau",
        "man": "Herr",
        "diverse": "Divers"
      },
      "inputFields": {
        "firstName": {
          "placeholder": "Vorname",
          "errorMessage": "Bitte geben Sie einen Vornamen an"
        },
        "lastName": {
          "placeholder": "Nachname",
          "errorMessage": "Bitte geben Sie einen Nachnamen an"
        }
      }
    },
    "licensePlate": {
      "title": "KFZ-Kennzeichen eingeben (inkl. H- und E-Kennzeichen)",
      "errorMessage": "Geben Sie bitte ein gültiges Kennzeichen ein, bevor Sie fortfahren können."
    },
    "damageOfDate": {
      "title": "Wann ist der Schaden entstanden?",
      "errorMessage": "Wählen Sie bitte ein Datum aus, bevor Sie fortfahren können."
    },
    "gdvDataTransmission": {
      "title": "Automatische Datenübermittlung",
      "infoDialog": "Wir ermitteln die erforderlichen Fahrzeug- und Versicherungsdaten automatisch für Sie. Das bedeutet: Sie haben weniger Aufwand und der Termin und die passende Scheibe können besser ermittelt werden.",
      "buttons": {
        "a11y": {
          "legend": "Zustimmung zur GDV-Abfrage"
        },
        "yes": "Ja, ich stimme zu",
        "no": "Nein, ich möchte alle Daten manuell eingeben"
      },
      "errorMessage": "Treffen Sie bitte eine Auswahl, bevor Sie fortfahren können."
    },
    "privacy": "Informationen zum Datenschutz"
  },
  "licensePlateNoDateNoGdv": {
    "title": "Wie lautet Ihr Kennzeichen?",
    "why": {
      "headline": "Warum möchten wir das wissen?",
      "text": "Diese Information benötigen wir für die Terminvereinbarung und die weitere Auftragsabwicklung.<br/><br/>Sollten Sie Ihren Termin ändern wollen oder Fragen haben, so können wir den Auftrag über Ihr Kennzeichen schnell finden."
    }
  },
  "olbTechnicalError": {
    "title": "Technischer Fehler",
    "paragraphs": {
      "first": "Wir entschuldigen uns für den Fehler und die Unannehmlichkeiten. Sie können die Buchung neu starten."
    },
    "ctas": {
      "title": "Buchung neu starten"
    }
  },
  "vehicleIdentificationManually": {
    "title": "Welcher PKW ist beschädigt?",
    "subHeadline": "Anhand Ihrer Angaben können wir die passende Scheibe ermitteln",
    "selections": {
      "brand": {
        "a11y": {
          "label": "Auswahl Hersteller"
        },
        "label": "Hersteller",
        "placeholder": "Wählen Sie Ihre Fahrzeugmarke aus",
        "errorMessage": "Bitte wählen Sie Ihre Fahrzeugmarke aus."
      },
      "model": {
        "a11y": {
          "label": "Auswahl Modell"
        },
        "label": "Modell",
        "placeholder": "Wählen Sie Ihr Modell aus",
        "errorMessage": "Bitte wählen Sie Ihr Modell aus."
      },
      "type": {
        "a11y": {
          "label": "Auswahl Fahrzeug-Typ"
        },
        "label": "Typ",
        "placeholder": "Wählen Sie den Fahrzeug-Typ aus",
        "errorMessage": "Bitte wählen Sie den Fahrzeug-Typ aus."
      },
      "buildDate": {
        "a11y": {
          "label": "Auswahl Baujahr"
        },
        "label": "Baujahr ab",
        "placeholder": "Wählen Sie ein Baujahr aus",
        "errorMessage": "Bitte wählen Sie ein Baujahr aus."
      }
    },
    "ctaLink": {
      "title": "Mein Fahrzeug steht nicht in der Liste"
    }
  },
  "bindingBooking": {
    "original": {
      "title": "Noch einen Klick und Ihre neue Scheibe ist unterwegs",
      "copy": "Dank Ihrer Angaben können wir die <strong>passende Scheibe</strong> für Ihr Fahrzeugmodell <strong>beschaffen</strong>. Dies ist kein Vertrag über den Kauf oder den Austausch der Scheibe. Diesen schließen Sie erst vor Ort im Service-Center ab - natürlich nach Begutachtung durch unsere Scheibenspezialisten.",
      "costs": {
        "title": "Kosten",
        "copy": "Die <strong>Lieferkosten</strong> der Scheibe in Höhe von ca. 20,- Euro übernehmen wir. Die Beschaffung ist <strong>für Sie kostenlos</strong>."
      },
      "delivery": {
        "title": "Geplante Lieferung",
        "copy": "zum Wunschtermin am: <strong>{{date}}</strong> zur Wunschfiliale: Carglass<sup>®</sup> {{city}}"
      },
      "orderCommitmentCheckbox": "Bestellbedingungen für die Scheibenbeschaffung akzeptieren",
      "termsAndConditions": "Unsere Bestellbedingungen",
      "goForward": "Scheibe beschaffen & Termin buchen",
      "goForwardShort": "Termin buchen"
    },
    "20905050160": {
      "title": "Noch einen Klick und Ihre neue Scheibe ist unterwegs",
      "copy": "Dank Ihrer Angaben können wir die <strong>passende Scheibe</strong> für Ihr Fahrzeugmodell <strong>beschaffen</strong>. Dies ist kein Vertrag über den Kauf oder den Austausch der Scheibe. Diesen schließen Sie erst vor Ort im Service-Center ab - natürlich nach Begutachtung durch unsere Scheibenspezialisten.",
      "costs": {
        "title": "Kosten",
        "copy": "Die <strong>Lieferkosten</strong> der Scheibe in Höhe von ca. 20,- Euro übernehmen wir. Die Beschaffung ist <strong>für Sie kostenlos</strong>."
      },
      "delivery": {
        "title": "Geplante Lieferung",
        "copy": "zum Wunschtermin am: <strong>{{date}}</strong> zur Wunschfiliale: Carglass<sup>®</sup> {{city}}"
      },
      "orderCommitmentCheckbox": "Bestellbedingungen für die Scheibenbeschaffung akzeptieren",
      "termsAndConditions": "Unsere Bestellbedingungen",
      "goForward": "Scheibe beschaffen & Termin buchen",
      "goForwardShort": "Termin buchen"
    },
    "21066150835": {
      "title": "Noch einen Klick und Ihre neue Scheibe ist unterwegs",
      "copy": "Dank Ihrer Angaben können wir die <strong>passende Scheibe</strong> für Ihr Fahrzeugmodell <strong>beschaffen</strong>. Dies ist kein Vertrag über den Kauf oder den Austausch der Scheibe. Diesen schließen Sie erst vor Ort im Service-Center ab - natürlich nach Begutachtung durch unsere Scheibenspezialisten.",
      "costs": {
        "title": "Kosten",
        "copy": "Die <strong>Lieferkosten</strong> der Scheibe in Höhe von ca. 20,- Euro übernehmen wir. Die Beschaffung ist <strong>für Sie kostenlos</strong>."
      },
      "delivery": {
        "title": "Geplante Lieferung",
        "copy": "zum Wunschtermin am: <strong>{{date}}</strong> zur Wunschfiliale: Carglass<sup>®</sup> {{city}}"
      },
      "orderCommitmentCheckbox": "Bestellbedingungen für die Scheibenbeschaffung akzeptieren",
      "termsAndConditions": "Unsere Bestellbedingungen",
      "goForward": "Scheibe beschaffen & Termin buchen",
      "goForwardShort": "Termin buchen",
      "errorMessageNoOptIn": "Bitte bestätigen Sie die Bestellbedingungen"
    },
    "21050721570": {
      "title": "Noch einen Klick und Ihre neue Scheibe ist unterwegs",
      "copy": "Dank Ihrer Angaben können wir die <strong>passende Scheibe</strong> für Ihr Fahrzeugmodell <strong>beschaffen</strong>. Dies ist kein Vertrag über den Kauf oder den Austausch der Scheibe. Diesen schließen Sie erst vor Ort im Service-Center ab - natürlich nach Begutachtung durch unsere Scheibenspezialisten.",
      "costs": {
        "title": "Kosten",
        "copy": "Die <strong>Beschaffungskosten</strong> der Scheibe in Höhe von 20,- Euro übernehmen wir. Die Beschaffung ist <strong>für Sie kostenlos</strong>."
      },
      "delivery": {
        "title": "Geplante Lieferung",
        "copy": "zum Wunschtermin am: <strong>{{date}}</strong> zur Wunschfiliale: Carglass<sup>®</sup> {{city}}"
      },
      "orderCommitmentCheckbox": "Meine Scheibe ins Service-Center liefern lassen",
      "termsAndConditions": "Unsere Bestellbedingungen",
      "goForward": "Scheibe bestellen",
      "goForwardShort": "Scheibe bestellen",
      "errorMessageNoOptIn": "Bitte bestätigen Sie die Bestellbedingungen"
    }
  },
  "vehicleIdentificationNumber": {
    "title": "Wie lautet die Identifikationsnummer des beschädigten Fahrzeugs (FIN)?",
    "subHeadline": "Anhand der <strong>Fahrzeugidentnummer (FIN)</strong> können wir die verbaute Scheibe identifizieren und bestellen. Die FIN finden Sie in Ihrem Fahrzeugschein in <strong>Feld E</strong>.",
    "vin": {
      "headline": "Fahrzeugidentifikationsnummer",
      "headlineAbTest": "Wie lautet die FIN?",
      "sublineAbTest": "Die Fahrzeugidentnummer <strong>(FIN)</strong> bitte hier angeben",
      "picture": {
        "alt": "Fahrzeugschein"
      },
      "vinInput": {
        "placeholder": "Fahrzeugidentnummer",
        "errorMessage": "Bitte überprüfen Sie Ihre Eingabe anhand der Regeln"
      },
      "checksumInput": {
        "placeholder": "0",
        "errorMessage": "0"
      },
      "errorMessage": "Bitte überprüfen Sie ihre FIN und die zugehörige Prüfziffer."
    },
    "hint": {
      "title": "Bitte beachten Sie folgende Regeln:"
    },
    "noFinTitle": "Keine FIN zur Hand?",
    "ctas": {
      "callUs": "Fahrzeug manuell auswählen",
      "agb": "Bitte nehmen Sie unsere AGB zur Kenntnis.",
      "privacy": "Bitte nehmen Sie unsere Datenschutzbestimmungen zur Kenntnis."
    },
    "list": {
      "chars": "Die folgenden Zeichen sind bei FINs nicht zulässig: \"I\", \"O\", \"Q\". Bitte verwenden Sie anstelle des \"O\" eine Null (\"0\").",
      "checksum": "Das separate Feld zur Prüfziffer kann in Einzelfällen in Ihrem Fahrzeugschein fehlen. Bitte lassen Sie das Feld dann leer."
    }
  },
  "navigation": {
    "back": "Zurück"
  },
  "footer": {
    "buttons": {
      "prev": "Zurück",
      "forward": "Weiter"
    }
  },
  "infoButton": {
    "text": "Mehr Infos",
    "shortText": "Mehr",
    "noText": ""
  },
  "damageLocationDetails": {
    "title": "Die Bereiche einer Scheibe",
    "edgeTitle": "1. Weniger als 10 cm vom Rand entfernt",
    "edgeText": "Das Reparaturwerkzeug benötigt einen Mindestabstand zum Rand (siehe auch den gelben Bereich in der Grafik oben). In diesem Bereich ist der Schaden nicht reparabel und Sie erhalten eine neue Windschutzscheibe.",
    "driverViewTitle": "2. Im Fahrersichtfeld",
    "driverViewText": "Das Fahrersichtfeld ist über dem Lenkrad, max. 29 cm breit sowie oben und unten durch das Scheibenwischerfeld begrenzt. In diesem Bereich ist <strong>eine Reparatur laut Gesetzgeber nicht zulässig.</strong>",
    "otherTitle": "3. In einem anderen Bereich",
    "otherText": "An allen anderen Stellen der Windschutzscheibe können wir Steinschläge abhängig von der Größe des Steinschlags reparieren."
  },
  "damageLocationMultipleChipsDetails": {
    "title": "Sind alle Steinschläge im grauen Bereich?",
    "driverViewTitle": "Nicht im Fahrersichtfeld?",
    "driverViewText": "Das Fahrersichtfeld ist über dem Lenkrad, max. 29 cm breit sowie oben und unten durch das Scheibenwischerfeld begrenzt. In diesem Bereich ist <strong>eine Reparatur laut Gesetzgeber nicht zulässig.</strong>",
    "edgeTitle": "10 cm vom Rand entfernt?",
    "edgeText": "Das Reparaturwerkzeug benötigt einen Mindestabstand zum Rand (siehe auch den gelben Bereich in der Grafik oben). In diesem Bereich ist der Schaden nicht reparabel und Sie erhalten eine neue Windschutzscheibe."
  },
  "olbEdit": {
    "text": "Auswahl ändern"
  },
  "olbProgressbar": {
    "step1": "Glasschaden angeben",
    "step2": "Versicherung auswählen",
    "step3": "Termin finden"
  },
  "olbSaveButton": {
    "label": "Speichern"
  },
  "spinner": {
    "title": "Einen Moment bitte.",
    "subtitle": "Es geht gleich weiter..."
  },
  "insuranceResultGdvCoverageNoCar": {
    "title": "Die Abfrage Ihrer Versicherungsdaten war erfolgreich!",
    "requiredService": {
      "repair": "Die automatische Prüfung hat eine höchstwahrscheinliche Kostenübernahme durch Ihre Versicherung ergeben. Sie zahlen Ihre vereinbarte Selbstbeteiligung.*",
      "replace": "Die automatische Prüfung hat eine höchstwahrscheinliche Kostenübernahme durch Ihre Versicherung ergeben. Sie zahlen Ihre vereinbarte Selbstbeteiligung.*"
    },
    "nextSteps": "So geht's weiter:",
    "list": {
      "repair": [
        "Sie geben Ihren Wunschort für die Reparatur an.",
        "Wir ermitteln den nächstmöglichen Termin in einem Service-Center in Ihrer Nähe.",
        "Ihre Scheibe wird am vereinbarten Termin repariert."
      ],
      "replace": [
        "Bitte geben Sie im nächsten Schritt die Fahrzeug-Identifizierungsnummer (FIN) aus Ihrem Fahrzeugschein ein, damit wir Ihre Scheibe automatisiert ermitteln können.",
        "Sie geben Ihren Wunschort für den Scheibenaustausch an.",
        "Wir ermitteln den nächstmöglichen Termin in einem Carglass<sup>®</sup> Service-Center in Ihrer Nähe."
      ]
    },
    "starText": {
      "repair": "*Sollte wider Erwarten kein Kaskoschutz bestehen (z.B. Versicherung erst vor kurzem gewechselt, Prämie nicht bezahlt oder aus anderen Gründen), so kostet Sie die Reparatur eines Steinschlages {{priceGm1}} EUR*. Die Reparatur von zwei oder drei Steinschlägen kostet Sie {{priceGm2_3}} EUR*.<br><br>*inkl. MwSt.",
      "replace": "*Sollte wider Erwarten kein Kaskoschutz bestehen (z.B. Versicherung erst vor kurzem gewechselt, Prämie nicht bezahlt oder aus anderen Gründen), so tragen Sie die Kosten."
    }
  },
  "insuranceResultNoGdvNoCoverageNoCar": {
    "title": "Bitte bringen Sie Ihre Versicherungsunterlagen mit.",
    "requiredService": {
      "repair": "Wir konnten automatisiert keinen Kasko-Schutz ermitteln. Kein Problem: Gerne beheben wir den Schaden an Ihrer Scheibe. Besteht kein Kaskoschutz, so tragen Sie die Kosten selbst. Die Kosten für die Reparatur eines Steinschlages betragen {{priceGm1}} EUR*. Die Reparatur von zwei oder drei Steinschlägen kostet {{priceGm2_3}} EUR*.<br><br>*inkl. MwSt.",
      "replace": "Wir konnten automatisiert keinen Kasko-Schutz ermitteln. Kein Problem: Gerne beheben wir den Schaden an Ihrer Scheibe. Besteht kein Kaskoschutz, so tragen Sie die Kosten selbst.*"
    },
    "determinedInsurance": "Sie sind kaskoversichert?",
    "determinedInsuranceAdditionalInfo": "Bringen Sie die Versicherungsunterlagen zum Termin mit und wir klären vor Ort, ob für Sie Kosten anfallen.",
    "nextSteps": "So geht's weiter:",
    "list": {
      "repair": [
        "Sie geben Ihren Wunschort für die Reparatur an.",
        "Wir ermitteln den nächstmöglichen Termin in einem Carglass<sup>®</sup> Service-Center in Ihrer Nähe.",
        "Ihre Scheibe wird am vereinbarten Termin repariert."
      ],
      "replace": [
        "Bitte geben Sie im nächsten Schritt die Fahrzeug-Identifizierungsnummer (FIN) aus Ihrem Fahrzeugschein ein, damit wir Ihre Scheibe automatisiert ermitteln können.",
        "Sie geben Ihren Wunschort für den Scheibenaustausch an.",
        "Wir ermitteln den nächstmöglichen Termin in einem Carglass<sup>®</sup> Service-Center in Ihrer Nähe.",
        "Ihre Scheibe wird am vereinbarten Termin eingebaut."
      ]
    },
    "starText": {
      "replace": "*Die Kosten für den Neueinbau einer Windschutzscheibe bei Carglass<sup>®</sup> starten bei 250,- € inkl. MwSt.. Ihre Kosten hängen von Fahrzeug und Ausstattung ab (Bsp.: beheizbare Scheibe, Fahrerassistenzsysteme)."
    }
  },
  "insuranceResultGdvSelfpayerCar": {
    "title": "Sie tragen die Kosten selbst.",
    "requiredService": {
      "repair": "Gerne beheben wir den Schaden an Ihrer Scheibe. Die Kosten für die Reparatur eines Steinschlages betragen {{priceGm1}} EUR*. Die Reparatur von zwei oder drei Steinschlägen kostet {{priceGm2_3}} EUR*.<br><br>*inkl. MwSt..",
      "replace": "Gerne beheben wir den Schaden an Ihrer Scheibe. Die Kosten für den Neueinbau einer Windschutzscheibe bei Carglass<sup>®</sup> starten bei 250,- € inkl. MwSt.. Ihre Kosten hängen von Fahrzeug und Ausstattung ab (Bsp.: beheizbare Scheibe, Fahrerassistenzsysteme). Wir erstellen Ihnen gerne auf Anfrage ein individuelles Angebot."
    },
    "determinedCar": {
      "title": "Ihr Fahrzeug:",
      "ctaLink": "Fahrzeugdaten korrigieren"
    },
    "nextSteps": "So geht's weiter:",
    "list": {
      "repair": [
        "Sie geben Ihren Wunschort für die Reparatur an.",
        "Wir ermitteln den nächstmöglichen Termin in einem Carglass<sup>®</sup> Service-Center in Ihrer Nähe.",
        "Ihre Scheibe wird am vereinbarten Termin repariert."
      ],
      "replace": [
        "Sie geben Ihren Wunschort für den Scheibenaustausch an.",
        "Wir ermitteln den nächstmöglichen Termin in einem Carglass<sup>®</sup> Service-Center in Ihrer Nähe.",
        "Ihre Scheibe wird am vereinbarten Termin eingebaut."
      ]
    }
  },
  "insuranceResultGdvSelfpayerNoCar": {
    "title": "Sie tragen die Kosten selbst.",
    "requiredService": {
      "repair": "Gerne beheben wir den Schaden an Ihrer Scheibe. Die Kosten für die Steinschlagreparatur Ihrer Windschutzscheibe betragen 129,67 Euro inkl. MwSt..",
      "replace": "Gerne beheben wir den Schaden an Ihrer Scheibe. Die Kosten für den Neueinbau einer Windschutzscheibe bei Carglass<sup>®</sup> starten bei 250,- € inkl. MwSt.. Ihre Kosten hängen von Fahrzeug und Ausstattung ab (Bsp.: beheizbare Scheibe, Fahrerassistenzsysteme). Wir erstellen Ihnen gerne auf Anfrage ein individuelles Angebot."
    },
    "nextSteps": "So geht's weiter:",
    "list": {
      "repair": [
        "Sie geben Ihren Wunschort für die Reparatur an.",
        "Wir ermitteln den nächstmöglichen Termin in einem Carglass<sup>®</sup> Service-Center in Ihrer Nähe.",
        "Ihre Scheibe wird am vereinbarten Termin repariert."
      ],
      "replace": [
        "Bitte geben Sie im nächsten Schritt die Fahrzeug-Identifizierungsnummer (FIN) aus Ihrem Fahrzeugschein an, damit wir Ihre Scheibe automatisiert ermitteln können.",
        "Sie geben Ihren Wunschort für den Scheibenaustausch an.",
        "Wir ermitteln den nächstmöglichen Termin in einem Carglass<sup>®</sup> Service-Center in Ihrer Nähe.",
        "Ihre Scheibe wird am vereinbarten Termin eingebaut."
      ]
    }
  },
  "vapsOffer": {
    "title": "Bessere Sicht mit Scheibenversiegelung",
    "subtitle": "Ihre Wahl des Schutzes:",
    "recommendationLabel": "Empfehlung",
    "noChoiceSelectedError": "Bitte wählen Sie eine der Optionen aus",
    "nextButton": {
      "default": "Weiter",
      "summary": "Zusammenfassung"
    },
    "info": {
      "title": "Noch sicherer unterwegs mit Carglass<sup>®</sup>-Protect",
      "list": {
        "title": "Dank Nano-Schutz Scheibenversiegelung:",
        "bulletpoint1": "Mehr Sichtweite bei schlechtem Wetter und Nachtfahrten",
        "bulletpoint2": "Frost, Schnee, Eis, aber auch Schmutz perlen leichter an der Scheibe ab",
        "bulletpoint3": "Dank Abperleffekt werden die Scheibenwischer weniger genutzt und die Lebensdauer verlängert"
      },
      "bosch": {
        "title": "Sicherer unterwegs mit Bosch-Scheibenwischern",
        "list": {
          "title": "Maximale Performance mit Bosch Aerotwin Scheibenwischer:",
          "bulletpoint1": "<strong>Maximale Wischqualität</strong> auch bei hoher Geschwindigkeit",
          "bulletpoint2": "<strong>Minimale Windgeräusche</strong> durch weniger Strömungsangriffsfläche",
          "bulletpoint3": "Bis zu <strong>30% längere Lebensdauer</strong> durch gleichmäßigen Anpressdruck",
          "bulletpoint4": "<strong>Perfekte Wischergebnisse</strong> durch Hightech-Federschiene",
          "bulletpoint5": "<strong>Verbesserte Wintertauglichkeit</strong>, da sich kein Eis an Metallrahmen oder Gelenken bildet"
        }
      }
    },
    "packages": {
      "a11y": {
        "legend": "Zusatzservice Pakete z.B. Scheibenwischer"
      },
      "economy": {
        "title": "Economy",
        "subtitle": "Ohne zusätzliche Services"
      },
      "standard": {
        "title": "Standard",
        "subtitle": "Protect Scheibenversiegelung"
      },
      "premium": {
        "title": "Premium",
        "subtitle": "Protect Scheibenversiegelung <br> und Bosch Fontscheibenwischer"
      }
    }
  },
  "wiperOffer": {
    "imageAlt": {
      "wipersPicture": "Neue Bosch-Wischer",
      "boschLogoWhitePicture": "BOSCH Logo",
      "boschLogoPicture": "BOSCH"
    },
    "wipersAddProduct": {
      "recommendationLabel": "Empfehlung",
      "title": "Unser Tipp für mehr Sicherheit",
      "introduction": "Es können sich noch Glassplitter in Ihren Wischern befinden. Schützen Sie daher Ihre neue Scheibe:",
      "hintLabel": "Mehr Infos",
      "hintText": "Wir besorgen die Frontwischer für Ihr Auto  zum Termin. Sie zahlen bis dahin noch nichts.",
      "subtitle": "Bosch Scheibenwischer",
      "additionalText": "Freie Sicht für Ihre Frontscheibe",
      "uvp": "UVP",
      "priceTitle": "Preis inkl. Montage",
      "addButtonText": "Hinzufügen",
      "dialog": {
        "success": {
          "description": "Sie haben <strong>Bosch Scheibenwischer</strong> zu Ihrem (bestehenden) Auftrag hinzugefügt.",
          "cancelButtonText": "Schließen"
        }
      }
    },
    "wipersRemoveProduct": {
      "title": "Ihr gewählter Carglass<sup>®</sup> Service",
      "subtitle": "Bosch Scheibenwischer",
      "imageOverlayAddedProduct": "Beauftragt",
      "additionalText": "Freie Sicht für Ihre Frontscheibe",
      "removeHintText": "<strong>Hinweis:</strong> Die gewählten Carglass<sup>®</sup> Services können bei Ihrem Besuch vor Ort jederzeit geändert werden.",
      "priceTitle": "Preis inkl. Montage",
      "removeButtonText": "Service entfernen",
      "dialog": {
        "success": {
          "title": "Carglass<sup>®</sup> Service erfolgreich entfernt",
          "description": "Wir haben <strong>Bosch Scheibenwischer</strong> aus Ihrem bestehenden Auftrag entfernt.",
          "cancelButtonText": "Schließen"
        }
      }
    },
    "info": {
      "title": "Sicherer unterwegs mit Bosch-Scheibenwischern",
      "subtitle": "Maximale Performance mit Bosch Aerotwin Scheibenwischer:",
      "list": {
        "bulletpoint1": "<strong>maximale Wischqualität</strong> auch bei hoher Geschwindigkeit",
        "bulletpoint2": "<strong>minimale Windgeräusche</strong> durch weniger Strömungsangriffsfläche",
        "bulletpoint3": "bis zu <strong>30% längere Lebensdauer</strong> durch gleichmäßigen Anpressdruck",
        "bulletpoint4": "<strong>perfekte Wischergebnisse</strong> durch Hightech-Federschiene",
        "bulletpoint5": "<strong>verbesserte Wintertauglichkeit</strong>, da sich kein Eis an Metallrahmen oder Gelenken bildet"
      }
    }
  },
  "exitOverlay": {
    "title": "Oh, Sie wollen die Buchung abbrechen?",
    "titleABTestVariant1": "Möchten Sie die Buchung beenden?",
    "subheading": "Solange Sie das Browserfenster geöffnet lassen, können Sie jederzeit wieder einsteigen.",
    "subheadingABTestVariant1": "Sie können jederzeit wieder einsteigen, solange das Browserfenster geöffnet bleibt.",
    "call": {
      "title": "Oder ist etwas unklar?",
      "text": "Rufen Sie uns kostenlos an."
    },
    "button": {
      "abort": "Buchung abbrechen",
      "return": "Weiter buchen"
    },
    "buttonABTestVariant1": {
      "abort": "Beenden",
      "return": "Fortsetzen"
    },
    "actionsABTestVariant1": {
      "title": "Ihre Meinung liegt uns sehr am Herzen",
      "button": "Feedback geben",
      "paragraph": "Es dauert nur <strong>2 Minuten</strong>."
    }
  },
  "saveDialogContent": {
    "title": "Sie sind schon so weit. Möchten Sie speichern, bevor Sie gehen?",
    "subtitle": "Sie benötigen Ihr <strong>Kennzeichen</strong> und Ihre <strong>E-Mail</strong> für den späteren Login.",
    "inputs": {
      "email": {
        "placeholder": "Ihre E-Mail-Adresse",
        "errorMessage": "Bitte geben Sie eine korrekte E-Mail-Adresse an"
      }
    },
    "checkboxText": "Ich bin einverstanden, dass Carglass<sup>®</sup> die von mir eingegebenen Daten zum Zwecke der Fortsetzung meiner Buchung speichern und mich ausschließlich zu dieser Buchung per E-Mail kontaktieren darf. Meine Einwilligung kann ich jederzeit mit Wirkung für die Zukunft widerrufen.",
    "saveBtnTitle": "Fortschritt speichern",
    "exitAb": {
      "title": "Fortschritt speichern",
      "subtitle": "Wir benötigen Ihr <strong>Kennzeichen</strong> und Ihre <strong>E-Mail</strong> für den späteren Login.",
      "checkboxText": "Carglass<sup>®</sup> darf die von mir eingegebenen Daten zur Fortsetzung dieser Buchung speichern und mich hierzu per E-Mail kontaktieren. Meine Einwilligung kann ich jederzeit mit Wirkung für die Zukunft widerrufen.",
      "button": "Jetzt speichern",
      "success": {
        "title": "Erfolgreich gespeichert",
        "subtitle": "Sie erhalten eine E-Mail, mit der Sie sich wieder anmelden können.",
        "button": "Startseite besuchen"
      }
    }
  },
  "saveSuccessDialogContent": {
    "title": "Erfolgreich gespeichert",
    "subtitle": "Sie erhalten eine E-Mail, mit der Sie sich wieder anmelden können.",
    "infoTitle": "Möchten Sie weitere Informationen?",
    "moreInfoBtnTitle": "Startseite besuchen"
  },
  "successDialog": {
    "title": "Vielen Dank - das hat geklappt"
  },
  "errorDialog": {
    "title": "Das hat leider nicht geklappt",
    "subTitle": "Es liegt ein <b>technischer Fehler</b> vor.<br/> Bitte kontaktieren Sie unseren <br/><b>Carglass</b><sup>®</sup><b> Service.</b>",
    "callHint": "Rufen Sie uns kostenlos an"
  },
  "jobApplication": {
    "metaDataDocumentTitle": "Bewerbung | Carglass®",
    "title": "Bewerbung | Carglass®",
    "headline": "Jetzt bewerben",
    "subHeadline": "Bitte beachte, dass für diese Stelle ein <b>PKW-Führerschein (mind. Klasse B)</b> erforderlich ist.",
    "footerHeadline": "Alles auf einen Blick",
    "cta": {
      "yourTasks": "Deine Aufgaben",
      "yourBenefits": "Deine Benefits",
      "process": "Der Bewerbungsprozess",
      "faq": "Deine Fragen – unsere Antworten"
    },
    "errorToastHeadline": "Hoppla, das hat nicht funktioniert.",
    "errorToastTryAgain": "Bitte versuche es nochmal",
    "formular": {
      "contactHeadline": "Wie erreichen wir dich?",
      "placeToWorkHeadline": "Wo möchtest du arbeiten?",
      "startToWorkHeadline": "Wann könntest du anfangen?",
      "salaryHeadline": "Welchen Gehaltswunsch hast du?",
      "cvHeadline": "Lebenslauf",
      "otherFilesHeadline": "Andere Unterlagen <span class=\"text-base font-normal\">(optional)</span>",
      "cvUploaderFormats": "Bitte lade mindestens deinen Lebenslauf und falls zur Hand, weitere Unterlagen hoch (insg. max. 5 Dateien in folgenden Datei-Formaten: PDF, Word, JPG und PNG).",
      "cvUploaderButton": "Datei hochladen (max. 10MB )",
      "sendCtaTitle": "Bewerbung absenden",
      "radioButtons": {
        "error": "Bitte auswählen",
        "jobTime": {
          "a11y": {
            "legend": "Jobart Mini-Job, Voll- oder Teilzeit"
          },
          "fullTime": "Vollzeit",
          "partTime": "Teilzeit",
          "miniJob": "Mini-Job"
        }
      },
      "checkbox": {
        "privacy": {
          "text": "<a href='{{privacyUrl}}' target='_blank' class='underline'>Datenschutzbestimmungen</a> gelesen und akzeptiert",
          "error": "Wir benötigen deine Zustimmung, um deine Bewerbung zu verarbeiten."
        }
      },
      "optionSelection": {
        "desiredPlaceToWorkPerimeter": {
          "a11y": {
            "label": "Arbeitsumkreis in km"
          },
          "placeholder": "Im Umkreis von (km)*",
          "error": "In welchem Umkreis von der Postleitzahl dürfen wir nach der passenden Stelle für dich suchen?"
        }
      },
      "inputs": {
        "firstname": {
          "placeholder": "Vorname",
          "error": "Wie heißt du?"
        },
        "lastname": {
          "placeholder": "Nachname",
          "error": "Bitte verrate uns deinen Nachnamen."
        },
        "street": {
          "placeholder": "Straße/Nr.",
          "error": "Bitte gebe eine Straße/Nr. an."
        },
        "streetNumber": {
          "placeholder": "Hausnummer",
          "error": "Bitte gebe eine Hausnummer an."
        },
        "postalCode": {
          "placeholder": "PLZ",
          "error": "Bitte gebe eine PLZ an."
        },
        "city": {
          "placeholder": "Ort",
          "error": "Bitte gebe einen Ort an."
        },
        "email": {
          "placeholder": "E-Mail",
          "error": "Gib bitte deine E-Mail-Adresse ein, damit wir dich kontaktieren können."
        },
        "phoneNumber": {
          "placeholder": "Mobilnummer",
          "error": "Unter welcher Mobilnummer können wir dich tagsüber erreichen?"
        },
        "desiredWorkplace": {
          "placeholder": "Wunschort (PLZ)",
          "error": "Gib die Postleitzahl für das gewünschte Carglass<sup>®</sup> Service-Center oder die deines Wohnorts ein."
        },
        "possibleStartOfWork": {
          "error": "Bitte nenne uns ein mögliches Startdatum. Wir benötigen diese Information für die nächsten Schritte mit dir."
        },
        "files": {
          "error": {
            "required": "Bitte lade deinen Lebenslauf hoch.",
            "maxFileSize": "Bitte achte auf die max. Dateigröße ({megaBytes}MB)",
            "maxFileCount": "Es dürfen max. {count} Dateien hinzugefügt werden.",
            "fileExtension": "Bitte achte auf die zulässigen Dateiformate."
          },
          "fileType": {
            "cv": "Lebenslauf",
            "other": "Datei"
          },
          "validFormats": "Formate: PDF, Word, JPG und PNG",
          "maxFiles": "Dateien: max. {count}, jeweils bis zu 10 MB"
        }
      }
    },
    "mandatory-license-info": {
      "headline": "Schade - Ein Pkw-Führerschein ist bei uns Pflicht",
      "description": "Sobald du einen Pkw-Führerschein hast, freuen wir uns auf deine Bewerbung."
    }
  },
  "cccOpeningHours": {
    "byPhone": {
      "default": "Rufen Sie uns kostenlos an",
      "baseError": "Unseren Support erreichen Sie unter:",
      "myCarglass": {
        "detail": "Haben Sie Fragen?",
        "error": "Unseren Support erreichen Sie unter:"
      },
      "contact": "Sie erreichen unseren Kundenservice kostenfrei unter",
      "appointmentConfirmation": "Haben Sie Fragen?",
      "technicalError": "Sollte es weiterhin Probleme geben, helfen wir Ihnen gerne persönlich weiter. Rufen Sie uns kostenlos an"
    }
  },
  "myCarglass": {
    "title": "MeinCarglass® | Carglass®",
    "login": {
      "titleLogin": "<strong>Mein</strong>Carglass<sup>®</sup>",
      "descriptionLogin": "Alle Informationen rund um Ihren bestehenden Termin an einer Stelle&nbsp– melden Sie sich hier an.",
      "descriptionLoginKtci": "Wir haben einen einmaligen <strong>Zugangscode</strong> an Ihre Mailadresse <strong>{{email}}</strong> gesendet.",
      "loginKtciInput": "Code eingeben",
      "lpnInput": "Kennzeichen eingeben",
      "failed": "Bitte rufen Sie für die Terminbuchung diese Nummer an: 0 800 / 10 05 035",
      "ktciInput": {
        "placeholder": "6-stelliger Code",
        "error": "Bitte geben Sie einen gültigen 6 stelligen Code ein"
      },
      "saveAndRestore": {
        "titleResume": "<strong>Terminbuchung</strong> fortsetzen",
        "subtitleResume": "Kennzeichen eingeben",
        "descriptionResume": "Setzen Sie Ihre Terminbuchung an der gespeicherten Stelle fort und sichern Sie sich Ihren Wunschtermin. ",
        "attempts": "Fehleingabe: Sie haben noch {{attempts}} Versuch(e)."
      },
      "directResume": {
        "titleResume": "<strong>Termin</strong> vereinbaren",
        "descriptionResume": "Ihre Versicherung hat uns Ihre Daten zur Terminvereinbarung übermittelt. Geben Sie bitte zur Sicherheit Ihr Kennzeichen ein."
      },
      "sendKtciCodeAgain": "Zugangscode erneut versenden",
      "passwordForgot": "Ich kann mich nicht anmelden",
      "problemsOverlay": {
        "headline": "Probleme bei der Anmeldung?",
        "description": "Sie haben Probleme bei der Anmeldung, gerne helfen wir Ihnen weiter. Bitte rufen Sie dazu unsere kostenlose Hotline an:",
        "closeButton": "Schließen"
      }
    },
    "statusToast": {
      "errorHeadline": "Es ist ein Fehler aufgetreten",
      "errorMessage": "Bitte versuchen Sie es erneut.",
      "successHeadline": "Daten wurden gespeichert",
      "logout": "Sie wurden ausgeloggt",
      "logoutInactivity": "Sie wurden aus Sicherheitsgründen automatisch ausgeloggt",
      "mailSentHeadline": "E-Mail wurde an {{emailAddress}} verschickt",
      "mailSentNotPossibleHeadline": "Hoppla, das hat nicht funktioniert",
      "mailSentNotPossibleMessage": "LoginCode kann nur alle 5 Minuten erneut versendet werden",
      "mailSentMessage": "Bitte kontrollieren Sie gegebenfalls auch den SPAM-Ordner.",
      "attemptsTitle": "Fehleingabe",
      "attemptsMessage": "Sie haben noch {{attempts}} Versuch(e).",
      "noScEditHeadline": "Das Service-Center kann nicht mehr verändert werden,",
      "noScEditMessage": "da wir bereits Ihre Scheibe geliefert haben."
    },
    "errorPage": {
      "loginNotPossible": "Anmeldung nicht möglich",
      "followingReasons": "Das kann folgende Gründe haben:",
      "phoneInfoTextKey": "Unsere Hotline erreichen Sie unter:",
      "reasons": {
        "spamFolder": "<strong>Der LoginCode von Carglass ist in Ihrem SPAM-Ordner gelandet.</strong><br>Bitte prüfen Sie Ihren Spam Ordner.",
        "tooManyRequests": "<strong>Der LoginCode wurde bereits dreimal angefordert.</strong><br>Kontaktieren Sie hierzu unseren Kundenservice.",
        "incorrectEmail": "<strong>Ihre E-Mail Adresse ist nicht korrekt.</strong><br>Haben Sie eventuell eine andere E-Mail Adresse genutzt?",
        "licensePlateNotFound": "<strong>Ihr Kennzeichen wird bei uns nicht gefunden.</strong><br>Kontaktieren Sie hierzu unseren Kundenservice.",
        "linkExpired": "<strong>Der Link ist bereits abgelaufen</strong><br>Aus Sicherheitsgründen läuft der Link nach 24 Stunden ab.",
        "alreadyBooked": "<strong>Ihr Termin hat bereits stattgefunden.</strong><br>Ihr Carglass Account wird nach dem Termin geschlossen."
      },
      "buttonText": "Zurück zum Login",
      "default": {
        "subHeadline": "Bei Problemen helfen wir Ihnen persönlich weiter.",
        "errorMessage": "Sollten Sie Probleme beim einloggen haben oder der Link zur Termin-Auswahl bereits abgelaufen sein, melden Sie sich gerne bei unserer Kundenhotline. Gerne helfen wir Ihnen persönlich weiter."
      },
      "expired": {
        "subHeadline": "Bei Problemen helfen wir Ihnen persönlich weiter.",
        "errorMessage": "Sollten Sie Probleme beim einloggen haben oder der Link zur Termin-Auswahl bereits abgelaufen sein, melden Sie sich gerne bei unserer Kundenhotline. Gerne helfen wir Ihnen persönlich weiter."
      },
      "existingAppointment": {
        "subHeadline": "Termin wurde bereits gebucht",
        "errorMessage": "Dieser Link ist nicht mehr gültig, da Ihr Termin bereits gebucht wurde. Sie können nun in MyCarglass den Termin einsehen und bearbeiten.",
        "buttonText": "Zu MyCarglass"
      },
      "existingChannelSwitch": {
        "subHeadline": "Bei Ihrem Termin helfen wir persönlich weiter.",
        "errorMessage": "Gerne besprechen wir bei einem persönlichen Gespräch die Details Ihres Termins und klären offene Fragen."
      }
    },
    "detail": {
      "oclock": "{{time}} Uhr",
      "calender": "Kalender",
      "calculateRoute": "Routenplaner",
      "mycarglass": "<strong>Mein</strong>Carglass<sup>®</sup>",
      "greeting": "Willkommen zurück",
      "appointmentBooked": "Termin gebucht",
      "appointmentChanged": "Termin umgebucht",
      "orderNumberInfo": "Auftrags-Nr.: {{orderNumber}}",
      "damageTypeReplace": "Austausch {{compromisedPart}}",
      "damageTypeRepair": "Reparatur {{compromisedPart}}",
      "multipleDamages": "Mehrere Glasschäden",
      "jobDescriptionReplace": "Austausch der Windschutzscheibe bei einem <strong>{{car}}</strong>",
      "jobDescriptionRepair": "Reparatur der Windschutzscheibe bei einem <strong>{{car}}</strong>",
      "cancelDateCtaTitle": "Möchten Sie den Termin absagen?",
      "contact": "Kontaktdaten",
      "damageDate": "Datum des Schadens",
      "address": "Adresse",
      "jobDescription": "Auftragsbeschreibung",
      "lpn": "Kennzeichen",
      "serviceCenter": "Service-Center<br> Carglass<sup>®</sup> {{city}}",
      "payment": "Zahlungsinformationen",
      "paymentDescription": "Bargeldlose Zahlung erwünscht. Wir akzeptieren die folgenden Zahlungsmittel:",
      "additionalAppointmentDetails": "Alle Termindetails",
      "backCta": "Zurück",
      "appointmentDetailsHeadlineMobile": "Termindetails",
      "appointmentDetailsSublineMobile": "Alle Informationen rund um Ihren: <strong>{{ case }}</strong>",
      "appointmentTimeInfo": "<strong>Hinweis</strong>: Bitte haben Sie Verständnis dafür, dass wir auch bei früherem Erscheinen nicht vorzeitig mit Ihrem Termin beginnen können.",
      "overlays": {
        "footerButtons": {
          "changeAppointment": "Termin ändern",
          "logout": "Ja, abmelden"
        },
        "customerAddressTitle": "Adresse ändern",
        "onlyCallbackAppointmentTitle": "Möchten Sie Ihren Termin ändern?",
        "editAppointmentByPhone": "Bei Ihrem Fahrzeug kümmern wir uns gerne persönlich um Änderungen an Ihrem Termin. Rufen Sie dazu bitte unseren Kundenservice an.",
        "editAppointmentTitle": "Termin verschieben",
        "browserBackOverlayTitle": "Möchten Sie sich wirklich abmelden?",
        "browserBackOverlaySubline": "Wenn Sie zurück navigieren, verlassen Sie den MeinCarglass<sup>®</sup> Bereich und müssen sich dann wieder erneut einloggen, um Ihren Termin zu öffnen.",
        "calloutAppointmentTitle": "Nichts passendes dabei?",
        "calloutAppointmentSubtitle": "Gerne helfen wir Ihnen persönlich am Telefon weiter, einen passenden Alternativ-Termin zu finden.",
        "noContactEditingTitle": "Kontaktdaten ändern",
        "noContactEditingSubline": "Änderungen an Ihren Adress- und Kontaktdaten sind für Ihren Auftrag nur persönlich möglich. Rufen Sie dazu bitte unseren Kundenservice an.",
        "baseInfoOverlayCancelBtn": "Schließen",
        "cancelReason": {
          "a11y": {
            "legend": "Auswahl des Stornierungsgrundes"
          },
          "title": "<span class=\"text-cg-green\">Helfen Sie uns, besser zu werden</span> - nennen Sie uns noch Ihren Absagegrund",
          "dropdownPlaceholder": "Grund wählen",
          "error": "Bitte wählen Sie einen Grund aus.",
          "successButton": "Termin absagen & Konto löschen"
        },
        "cancelAppointment": {
          "title": "Möchten Sie Ihren Termin absagen oder lieber verschieben?",
          "descriptionText": "Sie können bequem jetzt Ihren Termin hier auf einen anderen Tag verschieben oder mit uns direkt sprechen und sich beraten lassen.",
          "hintText": "Wenn Sie Ihren Termin absagen, löschen Sie damit auch den Zugang zu Ihrem <strong>Mein</strong>Carglass<sup>®</sup> Kundenkonto.",
          "buttonChangeAppointment": "Termin verschieben",
          "buttonAppointmentCancellation": "Zur Terminabsage",
          "buttonCallback": "Sofort beraten/helfen lassen",
          "separatorAlternative": "oder"
        },
        "reasonDescriptions": {
          "MC01": "Kein Glasschaden",
          "MC02": "Persönliche Gründe",
          "competitor": "Ich gehe zum Mitbewerber",
          "MC03": "Bessere Terminverfügbarkeit",
          "MC05": "Werkstattbindung",
          "MC07": "Rücksprache mit Versicherung nötig",
          "MC08": "Unzufriedenheit mit Carglass<sup>®</sup>",
          "MC09": "Preis zu hoch",
          "MC10": "Sonstiges",
          "MC11": "Anderer Grund"
        },
        "cancelSuccess": {
          "paragraph": "Wir haben Ihren <b>Termin abgesagt</b> und Ihr <b>Mein</b>Carglass Konto <b>gelöscht.</b>",
          "backToHome": "Zurück zur Startseite"
        }
      },
      "actions": {
        "title": "Weitere Funktionen",
        "changeAppointment": "Termin verschieben",
        "removeAppointment": "Termin absagen"
      },
      "serviceCenterOpeningHours": {
        "title": "Öffnungszeiten",
        "subTitle": "<strong>{{dateOfAppointment}}</strong>&nbsp;&nbsp;{{openingHours}}"
      }
    },
    "edit": "Ändern",
    "badge": {
      "appointment": "Termin verschoben",
      "customer": "Daten geändert"
    }
  },
  "trustModel": {
    "headline": "Carglass<sup>®</sup> - TÜV-geprüfte Experten",
    "list": {
      "1": "Kostenlose Steinschlagreparatur",
      "2": "Scheiben in Originalqualität",
      "3": "Direkte Abwicklung mit Ihrer Versicherung"
    },
    "alt": "TÜV Logo",
    "description": "Carglass<sup>®</sup> ist die Nr. 1 unter den Auto-Spezialisten in Deutschland. Wir helfen Autofahrern von Montag bis Freitag: 07:00 bis 20:00 Uhr, Samstag 09:00 bis 18:00 Uhr und Sonntag 10:00 bis 18:00 Uhr."
  },
  "location": {
    "exit": "Schließen",
    "closed": "Vorübergehend geschlossen.",
    "detailsLink": "Mehr zum Service-Center",
    "stageHeadline": "Finden Sie Carglass<sup>®</sup> in Ihrer Nähe",
    "search": "Standort suchen",
    "arrangeAppointment": "Jetzt Termin vereinbaren",
    "headline": {
      "name": "Carglass",
      "appointment": "Termin vereinbaren",
      "route": "Route planen"
    },
    "result": {
      "headline": "Adresse: ",
      "hint": "Alle Service-Center erreichen Sie unter der zentralen Rufnummer. Kostenlos - Montag bis Freitag 07:00 bis 20:00 Uhr und Samstag 09:00 bis 14:00 Uhr."
    },
    "openingHours": {
      "headline": "Öffnungszeiten",
      "closed": "geschlossen"
    },
    "service": {
      "headline": "Unsere Leistungen",
      "list": {
        "headline": "Services für Ihren Aufenthalt"
      }
    },
    "pagination": {
      "page": "Seite",
      "of": "von"
    },
    "noResults": {
      "errorMessage": "Im Umkreis von <strong>25 km</strong> konnten keine Service-Center gefunden werden. ",
      "title": "nächste Service-Center:"
    }
  },
  "opportunityFunnelDamageSide": {
    "buttons": {
      "a11y": {
        "legend": "Auswahl Anzahl beschädigte Seitenscheiben"
      },
      "single": "Eine Scheibe",
      "multiple": "Mehrere Scheiben"
    },
    "title": "Wie viele Seitenscheiben sind beschädigt?"
  },
  "opportunityFunnelDamageRear": {
    "a11y": {
      "legend": "Auswahl Anzahl beschädigte Heckscheiben"
    },
    "buttons": {
      "single": "Eine Heckscheibe",
      "multiple": "Mehrere Scheiben"
    },
    "title": "Welches Heckscheiben Modell liegt vor?"
  },
  "opportunityFunnelDamageAssessmentResult": {
    "title": "Wir tauschen Ihre Scheibe aus",
    "subTitleLeftSide": "Seitenscheiben müssen aufgrund ihrer Beschaffenheit immer ausgetauscht werden.",
    "subTitleRear": "Heckscheiben müssen aufgrund ihrer Beschaffenheit immer ausgetauscht werden."
  },
  "opportunityFunnelCosts": {
    "title": "Wer trägt die Kosten?",
    "list": {
      "kasko": {
        "item1": "Die <strong>Hauptkosten trägt Ihre Versicherung</strong>. Sie zahlen nur die vereinbarte Selbstbeteiligung.",
        "item2": "Ihr bestehender <strong>Schadenfreiheitsrabatt bleibt</strong> in jedem Fall <strong>unberührt</strong>."
      },
      "noKasko": {
        "item1": "Die genauen Kosten hängen von Fahrzeugtyp und Ausstattung ab (z.B. beheizbare Scheibe, oder Tönung)",
        "item2": "Wir beraten Sie vor dem Termin telefonisch und Sie erhalten ein individuelles Angebot."
      }
    },
    "tab": {
      "title1": "Mit Kasko Versicherung",
      "title1Mobile": "Mit Kasko Versich.",
      "title2": "Ohne Kasko Versicherung",
      "title2Mobile": "Ohne Kasko Versich."
    },
    "text": {
      "exchange": "Austausch",
      "sideWindow": "Seitenscheibe:",
      "rearWindow": "Heckscheibe:",
      "priceKasko": "0 €",
      "priceNoKaskoPrefix": "ab"
    },
    "ctaTitle": "Jetzt online Termin anfragen",
    "ctaCallbackTitle": "Sofort beraten/helfen lassen"
  },
  "opportunityFunnelLicensePlate": {
    "title": "Wie lautet Ihr Kennzeichen?",
    "ctaTitle": "Kosten ermitteln",
    "lpnTitle": "KFZ-Kennzeichen eingeben",
    "gdvDataTransmission": {
      "title": "Automatische Datenübermittlung",
      "buttons": {
        "yes": "Ja, ich stimme zu",
        "no": "Nein, ich stimme nicht zu"
      },
      "errorMessage": "Treffen Sie bitte eine Auswahl, bevor Sie fortfahren können."
    },
    "sslText": "SSL sichere Verbindung.",
    "gdvInfoDialog": {
      "title": "Automatische Datenübermittlung",
      "text": "Wir ermitteln die erforderlichen Fahrzeug- und Versicherungsdaten automatisch für Sie. Das bedeutet: Sie haben weniger Aufwand und der Termin und die passende Scheibe können besser ermittelt werden."
    },
    "lpnSubtitle": "(inkl. H- und E-Kennzeichen)"
  },
  "opportunityFunnelCustomerAddress": {
    "title": "Wie können wir Sie erreichen?",
    "subTitle": "Alle Infos rund um Ihren Austauschtermin erhalten Sie per <strong>E-Mail</strong> und <strong>SMS.</strong>",
    "firstnameInput": {
      "placeholder": "Vorname *",
      "errorMessage": "Bitte geben Sie einen Vornamen an"
    },
    "lastnameInput": {
      "placeholder": "Nachname *",
      "errorMessage": "Bitte geben Sie einen Nachnamen an"
    },
    "emailInput": {
      "placeholder": "E-Mail *",
      "errorMessage": "Bitte geben Sie eine korrekte E-Mail-Adresse an"
    },
    "phoneInput": {
      "placeholder": "Mobilnummer *",
      "errorMessage": "Bitte geben Sie eine korrekte Mobilnummer an"
    },
    "checkboxText": "Ich bin einverstanden, dass Carglass<sup>®</sup> mich unter der angegebenen Mobilnummer zur Beratung hinsichtlich meines Glasschadens sowie zur Vereinbarung eines Termins einmalig kontaktiert.",
    "ctaTitle": "Erreichbarkeit angeben"
  },
  "opportunityFunnelSuccess": {
    "title": "Super - der erste <wbr/> Schritt ist getan",
    "subTitle": "Ihre Terminanfrage wurde gesendet",
    "gridTitle": "So geht es weiter...",
    "items": {
      "text1": "<strong>Rückmeldung</strong> zu Ihrer Terminanfrage <strong>innerhalb eines Werktages.</strong>",
      "text2": "<strong>Kontinuierliche Updates</strong> rund um die Scheibenbestellung und - lieferung.",
      "text3": "<strong>Ihr Austauschtermin</strong> – Ihr Glasschaden wird behoben."
    }
  },
  "opportunityFunnelContactTime": {
    "title": "Wann dürfen wir Sie für Rückfragen kontaktieren?",
    "titleCccClosed": "Bei Rückfragen melden wir uns noch einmal bei Ihnen",
    "ctaTitle": "Angaben überprüfen",
    "radioGroup": {
      "a11y": {
        "legend": "Auswahl Rückrufzeit"
      },
      "now": "Sofort <span class='normal-text'>(in ca. {{ewt}} {{timeUnit}})</span>",
      "later": "Später <span class='normal-text'>(innerh. eines Werktages)</span>"
    },
    "callText": {
      "noselection": "Bitte wählen Sie einen der oben genannten Zeiträume aus.",
      "now": "Wir melden uns in <span class='green'>ca. {{ewt}} {{timeUnit}}</span> bei Ihnen.",
      "later": "Wir rufen Sie innerhalb <span class='green'>eines Werktages</span> zurück.",
      "cccClosed": "Sollten wir noch Informationen von Ihnen benötigen, werden Sie <strong>zeitnah</strong> telefonisch von uns kontaktiert."
    },
    "callTextSubline": "Unsere Vorwahl beginnt mit <strong>02236 oder 0800.</strong>"
  },
  "opportunityFunnelAppointmentRequest": {
    "title": "Wo und wann soll ausgetauscht werden?",
    "search": {
      "a11y": {
        "searchButton": "Service-Center suchen"
      },
      "placeholder": "Wunschort/PLZ",
      "error": "Bitte geben Sie Ihren Wunschort ein"
    },
    "serviceCenter": {
      "a11y": {
        "label": "Auswahl Service-Center"
      },
      "placeholder": "Service-Center wählen",
      "error": "Bitte treffen Sie eine Auswahl"
    },
    "calendarError": "Bitte wählen Sie einen Tag aus.",
    "calendarTitle": "Welcher Tag passt am besten?",
    "radioGroup": {
      "a11y": {
        "legend": "Auswahl zwischen Vormittags und Nachmittags"
      },
      "morning": "Vormittags",
      "afternoon": "Nachmittags"
    },
    "ctaTitle": "Kontaktdaten angeben",
    "ctaTitleAddress": "Adressdaten angeben",
    "ctaCallbackTitle": "Sofort beraten/helfen lassen"
  },
  "teslaRestrictions": {
    "title": "Schnelle Hilfe für Ihr Tesla {{model}}",
    "customerReassurance": {
      "warranty": "Neue Scheibe in <strong>Herstellerqualität</strong> mit <strong>30 Jahren Dichtigkeitsgarantie</strong>",
      "skilledWorkers": "Speziell auf <strong>Tesla geschulte KFZ-Monteure</strong> in <strong>{{numberOfScs}} Service-Centern</strong>",
      "manufacturerQuality": "Einhaltung der <strong>Tesla-Qualitätsstandards</strong>"
    },
    "ctaTitle": "Zur Terminauswahl",
    "changeCar": {
      "text": "Nicht ihr Fahrzeug?",
      "link": "Hier ändern"
    }
  },
  "teslaAppointmentRequest": {
    "title": "Wo soll der Termin stattfinden?",
    "serviceCenter": {
      "placeholder": "Service-Center wählen",
      "error": "Bitte treffen Sie eine Auswahl"
    },
    "subHeadline": "<span class='text-cg-green'>{{ count }} Service-Center</span> sind<br/>auf <span class='text-cg-green'>Tesla spezialisiert</span>"
  },
  "editAppointment": {
    "toast": {
      "message": "<strong>Das Service-Center kann nicht mehr verändert werden,</strong> da wir bereits Ihre Scheibe geliefert haben."
    }
  },
  "customerRating": {
    "title": "Sehen Sie selbst, wie unsere Kunden uns erlebt haben",
    "paragraph": "Schauen Sie sich unsere Kundenbewertungen auf trustpilot.de an.",
    "cta": "Kundenbewertungen ansehen"
  },
  "opportunityFunnelProductNotFound": {
    "title": "<span class='text-cg-green'>Gute Neuigkeiten</span> - wir können Ihren Glasschaden beheben",
    "paragraph": "Ob <strong>online</strong> oder <strong>telefonisch</strong> wir helfen Ihnen gerne weiter."
  },
  "vapsProtectSecondChance": {
    "title": "Kein Protect hinzugefügt",
    "repairSubTitle": "Wir empfehlen für Ihre reparierte Scheibe eine Scheibenversiegelung mitzubuchen.",
    "replaceSubTitle": "Wir empfehlen für Ihre neue Scheibe eine Scheibenversiegelung mitzubuchen.",
    "addBtnText": "Protect hinzufügen",
    "noBtnText": "Weiter ohne Protect",
    "list": {
      "cloud": "<strong>Bessere Sicht</strong> bei schlechtem Wetter und Nachtfahrten",
      "snow": "Frost, Schnee, Eis und <strong>Schmutz perlen leichter an der Scheibe ab</strong>",
      "wiper": "Dank Abperleffekt werden die <strong>Scheibenwischer</strong> weniger genutzt und die <strong>Lebensdauer verlängert</strong>"
    }
  },
  "welcome": {
    "title": "Carglass<sup>®</sup> ist autorisierter Partner der <span style='background-color: {{ color }}; color: {{ textColor }}' class='px-1 text-2xl font-bold'>{{ insurance }}</span>",
    "subTitle": "Gut zu wissen:",
    "claim": "Wir kümmern uns um die Schadensabwicklung und Kommunikation mit Ihrer Versicherung.",
    "cta": "Zur Terminbuchung"
  },
  "qualtricsTeaser": {
    "title": "<strong>Kurz gefragt: Wie war die Buchung?</strong> (Dauer 2 Min.)",
    "btnText": "Feedback geben"
  },
  "fileInput": {
    "actionDescription": "<span>{{fileType}} auswählen</span> & hochladen"
  },
  "faq": {
    "headline": "Häufige Fragen",
    "repair": {
      "driveWithChip": {
        "question": "Sollte ich mit einem Steinschlag noch weiterfahren?",
        "answer": "Einen Steinschlag in der Windschutzscheibe sollten Sie schnell reparieren lassen. <strong>Fahren Sie bitte nicht lange damit weiter,</strong> denn: Auch aus einem kleinen Steinschlag kann schnell ein gefährlicher Riss werden. Außerdem kann Schmutz in die Steinschlag-Stelle gelangen. Das beeinträchtigt zwar später nicht die Qualität unserer Reparatur, aber manchmal das optische Ergebnis. Unser Tipp deshalb: Kleben Sie durchsichtiges Klebeband über den Steinschlag, um die Stelle zu schützen – und kommen Sie so bald wie möglich zu uns."
      },
      "insuranceWillPay": {
        "question": "Zahlt meine Versicherung den Schaden?",
        "answer": "Wenn Ihr Auto <strong>voll- oder teilkaskoversichert ist, bezahlt die Versicherung in der Regel die komplette Steinschlag-Reparatur.</strong> Ihr Schadenfreiheitsrabatt bleibt davon völlig unberührt. Bequem für Sie: Wenn Sie möchten, <strong>rechnen wir die Reparatur direkt mit Ihrer Versicherung ab.</strong>\nSie haben lediglich die Kfz-Haftpflichtversicherung? Diese deckt Steinschlag-Schäden am eigenen Auto nicht ab. <strong>Günstig</strong> für Sie ist hier aber, dass wir den <strong>Schaden reparieren können.</strong> Denn das ist in jedem Fall kostensparender, als die Scheibe auszutauschen."
      },
      "damageVisibleAfterRepair": {
        "question": "Sieht man den Steinschlag nach der Reparatur noch?",
        "answer": "Mit unserer patentierten GlassMedic<sup>®</sup> Methode <strong>versiegeln wir den Steinschlag.</strong> Auch wenn die versiegelte Stelle ganz leicht sichtbar bleiben sollte: Ihre Windschutzscheibe ist durch die Reparatur wieder so sicher und stabil wie eine neue Scheibe. Dank der Reparatur <strong>muss Ihre Scheibe nicht getauscht werden</strong> – das spart im Vergleich rund 80% CO<sub>2</sub> ein. Für ein gutes optisches Reparaturergebnis können Sie etwas tun: Vereinbaren Sie so bald wie möglich einen Termin bei uns – und schützen Sie die Steinschlag-Stelle bis zur Reparatur mit einem durchsichtigen Klebeband vor Schmutz."
      }
    },
    "replace": {
      "driveWithCrack": {
        "question": "Darf ich mit gerissener Scheibe noch weiterfahren?",
        "answer": "Mit einem Riss in der Windschutzscheibe sollten Sie bitte <strong>nicht weiterfahren</strong>. Ein kleiner Riss kann schnell zu einem großen Sprung werden. Unbehandelt ist der Riss ein <strong>Sicherheitsrisiko</strong>. Außerdem drohen Bußgeld und ein Punkt in Flensburg. Ausnahme: Wenn Sie nachweisbar einen Reparaturtermin vereinbart haben, dürfen Sie noch fahren. Dann bitte so wenig wie möglich – und so bald wie möglich zu uns."
      },
      "crackInsuranceWillPay": {
        "question": "Zahlt meine Versicherung den Schaden?",
        "answer": "Wenn Sie eine so genannte <strong>Kaskoversicherung</strong> für Ihr Auto haben, ist der Riss in der Windschutzscheibe in den <strong>meisten Fällen mitversichert</strong>. Abgedeckt werden Glasschäden normalerweise von der Teilkaskoversicherung – die übrigens bei einer Vollkaskoversicherung schon enthalten ist. Gut zu wissen: <strong>Ihr Teilkasko-Beitrag steigt nach einem Schaden nicht</strong>. Ob die Versicherung den Scheibenaustausch komplett zahlt, hängt davon ab, ob Sie einen <strong>Selbstbehalt</strong> (auch Eigenanteil genannt) vereinbart haben. Besonders bequem für Sie: In vielen Fällen <strong>können wir auch direkt mit der Versicherung abrechnen</strong>."
      },
      "repairInsteadOfReplace": {
        "question": "Kann ich die Windschutzscheibe auch reparieren lassen?",
        "answer": "Ob wir Ihre Windschutzscheibe reparieren können, hängt vom Schaden ab. Problemlos reparieren können wir in der Regel Schäden, die nicht größer als eine 2-Euro-Münze sind sowie mindestens 10 cm vom Rand entfernt und nicht im Fahrersichtfeld liegen. <strong>Wenn die Scheibe aber gerissen ist oder es mehr als drei Steinschläge gibt, ist eine Reparatur nicht erlaubt.</strong> Das gleiche gilt, wenn die Sicht hinter dem Lenkrad beeinträchtigt ist. In diesen Fällen sorgen wir für <strong>Sicherheit</strong>, indem wir die Scheibe in <strong>Originalqualität</strong> austauschen."
      }
    }
  },
  "chat": {
    "message": {
      "general": {
        "toOlbForAppointment": "Darf ich nach einem passenden Termin für Sie suchen?",
        "forwardToOlb": "Ich leite Sie direkt zur Online-Buchung weiter.",
        "whatNext": {
          "00": "Was möchten Sie als nächstes tun?",
          "01": "Was möchten Sie als nächstes machen?",
          "strong": {
            "01": "<strong>Was möchten Sie als nächstes machen?</strong>"
          }
        }
      },
      "welcome": {
        "general": {
          "helloAndWelcome": "Hallo und willkommen.",
          "noAppointmentYet": "Übrigens: Sie buchen damit natürlich noch <strong>keinen Termin.</strong>",
          "whereDoYouWantToStart": "Womit möchten Sie starten?"
        },
        "neutral": {
          "damageJourneyIntroduction": "Was es <strong>kostet</strong>, Ihren Autoglasschaden zu beheben, sage ich Ihnen gern. Dazu brauche ich nur wenige Angaben."
        },
        "chip": {
          "damageJourneyIntroduction": "Was es kostet, <strong>einen Steinschlag in Ihrer Windschutzscheibe zu reparieren</strong>, finden wir in wenigen Schritten heraus."
        },
        "crack": {
          "damageJourneyIntroduction": "Was es kostet, <strong>einen Riss in Ihrer Windschutzscheibe zu reparieren</strong>, finden wir in wenigen Schritten heraus."
        }
      },
      "selectionEmotionResponse": {
        "agreeNeutral": {
          "00": "Okay!"
        },
        "agreeWeCanDoThis": {
          "00": "Sehr gern!",
          "01": "Machen wir!"
        },
        "agreeHappy": {
          "00": "Super!",
          "01": "Das freut mich."
        }
      },
      "damage": {
        "costs": {
          "paymentDependsOnDamageAndInsurance": "Ob Sie überhaupt etwas bezahlen müssen, hängt vom <strong>Schaden</strong> und von der Art Ihrer <strong>Versicherung</strong> ab.",
          "whatShouldBeHandledFirst": "Was wollen wir zuerst klären?",
          "damageRequiredAfterInsurance": "Um den genauen Preis zu nennen, müssen wir kurz den Schaden beurteilen.",
          "howDoesTheDamageLookLike": "Wie sieht der Schaden am ehesten aus?",
          "goodNewsMainCostsOnInsurance": "Gute Nachrichten: <strong>Die Hauptkosten bezahlt Ihre Versicherung.</strong>",
          "crackCostGeneral": "Ob und wieviel Sie zuzahlen müssen, hängt von Ihrer vereinbarten Selbstbeteiligung ab. <strong>Oft liegt diese bei <span style=\"white-space: nowrap\">150 €</span>.</strong>"
        },
        "chip": {
          "howManyChips": "Wie viele Steinschläge sind vorhanden?",
          "isOneLargerThanCoin": "Ist ein Schaden größer als eine zwei Euro Münze?",
          "areAllInGray": "Sind alle Steinschläge im grauen Bereich?",
          "whereIsTheChipLocated": "Okay! In welchem Bereich befindet sich der Steinschlag?",
          "howLargeIsTheChip": "Und wie groß ist der Steinschlag?"
        },
        "crack": {}
      },
      "insurance": {
        "general": {
          "fullOrPartialInsurance": "<strong>Haben Sie eine Voll-/Teilkasko-Versicherung?</strong>",
          "whichInsurance": "Bei welcher Versicherung?",
          "lpn": {
            "noProblem": "Macht gar nichts, das finden wir schnell heraus.",
            "lpnToInsurance": "Mit Ihrem Kennzeichen kann ich Ihren Versicherungsstatus abfragen.",
            "prompt": "<strong>Bitte geben Sie Ihr Kennzeichen ein (auch Zusätze wie H oder E)</strong>",
            "promptHint00": "Mit Ihrem Kennzeichen kann ich Ihren Versicherungsstatus abfragen.",
            "promptHint01": "Um Ihnen den genauen Preis nennen zu können, benötige ich nur noch Ihr Kennzeichen."
          },
          "unknown": {
            "orientation": "Alles klar. Ich gebe Ihnen gern zwei <strong>Preise zur Orientierung an.</strong>",
            "selfPayer": "Sie tragen die Kosten selbst, aber das zum fairen Preis.",
            "generalPricesReplace": "Windschutzscheiben ohne Fahr-Assistenzsystem tauschen wir ab <span style=\"white-space: nowrap\">{{opportunity.price_front_without_assistant}} €</span>. Mit Fahr-Assistenzsystem kostet der Austausch ab {{opportunity.price_front_with_assistant}} €. Wieviel es genau kostet, hängt von Ihrem Fahrzeugtyp und der Ausstattung ab.",
            "generalPricesRepair": "Carglass<sup>®</sup> repariert den Steinschlag schon für <span style=\"white-space: nowrap\">{{opportunity.price_1_stone}} €</span> inkl. MwSt.",
            "generalPricesRepairTwoStone": "Carglass<sup>®</sup> repariert zwei Steinschlägen schon für <span style=\"white-space: nowrap\">{{opportunity.price_2_stone}} €</span> inkl. MwSt.",
            "generalPricesRepairThreeStone": "Carglass<sup>®</sup> repariert drei Steinschlägen schon für <span style=\"white-space: nowrap\">{{opportunity.price_2_stone}} €</span> inkl. MwSt."
          }
        },
        "repair": {
          "damageAssessmentType": "Das ist gut! <strong>Wir können Ihre Scheibe reparieren.</strong>",
          "oftenNoCosts": "Oft ist das für Sie sogar kostenlos.",
          "costDependsOnInsuranceType": "Das hängt davon ab, wie Ihr Auto versichert ist. Um den exakten Preis zu ermitteln, benötige ich daher Informationen zu Ihrer Versicherung.",
          "fullyCovered": {
            "00": "Gute Nachrichten: <strong>Ihre Versicherung bezahlt die Reparatur komplett!</strong>",
            "01": "Die Scheibenreparatur bei Carglass<sup>®</sup> kostet Sie also <span style=\"white-space: nowrap\"><strong>0 €</strong></span>."
          }
        },
        "replace": {
          "damageAssessmentType": "Sehr gut, der Fall ist klar: Sie <strong>bekommen eine neue Windschutzscheibe!</strong>",
          "costDependsOnInsuranceType": "Was das für Sie kostet, hängt davon ab, wie Ihr Auto versichert ist."
        }
      },
      "gdvDataTransmission": {
        "buttons": {
          "yes": "Ja, ich stimme zu",
          "no": "Nein, ich stimme nicht zu"
        }
      },
      "scSearch": {
        "letUsShow": "Gern, ich zeige Ihnen Carglass<sup>®</sup> Standorte in Ihrer Nähe."
      }
    },
    "scSearch": {
      "showMore": "Mehr Service-Center in der Nähe anzeigen"
    }
  },
  "wipersGdv": {
    "headline": "Automatische Modellermittlung",
    "subHeadline": "Ihr Kennzeichen ist:",
    "buttons": {
      "yes": "Wischer hinzufügen",
      "no": "Abbrechen"
    },
    "paragraph": "Um bequem für Sie die passenden Scheibenwischer zu finden, benötigen wir das Automodell. Das Modell fragen wir mit Ihrer Zustimmung automatisch bei Ihrer Versicherung ab."
  },
  "protectBookingPage": {
    "cockpit": {
      "badge": "Tipp für mehr Sicherheit",
      "items": {
        "1": "<strong>Nanobeschichtung</strong> weist Regen und Schmutz ab",
        "2": "Von uns fachgerecht aufgebracht <strong>in nur einer Stunde</strong>",
        "3": "Bessere Sicht für <strong>bis zu 6 Monate</strong>"
      }
    }
  },
  "protectBooking": {
    "title": "Protect buchen | Carglass®",
    "headlines": {
      "title": "In nur einer Stunde erledigt: <br /> Scheibenversiegelung einzeln - <span class='text-cg-red'>nur {{price}} €</span>",
      "contact": "Wie erreichen wir Sie?",
      "place": "Wo soll der Termin stattfinden?",
      "appointment": "Wann soll der Termin sein?",
      "lpn": "Wie lautet ihr Kennzeichen?"
    },
    "inputs": {
      "firstname": {
        "placeholder": "Vorname",
        "errorMessage": "Bitte geben Sie einen Vornamen an."
      },
      "lastname": {
        "placeholder": "Nachname",
        "errorMessage": "Bitte geben Sie einen Nachnamen an."
      },
      "email": {
        "placeholder": "E-Mail",
        "errorMessage": "Bitte geben Sie eine korrekte E-Mail-Adresse an."
      },
      "place": {
        "placeholder": "PLZ oder Adresse",
        "errorMessage": "Bitte geben Sie eine PLZ oder Adresse an."
      }
    },
    "scSelection": {
      "a11y": {
        "label": "Service-Center wählen"
      },
      "placeholder": "Service-Center wählen"
    },
    "scCount": "<strong>{{ count }} Service-Center</strong> im Umkreis von 40km",
    "appointment": {
      "a11y": {
        "label": "Zeitraum wählen"
      },
      "placeholder": "Zeit wählen",
      "placeholderNoAppointments": "keine Termine",
      "error": "Bitte wählen Sie einen anderen Tag."
    },
    "cta": "Diesen Termin buchen",
    "errorOverlay": {
      "title": "Irgendwas hakt leider",
      "subTitle": "Wir kümmern uns darum. Bitte versuchen Sie es in 1–2 Minuten noch einmal.",
      "cta": "Hinweis schließen"
    }
  },
  "cgx": {
    "damageTypeTable": {
      "header": {
        "damageType": "Schadensart",
        "repair": "Reparatur",
        "replace": "Austausch"
      },
      "statement": {
        "validRepairChipAmount": "Es sind bis zu drei Steinschläge",
        "validRepairChipSize": "Alle Steinschläge sind kleiner als eine 2,- € Münze",
        "replaceChipBorderPosition": "Ein Steinschlag befindet sich weniger als 10 cm vom Rand entfernt",
        "replaceChipViewPosition": "Der Schaden befindet sich im Fahrersichtfeld (über dem Lenkrad)",
        "replaceCrack": "Es ist ein Riss"
      }
    },
    "trustpilot": {
      "title": "Schauen Sie sich die Kundenstimmen auf trustpilot.de an.",
      "cta": "Kundenbewertungen ansehen"
    }
  },
  "lpnInput": {
    "a11y": {
      "legend": "Kennzeicheneingabe"
    },
    "labels": {
      "region": "Region/Stadt",
      "letters": "Buchstaben",
      "numbers": "Zahlen",
      "additionalNumbers": "Zusätzliche Zahlen (Sonderkennzeichen)"
    }
  },
  "titleSelection": {
    "errorMessage": "Bitte geben Sie Ihr Geschlecht an, um fortzufahren.",
    "radioButtons": {
      "title": {
        "a11y": {
          "legend": "Anrede"
        },
        "male": "Herr",
        "female": "Frau",
        "diverse": "Divers"
      }
    }
  }
}
