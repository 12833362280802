<div
  class="relative z-20 box-border flex w-full flex-col items-center overflow-hidden overflow-x-visible bg-white shadow-card"
  [formGroup]="form"
  [ngClass]="{
    'animate-cg-sc-filter-expand rounded-2xl': expanded(),
    'animate-cg-sc-filter-collapse cursor-pointer rounded-[28px]': !expanded() && !isTablet()
  }"
>
  <div
    class="flex w-full flex-row items-center px-4"
    [ngClass]="{
      'animate-sc-filter-fade-in hidden duration-[800ms] ease-in-out': expanded() || isTablet(),
      'flex min-h-[56px] animate-cg-sc-filter-fade-in opacity-100 duration-[800ms] ease-in-out':
        !expanded() && !isTablet(),
      'pl-0': this.isScSelectDisabled()
    }"
    (click)="expandFilter()"
  >
    <cg-icon class="h-6 w-6 px-1 [&>mat-icon>svg>path]:fill-cg-anthracite" [content]="pinIcon"></cg-icon>
    <div class="ml-4 flex flex-col">
      <span
        data-cs-mask
        class="typo-body-1 mb-1 font-roboto-condensed text-[15px] font-bold leading-4 text-cg-anthracite"
        >{{ locality() }}</span
      >
      <span class="typo-body-1 text-xs leading-4 text-cg-anthracite">
        <cg-service-center-selection-label
          data-cs-mask
          class="font-roboto-condensed"
          [countSelected]="countSelectedServiceCenter"
          [countServiceCenters]="serviceCenterCount()"
          [distance]="distance()"
        ></cg-service-center-selection-label>
      </span>
    </div>
    <div
      data-cy="appointment-filter-icon"
      class="absolute right-0 top-0 flex h-14 w-14 items-center justify-center rounded-full bg-cg-anthracite"
      [ngClass]="{ hidden: this.isScSelectDisabled() }"
    >
      <cg-icon class="h-[18px] w-[18px] [&>mat-icon>svg>path]:stroke-white" [content]="filterIcon"></cg-icon>
    </div>
  </div>
  <div
    part="input-elements-wrapper"
    class="flex w-full flex-col p-4 t:x-[mx-auto,box-border,max-w-container,p-6,!pt-4] d:x-[!flex-row,items-end,gap-6] w:max-w-5xl"
    [ngClass]="{
      'animate-cg-sc-filter-fade-in': expanded(),
      'hidden animate-cg-sc-filter-fade-out opacity-0': !expanded() && !isTablet()
    }"
  >
    @if (showChevron() && !isTablet()) {
      <div class="pb-2">
        <div class="cursor-pointer" (click)="collapseFilter()">
          <cg-icon class="ml-auto h-6 w-6" [content]="arrowIcon"></cg-icon>
        </div>
      </div>
    }

    <cg-new-appointment-search-location-input
      class="appointment-search--input flex-1 t:min-w-[230px] d:w-[229px] w:w-[279px] fhd:w-[408px] [&_input]:!border-b-cg-anthracite [&_input]:!bg-white"
      [icon]="searchIcon"
      [clearIcon]="clearIcon"
    ></cg-new-appointment-search-location-input>

    <div class="t:min-w-60">
      <span class="typo-body-1 my-2 text-xs text-cg-anthracite-light-30">
        <cg-service-center-selection-label
          class="font-roboto-condensed"
          [countSelected]="countSelectedServiceCenter"
          [countServiceCenters]="serviceCenterCount()"
          [distance]="distance()"
        ></cg-service-center-selection-label>
      </span>
      <cg-fake-dropdown
        class="d:x-[block,w-[244px]] w:w-[260px]"
        [placeholder]="selectionText()"
        (press)="showScSelectionDialog()"
        [showChevron]="serviceCenterCount() > 1"
      ></cg-fake-dropdown>
    </div>
    <!-- This button is shown on mobile/desktop -->
    <button
      type="button"
      cg-base-button
      iconPosition="left"
      class="costs--button mt-4 block w-full part-[icon-text-wrapper]:x-[px-4,justify-center] part-[icon|>mat-icon>svg>path]:x-[stroke-transparent,fill-white] d:hidden d:part-[icon-text-wrapper]:gap-2 d:part-[text]:text-nowrap w:x-[block,w-[261px]]"
      [btnText]="'newAppointment.appointmentFilter.button.findAppointment' | transloco"
      [icon]="calendarIcon"
      (click)="search($event)"
    ></button>
    <!-- This button is shown only on tablet -->
    <button
      type="button"
      cg-base-button
      iconPosition="left"
      class="costs--button mt-4 hidden w-fit part-[icon-text-wrapper]:x-[px-4,justify-center] part-[icon|>mat-icon>svg>path]:x-[stroke-transparent,fill-white] d:block d:part-[icon-text-wrapper]:gap-2 w:hidden"
      [btnText]="'newAppointment.appointmentFilter.button.findAppointmentTablet' | transloco"
      [icon]="calendarIcon"
      (click)="search($event)"
    ></button>
  </div>
</div>
