<section class="duplicate-container">
  <ng-template #master>
    <div class="max-w-[888px]">
      <cg-olb-headline
        [title]="'appointmentAlreadyExists.texts.title' | transloco"
        [subtitle]="'appointmentAlreadyExists.texts.subtitle' | transloco: { lpn: lpn$ | async }"
      ></cg-olb-headline>

      <cg-olb-headline
        [type]="'h3'"
        [title]="'appointmentAlreadyExists.texts.detailTitle' | transloco"
      ></cg-olb-headline>

      <cg-richtext
        [content]="{
          text: 'appointmentAlreadyExists.texts.detailParagraph' | transloco
        }"
      ></cg-richtext>

      <div class="mb-6 mt-4 flex flex-col d:mb-12">
        <cg-ctalink [content]="additionalOptions.firstCtaLink" (clickedFn)="trackClickToMyCarglass()"></cg-ctalink>
      </div>

      <cg-olb-headline [type]="'h3'" [title]="'appointmentAlreadyExists.texts.telTitle' | transloco"></cg-olb-headline>

      <div class="mb-6 mt-4 flex flex-col gap-2 d:mb-12">
        <cg-ctalink [content]="additionalOptions.secondCtaLink" (clickedFn)="onTrack()"></cg-ctalink>
      </div>
    </div>
  </ng-template>

  <cg-split-view [master]="master"></cg-split-view>
</section>
