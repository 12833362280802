import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormGroup, ReactiveFormsModule, UntypedFormControl, Validators } from "@angular/forms";
import { Observable, of } from "rxjs";
import { filter, first, take } from "rxjs/operators";
import { DamageFacade, DamageState, ProcessFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { TrackingEvent, TrackingService } from "@cg/analytics";
import { AddFormControls } from "@cg/core/types";
import {
  ChooseDamagedChipCountTypeExitIds,
  OLB_PROCESS_FLOW_MODEL,
  OlbHeadlineComponent,
  ProcessFlow,
  ScrollService
} from "@cg/olb/shared";
import { DamageChipCount, ProcessId, SplitViewComponent } from "@cg/shared";
import { DamageAssessmentContent } from "../../interfaces/damage-assessment-content";
import { ExitNodeResolverService } from "../../services/exit-node-resolver.service";
import { DamageAssessmentTileComponent } from "../../tiles/damage-assessment-tile/damage-assessment-tile.component";
import { BaseDirective } from "../core/directives/base/base.directive";
import { DamageChipCountForm } from "./interfaces/damage-chip-count-form.interface";
import { damageChipCountTileContent } from "./models/damage-chip-count-tile-content.model";
import { eventDamageChipCountMapping } from "./models/damage-chip-count-tracking-mapping.model";

@Component({
  selector: "cg-damage-chip-count",
  templateUrl: "./damage-chip-count.component.html",
  styleUrls: ["./damage-chip-count.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoPipe, ReactiveFormsModule, OlbHeadlineComponent, DamageAssessmentTileComponent, SplitViewComponent]
})
export class DamageChipCountComponent
  extends BaseDirective<AddFormControls<DamageChipCountForm>>
  implements OnInit, OnDestroy
{
  public damageTileContent: DamageAssessmentContent = damageChipCountTileContent;

  public get damageChipCount(): DamageChipCount {
    return this.form.controls.damageChipCount.value;
  }

  // eslint-disable-next-line max-params
  public constructor(
    cdr: ChangeDetectorRef,
    processFacade: ProcessFacade,
    exitNodeResolver: ExitNodeResolverService,
    scrollService: ScrollService,
    @Inject(OLB_PROCESS_FLOW_MODEL) processFlow: ProcessFlow,
    private damageFacade: DamageFacade,
    protected trackingService: TrackingService
  ) {
    super(cdr, processFacade, exitNodeResolver, trackingService, scrollService, processFlow);
  }

  public async ngOnInit() {
    super.ngOnInit();

    this.processFacade.currentProcessId$
      .pipe(
        filter((processId: ProcessId) => processId === this.processId),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.form.reset();
      });
  }

  public ngOnDestroy() {
    super.ngOnDestroy();

    this.damageFacade.setDamageChipCount(null);
  }

  public initFormGroup(): void {
    this.form = new FormGroup<AddFormControls<DamageChipCountForm>>({
      damageChipCount: new UntypedFormControl("", Validators.required)
    });
  }

  public setFormValues(): void {
    this.damageFacade.damageChipCount$
      .pipe(
        filter((damageChipCount: DamageChipCount) => !!damageChipCount),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((damageChipCount: DamageChipCount) => {
        this.form.controls.damageChipCount.setValue(damageChipCount);
      });
  }

  public getExitIdForSavedForm(): Observable<ChooseDamagedChipCountTypeExitIds> {
    switch (this.damageChipCount) {
      case DamageChipCount.MORE_THAN_THREE:
        return of("moreThanThreeDamageChipCount");

      case DamageChipCount.TWO:
      case DamageChipCount.THREE:
        return of("twoOrThreeDamageChipCount");

      default:
        break;
    }
  }

  public goForward(): void {
    if (this.form.valid) {
      this.trackDamageChipCount();
    }

    // current process id is checked so the forward is not triggered on restore with resume id
    this.processFacade.currentProcessId$
      .pipe(
        first(),
        filter((id: ProcessId) => id === "damage-chip-count")
      )
      .subscribe(() => super.goForward());
  }

  public saveForm(): void {
    this.damageFacade.setDamageChipCount(this.damageChipCount);
  }

  public override postSaveForm() {
    super.postSaveForm();

    if (this.damageChipCount === DamageChipCount.MORE_THAN_THREE) {
      this.damageFacade.damageState$
        .pipe(
          filter((damageState: DamageState) => this.damageChipCount === damageState.damageChipCount),
          take(1),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe((damageState: DamageState) => this.damageFacade.updateDamage(damageState));
    }
  }

  protected restoreFromEntryState() {
    this.damageFacade.damageChipCount$.pipe(take(1)).subscribe((chipCount: DamageChipCount) => {
      setTimeout(() => {
        this.form.controls.damageChipCount.setValue(chipCount);
        this.cdr.markForCheck();
        this.goForward();
      }, 100);
    });
  }

  private trackDamageChipCount() {
    const chipCount = eventDamageChipCountMapping.get(this.damageChipCount);

    this.trackingService.trackEvent({
      eventAction: "damage-chip-count-selection",
      eventLabel: chipCount,
      damage: {
        type: chipCount
      }
    } as Partial<TrackingEvent>);
  }
}
