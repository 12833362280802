import { ElementRef, inject, Injectable } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { BreakpointService } from "@cg/shared";

@Injectable({
  providedIn: "root"
})
export class NewAppointmentTileSearchbarService {
  private readonly breakpointService = inject(BreakpointService);

  private isDesktop = toSignal(this.breakpointService.isDesktop$);

  private prevMarginLeft: string;
  private prevMarginRight: string;

  public handleSearchbarPosition(searchComponent: ElementRef, searchIsSticky: boolean): void {
    if (!this.isDesktop()) {
      return;
    }

    // position the search component to the left of the screen
    this.resetSearchBarMargin(searchComponent);

    if (!searchIsSticky) {
      window.onresize = null;
      return;
    }

    const { x } = searchComponent.nativeElement.getBoundingClientRect();
    this.prevMarginLeft = searchComponent.nativeElement.style.marginLeft;
    this.prevMarginRight = searchComponent.nativeElement.style.marginRight;
    searchComponent.nativeElement.style.marginLeft = `-${x}px`;

    window.onresize = () => {
      this.resetSearchBarMargin(searchComponent);

      const { x: newX } = searchComponent.nativeElement.getBoundingClientRect();

      searchComponent.nativeElement.style.marginLeft = `-${newX}px`;

      const { x: afterSetX } = searchComponent.nativeElement.getBoundingClientRect();

      const marginLeft = parseInt(searchComponent.nativeElement.style.marginLeft.replace("px", ""), 10);

      searchComponent.nativeElement.style.marginLeft = `${marginLeft - afterSetX}px`;
    };
  }

  public resetSearchBarMargin(searchComponent: ElementRef): void {
    if (!searchComponent) {
      return;
    }

    searchComponent.nativeElement.style.marginLeft = this.prevMarginLeft;
    searchComponent.nativeElement.style.marginRight = this.prevMarginRight;
  }
}
