import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { distinctUntilChanged, filter, map, mergeMap, take, withLatestFrom } from "rxjs/operators";
import { CarIdentificationService } from "@cg/olb/services";
import { DamageFacade, InsuranceFacade, OlbFacade, ProcessFacade, ProductFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { OptimizelyService, TrackingEvent, TrackingService } from "@cg/analytics";
import { AddFormControls } from "@cg/core/types";
import {
  OLB_PROCESS_FLOW_MODEL,
  OlbFooterComponent,
  OlbHeadlineComponent,
  ProcessFlow,
  Product,
  ScrollService,
  VehicleIdentificationManuallyExitIds
} from "@cg/olb/shared";
import {
  CtalinkComponent,
  ErrorMessageComponent,
  OptionSelectionItem,
  OptionsSelectionComponent,
  RequiredService,
  SplitViewComponent
} from "@cg/shared";
import { OptimizelyExperiment } from "@cg/core/enums";
import { ExitNodeResolverService } from "../../../../services/exit-node-resolver.service";
import { BaseDirective } from "../../../core/directives/base/base.directive";
import { VehicleIdentificationManuallyForm } from "./interfaces/vehicle-identification-manually-form.interface";
import { carIsNotListedCtaLinkModel } from "./models/car-is-not-listed.model";

@Component({
  selector: "cg-vehicle-identification-manually",
  templateUrl: "./vehicle-identification-manually.component.html",
  styleUrls: ["./vehicle-identification-manually.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoPipe,
    ReactiveFormsModule,
    OlbHeadlineComponent,
    OptionsSelectionComponent,
    ErrorMessageComponent,
    CtalinkComponent,
    SplitViewComponent,
    OlbFooterComponent
  ]
})
export class VehicleIdentificationManuallyComponent
  extends BaseDirective<AddFormControls<VehicleIdentificationManuallyForm>>
  implements OnInit
{
  public get brand() {
    return this.form.controls.brand.value;
  }

  public set brand(value: string | null) {
    this.form.controls.brand.setValue(value);
  }

  public get hasManufacturerError() {
    const control = this.form.controls.brand;
    return control.touched && control.invalid;
  }

  public get model() {
    return this.form.controls.model.value;
  }

  public set model(value: string | null) {
    this.form.controls.model.setValue(value);
  }

  public get hasModelError() {
    const control = this.form.controls.model;
    return control.touched && control.invalid;
  }

  public get modelType() {
    return this.form.controls.type.value;
  }

  public set modelType(value: string | null) {
    this.form.controls.type.setValue(value);
  }

  public get hasModelTypeError() {
    const control = this.form.controls.type;
    return control.touched && control.invalid;
  }

  public get buildDate() {
    return this.form.controls.buildDate.value;
  }

  public set buildDate(value: string | null) {
    this.form.controls.buildDate.setValue(value);
  }

  public get hasBuildDateError() {
    const control = this.form.controls.buildDate;
    return control.touched && control.invalid;
  }

  public brands: OptionSelectionItem[];
  public models: OptionSelectionItem[];
  public types: OptionSelectionItem[];
  public buildDates: OptionSelectionItem[];

  public carNotListed = carIsNotListedCtaLinkModel;

  // eslint-disable-next-line max-params
  public constructor(
    processFacade: ProcessFacade,
    exitNodeResolverService: ExitNodeResolverService,
    trackingService: TrackingService,
    scrollService: ScrollService,
    @Inject(OLB_PROCESS_FLOW_MODEL) processFlow: ProcessFlow,
    private productFacade: ProductFacade,
    private olbFacade: OlbFacade,
    private insuranceFacade: InsuranceFacade,
    protected cdr: ChangeDetectorRef,
    private readonly damageFacade: DamageFacade,
    private readonly carIdentificationService: CarIdentificationService,
    private readonly optimizelyService: OptimizelyService
  ) {
    super(cdr, processFacade, exitNodeResolverService, trackingService, scrollService, processFlow);
  }

  public async ngOnInit() {
    await super.ngOnInit();
    this.productFacade.loadAllManufacturers();
  }

  public getExitIdForSavedForm(): Observable<VehicleIdentificationManuallyExitIds> {
    return this.productFacade.products$.pipe(
      filter((products: Product[]) => !!products),
      take(1),
      mergeMap((products: Product[]) => this.handleProducts(products))
    );
  }

  public initFormGroup(): void {
    this.form = new FormGroup<AddFormControls<VehicleIdentificationManuallyForm>>({
      brand: new FormControl<string>(null, Validators.required),
      model: new FormControl<string>(null, Validators.required),
      type: new FormControl<string>(null, Validators.required),
      buildDate: new FormControl<string>(null, Validators.required)
    });
  }

  public saveForm(): void {
    if (!this.brand || !this.model || !this.modelType || !this.buildDate) {
      return;
    }
    this.insuranceFacade.setInsuranceVin(null);

    this.productFacade.setSelectedManufacturer(this.brand);
    this.productFacade.setSelectedModel(this.model);
    this.productFacade.setSelectedModelType(this.modelType);
    this.productFacade.setSelectedBuildDate(this.buildDate);
    this.olbFacade.getAllProducts();
  }

  public setFormValues(): void {
    this.productFacade
      .createManufacturerOptions()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((brands: OptionSelectionItem[]) => {
        this.brands = brands;
        this.cdr.markForCheck();
      });

    this.productFacade
      .createModelOptions()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((res: OptionSelectionItem[]) => !!res),
        distinctUntilChanged()
      )
      .subscribe((models: OptionSelectionItem[]) => {
        this.models = models;
        this.cdr.markForCheck();
      });

    this.productFacade
      .createTypeOptions()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((types: OptionSelectionItem[]) => {
        this.types = types;
        this.cdr.markForCheck();
      });

    this.productFacade
      .createBuildDateOptions()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((res: OptionSelectionItem[]) => !!res)
      )
      .subscribe((buildDates: OptionSelectionItem[]) => {
        this.buildDates = buildDates;
        this.cdr.markForCheck();
      });

    this.productFacade.selectedManufacturer$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((manufacturer: string) => !!manufacturer),
        distinctUntilChanged()
      )
      .subscribe((manufacturer: string) => (this.brand = manufacturer));
    this.productFacade.selectedModel$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((model: string) => !!model),
        distinctUntilChanged()
      )
      .subscribe((model: string) => (this.model = model));
    this.productFacade.selectedModelType$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((modelType: string) => !!modelType),
        distinctUntilChanged()
      )
      .subscribe((modelType: string) => (this.modelType = modelType));
    this.productFacade.selectedBuildDate$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((buildDate: string) => !!buildDate),
        distinctUntilChanged()
      )
      .subscribe((buildDate: string) => (this.buildDate = buildDate));

    this.form.controls.brand.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((brand: string) => !!brand),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.form.controls.model.reset(null);
        this.form.controls.type.reset(null);
        this.form.controls.buildDate.reset(null);
        this.productFacade.resetModels();
        this.productFacade.resetTypes();
        this.productFacade.resetBuildDates();
        this.cdr.markForCheck();
      });

    this.form.controls.model.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((value: string) => !!value),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.form.controls.type.reset(null);
        this.form.controls.buildDate.reset(null);
        this.productFacade.resetTypes();
        this.productFacade.resetBuildDates();
        this.cdr.markForCheck();
      });

    this.form.controls.type.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((value: string) => !!value),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.form.controls.buildDate.reset(null);
        this.productFacade.resetBuildDates();
        this.cdr.markForCheck();
      });
  }

  public handleCarIsNotListed($event: PointerEvent) {
    $event.preventDefault();

    this.saveForm();
    this.processFacade.setChannelSwitchReason("VEHICLE_NOT_FOUND");
    this.trackManualVehicleSelection(0);

    return this.optimizelyService
      .isVariationOfExperimentActive(OptimizelyExperiment.OPPORTUNITY_FUNNEL_PRODUCT_NOT_FOUND)
      .pipe(
        take(1),
        map((isProductNotFoundAbTestActive: boolean) =>
          isProductNotFoundAbTestActive ? "noProductFoundOpportunityAbTest" : "channelSwitch"
        )
      )
      .subscribe((exitId: "channelSwitch" | "noProductFoundOpportunityAbTest") => this.skipFormWithExitId(exitId));
  }

  public handleManufacturerChange() {
    if (this.brand) {
      this.productFacade.loadAllModels(this.brand);
      this.cdr.markForCheck();
    }
  }

  public handleModelChange() {
    if (this.brand && this.model) {
      this.productFacade.loadAllTypes({
        brand: this.brand,
        model: this.model
      });
      this.cdr.markForCheck();
    }
  }

  public handleTypeChange() {
    if (this.brand && this.model && this.modelType) {
      this.productFacade.loadAllBuildDates({
        brand: this.brand,
        model: this.model,
        type: this.modelType
      });
      this.cdr.markForCheck();
    }
  }

  public handleProducts(products: Product[]): Observable<VehicleIdentificationManuallyExitIds> {
    if (this.form.valid) {
      this.trackManualVehicleSelection(products.length);
    } else {
      this.trackMissingFormFieldEntries();
    }

    return this.damageFacade.requiredService$.pipe(
      take(1),
      withLatestFrom(
        this.optimizelyService.isVariationOfExperimentActive(OptimizelyExperiment.OPPORTUNITY_FUNNEL_PRODUCT_NOT_FOUND)
      ),
      map(([requiredService, isOpportunityAbTestActive]: [RequiredService, boolean]) => {
        if (products.length === 1) {
          const carIdentified = this.carIdentificationService.getCarIdentifiedProcessId({ brand: this.brand });
          return requiredService === RequiredService.REPLACE ? carIdentified : "appointment";
        } else {
          this.processFacade.setChannelSwitchReason("PRODUCT_NOT_FOUND");
          return isOpportunityAbTestActive ? "noProductFoundOpportunityAbTest" : "channelSwitch";
        }
      })
    );
  }

  public trackManualVehicleSelection(products: number): void {
    const mapProductLengthToWindowValue = () => {
      let window;
      if (products === 0) {
        window = "not-found";
      } else if (products === 1) {
        window = "found";
      } else {
        window = "multiple-options";
      }

      return window;
    };

    this.trackingService.trackEvent({
      eventAction: "vehicle-information",
      eventLabel: "manual-vehicle-selection",
      vehicle: {
        "vehicle-identification-source": "manual-vehicle-selection",
        window: mapProductLengthToWindowValue(),
        brand: this.brand,
        model: this.model,
        "year-of-built": this.buildDate
      }
    } as Partial<TrackingEvent>);
  }

  public trackMissingFormFieldEntries() {
    const track = (eventLabelValue: string) => {
      this.trackingService.trackEvent({
        eventAction: "error",
        eventLabel: eventLabelValue
      } as Partial<TrackingEvent>);
    };

    if (this.form.controls.brand.invalid) {
      track("brand");
    }
    if (this.form.controls.model.invalid) {
      track("model");
    }
    if (this.form.controls.buildDate.invalid) {
      track("year-of-built");
    }
  }
}
