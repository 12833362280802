<section class="flex flex-col">
  <ng-template #master>
    <form [formGroup]="form">
      <cg-olb-headline
        class="max-w-content"
        [title]="'customerContact.title' | transloco"
        [subtitle]="'customerContact.subHeadline' | transloco"
      ></cg-olb-headline>

      <cg-customer-contact-form
        class="block max-w-content"
        [customerCaseId]="customerCaseId$ | async"
        [isEmailOptional]="isEmailOptional"
        [currentProcessId$]="processFacade.currentProcessId$"
        data-cy="customer-contact-form"
      ></cg-customer-contact-form>

      <p
        class="mb-6 max-w-content font-roboto text-base text-cg-anthracite-light-30"
        [innerHtml]="'customerContact.agreement' | transloco"
      ></p>

      <div class="flex flex-col">
        <cg-ctalink
          [content]="privacyStatementCta"
          (clickedFn)="onTrack('privacy-statement')"
          class="[&>div>div>a]:hover:text-cg-red"
        ></cg-ctalink>
      </div>
    </form>
  </ng-template>

  <cg-split-view [master]="master"></cg-split-view>
  <cg-olb-footer
    class="overflow-hidden rounded-b-card"
    (backwardBtnClicked)="goBack()"
    (forwardBtnClicked)="goForward()"
    [forwardBtnContent]="forwardBtnContent"
    [disableBackBtn]="hideBtns"
    [disableForwardBtn]="hideBtns"
  ></cg-olb-footer>
</section>
