import { addDays, addHours } from "date-fns";
import type { NewAppointmentData, ServiceCenterData } from "@cg/shared";

const monday = new Date(2000, 0, 3, 8);

export const berlin: ServiceCenterData = {
  serviceCenterId: "1",
  distance: 5
} as ServiceCenterData;

export const hamburg: ServiceCenterData = {
  serviceCenterId: "2",
  distance: 20
} as ServiceCenterData;

export const bremen: ServiceCenterData = {
  serviceCenterId: "3",
  distance: 50
} as ServiceCenterData;

export const berlinAppointments: NewAppointmentData[][] = [
  [
    {
      appointmentId: "11",
      serviceCenterId: "1",
      customerAppointmentStart: monday.toString()
    } as NewAppointmentData,
    {
      appointmentId: "12",
      serviceCenterId: "1",
      customerAppointmentStart: addDays(monday, 1).toString()
    } as NewAppointmentData,
    {
      appointmentId: "13",
      serviceCenterId: "1",
      customerAppointmentStart: addDays(monday, 2).toString()
    } as NewAppointmentData,
    {
      appointmentId: "14",
      serviceCenterId: "1",
      customerAppointmentStart: addDays(monday, 3).toString()
    } as NewAppointmentData
  ]
];

export const hamburgAppointments: NewAppointmentData[][] = [
  [
    {
      appointmentId: "20",
      serviceCenterId: "2",
      customerAppointmentStart: addDays(monday, 1).toString()
    } as NewAppointmentData,
    {
      appointmentId: "22",
      serviceCenterId: "2",
      customerAppointmentStart: addDays(monday, 3).toString()
    } as NewAppointmentData,
    {
      appointmentId: "23",
      serviceCenterId: "2",
      customerAppointmentStart: addDays(monday, 5).toString()
    } as NewAppointmentData
  ],
  [
    {
      appointmentId: "24",
      serviceCenterId: "2",
      customerAppointmentStart: addDays(monday, 7).toString()
    } as NewAppointmentData
  ]
];

export const bremenAppointments: NewAppointmentData[][] = [
  [
    {
      appointmentId: "30",
      serviceCenterId: "3",
      customerAppointmentStart: addHours(monday, 1).toString()
    } as NewAppointmentData,
    {
      appointmentId: "32",
      serviceCenterId: "3",
      customerAppointmentStart: addDays(monday, 2).toString()
    } as NewAppointmentData,
    {
      appointmentId: "33",
      serviceCenterId: "3",
      customerAppointmentStart: addDays(monday, 4).toString()
    } as NewAppointmentData
  ],
  [
    {
      appointmentId: "34",
      serviceCenterId: "3",
      customerAppointmentStart: addDays(monday, 8).toString()
    } as NewAppointmentData
  ]
];
