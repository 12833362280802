@if (!this.isDesktop() || !searchClicked()) {
  <cg-branding
    class="[&>div]:mx-auto"
    [ngClass]="{
      'mb-4 mt-[23dvh] block w-full': this.isDesktop(),
      'mt-4': !this.isDesktop()
    }"
  ></cg-branding>
  <cg-headline
    class="mb-6 block text-center [&>h1]:font-roboto-condensed [&>h1]:text-[26px] [&>h1]:font-bold [&>h1]:leading-8"
    [ngClass]="{
      'd:[&>h1]:text-[30px] d:[&>h1]:leading-10': this.isDesktop(),
      'mt-3': !this.isDesktop()
    }"
    [content]="'newAppointment.title' | transloco"
    [styleType]="HeadlineType.h2"
  ></cg-headline>
}
