<fieldset>
  <legend class="sr-only">{{ content.a11y.legend | transloco }}</legend>
  <div class="damage-assessment-selection--wrapper" part="damage-assessment-selection-wrapper" cgKeyboardNavigationRoot>
    @for (button of content.buttons; track trackByFn($index)) {
      <div
        class="damage-assessment-selection--button order-2"
        [@overlay]="value === button.radio.value ? 'checked' : 'not-checked'"
        (@overlay.start)="onAnimationStart()"
        (@overlay.done)="onAnimationDone($event)"
        [ngClass]="{
          hover: !noHoverEffect,
          checked: value === button.radio.value,
          '!order-1 border border-cg-yellow': !!preselectValue && preselectValue === button.radio.value,
          '!bg-white': !!preselectValue && preselectValue === button.radio.value && !disabled
        }"
        [tabindex]="0"
        (keyup.enter)="writeValue(button.radio.value)"
        (keyup.space)="writeValue(button.radio.value)"
        cgKeyboardNavigation
        [dataFocusIndex]="$index"
        [dataCount]="content.buttons.length"
      >
        <label [for]="button.radio.id">
          <input
            type="radio"
            tabindex="-1"
            [name]="content.controlName"
            [id]="button.radio.id"
            [value]="button.radio.value"
            [checked]="value === button.radio.value"
            (change)="selectionChanged($event)"
            [disabled]="disabled"
          />
          <cg-icon
            [ngClass]="{
              '[&>mat-icon>svg>g>path]:fill-cg-anthracite-light-70 [&>mat-icon>svg>g>use]:stroke-cg-anthracite-light-70 [&>mat-icon>svg>path]:fill-cg-anthracite-light-70':
                disabled && value !== button.radio.value
            }"
            [content]="button.picture"
          ></cg-icon>
          <div
            class="pb-2 text-center font-roboto-condensed text-[13px] font-bold text-cg-anthracite m:text-base t:text-[13px] d:text-base"
            [ngClass]="{ 'text-cg-anthracite-light-70': disabled && value !== button.radio.value }"
          >
            {{ button.title | transloco }}
          </div>
        </label>
      </div>
    }
  </div>
</fieldset>
