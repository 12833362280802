import { DamageLocation } from "@cg/shared";
import type { RadioButtonGroup } from "@cg/shared";

export const damageLocationMultipleChipsOptions: RadioButtonGroup = {
  controlName: "damageLocation",
  a11y: {
    legend: "damageLocationMultipleChips.buttons.a11y.legend"
  },
  buttons: [
    {
      title: "damageLocationMultipleChips.buttons.yes",
      radio: { id: "damage-location-multiple-chips-yes", value: DamageLocation.OTHER }
    },
    {
      title: "damageLocationMultipleChips.buttons.no",
      radio: { id: "damage-location-multiple-chips-no", value: DamageLocation.VIEW }
    }
  ]
};
