import { DatePipe } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  input,
  OnInit,
  signal
} from "@angular/core";
import { takeUntilDestroyed, toSignal } from "@angular/core/rxjs-interop";
import { Actions, ofType } from "@ngrx/effects";
import { combineLatest, filter, finalize, take, tap } from "rxjs";
import { DamageFacade, NewAppointmentActions, NewAppointmentFacade, ProcessFacade } from "@cg/olb/state";
import { SpinnerMode, SpinnerService } from "@cg/spinner";
import { TranslocoPipe } from "@jsverse/transloco";
import { HeadlineComponent, HeadlineType, ParagraphComponent, PictureComponent } from "@cg/core/ui";
import { EnvironmentService } from "@cg/environments";
import {
  BaseButtonComponent,
  DrawerComponent,
  NewAppointmentData,
  OpeningHour,
  OverlayService,
  ProcessId,
  RequiredService,
  ServiceCenterData
} from "@cg/shared";
import { USER_DECISION } from "@cg/core/enums";
import { getOpeningHour } from "../../utils/opening-hour.util";
import { NewAppointmentAllScAppointmentsDialogComponent } from "../new-appointment-all-sc-appointments-dialog/new-appointment-all-sc-appointments-dialog.component";
import { NewAppointmentCarBringingScheduleTextComponent } from "../new-appointment-car-bringing-schedule-text/new-appointment-car-bringing-schedule-text.component";
import { NewAppointmentTimeComponent } from "../new-appointment-time/new-appointment-time.component";
import { NewAppointmentDetailContentInterface } from "./interfaces/new-appointment-detail-content.interface";
import { repair } from "./models/repair.model";
import { replaceWithAdas } from "./models/replace-with-adas.model";
import { replaceWithoutAdas } from "./models/replace-without-adas.model";

// Feature.NEW_APPOINTMENT_TILE
@Component({
  selector: "cg-new-appointment-detail",
  templateUrl: "./new-appointment-detail.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DatePipe,
    TranslocoPipe,
    DrawerComponent,
    HeadlineComponent,
    ParagraphComponent,
    PictureComponent,
    BaseButtonComponent,
    NewAppointmentTimeComponent,
    NewAppointmentCarBringingScheduleTextComponent
  ]
})
export class NewAppointmentDetailComponent implements OnInit {
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly overlayService = inject(OverlayService);
  private readonly processFacade = inject(ProcessFacade);
  private readonly actions$ = inject(Actions);
  private readonly spinnerService = inject(SpinnerService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly appointmentFacade = inject(NewAppointmentFacade);
  private readonly damageFacade = inject(DamageFacade);
  private readonly environmentService = inject(EnvironmentService);

  public readonly HeadlineType = HeadlineType;

  public isParentAllScDialog = input<boolean>(false);

  public appointment = toSignal(this.appointmentFacade.selectedAppointment$);
  public serviceCenter = toSignal(this.appointmentFacade.selectedServiceCenter$);

  public requiredService: RequiredService;
  public serviceCenterText = "";
  public openingHour: OpeningHour;

  // TODO Warum wird das befüllt und nicht nicht direkt die signals in der html?
  public isCalibration = false;
  public isRepair = false;
  public isReplaceWithAdas = false;
  public isReplaceWithoutAdas = false;

  public content: NewAppointmentDetailContentInterface;

  public get activeVapsOffer(): boolean {
    return this.environmentService.env.features.vaps.active;
  }

  public ngOnInit(): void {
    combineLatest([
      this.appointmentFacade.selectedAppointment$,
      this.appointmentFacade.selectedServiceCenter$,
      this.appointmentFacade.isCalibration$,
      this.damageFacade.requiredService$
    ])
      .pipe(
        filter(
          ([selectedAppointment]: [NewAppointmentData, ServiceCenterData, boolean, RequiredService]) =>
            !!selectedAppointment
        ),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(
        ([selectedAppointment, selectedServiceCenter, isCalibration, requiredService]: [
          NewAppointmentData,
          ServiceCenterData,
          boolean,
          RequiredService
        ]) => {
          if (!selectedAppointment) {
            return;
          }

          this.requiredService = requiredService;
          this.isCalibration = isCalibration;

          this.openingHour = getOpeningHour(selectedAppointment, selectedServiceCenter);
          this.initServiceType();
          this.initModel();
          this.initServiceCenterText();

          this.cdr.markForCheck(); // required due to OnPush
          this.cdr.detectChanges(); // run changeDetection for previous marked change
        }
      );
  }

  public initServiceType(): void {
    this.isRepair = this.requiredService === RequiredService.REPAIR;
    this.isReplaceWithAdas = this.requiredService === RequiredService.REPLACE && this.isCalibration === true;
    this.isReplaceWithoutAdas = this.requiredService === RequiredService.REPLACE && this.isCalibration === false;
  }

  public initModel(): void {
    if (this.isRepair) {
      this.content = repair;
    } else if (this.isReplaceWithAdas) {
      this.content = replaceWithAdas;
    } else if (this.isReplaceWithoutAdas) {
      this.content = replaceWithoutAdas;
    } else {
      throw new Error("Detail view could not be initialized. This should never happen.");
    }
  }

  public initServiceCenterText(): void {
    if (!this.appointment()) {
      this.serviceCenterText = "";
    } else {
      const address = this.serviceCenter().address;
      const subLocalityText = address.subLocality ? ` - ${address.subLocality}` : "";
      const location = `${address.city}${subLocalityText}`;

      this.serviceCenterText = `<strong>Carglass<sup>®</sup>-Service-Center</strong><br>${address.street}<br>${address.postalCode} <strong>${location}</strong>`;
    }
  }

  public clickedOk(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    this.appointmentFacade.confirmAppointment();
    this.spinnerService.addPendingRequest(SpinnerMode.ALWAYS);

    this.actions$
      .pipe(
        ofType(NewAppointmentActions.confirmAppointmentSuccess),
        take(1),
        tap(() => {
          const exitId: ProcessId = this.activeVapsOffer ? "vaps-offer" : "customer-contact";

          this.processFacade.goForward(exitId);
          this.overlayService.close({ decision: USER_DECISION.ACCEPT });
        }),
        finalize(() => this.spinnerService.decrementPendingRequests()),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  public clickedCancel(): void {
    if (!this.isParentAllScDialog()) {
      return;
    }
    combineLatest([this.appointmentFacade.selectedServiceCenter$, this.appointmentFacade.offeredAppointments$])
      .pipe(
        take(1),
        tap(([selectedSc, availableAppointments]: [ServiceCenterData, Record<string, NewAppointmentData[][]>]) => {
          const appointmentsForSc = availableAppointments[selectedSc.serviceCenterId];

          this.overlayService.open(NewAppointmentAllScAppointmentsDialogComponent, {
            selectedSc: signal(selectedSc),
            availableAppointments: signal(appointmentsForSc)
          });
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }
}
