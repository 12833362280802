import { HttpErrorResponse } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, filter, map, mergeMap, tap, withLatestFrom } from "rxjs/operators";
import { OLB_CONFIG, OlbConfiguration } from "@cg/olb/configuration";
import {
  ContactDataFacade,
  CustomerCaseActions,
  CustomerCaseFacade,
  DamageActions,
  DamageFacade,
  InsuranceFacade,
  ProcessActions,
  ProductFacade
} from "@cg/olb/state";
import {
  AddWipersErrorComponent,
  AddWipersSuccessComponent,
  RemoveWipersErrorComponent,
  RemoveWipersSuccessComponent,
  WipersGdvDialogComponent
} from "@cg/olb/tiles";
import { ResumeActions, ResumeFacade } from "@cg/resume-core";
import { UnifiedError } from "@cg/core/types";
import { errorToString } from "@cg/core/utils";
import { EnvironmentService } from "@cg/environments";
import { LocationsFacade } from "@cg/locations";
import {
  InsuranceResponse,
  VAPsEventFlag,
  VAPsFailureEventData,
  VAPsSuccessEventData,
  VAPsTriggerEventData
} from "@cg/olb/shared";
import {
  AdditionalProduct,
  CheckDuplicateReponse,
  CustomerCase,
  CustomerCaseService,
  Lpn,
  OverlayService,
  ProcessId,
  RequiredService,
  Resume,
  ResumeType,
  SwitchChannelPayload
} from "@cg/shared";

@Injectable()
export class CustomerCaseEffects {
  private readonly actions$ = inject(Actions);
  private readonly customerCaseService = inject(CustomerCaseService);
  private readonly customerCaseFacade = inject(CustomerCaseFacade);
  private readonly productFacade = inject(ProductFacade);
  private readonly insuranceFacade = inject(InsuranceFacade);
  private readonly contactDataFacade = inject(ContactDataFacade);
  private readonly damageFacade = inject(DamageFacade);
  private readonly resumeFacade = inject(ResumeFacade);
  private readonly locationsFacade = inject(LocationsFacade);
  private readonly overlayService = inject(OverlayService);
  private readonly config: OlbConfiguration = inject(OLB_CONFIG);
  private readonly environmentService = inject(EnvironmentService);

  public get vapsOffer(): boolean {
    return this.environmentService.env.features.vaps.active;
  }

  public getCustomerCase$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerCaseActions.fetchCustomerCase),
      mergeMap(({ payload }: { payload: string }) =>
        this.customerCaseService.getCustomerCase$(payload).pipe(
          map((customerCase: CustomerCase) => CustomerCaseActions.fetchCustomerCaseSuccess({ payload: customerCase })),
          catchError((error: HttpErrorResponse) =>
            of(CustomerCaseActions.fetchCustomerCaseFailure({ error: errorToString(error) }))
          )
        )
      )
    )
  );

  public confirmCustomerCase$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerCaseActions.confirmCustomerCase),
      mergeMap(({ payload }: { payload: string }) =>
        this.customerCaseService.confirmCustomerCase$(payload).pipe(
          map((customerCase: CustomerCase) =>
            CustomerCaseActions.confirmCustomerCaseSuccess({ payload: customerCase })
          ),
          catchError((error: HttpErrorResponse) =>
            of(CustomerCaseActions.confirmCustomerCaseFailure({ error: errorToString(error) }))
          )
        )
      )
    )
  );

  public updateCustomerCase$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerCaseActions.updateCustomerCase),
      mergeMap(({ payload }: { payload: CustomerCase }) =>
        this.customerCaseService.updateCustomerCase$(payload).pipe(
          map((customerCase: CustomerCase) => CustomerCaseActions.updateCustomerCaseSuccess({ payload: customerCase })),
          catchError((error: HttpErrorResponse) =>
            of(CustomerCaseActions.updateCustomerCaseFailure({ error: errorToString(error) }))
          )
        )
      )
    )
  );

  public checkDuplicateCase$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerCaseActions.checkDuplicate),
      mergeMap(({ payload }: { payload: { newCustomerCaseId: string; lpn: Lpn } }) =>
        this.customerCaseService.checkDuplicateCase$(payload).pipe(
          map((checkDuplicateResponse: CheckDuplicateReponse) =>
            CustomerCaseActions.checkDuplicateSuccess({ payload: checkDuplicateResponse })
          ),
          catchError((error: HttpErrorResponse) =>
            of(CustomerCaseActions.checkDuplicateFailure({ error: errorToString(error) }))
          )
        )
      )
    )
  );

  public setOrderCommitment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerCaseActions.setOrderCommitment),
      withLatestFrom(this.customerCaseFacade.customerCaseId$),
      mergeMap(([action, customerCaseId]: [{ payload: boolean }, string]) =>
        this.customerCaseService
          .setOrderCommitment$({
            customerCaseId,
            orderCommitment: action.payload
          })
          .pipe(
            map(() => CustomerCaseActions.setOrderCommitmentSuccsess()),
            catchError((error: UnifiedError) =>
              of(CustomerCaseActions.setOrderCommitmentFailure({ error: errorToString(error) }))
            )
          )
      )
    )
  );

  // confirms the customer case for CP-18000 A/B test for binding bookings
  public confirmCustomerCaseAbTest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerCaseActions.setOrderCommitmentSuccsess),
      withLatestFrom(this.customerCaseFacade.customerCaseId$),
      map(([_, id]: [Action, string]) => CustomerCaseActions.confirmCustomerCase({ payload: id }))
    )
  );

  public confirmCustomerCaseOnConfirmation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessActions.setCurrentProcess),
      filter(({ payload }: { payload: ProcessId }) => payload === "appointment-confirmation"),
      withLatestFrom(this.customerCaseFacade.customerCaseId$),
      map(([_, id]: [{ payload: ProcessId }, string]) => CustomerCaseActions.confirmCustomerCase({ payload: id }))
    )
  );

  public switchChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerCaseActions.switchChannel),
      mergeMap(({ payload }: { payload: SwitchChannelPayload }) =>
        this.customerCaseService.switchChannelCase$(payload).pipe(
          map((customerCase: CustomerCase) => CustomerCaseActions.switchChannelSuccess({ payload: customerCase })),
          catchError((error: Error) => of(CustomerCaseActions.switchChannelFailure({ error: errorToString(error) })))
        )
      )
    )
  );

  public updateCustomerCaseWhenDamageUpdateWasSuccessful$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DamageActions.updateDamageSuccess),
      withLatestFrom(this.customerCaseFacade.customerCaseId$),
      filter(([_, customerCaseId]: [Action, string]) => !!customerCaseId),
      map(([_, customerCaseId]: [Action, string]) => CustomerCaseActions.fetchCustomerCase({ payload: customerCaseId }))
    )
  );

  public requestAddVAP$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerCaseActions.requestAddVAP),
      withLatestFrom(
        this.damageFacade.requiredService$,
        this.customerCaseFacade.protectProduct$,
        this.customerCaseFacade.wiperProduct$
      ),
      filter(
        ([{ payload }, _requiredService, hasProtect, hasWiper]: [
          { payload: VAPsTriggerEventData },
          RequiredService,
          boolean,
          boolean
        ]) => this.shouldAddAdditionalProduct(payload, hasProtect, hasWiper)
      ),
      map(([{ payload }, requiredService]: [{ payload: VAPsTriggerEventData }, RequiredService, boolean, boolean]) => {
        if (requiredService === RequiredService.REPAIR && payload.product === AdditionalProduct.WIPER) {
          return CustomerCaseActions.showVAPWipersGdvDialog({ payload: payload.flags });
        }

        return CustomerCaseActions.addVAP({ payload });
      })
    )
  );

  public showWipersGdvDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CustomerCaseActions.showVAPWipersGdvDialog),
        tap(({ payload }: { payload: VAPsEventFlag[] }) => {
          this.overlayService.open(WipersGdvDialogComponent, {
            data: { flags: payload }
          });
        })
      ),
    { dispatch: false }
  );

  public VAPTriggerAddToCustomerCase$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerCaseActions.addVAP),
      withLatestFrom(
        this.customerCaseFacade.customerCaseId$,
        this.insuranceFacade.insuranceResponse$,
        this.customerCaseFacade.protectProduct$,
        this.customerCaseFacade.wiperProduct$
      ),
      filter(
        ([{ payload }, _id, _insuranceResponse, hasProtect, hasWiper]: [
          { payload: VAPsTriggerEventData },
          string,
          InsuranceResponse,
          boolean,
          boolean
        ]) => this.shouldAddAdditionalProduct(payload, hasProtect, hasWiper)
      ),
      mergeMap(
        ([event, id, insuranceResponse, _hasProtect, _hasWiper]: [
          { payload: VAPsTriggerEventData },
          string,
          InsuranceResponse,
          boolean,
          boolean
        ]) =>
          this.customerCaseService
            .addProduct$(id, event.payload?.product, {
              hsn: insuranceResponse?.hsn,
              tsn: insuranceResponse?.tsn
            })
            .pipe(
              map(() =>
                CustomerCaseActions.addVAPSuccess({
                  payload: {
                    product: event.payload?.product,
                    customerCaseId: id,
                    flags: event.payload?.flags
                  } as VAPsSuccessEventData
                })
              ),
              catchError((error: UnifiedError) =>
                of(
                  CustomerCaseActions.addVAPFailure({
                    payload: {
                      errorMessage: errorToString(error),
                      customerCaseId: id,
                      product: event.payload?.product,
                      flags: event.payload?.flags
                    } as VAPsFailureEventData
                  })
                )
              )
            )
      )
    )
  );

  public VAPTriggerRemoveFromCustomerCase$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerCaseActions.removeVAP),
      withLatestFrom(this.customerCaseFacade.customerCaseId$),
      mergeMap(([event, id]: [{ payload: VAPsTriggerEventData }, string]) =>
        this.customerCaseService.removeProduct$(id, event.payload.product).pipe(
          map(() =>
            CustomerCaseActions.removeVAPSuccess({
              payload: {
                product: event.payload?.product,
                customerCaseId: id,
                flags: event.payload?.flags
              } as VAPsSuccessEventData
            })
          ),
          catchError((error: UnifiedError) =>
            of(
              CustomerCaseActions.removeVAPFailure({
                payload: {
                  errorMessage: errorToString(error),
                  customerCaseId: id,
                  product: event.payload?.product,
                  flags: event.payload?.flags
                } as VAPsFailureEventData
              })
            )
          )
        )
      )
    )
  );

  public VAPAddedToCustomerCaseSuccessAfterConfirmation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CustomerCaseActions.addVAPSuccess),
        filter(({ payload }: { payload: VAPsSuccessEventData }) =>
          payload?.flags?.includes(VAPsEventFlag.AFTER_CONFIRMATION)
        ),
        tap(({ payload }: { payload: VAPsSuccessEventData }) =>
          this.customerCaseFacade.bookVAPSuccess(payload.product, payload.flags)
        )
      ),
    { dispatch: false }
  );

  // TODO make dialog available for all VAP with: https://int.carglass.de/jira/browse/CP-21527
  public wiperProductAddedToCustomerCaseSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CustomerCaseActions.addVAPSuccess),
        filter(({ payload }: { payload: VAPsSuccessEventData }) => payload?.product === AdditionalProduct.WIPER),
        filter(({ payload }: { payload: VAPsSuccessEventData }) => !payload?.flags?.includes(VAPsEventFlag.SILENT)),
        tap((): void => {
          this.overlayService.open(AddWipersSuccessComponent, null, {
            positionByBreakpoints: OverlayService.POSITION_M_CENTER
          });
        })
      ),
    { dispatch: false }
  );

  // TODO make dialog available for all VAP with: https://int.carglass.de/jira/browse/CP-21527
  public wiperProductAddedToCustomerCaseFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CustomerCaseActions.addVAPFailure),
        filter(({ payload }: { payload: VAPsFailureEventData }) => payload?.product === AdditionalProduct.WIPER),
        filter(({ payload }: { payload: VAPsSuccessEventData }) => !payload?.flags?.includes(VAPsEventFlag.SILENT)),
        tap((): void => {
          this.overlayService.open(AddWipersErrorComponent, null, {
            positionByBreakpoints: OverlayService.POSITION_M_CENTER
          });
        })
      ),
    { dispatch: false }
  );

  // TODO make dialog available for all VAP with: https://int.carglass.de/jira/browse/CP-21527
  public wiperProductRemovedFromCustomerCaseSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CustomerCaseActions.removeVAPSuccess),
        filter(({ payload }: { payload: VAPsSuccessEventData }) => payload?.product === AdditionalProduct.WIPER), // TODO Show dialog for both, when no silent parameter is set in event -> pass product name string
        filter(({ payload }: { payload: VAPsSuccessEventData }) => !payload?.flags?.includes(VAPsEventFlag.SILENT)),
        tap(() =>
          this.overlayService.open(RemoveWipersSuccessComponent, null, {
            positionByBreakpoints: OverlayService.POSITION_M_CENTER
          })
        )
      ),
    { dispatch: false }
  );

  // TODO make dialog available for all VAP with: https://int.carglass.de/jira/browse/CP-21527
  public wiperProductRemovedFromCustomerCaseFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CustomerCaseActions.removeVAPFailure),
        filter(({ payload }: { payload: VAPsFailureEventData }) => payload?.product === AdditionalProduct.WIPER),
        filter(({ payload }: { payload: VAPsSuccessEventData }) => !payload?.flags?.includes(VAPsEventFlag.SILENT)),
        tap(() =>
          this.overlayService.open(RemoveWipersErrorComponent, null, {
            positionByBreakpoints: OverlayService.POSITION_M_CENTER
          })
        )
      ),
    { dispatch: false }
  );

  public loadCustomerCase$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResumeActions.loadResumeSuccess),
      filter(({ response }: { response: Resume }) =>
        [ResumeType.B2C_OLB, ResumeType.B2C_MYCARGLASS].includes(response.resumeType)
      ),
      map(({ response }: { response: Resume }) =>
        CustomerCaseActions.fetchCustomerCase({ payload: response.customerCaseId })
      )
    )
  );

  public restoreRequiredServiceOnSaveAndRestore$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CustomerCaseActions.fetchCustomerCaseSuccess),
        map(({ payload }: { payload: CustomerCase }) => payload),
        withLatestFrom(this.resumeFacade.resumeResponse$),
        filter(
          ([_customerCase, resumeResponse]: [CustomerCase, Resume]) => resumeResponse?.resumeType === ResumeType.B2C_OLB
        ),
        map(([customerCase, _resumeResponse]: [CustomerCase, Resume]) =>
          this.damageFacade.setRequiredService(RequiredService[customerCase.damages[0].requiredService])
        )
      ),
    { dispatch: false }
  );

  public restoreB2BGdv$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CustomerCaseActions.fetchCustomerCaseSuccess),
        map(({ payload }: { payload: CustomerCase }) => payload),
        withLatestFrom(this.resumeFacade.resumeResponse$),
        filter(
          ([customerCase, resumeResponse]: [CustomerCase, Resume]) =>
            [ResumeType.B2B_GDV, ResumeType.B2B_HDI, ResumeType.B2B_IOM].includes(resumeResponse?.resumeType) &&
            this.config.entryChannel !== customerCase.entryChannel
        ),
        map(([customerCase, resumeResponse]: [CustomerCase, Resume]) => {
          // need to override the entry channel in case of direct resume
          this.config.entryChannel = customerCase.entryChannel;

          const customerCaseCar = customerCase.car;
          this.productFacade.setSelectedModel(customerCaseCar.model);
          this.productFacade.setSelectedManufacturer(customerCaseCar.brand);
          this.productFacade.setSelectedModelType(customerCaseCar.vehicleType);
          this.productFacade.setSelectedModel(customerCaseCar.model);
          this.insuranceFacade.setInsuranceVin(customerCaseCar.vin);
          this.damageFacade.setLpn(customerCaseCar.lpn);

          if (customerCase.damages.length && customerCase.damages[0].occurrenceDate) {
            this.damageFacade.setDamageDate(new Date(customerCase.damages[0].occurrenceDate));
          }

          const customer = customerCase.customer;
          const customerAddress = customer.customerShipAddressLine2 ?? customer.customerShipAddressLine1;
          const hasCustomerPhoneData = !!customer.customerPhone1 || !!customer.customerPhone2;
          const customerPhone = hasCustomerPhoneData
            ? customer.customerPhone2 || customer.customerPhone1
            : customer.contactPhone2 || customer.contactPhone1;
          const customerCity = customer.customerShipCity;
          const customerCountry = customer.customerShipCountry;
          const customerZipCode = customer.customerShipZipCode;

          this.contactDataFacade.setDriverTitle(customer.contactTitle ?? customer.customerTitle);
          this.contactDataFacade.setDriverFirstname(customer.contactFirstName ?? customer.customerFirstName ?? "");
          this.contactDataFacade.setDriverLastname(customer.contactLastName ?? customer.customerLastName);
          this.contactDataFacade.setDriverStreet(customerAddress);
          this.contactDataFacade.setDriverZip(customerZipCode);
          this.contactDataFacade.setDriverCity(customerCity);
          this.contactDataFacade.setDriverCountry(customerCountry);
          this.contactDataFacade.setInsuranceHolderTitle(customer.customerTitle);
          this.contactDataFacade.setInsuranceHolderFirstname(customer.contactFirstName ?? customer.customerFirstName);
          this.contactDataFacade.setInsuranceHolderLastname(customer.contactLastName ?? customer.customerLastName);
          this.contactDataFacade.setInsuranceHolderStreet(customerAddress);
          this.contactDataFacade.setInsuranceHolderZip(customerZipCode);
          this.contactDataFacade.setInsuranceHolderCity(customerCity);
          this.contactDataFacade.setInsuranceHolderCountry(customerCountry);
          this.contactDataFacade.setInsuranceHolderPhone(customerPhone);
          this.contactDataFacade.setMobile(customerPhone);
          this.contactDataFacade.setEmail(customer.contactEmail ?? customer.customerEmail);

          if (resumeResponse.resumeType === ResumeType.B2B_HDI) {
            this.contactDataFacade.setIsPolicyHolder(true);
          }

          const preferredServiceCenterCoordinates = resumeResponse.preferredServiceCenterCoordinates;

          if (preferredServiceCenterCoordinates) {
            const lat = preferredServiceCenterCoordinates.lat;
            const lng = preferredServiceCenterCoordinates.lon;

            this.locationsFacade.setUserLocation({
              lat,
              lng
            });
          }
        })
      ),
    { dispatch: false }
  );

  private shouldAddAdditionalProduct(payload: VAPsTriggerEventData, hasProtect: boolean, hasWiper: boolean): boolean {
    if (!Object.values(AdditionalProduct).includes(payload.product)) {
      // unknown product so ignore it
      return true;
    }

    return (
      (payload.product === AdditionalProduct.PROTECT && !hasProtect) ||
      (payload.product === AdditionalProduct.WIPER && !hasWiper)
    );
  }
}
