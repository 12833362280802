<div
  class="relative z-20 mt-2.5 box-border flex w-full flex-row items-center overflow-hidden rounded-[28px] border-2 border-cg-anthracite-light-90 bg-white py-2.5"
  [ngClass]="{
    'animate-cg-sc-filter-expand': expanded,
    'animate-cg-sc-filter-collapse cursor-pointer': !expanded,
    'border-0': appointmentData.isScSelectDisabled
  }"
  [formGroup]="form"
>
  <div
    tabindex="0"
    class="flex w-full flex-row items-center px-4"
    [ngClass]="{
      'animate-sc-filter-fade-in hidden duration-[800ms] ease-in-out': expanded,
      'flex animate-cg-sc-filter-fade-in opacity-100 duration-[800ms] ease-in-out': !expanded,
      'pl-0': appointmentData.isScSelectDisabled
    }"
    (click)="expandFilter()"
    (keyup.space)="expandFilter()"
    (keyup.enter)="expandFilter()"
  >
    <cg-icon class="appointment-filter--icon h-6 w-6" [content]="pinIcon"></cg-icon>
    <div class="ml-4 flex flex-col">
      <span data-cs-mask class="typo-body-1 mb-1 text-[15px] font-bold leading-4 text-cg-anthracite">{{
        appointmentData.formattedAddress$ | async
      }}</span>
      <span class="typo-body-1 text-xs leading-4 text-cg-anthracite">
        <cg-service-center-selection-label
          data-cs-mask
          [countServiceCenters]="serviceCenterCount"
          [countSelected]="countSelectedServiceCenter"
          [distance]="serviceCenterDistance"
        ></cg-service-center-selection-label>
      </span>
    </div>
    <div
      class="absolute right-0 top-0 flex h-14 w-14 items-center justify-center rounded-full bg-cg-anthracite"
      data-cy="appointment-filter-icon"
      [ngClass]="{ hidden: appointmentData.isScSelectDisabled }"
    >
      <cg-icon class="h-6 w-6" [content]="filterIcon"></cg-icon>
    </div>
  </div>
  <div
    class="flex w-full flex-col p-4 pt-8 t:px-6"
    [ngClass]="{
      'flex animate-cg-sc-filter-fade-in': expanded,
      'hidden animate-cg-sc-filter-fade-out opacity-0': !expanded
    }"
  >
    <div
      tabindex="0"
      (click)="collapseFilter()"
      (keyup.space)="collapseFilter()"
      (keyup.enter)="collapseFilter()"
      class="absolute right-4 top-2 rotate-180 transform cursor-pointer"
    >
      <cg-icon class="h-6 w-6" [content]="arrowIcon"></cg-icon>
    </div>

    <cg-olb-search-for-sc-input
      class="appointment-search--input"
      [appointmentData]="appointmentData"
      #scSearchInput
    ></cg-olb-search-for-sc-input>

    <span class="typo-body-1 mt-2 text-xs text-cg-anthracite-light-30">
      <cg-service-center-selection-label
        [countServiceCenters]="serviceCenterCount"
        [countSelected]="countSelectedServiceCenter"
        [distance]="serviceCenterDistance"
      ></cg-service-center-selection-label>
    </span>
    <cg-options-selection
      class="appointment--filter-service-centers"
      [items]="options"
      [label]="'appointment.appointmentFilter.a11y.label' | transloco"
      [selectionId]="'appointment-sc-selection'"
      [placeholder]="'appointment.appointmentFilter.placeholder.serviceCenter' | transloco"
      [isPlaceholderDisabled]="false"
      [formControlName]="'scId'"
      (selectionChangedEmitter)="scFilterChanged($event)"
    ></cg-options-selection>
  </div>
</div>
