@if (showAppointmentResumeInfo) {
  <cg-appointment-resume-info
    #appointmentResumeInfo
    (clickedClose)="onAppointmentResumeInfoCloseClick()"
  ></cg-appointment-resume-info>
}

<div
  tabindex="-1"
  part="appointment-list"
  class="appointment-list relative mt-0 max-h-[60vh] t:mt-14"
  [cgScroll]="onScroll.bind(this)"
>
  @if (hasAppointmentError) {
    <div>
      <cg-error-message
        class="appointment-list--error"
        [errorMessage]="'appointment.appointmentList.errors.noAppointmentSelected' | transloco"
      ></cg-error-message>
    </div>
  }
  @if (hasAdverseBuyAppointments$ | async) {
    <cg-info-button
      class="leading-6 [&>button>div>span]:text-[13px] [&>button>div>span]:text-cg-anthracite-light-30 [&>button>div]:pl-0 [&>button]:text-left"
      [label]="'appointmentAdverseBuyInfoText.info.ctas.question' | transloco"
      [detailContent]="detailContent"
      iconPosition="left"
    ></cg-info-button>
  }
  @if (weeks?.length > 0) {
    @for (item of weeks; track item; let first = $first) {
      <div>
        @if (item.isShown) {
          <div class="typo-caption flex h-7 items-center py-7 text-cg-anthracite">
            <strong>KW {{ item.week }} </strong> &nbsp; {{ item.period }}
          </div>
        }
        @for (sameDayAppointments of item.sameWeekAppointments; track sameDayAppointments) {
          <div>
            <cg-appointment-list-item
              [appointmentData]="appointmentData"
              [selectedAppointmentId]="value"
              [requiredService]="requiredService"
              [isExpanded]="first"
              (selectedAppointmentChange)="onSelectedAppointmentChange($event)"
              [sameDayAppointments]="sameDayAppointments"
              [disabled]="disabled"
              [showAppointmentDetails]="showAppointmentDetails"
            >
            </cg-appointment-list-item>
          </div>
        }
      </div>
    }
    @if ((nextLoadingLimitReached$ | async) === false && (appointmentNextLoading$ | async) === false) {
      <cg-next-button
        class="cursor-pointer [&>div]:bg-cg-gray-light-70 [&>div]:text-cg-anthracite"
        (click)="loadNextClicked()"
      ></cg-next-button>
    }
    <div class="typo-body-1 mt-8 flex flex-col items-center text-center text-cg-anthracite">
      @if (nextLoadingLimitReached$ | async) {
        <div class="font-bold">
          {{ "appointment.appointmentList.texts.noAppointment" | transloco }}
        </div>
        {{ "appointment.appointmentList.texts.appointmentLimit" | transloco }}
      }
      @if (appointmentNextLoading$ | async) {
        <cg-loading-spinner></cg-loading-spinner>
      }
    </div>
    @if (showMobileService$ | async) {
      <cg-cta-two-lines
        class="mt-8 block"
        [title]="'appointment.appointmentList.ctas.mobileServiceTitle' | transloco"
        [subtitle]="'appointment.appointmentList.ctas.mobileServiceSubtitle' | transloco"
        [icon]="serviceIcon"
        (click)="mobileServiceClicked($event)"
        data-cy="mobile-service"
      >
      </cg-cta-two-lines>
    }
    @if ((appointmentData.appointmentNextLoading$ | async) === false) {
      <div class="pointer-events-none sticky bottom-0 h-6 w-full bg-gradient-to-b from-transparent to-white"></div>
    }
  }
</div>

<ng-template #detailContent>
  <cg-appointment-adverse-buy-info></cg-appointment-adverse-buy-info>
</ng-template>
