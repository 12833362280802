import { effect, inject, Injectable, untracked } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { CustomerCaseFacade, DamageFacade, NewAppointmentFacade, ProcessFacade, UiLoadingState } from "@cg/olb/state";
import { ProcessMetadata } from "@cg/shared";

@Injectable({
  providedIn: "root"
})
export class NewAppointmentCleanUpService {
  private readonly appointmentFacade = inject(NewAppointmentFacade);
  private readonly damageFacade = inject(DamageFacade);
  private readonly customerCaseFacade = inject(CustomerCaseFacade);
  private readonly processFacade = inject(ProcessFacade);

  // General Position Information
  public firstServiceCenterSearch = toSignal(this.appointmentFacade.firstServiceCenterSearch$);
  public firstAppointmentSearch = toSignal(this.appointmentFacade.firstAppointmentSearch$);
  public loadingState = toSignal(this.appointmentFacade.loadingState$);

  // Required for SC and Appointment Call
  public searchLocation = toSignal(this.appointmentFacade.searchLocation$);
  public requiredService = toSignal(this.damageFacade.requiredService$);

  // Repair Case for SC and Appointment Call
  public damageCount = toSignal(this.damageFacade.damageChipCount$);

  // Replace Case for SC and Appointment Call
  public shoppingCartEntries = toSignal(this.customerCaseFacade.shoppingCartEntries$);

  public currentProcessId = toSignal(this.processFacade.currentProcessId$);
  public processMetaData = toSignal(this.processFacade.processMetaData$);

  #clearSearchLocationOnRequirementsChange = effect(() => {
    // Triggers
    this.requiredService();
    this.damageCount();
    this.shoppingCartEntries();

    untracked(() => {
      // Don't clean up, when reaching last tile, where the shopping cart is updated again
      if (this.currentProcessId() === "appointment-confirmation") return;
      // Not on first offer, since it is already null then!
      if (!this.firstServiceCenterSearch()) {
        this.appointmentFacade.clearSearchLocationRecursive(this.loadingState());
      }
    });
  });

  #clearOfferedServiceCentersOnSearchLocationChange = effect(() => {
    // Trigger: searchLocation
    const location = this.searchLocation();
    if (location.lat !== null && location.lng !== null) {
      untracked(() => {
        // This also needs to happen before first search
        if (!this.firstServiceCenterSearch()) {
          this.appointmentFacade.clearSelectedServiceCenterIds();
        }
        // Not on first search, since it is already null then!
        if (!this.firstAppointmentSearch()) {
          const forceClearLoadingState = UiLoadingState.APPOINTMENT_CONFIRMED;
          this.appointmentFacade.clearOfferedServiceCentersRecursive(forceClearLoadingState);
        }
      });
    }
  });

  #clearConfirmedAppointmentDataOnSteppingBackToAppointmentTile = effect(() => {
    // Trigger: currentProcessId - Step on tile
    const currentId = this.currentProcessId();

    untracked(() => {
      // Only clean up, when going back to tile or before the tile. Not when stepping somewhere after it or going on appointment confirmation!
      if (
        this.loadingState() >= UiLoadingState.REQUESTING_CONFIRMATION &&
        (currentId === "appointment" ||
          !this.processMetaData().some(
            (pm: ProcessMetadata): boolean => pm.id === "appointment" || pm.id === "appointment-confirmation"
          ))
      ) {
        this.appointmentFacade.clearConfirmedAppointmentDataRecursive(this.loadingState());
      }
    });
  });

  // Selected Service Centers is NOT triggering clean up. No data change!
  // searchDate is only interesting, when user is searching at night / multiple days (edge case)
  // selecting and deselecting works by Tile in small scope

  // TODO Move to facade and move facade clean up methods into this service?
}
