import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input } from "@angular/core";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { filter, take } from "rxjs";
import { CustomerCaseFacade, DamageFacade, GdvFacade, InsuranceFacade, OlbFacade } from "@cg/olb/state";
import { TranslocoModule } from "@jsverse/transloco";
import { IconComponent, ParagraphComponent } from "@cg/core/ui";
import { LpnInputComponent, LpnInputForm } from "@cg/lpn-input";
import { InsuranceResponse, InsuranceType, OlbHeadlineComponent, VAPsEventFlag } from "@cg/olb/shared";
import { AdditionalProduct, BaseButtonComponent, DrawerComponent, GetGdv, Lpn, OverlayService } from "@cg/shared";
import { USER_DECISION } from "@cg/core/enums";

@Component({
  selector: "cg-wipers-gdv-dialog",
  standalone: true,
  imports: [
    TranslocoModule,
    ReactiveFormsModule,
    FormsModule,
    DrawerComponent,
    BaseButtonComponent,
    IconComponent,
    OlbHeadlineComponent,
    ParagraphComponent,
    LpnInputComponent
  ],
  templateUrl: "./wipers-gdv-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WipersGdvDialogComponent implements AfterViewInit {
  private readonly overlayService = inject(OverlayService);
  private readonly damageFacade = inject(DamageFacade);
  private readonly gdvFacade = inject(GdvFacade);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly olbFacade = inject(OlbFacade);
  private readonly insuranceFacade = inject(InsuranceFacade);
  private readonly customerCaseFacade = inject(CustomerCaseFacade);

  @Input()
  public data: { flags: VAPsEventFlag[] };

  public form = new FormGroup({
    lpn: new FormControl<LpnInputForm>(null, Validators.required)
  });

  public ngAfterViewInit(): void {
    this.damageFacade.lpn$
      .pipe(
        filter((lpn: Lpn) => !!lpn?.region && !!lpn?.letters && !!lpn?.numbers),
        take(1)
      )
      .subscribe((lpn: Lpn) => {
        lpn = { ...lpn };
        delete lpn.raw;
        this.form.controls.lpn.setValue(lpn);
        this.cdr.detectChanges();
      });
  }

  public submit(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.damageFacade.setLpn(this.form.value.lpn as unknown as Lpn);
    this.gdvFacade.setFetch(GetGdv.TRANSMISSION);

    this.damageFacade.setNoDate();

    this.gdvFacade.setUseNoDate(true);

    // we need to set insurance type to unknown to make a gdv request for a customer case with repair within this backend call
    this.insuranceFacade.setInsuranceType(InsuranceType.UNKNOWN);
    this.olbFacade.getInsurance();

    this.insuranceFacade.insuranceResponse$
      .pipe(
        filter((insuranceResponse: InsuranceResponse) => !!insuranceResponse),
        take(1)
      )
      .subscribe(() => {
        this.customerCaseFacade.addVAP(AdditionalProduct.WIPER, this.data?.flags);
        this.overlayService.close(USER_DECISION.ACCEPT);
      });
  }

  public close(): void {
    this.overlayService.close(USER_DECISION.DECLINE);
  }
}
