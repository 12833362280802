import { damageTypeChip1Icon, damageTypeChip3Icon, damageTypeCrackIcon } from "@cg/icon";
import { DamageType } from "@cg/shared";
import type { DamageAssessmentContent } from "../../../interfaces/damage-assessment-content";

export const damageTileMultipleChipsContent: DamageAssessmentContent = {
  controlName: "damageType",
  a11y: {
    legend: "damageTypeMultipleChips.buttons.a11y.legend"
  },
  buttons: [
    {
      title: "damageTypeMultipleChips.buttons.1",
      picture: damageTypeChip1Icon,
      radio: {
        id: "damage-type-1",
        value: DamageType.MINOR
      }
    },
    {
      title: "damageTypeMultipleChips.buttons.moreThan1",
      picture: damageTypeChip3Icon,
      radio: {
        id: "damage-type-moreThan1",
        value: DamageType.MAJOR
      }
    },
    {
      title: "damageTypeMultipleChips.buttons.crack",
      picture: damageTypeCrackIcon,
      radio: {
        id: "damage-type-crack",
        value: DamageType.CRACK
      }
    }
  ]
};
