import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Observable, of } from "rxjs";
import { filter, map, take, tap, withLatestFrom } from "rxjs/operators";
import { ChannelSwitchFacade, DamageFacade, ProcessFacade } from "@cg/olb/state";
import { TranslocoPipe, TranslocoService } from "@jsverse/transloco";
import { TrackingEvent, TrackingService } from "@cg/analytics";
import { Paragraph } from "@cg/content-api/typescript-interfaces";
import { AddFormControls } from "@cg/core/types";
import { ParagraphComponent } from "@cg/core/ui";
import {
  OLB_PROCESS_FLOW_MODEL,
  OlbHeadlineComponent,
  OpportunityFunnelContactTimeExitIds,
  ProcessFlow,
  ScrollService
} from "@cg/olb/shared";
import {
  BaseButtonComponent,
  DamageWindow,
  OpportunityFunnelCallTime,
  RadioButtonGroup,
  RadioButtonGroupComponent,
  SplitViewComponent
} from "@cg/shared";
import { ExitNodeResolverService } from "../../../services/exit-node-resolver.service";
import { CallbackTimePipe } from "../../channel-switch/pipes/callback-time.pipe";
import { BaseDirective } from "../../core/directives/base/base.directive";
import { damageWindowToTrackingCase } from "../utils/tracking-case-to-damage-window.util";
import { OpportunityFunnelContactTimeForm } from "./interfaces/opportunity-funnel-contact-time-form.interface";

@Component({
  selector: "cg-opportunity-funnel-contact-time",
  templateUrl: "./opportunity-funnel-contact-time.component.html",
  styleUrls: ["./opportunity-funnel-contact-time.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    TranslocoPipe,
    ReactiveFormsModule,
    SplitViewComponent,
    OlbHeadlineComponent,
    RadioButtonGroupComponent,
    ParagraphComponent,
    BaseButtonComponent
  ]
})
export class OpportunityFunnelContactTimeComponent
  extends BaseDirective<AddFormControls<OpportunityFunnelContactTimeForm>>
  implements OnInit
{
  public callOptions$: Observable<RadioButtonGroup>;
  public loaded = false;

  private readonly callbackTimePipe: CallbackTimePipe = new CallbackTimePipe();

  private ewt = 30;

  // eslint-disable-next-line max-params
  public constructor(
    cdr: ChangeDetectorRef,
    processFacade: ProcessFacade,
    exitNodeResolver: ExitNodeResolverService,
    trackingService: TrackingService,
    scrollService: ScrollService,
    @Inject(OLB_PROCESS_FLOW_MODEL) processFlow: ProcessFlow,
    private readonly translocoService: TranslocoService,
    private readonly channelSwitchFacade: ChannelSwitchFacade,
    private readonly damageFacade: DamageFacade
  ) {
    super(cdr, processFacade, exitNodeResolver, trackingService, scrollService, processFlow);

    this.callOptions$ = this.ewt$.pipe(
      filter((time: number) => !!time),
      tap((ewt: number) => (this.ewt = ewt)),
      take(1),
      map((ewt: number) => ({
        controlName: "callTime",
        a11y: {
          legend: "opportunityFunnelContactTime.radioGroup.a11y.legend"
        },
        buttons: [
          {
            title: this.translocoService.translate("opportunityFunnelContactTime.radioGroup.now", {
              ewt: this.callbackTimePipe.transform(ewt),
              timeUnit: this.getTimeUnit(ewt)
            }),
            radio: { id: "now", value: OpportunityFunnelCallTime.NOW }
          },
          {
            title: "opportunityFunnelContactTime.radioGroup.later",
            radio: { id: "later", value: OpportunityFunnelCallTime.LATER }
          }
        ]
      }))
    );
  }

  public get title$(): Observable<string> {
    return this.isFallbackNeeded$.pipe(
      map((needFallback: boolean) =>
        this.translocoService.translate(
          needFallback ? "opportunityFunnelContactTime.titleCccClosed" : "opportunityFunnelContactTime.title"
        )
      )
    );
  }

  public get callText$(): Observable<Paragraph> {
    let transKey = null;
    let params = {};

    switch (this.form.controls.callTime.value) {
      case OpportunityFunnelCallTime.LATER:
        transKey = "opportunityFunnelContactTime.callText.later";
        break;
      case OpportunityFunnelCallTime.NOW:
        transKey = "opportunityFunnelContactTime.callText.now";
        params = { ewt: this.callbackTimePipe.transform(this.ewt), timeUnit: this.getTimeUnit(this.ewt) };
        break;
      default:
        transKey = "opportunityFunnelContactTime.callText.noselection";
    }

    return this.isFallbackNeeded$.pipe(
      map((needFallback: boolean) => {
        if (needFallback) {
          transKey = "opportunityFunnelContactTime.callText.cccClosed";
        }

        return { text: this.translocoService.translate(transKey, params), ngTemplate: "cgParagraph" };
      })
    );
  }

  public get callTextSubline$(): Observable<Paragraph> {
    return of({
      text: this.translocoService.translate("opportunityFunnelContactTime.callTextSubline"),
      ngTemplate: "cgParagraph"
    });
  }

  public get ewt$(): Observable<number> {
    return this.channelSwitchFacade.estimatedTimeToCallback$.pipe(filter((ewt: number) => !!ewt));
  }

  public get isFallbackNeeded$(): Observable<boolean> {
    return this.channelSwitchFacade.needFallback$;
  }

  public override async ngOnInit(): Promise<void> {
    await super.ngOnInit();

    this.channelSwitchFacade.getEstimatedWaitingTime();
    this.channelSwitchFacade.isCCCOpen();

    this.channelSwitchFacade.needFallback$
      .pipe(
        filter((needFallback: boolean) => needFallback !== null),
        take(1)
      )
      .subscribe(() => {
        this.loaded = true;
        this.cdr.markForCheck();
      });
  }

  public initFormGroup(): void {
    this.form = new FormGroup<AddFormControls<OpportunityFunnelContactTimeForm>>({
      callTime: new FormControl<OpportunityFunnelCallTime>(null, Validators.required)
    });

    this.isFallbackNeeded$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isFallbackNeeded: boolean) => {
      const callTime = this.form.controls.callTime;

      if (isFallbackNeeded) {
        callTime.clearValidators();
      } else {
        callTime.addValidators(Validators.required);
      }

      callTime.updateValueAndValidity();
      this.cdr.markForCheck();
    });
  }

  public setFormValues(): void {
    this.channelSwitchFacade.opportunityFunnelCallTime$
      .pipe(
        filter((callTime: OpportunityFunnelCallTime) => !!callTime),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((callTime: OpportunityFunnelCallTime) => {
        this.form.controls.callTime.setValue(callTime);
      });
  }

  public getExitIdForSavedForm(): Observable<OpportunityFunnelContactTimeExitIds> {
    this.trackEvent();
    return of("default");
  }

  public trackEvent(): void {
    this.damageFacade.selectedDamage$
      .pipe(
        map((selectedDamage: DamageWindow) => damageWindowToTrackingCase(selectedDamage)),
        withLatestFrom(this.isFallbackNeeded$),
        take(1),
        tap(([trackingCase, isFallbackNeeded]: [string, boolean]) =>
          this.sendTrackingEvent(isFallbackNeeded, trackingCase)
        ),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  public saveForm(): void {
    if (this.form.invalid) {
      return;
    }

    this.channelSwitchFacade.setOpportunityFunnelCallTime(this.form.controls.callTime.value);
  }

  private sendTrackingEvent(needFallback: boolean, trackingCase: string): void {
    this.trackingService.trackEvent({
      eventAction: "opportunity-funnel-contact-time",
      eventLabel: needFallback ? "ccc-closed" : this.form.controls.callTime.value.toLocaleLowerCase(),
      "opportunity-funnel": {
        case: trackingCase
      }
    } as Partial<TrackingEvent>);
  }

  private getTimeUnit(ewt: number) {
    return ewt > 59 ? "Min." : "Sek.";
  }
}
