import { NgTemplateOutlet } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, inject, input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { TranslocoPipe } from "@jsverse/transloco";
import { IconComponent } from "@cg/core/ui";
import { homeIcon } from "@cg/icon";
import { ServiceCenterData } from "@cg/shared";
import { NewAppointmentCircleComponent } from "../new-appointment-circle/new-appointment-circle.component";
import { NewAppointmentDistanceLabelComponent } from "../new-appointment-distance-label/new-appointment-distance-label.component";

// Feature.NEW_APPOINTMENT_TILE
@Component({
  selector: "cg-new-appointment-multi-select",
  templateUrl: "./new-appointment-multi-select.component.html",
  styleUrls: ["./new-appointment-multi-select.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NewAppointmentMultiSelectComponent),
      multi: true
    }
  ],
  standalone: true,
  imports: [
    NgTemplateOutlet,
    TranslocoPipe,
    IconComponent,
    NewAppointmentCircleComponent,
    NewAppointmentDistanceLabelComponent
  ]
})
export class NewAppointmentMultiSelectComponent implements ControlValueAccessor {
  private readonly cdr = inject(ChangeDetectorRef);

  public readonly items = input.required<ServiceCenterData[]>();

  public readonly homeIcon = homeIcon;

  private selectedItems: string[] = [];

  private onChange: (value: string[]) => void;
  private onTouched: () => void;

  public writeValue(obj: string[]): void {
    this.selectedItems = obj ? obj : [];

    // TODO: maybe we can remove this when signal migration of new appointment tile is done
    this.cdr.markForCheck();
  }

  public registerOnChange(fn: (value: string[]) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public handleSelectItem({ serviceCenterId }: ServiceCenterData) {
    if (!this.isItemSelected(serviceCenterId)) {
      this.selectedItems = [...this.selectedItems, serviceCenterId];
    } else {
      this.selectedItems = this.selectedItems.filter((item: string) => item !== serviceCenterId);
    }

    this.onChange(this.selectedItems);
    this.onTouched();
  }

  public handleSelectAll(): void {
    this.selectedItems = !this.isAllSelected()
      ? this.items().map((item: ServiceCenterData) => item.serviceCenterId)
      : [];

    this.onChange(this.selectedItems);
    this.onTouched();
  }

  public isItemSelected(serviceCenter: string): boolean {
    return this.selectedItems.includes(serviceCenter);
  }

  public isAllSelected() {
    return this.selectedItems.length === this.items().length;
  }
}
