import type { ProcessFlowTileMapping } from "@cg/olb/shared";
import type { ProcessId } from "@cg/shared";
import { AppointmentComponent } from "../tiles/appointment/appointment.component";
import { AppointmentAlreadyExistsComponent } from "../tiles/appointment-already-exists/appointment-already-exists.component";
import { AppointmentConfirmationComponent } from "../tiles/appointment-confirmation/appointment-confirmation.component";
import { BindingBookingComponent } from "../tiles/binding-booking/binding-booking.component";
import { CarIdentifiedComponent } from "../tiles/car-identified/car-identified.component";
import { ChannelSwitchComponent } from "../tiles/channel-switch/channel-switch.component";
import { CustomerAddressWrapperComponent } from "../tiles/customer-address-wrapper/customer-address-wrapper.component";
import { CustomerContactComponent } from "../tiles/customer-contact/customer-contact.component";
import { DamageAssessmentResultComponent } from "../tiles/damage-assessment-result/damage-assessment-result.component";
import { DamageChipCountComponent } from "../tiles/damage-chip-count/damage-chip-count.component";
import { DamageLocationComponent } from "../tiles/damage-location/damage-location.component";
import { DamageLocationMultipleChipsComponent } from "../tiles/damage-location-multiple-chips/damage-location-multiple-chips.component";
import { DamageSizeComponent } from "../tiles/damage-size/damage-size.component";
import { DamageSizeMultipleChipsComponent } from "../tiles/damage-size-multiple-chips/damage-size-multiple-chips.component";
import { DamageTypeComponent } from "../tiles/damage-type/damage-type.component";
import { DamageWindowComponent } from "../tiles/damage-window/damage-window.component";
import { InsuranceTypeComponent } from "../tiles/insurance-type/insurance-type.component";
import { LicensePlateDefaultComponent } from "../tiles/license-plate/components/variations/default/license-plate-default.component";
import { LicensePlateDefaultWithNameComponent } from "../tiles/license-plate/components/variations/default-with-name/license-plate-default-with-name.component";
import { LicensePlateNoDateNoGdvComponent } from "../tiles/license-plate/components/variations/no-date-no-gdv/license-plate-no-date-no-gdv.component";
import { LicensePlateNoGdvComponent } from "../tiles/license-plate/components/variations/replace/no-gdv/license-plate-no-gdv.component";
import { LicensePlateWithGdvAndFinComponent } from "../tiles/license-plate/components/variations/replace/with-gdv-and-fin/license-plate-with-gdv-and-fin.component";
import { OpportunityFunnelAppointmentRequestComponent } from "../tiles/opportunity-funnel/opportunity-funnel-appointment-request/opportunity-funnel-appointment-request.component";
import { OpportunityFunnelContactTimeComponent } from "../tiles/opportunity-funnel/opportunity-funnel-contact-time/opportunity-funnel-contact-time.component";
import { OpportunityFunnelCostsComponent } from "../tiles/opportunity-funnel/opportunity-funnel-costs/opportunity-funnel-costs.component";
import { OpportunityFunnelCustomerAddressComponent } from "../tiles/opportunity-funnel/opportunity-funnel-customer-address/opportunity-funnel-customer-address.component";
import { OpportunityFunnelDamageRearComponent } from "../tiles/opportunity-funnel/opportunity-funnel-damage-rear/opportunity-funnel-damage-rear.component";
import { OpportunityFunnelDamageSideComponent } from "../tiles/opportunity-funnel/opportunity-funnel-damage-side/opportunity-funnel-damage-side.component";
import { OpportunityFunnelLicensePlateComponent } from "../tiles/opportunity-funnel/opportunity-funnel-license-plate/opportunity-funnel-license-plate.component";
import { OpportunityFunnelProductNotFoundComponent } from "../tiles/opportunity-funnel/opportunity-funnel-product-not-found/opportunity-funnel-product-not-found.component";
import { OpportunityFunnelSuccessComponent } from "../tiles/opportunity-funnel/opportunity-funnel-success/opportunity-funnel-success.component";
import { SummaryComponent } from "../tiles/summary/summary.component";
import { TechnicalErrorComponent } from "../tiles/technical-error/technical-error.component";
import { TeslaAppointmentComponent } from "../tiles/tesla-appointment/tesla-appointment.component";
import { TeslaRestrictionsComponent } from "../tiles/tesla-restrictions/tesla-restrictions.component";
import { VapsOfferComponent } from "../tiles/vaps-offer/vaps-offer.component";
import { VehicleIdentificationManuallyComponent } from "../tiles/vin/original/vehicle-identification-manually/vehicle-identification-manually.component";
import { VehicleIdentificationNumberComponent } from "../tiles/vin/original/vehicle-identification-number/vehicle-identification-number.component";

// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
export const CoreProcessComponentTileMapping: ProcessFlowTileMapping<ProcessId> = {
  "damage-window": DamageWindowComponent,
  "damage-location": DamageLocationComponent,
  "damage-location-multiple-chips": DamageLocationMultipleChipsComponent,
  "damage-type": DamageTypeComponent,
  "damage-chip-count": DamageChipCountComponent,
  "damage-size": DamageSizeComponent,
  "damage-size-multiple-chips": DamageSizeMultipleChipsComponent,
  "license-plate/default": LicensePlateDefaultComponent,
  "license-plate/gdv-and-fin-for-replace": LicensePlateWithGdvAndFinComponent,
  "license-plate/no-date-no-gdv": LicensePlateNoDateNoGdvComponent,
  "license-plate/no-gdv-for-replace": LicensePlateNoGdvComponent,
  "license-plate/default-with-name": LicensePlateDefaultWithNameComponent,
  "vehicle-identification-number": VehicleIdentificationNumberComponent,
  "vehicle-identification-manually": VehicleIdentificationManuallyComponent,
  "damage-assessment-result": DamageAssessmentResultComponent,
  appointment: AppointmentComponent,
  "customer-contact": CustomerContactComponent,
  "customer-address": CustomerAddressWrapperComponent,
  "appointment-confirmation": AppointmentConfirmationComponent,
  "appointment-already-exists": AppointmentAlreadyExistsComponent,
  "olb-technical-error": TechnicalErrorComponent,
  "insurance-type": InsuranceTypeComponent,
  "channel-switch": ChannelSwitchComponent,
  "opportunity-funnel-channel-switch": ChannelSwitchComponent,
  "binding-booking": BindingBookingComponent,
  "vaps-offer": VapsOfferComponent,
  "opportunity-funnel-damage-rear": OpportunityFunnelDamageRearComponent,
  "opportunity-funnel-damage-side": OpportunityFunnelDamageSideComponent,
  "opportunity-funnel-costs": OpportunityFunnelCostsComponent,
  "opportunity-funnel-license-plate": OpportunityFunnelLicensePlateComponent,
  "opportunity-funnel-appointment-request": OpportunityFunnelAppointmentRequestComponent,
  "opportunity-funnel-customer-address": OpportunityFunnelCustomerAddressComponent,
  "opportunity-funnel-contact-time": OpportunityFunnelContactTimeComponent,
  "opportunity-funnel-summary": SummaryComponent,
  "opportunity-funnel-success": OpportunityFunnelSuccessComponent,
  "opportunity-funnel-product-not-found": OpportunityFunnelProductNotFoundComponent,
  "car-identified": CarIdentifiedComponent,
  "tesla-restrictions": TeslaRestrictionsComponent,
  "tesla-appointment": TeslaAppointmentComponent,
  summary: SummaryComponent
};
