<div class="relative flex h-full cursor-pointer flex-row cgxT:flex-col" [formGroup]="form" (click)="handleItemClick()">
  @if (content().badge) {
    <cg-badge class="absolute -left-1.5 -translate-y-1/2" [text]="content().badge" variation="secondary"></cg-badge>
  }
  <div
    cgxResponsiveBg
    class="aspect-square h-[120px] bg-cover bg-center bg-no-repeat cgxT:aspect-video cgxT:h-auto"
    [src]="content().picture"
  ></div>
  <div
    class="flex flex-1 flex-col gap-2 rounded-r-tile bg-cg-gray-light-80 p-3 hover:bg-cg-gray-light-70 cgxT:rounded-b-tile"
    [ngClass]="{
      '!bg-cg-yellow hover:!bg-cg-yellow-hover': content().highlighted
    }"
  >
    <div class="flex items-center font-bold">
      <cgx-headline
        class="part-[headline]:text-[18px]"
        [content]="{
          title: content().title,
          type: 'h3'
        }"
      ></cgx-headline>
      <cgx-paragraph class="ml-auto mr-0.5" size="s">{{ content().price }}</cgx-paragraph>
      <label [for]="content().id" class="sr-only">{{ content().title }}</label>
      <input
        type="radio"
        tabindex="-1"
        class="'m-0 size-6 cursor-pointer appearance-none"
        [attr.data-cy]="content().id"
        [id]="content().id"
        [value]="content().value"
        [checked]="isChecked()"
        formControlName="choice"
        name="choice"
      />
    </div>
    <cgx-paragraph
      [content]="{
        text: content().subtitle,
        align: 'left',
        condensed: true,
        size: 'm',
        ngTemplate: 'cgParagraph'
      }"
    ></cgx-paragraph>
  </div>
</div>
