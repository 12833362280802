import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { first, Observable, of, tap } from "rxjs";
import { filter, take } from "rxjs/operators";
import { ContactDataFacade, CustomerCaseFacade, ProcessFacade } from "@cg/olb/state";
import * as CgValidators from "@cg/validators";
import { emailMxValidatorFn } from "@cg/validators";
import { TranslocoPipe } from "@jsverse/transloco";
import { TrackingService } from "@cg/analytics";
import { AddFormControls } from "@cg/core/types";
import {
  OLB_PROCESS_FLOW_MODEL,
  OlbHeadlineComponent,
  OpportunityFunnelCustomerAddressExitIds,
  ProcessFlow,
  ScrollService
} from "@cg/olb/shared";
import {
  BaseButtonComponent,
  ComponentOverarchingChangeDetectionService,
  CustomerService,
  InputType,
  RadioButtonHorizontalGroupComponent,
  SplitViewComponent,
  TextInput,
  TextInputComponent,
  TitleSelectionComponent
} from "@cg/shared";
import { ExitNodeResolverService } from "../../../services/exit-node-resolver.service";
import { BaseDirective } from "../../core/directives/base/base.directive";
import { OpportunityFunnelCustomerAddressForm } from "./interfaces/opportunity-funnel-customer-address-form.interface";

@Component({
  selector: "cg-opportunity-funnel-customer-address",
  templateUrl: "./opportunity-funnel-customer-address.component.html",
  styleUrls: ["./opportunity-funnel-customer-address.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoPipe,
    ReactiveFormsModule,
    SplitViewComponent,
    OlbHeadlineComponent,
    RadioButtonHorizontalGroupComponent,
    TextInputComponent,
    BaseButtonComponent,
    TitleSelectionComponent
  ]
})
export class OpportunityFunnelCustomerAddressComponent
  extends BaseDirective<AddFormControls<OpportunityFunnelCustomerAddressForm>>
  implements AfterViewInit
{
  public readonly firstnameInput: TextInput = {
    id: "firstname",
    controlName: "firstname",
    placeholder: "opportunityFunnelCustomerAddress.firstnameInput.placeholder",
    errorMessage: "opportunityFunnelCustomerAddress.firstnameInput.errorMessage"
  };

  public readonly lastnameInput: TextInput = {
    id: "lastname",
    controlName: "lastname",
    placeholder: "opportunityFunnelCustomerAddress.lastnameInput.placeholder",
    errorMessage: "opportunityFunnelCustomerAddress.lastnameInput.errorMessage"
  };

  public readonly emailInput: TextInput = {
    id: "email",
    controlName: "email",
    placeholder: "opportunityFunnelCustomerAddress.emailInput.placeholder",
    errorMessage: "opportunityFunnelCustomerAddress.emailInput.errorMessage"
  };

  public readonly phoneInput: TextInput = {
    id: "phone",
    controlName: "phone",
    placeholder: "opportunityFunnelCustomerAddress.phoneInput.placeholder",
    errorMessage: "opportunityFunnelCustomerAddress.phoneInput.errorMessage"
  };

  protected readonly InputType = InputType;

  // eslint-disable-next-line max-params
  public constructor(
    cdr: ChangeDetectorRef,
    processFacade: ProcessFacade,
    exitNodeResolver: ExitNodeResolverService,
    trackingService: TrackingService,
    scrollService: ScrollService,
    @Inject(OLB_PROCESS_FLOW_MODEL) processFlow: ProcessFlow,
    private readonly contactDataFacade: ContactDataFacade,
    private readonly customerService: CustomerService,
    private readonly customerCaseFacade: CustomerCaseFacade,
    private readonly cdrService: ComponentOverarchingChangeDetectionService
  ) {
    super(cdr, processFacade, exitNodeResolver, trackingService, scrollService, processFlow);
  }

  public ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.cdrService.changeDetectionRequest$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.cdr.detectChanges());
  }

  public initFormGroup(): void {
    this.customerCaseFacade.customerCaseId$.pipe(take(1)).subscribe((customerCaseId: string) => {
      this.form = new FormGroup<AddFormControls<OpportunityFunnelCustomerAddressForm>>({
        title: new FormControl<string>("", Validators.required),
        firstname: new FormControl<string>("", CgValidators.nameValidators()),
        lastname: new FormControl<string>("", CgValidators.nameValidators()),
        email: new FormControl<string>("", CgValidators.emailValidators(true), [
          CgValidators.emailMxValidator(this.getEmailMxValidatorFn(customerCaseId), 750)
        ]),
        phone: new FormControl<string>("", CgValidators.phoneValidators())
      });
    });
  }

  public setFormValues(): void {
    this.contactDataFacade.driverTitle$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((title: string) => !!title),
        take(1)
      )
      .subscribe((title: string) => this.form.controls.title.setValue(title));

    this.contactDataFacade.driverFirstname$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((firstname: string) => !!firstname),
        take(1)
      )
      .subscribe((firstname: string) => this.form.controls.firstname.setValue(firstname));

    this.contactDataFacade.driverLastname$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((lastname: string) => !!lastname),
        take(1)
      )
      .subscribe((lastname: string) => this.form.controls.lastname.setValue(lastname));

    this.contactDataFacade.email$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((email: string) => !!email),
        take(1)
      )
      .subscribe((email: string) => this.form.controls.email.setValue(email));

    this.contactDataFacade.mobile$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((phone: string) => !!phone),
        take(1)
      )
      .subscribe((phone: string) => this.form.controls.phone.setValue(phone));
  }

  public getExitIdForSavedForm(): Observable<OpportunityFunnelCustomerAddressExitIds> {
    return of("default");
  }

  public saveForm(): void {
    const controls = this.form.controls;

    if (!this.form.valid) {
      return;
    }

    this.contactDataFacade.setDriverTitle(controls.title.value);
    this.contactDataFacade.setDriverFirstname(controls.firstname.value);
    this.contactDataFacade.setDriverLastname(controls.lastname.value);
    this.contactDataFacade.setEmail(controls.email.value);
    this.contactDataFacade.setMobile(controls.phone.value);
    this.contactDataFacade.setIsPolicyHolder(true);

    this.contactDataFacade.updateCustomerContactOpportunityFunnel();
  }

  private getEmailMxValidatorFn(customerCaseId: string): emailMxValidatorFn {
    return (value: string): Observable<boolean> =>
      this.customerService.validateEmail(customerCaseId, value).pipe(
        tap(() => {
          this.cdr.markForCheck();
        }),
        first(),
        takeUntilDestroyed(this.destroyRef)
      );
  }
}
