import { MAT_DATE_FORMATS } from "@angular/material/core";
import { provideLocations } from "@cg/locations";
import { CustomDateAdapter } from "@cg/shared";
import { ComponentResolverService } from "./services/component-resolver.service";
import { ConditionalComponentResolverService } from "./services/conditional-component-resolver.service";
import { ExitNodeResolverService } from "./services/exit-node-resolver.service";

export function provideTiles() {
  return [
    CustomDateAdapter,
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: "LL"
        },
        display: {
          dateInput: "LL",
          monthYearLabel: { month: "long", year: "numeric" },
          dateA11yLabel: "LL",
          monthYearA11yLabel: "MMMM YYYY"
        }
      }
    },
    ComponentResolverService,
    ConditionalComponentResolverService,
    ExitNodeResolverService,
    provideLocations()
  ];
}
