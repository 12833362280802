import {
  AfterViewInit,
  ChangeDetectorRef,
  DestroyRef,
  Directive,
  DoCheck,
  inject,
  Inject,
  Input,
  OnDestroy,
  OnInit
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { AbstractControl, FormControlStatus, FormGroup } from "@angular/forms";
import { combineLatest, firstValueFrom, Observable, of } from "rxjs";
import { distinctUntilChanged, filter, map, pairwise, take, tap, withLatestFrom } from "rxjs/operators";
import { ProcessFacade } from "@cg/olb/state";
import formatISO from "date-fns/formatISO";
import isEqual from "lodash/isEqual";
import { TrackingService } from "@cg/analytics";
import {
  ExitId,
  ExitIds,
  OLB_PROCESS_FLOW_MODEL,
  ProcessFlow,
  ProcessToHtmlIdPipe,
  ScrollService
} from "@cg/olb/shared";
import { ProcessId, ProcessMetadata } from "@cg/shared";
import { ExitNodeResolverService } from "../../../../services/exit-node-resolver.service";

@Directive()
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export abstract class BaseDirective<F extends { [K in keyof F]: AbstractControl<unknown, unknown> }>
  implements OnInit, DoCheck, AfterViewInit, OnDestroy
{
  public readonly destroyRef = inject(DestroyRef);

  public processId: ProcessId;
  public currentProcessId: ProcessId;
  public form?: FormGroup<F>;
  public hideBtns: boolean;
  private formDataChangeAvailable = false;
  private ProcessIdWithResetOption = [
    "damage-window",
    "damage-type",
    "damage-location",
    "damage-location-multiple-chips",
    "damage-size",
    "damage-size-multiple-chips",
    "damage-chip-count"
  ];
  protected nextSuccessEventAction = "next/success";

  private readonly PREV_TABINDEX_ATTRNAME = "data-prev-tabindex";
  private initOlbAfterError?: boolean;
  private readonly processToHtmlIdPipe = new ProcessToHtmlIdPipe();

  public constructor(
    protected readonly cdr: ChangeDetectorRef,
    protected readonly processFacade: ProcessFacade,
    protected readonly exitNodeResolver: ExitNodeResolverService,
    protected readonly trackingService: TrackingService,
    protected readonly scrollService: ScrollService,
    @Inject(OLB_PROCESS_FLOW_MODEL) private processFlow: ProcessFlow
  ) {
    this.processFacade.currentProcessId$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((processId: ProcessId) => {
      this.currentProcessId = processId;
      this.hideBtns = this.currentProcessId !== this.processId;
      this.resetFormCheck();
    });
  }

  private _processMetadata: ProcessMetadata | undefined;

  @Input()
  public get processMetadata() {
    return this._processMetadata;
  }

  public set processMetadata(processMetadata: ProcessMetadata) {
    this._processMetadata = processMetadata;

    if (this._processMetadata) {
      this.processId = this._processMetadata.id;

      this.hideBtns = this.currentProcessId !== this.processId;
    }
  }

  public get hasError(): boolean {
    return !this.form || (this.form.touched && this.form.invalid);
  }

  public async ngOnInit(): Promise<void> {
    this.processFacade.initOlbAfterError$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value: boolean) => (this.initOlbAfterError = value));

    this.initFormGroup();
  }

  public ngAfterViewInit() {
    this.setResumeFormData();
    this.disableFormIfProcessMetaDataIsValid();
    this.setFormValues();
    this.tryRestoreFromEntryState();
    this.listenToFormValueChanged();
    this.resetFormCheck();

    this.processFacade.currentProcessId$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((processId: ProcessId) => {
      if (processId === this.processId) {
        this._enableFocus();
      } else {
        this._disableFocus();
      }
    });

    if (this.processId) {
      this.processFacade.scrollToTile(this.processId);
    }
  }

  public ngOnDestroy(): void {
    if (
      ![
        "damage-type",
        "damage-location",
        "damage-size",
        "damage-chip-count",
        "damage-size-multiple-chips",
        "damage-location-multiple-chips",
        "channel-switch",
        "appointment-confirmation"
      ].includes(this.currentProcessId) &&
      !this.initOlbAfterError
    ) {
      this.saveForm();
    }
  }

  public ngDoCheck() {
    if (this.formDataChangeAvailable) {
      this.updateFormValues();

      this.formDataChangeAvailable = false;
    }
  }

  public abstract initFormGroup(): void;

  public abstract setFormValues(): void;

  public abstract getExitIdForSavedForm(): Observable<ExitId> | undefined;

  public abstract saveForm(): void;

  public postSaveForm(): void {}

  protected tryRestoreFromEntryState(): void {
    combineLatest([this.processFacade.enterWithStateData$, this.processFacade.restoredIdsFromState$])
      .pipe(take(1))
      .subscribe(([enterWithState, restoredIds]: [boolean, string[]]) => {
        if (!enterWithState || restoredIds.includes(this.processId)) {
          return;
        }

        this.processFacade.addRestoredId(this.currentProcessId);
        this.restoreFromEntryState();
      });
  }

  protected restoreFromEntryState(): void {}

  public goBack(): void {
    this.processFacade.goBack(this.currentProcessId);
    this.saveForm();
    this.processFacade.goBackwardWithGivenNodeId(this.currentProcessId);
    this.processFacade.scrollToTile(this.currentProcessId);
  }

  public goForward(): void {
    const exitId$ = this.getExitIdForSavedForm();

    switch (this.formStatus(this.form)) {
      // We do not always have  a form here (LIKE gdv info tiles)
      case "no-form":
        if (exitId$ instanceof Observable) this.getExitIdForTile(exitId$, this.nextSuccessEventAction);
        break;
      case "pending":
        break;
      case "invalid":
        this.goForwardFailure();
        break;
      case "valid":
        this.saveForm();
        this.postSaveForm();
        if (exitId$ instanceof Observable) this.getExitIdForTile(exitId$, this.nextSuccessEventAction);
        break;
    }
  }

  public skipFormWithExitId(exitId: ExitId, eventAction: string = this.nextSuccessEventAction) {
    this.getExitIdForTile(of(exitId), eventAction);
  }

  public goForwardFailure() {
    this.form.markAllAsTouched();
    this.scrollService.scrollToFirstError();

    const invalidFormFields = Object.keys(this.form.controls).reduce((acc: string[], controlName: string) => {
      const isInvalid = this.form.get(controlName as unknown as Extract<keyof F, string>).invalid;
      return isInvalid ? [...acc, controlName] : [...acc];
    }, []);

    this.processFacade.goForwardFailure(this.currentProcessId, invalidFormFields);
  }

  protected goForwardSuccess(eventAction: string, exitId?: ExitIds) {
    this.processFacade.goForwardSuccess(this.currentProcessId, eventAction);

    if (exitId) {
      this.maybeHandlePassThrough(exitId);
    }
  }

  protected updateFormValues() {
    if (this.form && this._processMetadata?.formData) {
      this.form.patchValue(this._processMetadata.formData);
      this.form.markAllAsTouched();
      this.cdr.markForCheck();
    }
  }

  private async maybeHandlePassThrough(exitId: ExitIds) {
    const nextTileId = this.processFlow[this.currentProcessId]?.exitNodes[exitId];

    const processMetaData = await firstValueFrom(this.processFacade.processMetaData$);
    const nextPassthroughId = this.processFlow[nextTileId]?.passthroughId;
    const alreadyExists = processMetaData.some((item: ProcessMetadata) => item.id === nextPassthroughId);

    if (alreadyExists || !nextTileId) {
      return;
    }

    if (this.processFlow[nextTileId].passthroughId) {
      this.processFacade.setPassthroughNextTileId(nextTileId);
    }
  }

  private resetFormCheck(): void {
    if (
      this.form &&
      this.currentProcessId === this.processId &&
      this.ProcessIdWithResetOption.includes(this.currentProcessId)
    ) {
      this.form.reset();
    }
    this.cdr.markForCheck();
  }

  protected getExitIdForTile(exitId$: Observable<ExitId>, eventAction: string) {
    exitId$
      .pipe(
        filter((exitId: string) => !!exitId),
        take(1),
        takeUntilDestroyed(this.destroyRef),
        withLatestFrom(this.processFacade.processMetaData$, this.processFacade.hasFunnelCompleted$)
      )
      .subscribe(([exitId, processMetaData, funnelCompleted]: [ExitId, ProcessMetadata[], boolean]) => {
        if (funnelCompleted) {
          console.error("getExitIdForTile was triggered after funnel has been completed, which is forbidden! ");
          return;
        }

        this.processFacade.setProcessMetaData({ id: this.processId, valid: true });
        const id = this.exitNodeResolver.getExitNodeByProcessId(this.processId, exitId);
        if (id) {
          this.goForwardSuccess(eventAction, exitId as ExitIds);

          // if passthrough component was rendered before skip it
          // Can happen because passthroughId is defined multiple times because of resumes
          const alreadyExists = processMetaData.some(
            (item: ProcessMetadata) => item.id === this.processFlow[id]?.passthroughId
          );

          const forwardId = alreadyExists ? id : this.processFlow[id]?.passthroughId ?? id;
          this.processFacade.goForward(forwardId);
        }
      });
  }

  protected forwardForPassthroughNextTileId() {
    return this.processFacade.passthroughNextTileId$.pipe(
      take(1),
      withLatestFrom(this.processFacade.hasFunnelCompleted$),
      tap(([id, funnelCompleted]: [ProcessId, boolean]) => {
        if (funnelCompleted) {
          console.error(
            "forwardForPassthroughNextTileId was triggered after funnel has been completed, which is forbidden! "
          );
          return;
        }

        this.processFacade.goForward(id);
        // Reset passthroughNextTileId after scroll finished
        setTimeout(() => {
          this.processFacade.setPassthroughNextTileId(null);
        }, 700);
      }),
      map(() => "")
    );
  }

  private formStatus(form?: FormGroup<F>): string {
    if (!form) return "no-form";
    if (form.pending) return "pending";
    return form.invalid ? "invalid" : "valid";
  }

  private disableFormIfProcessMetaDataIsValid(): void {
    this.processFacade.processMetaData$
      .pipe(
        // do not run when not current tile or when form values changes
        filter((data: ProcessMetadata[]) => data.length > 0 && data[data.length - 1].id === this.processId),
        map((processMetaData: ProcessMetadata[]) =>
          processMetaData.map((data: ProcessMetadata) => ({ ...data, formData: null }))
        ),
        distinctUntilChanged(
          (prev: ProcessMetadata[], cur: ProcessMetadata[]) => JSON.stringify(prev) === JSON.stringify(cur)
        ),
        pairwise(),
        map(([prevMetaData, curMetaData]: [ProcessMetadata[], ProcessMetadata[]]) => {
          // check if the tile in o ur process is new
          const isNew = !prevMetaData.some((data: ProcessMetadata) => data.id === this.processId);
          return {
            metaData: curMetaData.find((data: ProcessMetadata) => data.id === this.processId),
            keepInitialState: isNew
          };
        }),
        filter(({ metaData }: { metaData: ProcessMetadata }) => !!metaData),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(({ metaData, keepInitialState }: { metaData: ProcessMetadata; keepInitialState: boolean }) => {
        if (!keepInitialState) {
          if (!metaData.valid) {
            this.form?.enable();
          } else {
            this.form?.disable();
          }
        }
      });
  }

  private listenToFormValueChanged() {
    this.form?.valueChanges
      .pipe(
        filter(() => this.currentProcessId === this.processId),
        distinctUntilChanged(isEqual),
        map((formValues: Record<string, unknown>) => this.convertAllDateValuesToString(formValues)),
        tap((formValues: Record<string, unknown>) =>
          this.processFacade.updateCurrentProcessMetaDataFormData(formValues)
        ),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private convertAllDateValuesToString(obj: Record<string, unknown>): Record<string, unknown> {
    for (const [key, value] of Object.entries(obj)) {
      if (value && typeof value === "object") {
        this.convertAllDateValuesToString(value as Record<string, unknown>);
      }
      if (value instanceof Date) {
        obj[key] = value ? formatISO(value, { representation: "date" }) : null;
      }
    }
    return obj;
  }

  private setResumeFormData() {
    if (this._processMetadata?.formData) {
      this.updateFormValues();
    }

    if (this.form?.pending) {
      this.form.statusChanges
        .pipe(
          filter((status: FormControlStatus) => status === "VALID"),
          take(1),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe(() => {
          this.saveForm();
        });
    } else if (this.form?.valid) {
      this.saveForm();
    }
  }

  private _getTabindexElements() {
    const htmlProcessId = this.processToHtmlIdPipe.transform(this.processId);

    return document.querySelectorAll(`#${htmlProcessId} [tabindex], #${htmlProcessId} a`);
  }

  private _disableFocus() {
    const formElements = this._getTabindexElements();

    formElements.forEach((element: Element) => {
      element.setAttribute(this.PREV_TABINDEX_ATTRNAME, element.getAttribute("tabindex") ?? "0");
      element.setAttribute("tabindex", "-1");
    });
  }

  private _enableFocus() {
    const formElements = this._getTabindexElements();

    formElements.forEach((element: Element) => {
      const prevTabindex = element.getAttribute(this.PREV_TABINDEX_ATTRNAME);

      if (prevTabindex) {
        element.setAttribute("tabindex", element.getAttribute(this.PREV_TABINDEX_ATTRNAME));
      }
    });
  }
}
