<div>
  <cg-headline
    class="typo-title block part-[headline]:!font-roboto-condensed part-[headline]:!text-[30px] part-[headline]:!font-bold part-[headline]:!leading-[40px]"
    [content]="'appointment.appointmentDetail.title' | transloco"
  ></cg-headline>

  <cg-paragraph
    class="mb-6 mt-0 block d:mt-6"
    [content]="{ text: serviceCenterText, ngTemplate: 'cgParagraph' }"
  ></cg-paragraph>

  <div class="flex flex-row gap-6">
    <div>
      <cg-headline
        [type]="HeadlineType.h2"
        [styleType]="HeadlineType.h3"
        [content]="'appointment.appointmentDetail.texts.date' | transloco"
      ></cg-headline>
      <cg-paragraph [attr.data-cy]="'appointment-detail-start-date'">{{
        appointment?.customerAppointmentStart | date: "dd.MM.yyyy"
      }}</cg-paragraph>
    </div>
    <div>
      <cg-headline
        [type]="HeadlineType.h2"
        [styleType]="HeadlineType.h3"
        [content]="'appointment.appointmentDetail.texts.appointment' | transloco"
      ></cg-headline>
      <cg-paragraph
        >{{ appointment?.customerAppointmentStart | date: "HH:mm" }}
        {{ "appointment.appointmentDetail.texts.clock" | transloco }}</cg-paragraph
      >
    </div>
  </div>

  <div class="py-8">
    @if (content?.picture) {
      <cg-picture [content]="content.picture" [shouldTranslateAlt]="true"></cg-picture>
    }
    @if (isReplaceWithoutAdas) {
      <cg-paragraph class="mb-2 block">
        {{ "appointment.appointmentDetail.texts.flexiblePickUpTimes" | transloco }}
      </cg-paragraph>
      <cg-appointment-time
        class="block w-full"
        [appointment]="appointment"
        [openingHour]="openingHour"
      ></cg-appointment-time>
    }
  </div>

  @if (content?.explaination) {
    <div class="pb-8">
      <cg-paragraph [content]="{ text: content.explaination | transloco, ngTemplate: 'cgParagraph' }"></cg-paragraph>
    </div>
  }

  <div class="flex">
    <cg-car-bringing-schedule-text></cg-car-bringing-schedule-text>
  </div>
</div>
