import {
  damageWindowFrontYellowIcon,
  damageWindowRearYellowIcon,
  damageWindowSideYellowIcon,
  damageWindowTopYellowIcon
} from "@cg/icon";
import { DamageWindow } from "@cg/shared";
import type { DamageAssessmentContent } from "../../../interfaces/damage-assessment-content";

export const damageWindowTileContent: DamageAssessmentContent = {
  controlName: "damagedWindow",
  a11y: {
    legend: "damageWindow.buttons.a11y.legend"
  },
  buttons: [
    {
      title: "damageWindow.buttons.front",
      picture: damageWindowFrontYellowIcon,
      radio: {
        id: "damage-window-front",
        value: DamageWindow.FRONT
      }
    },
    {
      title: "damageWindow.buttons.side",
      picture: damageWindowSideYellowIcon,
      radio: {
        id: "damage-window-side",
        value: DamageWindow.LEFT_SIDE
      }
    },
    {
      title: "damageWindow.buttons.rear",
      picture: damageWindowRearYellowIcon,
      radio: {
        id: "damage-window-rear",
        value: DamageWindow.REAR
      }
    },
    {
      title: "damageWindow.buttons.roof",
      picture: damageWindowTopYellowIcon,
      radio: {
        id: "damage-window-roof",
        value: DamageWindow.ROOF
      }
    }
  ]
};
