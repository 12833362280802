<div class="flex flex-col">
  <form [formGroup]="form">
    @if ((isInitialState$ | async) === false) {
      <cg-new-appointment-filter
        class="max-w-[480px]"
        id="appointment-filter"
        [isFormTouchedProgrammatically]="isFormTouchedProgrammatically"
        [showChevron]="selectedServiceCenterIds().length !== 0"
        (filterChanged)="filterHasChanged()"
        (searchClicked)="search()"
        [showDropDownChevron]="offeredServiceCenters()?.length > 1"
      ></cg-new-appointment-filter>
    } @else {
      <div class="rounded-2xl bg-white p-4 shadow-card">
        <cg-new-appointment-search-location-input
          class="[&_.suffix-icon>mat-icon>svg>g]:stroke-cg-anthracite [&_input]:!bg-white"
          [clearIcon]="clearIcon"
          [ngClass]="{
            '[&_input]:border-b-cg-anthracite': searchInput
          }"
        ></cg-new-appointment-search-location-input>
      </div>
    }
  </form>
</div>
