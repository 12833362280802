import { InsuranceType } from "@cg/olb/shared";
import type { RadioButtonGroup } from "@cg/shared";

export const insuranceTypeInformation: RadioButtonGroup = {
  controlName: "insuranceType",
  a11y: {
    legend: "insuranceType.buttons.a11y.legend"
  },
  buttons: [
    {
      title: "insuranceType.buttons.fully",
      radio: { id: `insurance-type-${InsuranceType.FULLY}`, value: InsuranceType.FULLY }
    },
    {
      title: "insuranceType.buttons.liability",
      radio: { id: `insurance-type-${InsuranceType.LIABILITY}`, value: InsuranceType.LIABILITY }
    },
    {
      title: "insuranceType.buttons.unknown",
      radio: { id: `insurance-type-${InsuranceType.UNKNOWN}`, value: InsuranceType.UNKNOWN }
    },
    {
      title: "insuranceType.buttons.none",
      radio: { id: `insurance-type-${InsuranceType.NONE}`, value: InsuranceType.NONE }
    },
    {
      title: "insuranceType.buttons.paid_by_perpetrator",
      radio: { id: `insurance-type-${InsuranceType.PAID_BY_PERPETRATOR}`, value: InsuranceType.PAID_BY_PERPETRATOR }
    }
  ]
};
