import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Actions, ofType } from "@ngrx/effects";
import { Observable } from "rxjs";
import { distinctUntilChanged, filter, map, skip, take, tap, withLatestFrom } from "rxjs/operators";
import { OLB_CONFIG, OlbConfiguration } from "@cg/olb/configuration";
import {
  ContactDataFacade,
  CustomerCaseActions,
  CustomerCaseFacade,
  DamageFacade,
  InsuranceFacade,
  ProcessFacade
} from "@cg/olb/state";
import { ResumeFacade } from "@cg/resume-core";
import { TranslocoPipe, TranslocoService } from "@jsverse/transloco";
import { OptimizelyService, TrackingService } from "@cg/analytics";
import { ConfigFacade } from "@cg/config";
import { Cta, Picture } from "@cg/content-api/typescript-interfaces";
import { ProtectConfig, WiperConfig } from "@cg/core/interfaces";
import { AddFormControls } from "@cg/core/types";
import { HeadlineComponent, ParagraphComponent, PictureComponent } from "@cg/core/ui";
import { EnvironmentService } from "@cg/environments";
import { arrowsIcon } from "@cg/icon";
import {
  InsuranceResponse,
  isDirectResumeFn,
  OlbFooterComponent,
  OlbHeadlineComponent,
  ScrollService,
  VAPsEventFlag,
  VapsOfferExitIds,
  VAPsPackage,
  VAPsSuccessEventData
} from "@cg/olb/shared";
import {
  AdditionalProduct,
  Button,
  InfoButtonComponent,
  OverlayService,
  RequiredService,
  Resume,
  ResumeType,
  SplitViewComponent
} from "@cg/shared";
import { OptimizelyExperiment, USER_DECISION } from "@cg/core/enums";
import { ExitNodeResolverService } from "../../services/exit-node-resolver.service";
import { WipersGdvDialogComponent } from "../appointment-confirmation/components/wipers-gdv-dialog/wipers-gdv-dialog.component";
import { BaseDirective } from "../core/directives/base/base.directive";
import { VapsProtectSecondChanceComponent } from "../vaps-protect-second-chance/vaps-protect-second-chance.component";
import { VapsOfferGridComponent } from "./components/vaps-offer-grid/vaps-offer-grid.component";
import { VapsOfferGridItem } from "./interfaces/vaps-offer-grid-item.interface";
import {
  generateEconomyPackagePictureModel,
  generatePremiumPackagePictureModel,
  generateStandardPackagePictureModel
} from "./utils/package-pictures-model-generator";

@Component({
  selector: "cg-vaps-offer",
  templateUrl: "./vaps-offer.component.html",
  styleUrls: ["./vaps-offer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    TranslocoPipe,
    ReactiveFormsModule,
    OlbHeadlineComponent,
    PictureComponent,
    InfoButtonComponent,
    SplitViewComponent,
    OlbFooterComponent,
    ParagraphComponent,
    OlbFooterComponent,
    HeadlineComponent,
    VapsOfferGridComponent
  ]
})
export class VapsOfferComponent extends BaseDirective<null> implements OnInit {
  protected readonly cdr = inject(ChangeDetectorRef);
  protected readonly processFacade = inject(ProcessFacade);
  protected readonly exitNodeResolver = inject(ExitNodeResolverService);
  protected readonly trackingService = inject(TrackingService);
  protected readonly scrollService = inject(ScrollService);
  private readonly translocoService = inject(TranslocoService);
  private readonly damageFacade = inject(DamageFacade);
  private readonly insuranceFacade = inject(InsuranceFacade);
  private readonly optimizelyService = inject(OptimizelyService);
  private readonly customerCaseFacade = inject(CustomerCaseFacade);
  private readonly configFacade = inject(ConfigFacade);
  private readonly contactDataFacade = inject(ContactDataFacade);
  private readonly resumeFacade = inject(ResumeFacade);
  private readonly overlayService = inject(OverlayService);
  private readonly _olbConfig: OlbConfiguration = inject(OLB_CONFIG);
  private readonly environmentService = inject(EnvironmentService);
  private readonly actions$ = inject(Actions);

  public cta: Cta;
  public declare form: FormGroup;
  public translationTexts = {
    title: "",
    subtitle: ""
  };

  public forwardBtnContent: Button = {
    id: "forward-btn",
    icon: arrowsIcon,
    iconPosition: "right",
    text: ""
  };

  public readonly economyPackagePicture: Picture = generateEconomyPackagePictureModel(
    this.environmentService.env.assetPath
  );
  public readonly standardPackagePicture: Picture = generateStandardPackagePictureModel(
    this.environmentService.env.assetPath
  );
  public readonly premiumPackagePicture: Picture = generatePremiumPackagePictureModel(
    this.environmentService.env.assetPath
  );

  public readonly boschLogo: Picture = {
    sizes: [],
    source: `${this.environmentService.env.assetPath}/olb/vaps-offer/bosch-logo.webp`,
    alt: "Bosch logo",
    ngTemplate: "cgPicture"
  };

  public content: VapsOfferGridItem[] = [];

  private lastChoice: VAPsPackage;
  private vapsAlreadyBooked: boolean;

  public get wiperBookingAllowed(): boolean {
    return this.environmentService.env.features.vaps.wiper;
  }

  public get protectConfig$(): Observable<ProtectConfig> {
    return this.configFacade.protectConfig$.pipe(
      map((config: ProtectConfig) => {
        const configClone = { ...config };

        if (config?.price_reduction_percent) {
          configClone.price_reduction_percent = config.price_reduction_percent.split(".")[0];
        }

        if (config?.price) {
          configClone.price = config.price.replace(".", ",");
        }

        if (config?.price_reduced) {
          configClone.price_reduced = config.price_reduced.replace(".", ",");
        }

        return configClone;
      })
    );
  }

  public get packagePrice$(): Observable<{ premium: string }> {
    return this.configFacade.protectConfig$.pipe(
      withLatestFrom(this.configFacade.wiperConfig$),
      map(([protectConfig, wiperConfig]: [ProtectConfig, WiperConfig]) => {
        const premiumPrice = parseFloat(protectConfig.price) + parseFloat(wiperConfig.price_wiper_reduced);

        return {
          premium: premiumPrice.toString().replace(".", ",")
        };
      })
    );
  }

  public get protectPrice$(): Observable<string> {
    return this.protectConfig$.pipe(map((config: ProtectConfig) => config.price));
  }

  public async ngOnInit(): Promise<void> {
    super.ngOnInit();

    this._setForwardBtnText();
    this._setTranslationTexts();

    this.customerCaseFacade.offerVAP(AdditionalProduct.PROTECT);

    if (this.wiperBookingAllowed) {
      this.customerCaseFacade.offerVAP(AdditionalProduct.WIPER);
      this.customerCaseFacade.offerVAPsPackage(VAPsPackage.PREMIUM);
    }
  }

  public initFormGroup(): void {
    this.form = new FormGroup<AddFormControls<{ choice: VAPsPackage }>>({
      choice: new FormControl<VAPsPackage>(null, Validators.required)
    });

    const choiceControl = this.form.controls.choice;

    choiceControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        filter((vapsPackage: VAPsPackage) => vapsPackage === VAPsPackage.ECONOMY && this.lastChoice !== vapsPackage),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this._showSecondChanceDialog();
      });
  }

  public setFormValues(): void {
    this.customerCaseFacade.protectProduct$
      .pipe(
        withLatestFrom(this.customerCaseFacade.wiperProduct$),
        take(1),
        filter(([protect, wiper]: [boolean, boolean]) => protect !== null || wiper !== null),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(([protect, wiper]: [boolean, boolean]) => {
        this._setVapsChoice(protect, wiper);
      });
  }

  public saveForm(): void {
    const value = this.form.controls.choice.value;

    if (value !== null && value !== this.lastChoice) {
      this.vapsAlreadyBooked = false;
    }

    if (this.form.invalid || value === this.lastChoice) {
      return;
    }

    this.lastChoice = value;

    switch (value) {
      case VAPsPackage.ECONOMY:
        this.customerCaseFacade.removeVAP(AdditionalProduct.PROTECT, [VAPsEventFlag.SILENT]);
        this.customerCaseFacade.removeVAP(AdditionalProduct.WIPER, [VAPsEventFlag.SILENT]);
        this.customerCaseFacade.setVAPsPackage(null);
        break;
      case VAPsPackage.STANDARD:
        this.customerCaseFacade.addVAP(AdditionalProduct.PROTECT, [VAPsEventFlag.SILENT]);
        this.customerCaseFacade.removeVAP(AdditionalProduct.WIPER, [VAPsEventFlag.SILENT]);
        this.customerCaseFacade.setVAPsPackage(null);
        break;
      case VAPsPackage.PREMIUM:
        // we don't use requestAddVAP here since we need to react on user decline specific in this place here
        this.insuranceFacade.insuranceResponse$.pipe(take(1)).subscribe((insuranceResponse: InsuranceResponse) => {
          if (insuranceResponse.vin || insuranceResponse.brand) {
            this.customerCaseFacade.addVAP(AdditionalProduct.WIPER, [VAPsEventFlag.SILENT]);
          } else {
            this.overlayService.open(WipersGdvDialogComponent, { data: { flags: [VAPsEventFlag.SILENT] } });
            this.overlayService.afterClosed$.pipe(take(1)).subscribe((decision: USER_DECISION | null) => {
              if (decision === USER_DECISION.ACCEPT) {
                return;
              }
              this.form.controls.choice.reset();
              this.lastChoice = null;
              this.customerCaseFacade.removeVAP(AdditionalProduct.WIPER, [VAPsEventFlag.SILENT]);
              this.customerCaseFacade.setVAPsPackage(null);
            });
          }
          this.customerCaseFacade.addVAP(AdditionalProduct.PROTECT, [VAPsEventFlag.SILENT]);
          this.customerCaseFacade.setVAPsPackage(VAPsPackage.PREMIUM);
        });
        break;
    }
  }

  public goForward(): void {
    const choice = this.form.controls.choice.value;

    if (choice !== VAPsPackage.PREMIUM) {
      this.saveForm();
    }

    if (choice === VAPsPackage.ECONOMY) {
      super.goForward();
      return;
    }

    if (choice === VAPsPackage.STANDARD) {
      this.customerCaseFacade.protectProduct$.pipe(take(1)).subscribe((hasProtect: boolean) => {
        // this is the case if user previously choose premium package and change his choice to protect only
        if (hasProtect) {
          super.goForward();
          return;
        }
        this._goForwardAfterVapsAdded();
      });
      return;
    }

    this.damageFacade.requiredService$
      .pipe(take(1), withLatestFrom(this.insuranceFacade.insuranceResponse$), takeUntilDestroyed(this.destroyRef))
      .subscribe(([requiredService, insuranceResponse]: [RequiredService, InsuranceResponse]) => {
        // GDV already has the wiper product no waiting needed
        if (
          choice === VAPsPackage.PREMIUM &&
          (requiredService === RequiredService.REPLACE || insuranceResponse.vin || insuranceResponse.brand)
        ) {
          this.saveForm();
          this._goForwardAfterVapsAdded();

          return;
        }

        this.insuranceFacade.insuranceResponse$
          .pipe(
            tap(() => this.saveForm()),
            // skip first result contains (it contains only coverNote in repair case)
            skip(1),
            filter((res: InsuranceResponse) => !!res),
            take(1),
            takeUntilDestroyed(this.destroyRef)
          )
          .subscribe(() => {
            if (this.form.controls.choice.value !== VAPsPackage.PREMIUM) {
              return super.goForward();
            }

            this._goForwardAfterVapsAdded();
          });
      });
  }

  public getExitIdForSavedForm(): Observable<VapsOfferExitIds> {
    return this.resumeFacade.resumeResponse$.pipe(
      take(1),
      withLatestFrom(
        this.contactDataFacade.mobile$,
        this.contactDataFacade.email$,
        this.contactDataFacade.driverCity$,
        this.contactDataFacade.driverStreet$,
        this.contactDataFacade.driverZip$
      ),
      map(([resumeResponse, mobile, email, city, street, zip]: [Resume, string, string, string, string, string]) => {
        if (isDirectResumeFn(this._olbConfig.entryChannel) && mobile && email) {
          if (resumeResponse.resumeType === ResumeType.B2B_IOM && (!city || !street || !zip)) {
            return "customerContact";
          }
          return "directResume";
        }

        const addressComplete = email && mobile && city && street && zip;
        return isDirectResumeFn(this._olbConfig.entryChannel) && addressComplete ? "directResume" : "customerContact";
      })
    );
  }

  private _setForwardBtnText(): void {
    this.optimizelyService
      .isVariationOfExperimentActive(OptimizelyExperiment.OLB_SUMMARY)
      .pipe(
        withLatestFrom(
          this.contactDataFacade.mobile$,
          this.contactDataFacade.email$,
          this.contactDataFacade.driverCity$,
          this.contactDataFacade.driverStreet$,
          this.contactDataFacade.driverZip$
        ),
        take(1)
      )
      .subscribe(
        ([summaryExperimentActive, mobile, email, city, street, zip]: [
          boolean,
          string,
          string,
          string,
          string,
          string
        ]) => {
          const addressComplete = email && mobile && city && street && zip;
          this.forwardBtnContent.text =
            summaryExperimentActive || (isDirectResumeFn(this._olbConfig.entryChannel) && addressComplete)
              ? "vapsOffer.nextButton.summary"
              : "vapsOffer.nextButton.default";
          this.cdr.markForCheck();
        }
      );
  }

  private _setTranslationTexts(): void {
    this.translocoService
      .selectTranslateObject("vapsOffer")
      .pipe(withLatestFrom(this.protectConfig$, this.packagePrice$), takeUntilDestroyed(this.destroyRef))
      .subscribe(
        ([translations, protectConfig, packagePrice]: [
          {
            title: string;
            subtitle: string;
            packages: {
              economy: {
                title: string;
                subtitle: string;
              };
              standard: {
                title: string;
                subtitle: string;
              };
              premium: {
                title: string;
                subtitle: string;
              };
            };
          },
          ProtectConfig,
          { premium: string }
        ]) => {
          this.translationTexts = {
            title: translations.title,
            subtitle: translations.subtitle
          };

          this.content = [
            {
              id: `vaps-offer-${VAPsPackage.ECONOMY}`,
              picture: this.economyPackagePicture,
              price: "+0,00€",
              subtitle: translations.packages.economy.subtitle,
              title: translations.packages.economy.title,
              value: VAPsPackage.ECONOMY
            },
            {
              id: `vaps-offer-${VAPsPackage.STANDARD}`,
              badge: "Empfehlung",
              picture: this.standardPackagePicture,
              price: `+${protectConfig.price}€`,
              subtitle: translations.packages.standard.subtitle,
              title: translations.packages.standard.title,
              value: VAPsPackage.STANDARD,
              highlighted: true
            }
          ];

          if (this.wiperBookingAllowed) {
            this.content = [
              ...this.content,
              {
                id: `vaps-offer-${VAPsPackage.PREMIUM}`,
                picture: this.premiumPackagePicture,
                price: `+${packagePrice.premium}€`,
                subtitle: translations.packages.premium.subtitle,
                title: translations.packages.premium.title,
                value: VAPsPackage.PREMIUM
              }
            ];
          }
          this.cdr.markForCheck();
        }
      );
  }

  private _setVapsChoice(protect: boolean, wiper: boolean): void {
    let choice: VAPsPackage = VAPsPackage.ECONOMY;

    if (protect && wiper) {
      choice = VAPsPackage.PREMIUM;
    } else if (protect) {
      choice = VAPsPackage.STANDARD;
    }

    this.form.get("choice").setValue(choice, { emitEvent: false });
    this.form.markAsTouched();
  }

  private _showSecondChanceDialog() {
    this.overlayService.afterClosed$
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe((add: boolean | null) => {
        if (add === null) {
          // dialog was closed without any choice
          return;
        }

        const choice = add ? VAPsPackage.STANDARD : VAPsPackage.ECONOMY;

        this.form.controls.choice.setValue(choice);

        this.form.markAsTouched();
        this.cdr.markForCheck();

        this.goForward();
      });

    this.overlayService.open(VapsProtectSecondChanceComponent);
  }

  private _goForwardAfterVapsAdded(): void {
    if (this.vapsAlreadyBooked) {
      super.goForward();
      return;
    }

    // is used to ensure that the vaps-added event for wiper is fired on the vaps-offer tile
    this.actions$
      .pipe(
        ofType(CustomerCaseActions.addVAPSuccess, CustomerCaseActions.addVAPFailure),
        withLatestFrom(this.customerCaseFacade.vapsPackage$),
        filter(
          ([{ payload }, vapsPackage]: [{ payload: VAPsSuccessEventData }, VAPsPackage]) =>
            (payload.product === AdditionalProduct.WIPER && vapsPackage === VAPsPackage.PREMIUM) ||
            vapsPackage !== VAPsPackage.PREMIUM
        ),
        take(1)
      )
      .subscribe(() => {
        this.vapsAlreadyBooked = true;
        super.goForward();
      });
  }
}
