@if (appointmentSummary) {
  <section class="appointment-confirmation">
    <form>
      <cg-branding></cg-branding>
      <div class="flex flex-col">
        <div class="appointment-confirmation--row appointment-confirmation--done-headline order-1 justify-start">
          <cg-icon class="appointment-confirmation--done-icon" [content]="successIcon"></cg-icon>
          <cg-olb-headline
            class="appointment-confirmation--headline appointment-confirmation-info-heading"
            [title]="'appointmentConfirmation.texts.title' | transloco"
            [subtitle]="'appointmentConfirmation.texts.mailConfirmation' | transloco"
          ></cg-olb-headline>
        </div>
        @if (!isConfirmationContentRelevancyTestActive || showDetailsWasClicked) {
          <div class="appointment-confirmation--row order-2" [ngClass]="{ '!order-4': showDetailsWasClicked }">
            <div class="appointment-confirmation--column appointment-confirmation--column-left biscet-row">
              <div class="appointment-confirmation--date last-child-left">
                <cg-olb-headline
                  class="appointment-date-heading part-[headline]:!mb-0"
                  type="h2"
                  styleType="h3"
                  [title]="'appointmentConfirmation.texts.appointment' | transloco"
                ></cg-olb-headline>

                <cg-appointment-date-info class="block pt-2"></cg-appointment-date-info>
                @if (requiredService === "REPLACE" && !isCalibration) {
                  <cg-car-bringing-schedule-text></cg-car-bringing-schedule-text>
                }

                <cg-ctalink
                  data-cs-mask
                  class="mt-2 block part-[link-icon-wrapper]:h-fit"
                  id="saveToCalendarLink"
                  [content]="appointmentLinks.saveToCalendarCtaLink"
                ></cg-ctalink>
              </div>
            </div>
            <div class="appointment-confirmation--column appointment-confirmation--column-right biscet-row">
              <div class="appointment-confirmation--navigate-with-gmaps">
                <cg-olb-headline
                  class="appointment-address-heading part-[headline]:!mb-0"
                  type="h2"
                  styleType="h3"
                  [title]="'appointmentConfirmation.texts.appointmentPlace' | transloco"
                ></cg-olb-headline>
                <cg-paragraph
                  data-cs-mask
                  class="link-description appointment-address-content"
                  [content]="{
                    text: createServiceCenterAddressInfo()
                  }"
                  data-cy="appointment-address"
                ></cg-paragraph>
                <cg-ctalink
                  data-cs-mask
                  class="mt-2 block part-[link-icon-wrapper]:h-fit"
                  id="navigateWithGoogleMapsLink"
                  [content]="appointmentLinks.navigateWithGoogleMaps"
                ></cg-ctalink>
              </div>
            </div>
          </div>
        }

        <hr class="appointment-confirmation--divider order-3" />
        @if (!isDirectResume) {
          <div class="appointment-confirmation--row order-4" [ngClass]="{ '!order-2': showDetailsWasClicked }">
            <div class="appointment-confirmation--column appointment-confirmation--column-left biscet-row">
              <cg-olb-headline
                type="h2"
                styleType="h3"
                [title]="'appointmentConfirmation.texts.appointmentNeccessary' | transloco"
              ></cg-olb-headline>
              <cg-list
                data-cs-mask
                class="reminder-content"
                [content]="documents$ | async"
                [shouldTranslateItem]="true"
              ></cg-list>
            </div>
            @if (isCalibration) {
              @if (!isTesla) {
                <div class="appointment-confirmation--column appointment-confirmation--column-right biscet-row">
                  <cg-olb-headline
                    type="h2"
                    styleType="h3"
                    [title]="'appointmentConfirmation.texts.adas.title' | transloco"
                  ></cg-olb-headline>
                  <cg-list
                    class="reminder-content"
                    [content]="appointmentElements.calibrationRequirements"
                    [shouldTranslateItem]="true"
                  ></cg-list>
                  <cg-paragraph
                    class="appointment-confirmation--explanation"
                    [content]="{
                      text: 'appointmentConfirmation.texts.adas.explanation' | transloco
                    }"
                  ></cg-paragraph>
                </div>
              } @else {
                <div class="appointment-confirmation--column appointment-confirmation--column-right biscet-row">
                  <cg-olb-headline
                    type="h2"
                    styleType="h3"
                    [title]="'appointmentConfirmation.texts.tesla.title' | transloco: { model: teslaModel }"
                  ></cg-olb-headline>
                  <cg-list
                    class="reminder-content"
                    [content]="appointmentElements.teslaRequirements"
                    [shouldTranslateItem]="true"
                  ></cg-list>
                  <cg-paragraph
                    class="appointment-confirmation--explanation"
                    [content]="{
                      text: 'appointmentConfirmation.texts.tesla.explanation' | transloco
                    }"
                  ></cg-paragraph>
                </div>
              }
            }
          </div>
          @if (isConfirmationContentRelevancyTestActive && !showDetailsWasClicked) {
            <cg-fake-dropdown
              class="order-5 mb-4 block w-full t:mb-6 t:max-w-[222px]"
              [placeholder]="'Termindetails anzeigen'"
              (click)="showAppointmentDetails()"
              (press)="showAppointmentDetails()"
            ></cg-fake-dropdown>
          }
        }

        @if (showWiperBooking$ | async) {
          <div data-cy="wiper-component" class="order-6">
            <hr class="appointment-confirmation--divider" />
            <cg-wiper-booking></cg-wiper-booking>
          </div>
        }

        @if (!isDirectResume) {
          <hr class="appointment-confirmation--divider order-7" />
        }
        <div class="appointment-confirmation--row appointment-confirmation--data order-8">
          <div class="appointment-confirmation--column appointment-confirmation--column-left biscet-row !pb-0">
            <div class="paragraph-spacing">
              <cg-olb-headline
                class="job-info-heading"
                type="h2"
                styleType="h3"
                [title]="'appointmentConfirmation.texts.description' | transloco"
              ></cg-olb-headline>
              @switch (requiredService) {
                @case ("REPAIR") {
                  <cg-paragraph
                    class="job-info-content pt-0"
                    [content]="{
                      text: 'appointmentConfirmation.texts.repairFrontWindow' | transloco
                    }"
                  ></cg-paragraph>
                }
                @case ("REPLACE") {
                  <cg-paragraph
                    class="job-info-content pt-0"
                    [content]="{
                      text: 'appointmentConfirmation.texts.replaceFrontWindow' | transloco
                    }"
                  ></cg-paragraph>
                }
              }
              @if (bookedProtect$ | async) {
                <cg-paragraph
                  [content]="{ text: 'appointmentConfirmation.texts.protectFrontWindow' | transloco }"
                ></cg-paragraph>
              }
              @if (bookedWiper$ | async) {
                <cg-paragraph
                  [content]="{ text: 'appointmentConfirmation.texts.wiperBooked' | transloco }"
                ></cg-paragraph>
              }
            </div>
            <div>
              <cg-olb-headline
                class="lpn-heading pt-0"
                type="h2"
                styleType="h3"
                [title]="'appointmentConfirmation.texts.lpn' | transloco"
              ></cg-olb-headline>
              <cg-paragraph
                class="lpn-content pt-0"
                data-cs-mask
                data-cy="lpn-content"
                [content]="{
                  text: appointmentSummary.lpn
                }"
              ></cg-paragraph>
            </div>
            @if ((showDamageDate$ | async) && appointmentSummary.damageDate) {
              <div class="paragraph-spacing last-child-left">
                <cg-olb-headline
                  class="damage-date-heading"
                  type="h2"
                  styleType="h3"
                  [title]="'appointmentConfirmation.texts.damageDate' | transloco"
                ></cg-olb-headline>
                <cg-paragraph
                  class="damage-date-content pt-0"
                  [content]="{
                    text: appointmentSummary.damageDate
                  }"
                ></cg-paragraph>
              </div>
            }
          </div>
          <div class="appointment-confirmation--column appointment-confirmation--column-right biscet-row">
            @if (appointmentSummary.insuranceHolderCompany && showCompanyData) {
              <div class="paragraph-spacing">
                <cg-olb-headline
                  class="company-info-heading"
                  type="h2"
                  styleType="h3"
                  [title]="'appointmentConfirmation.texts.company' | transloco"
                ></cg-olb-headline>
                <cg-paragraph
                  class="company-info-content pt-0"
                  data-cy="company-info-content"
                  data-cs-mask
                  [content]="{
                    text: createCompanyContactInfo()
                  }"
                ></cg-paragraph>
              </div>
            }
            <div class="paragraph-spacing">
              <cg-olb-headline
                class="driver-info-heading"
                type="h2"
                styleType="h3"
                [title]="driverHeadingTextKey | transloco"
              ></cg-olb-headline>
              <cg-paragraph
                class="driver-info-content pt-0"
                data-cy="driver-info-content"
                data-cs-mask
                [content]="{
                  text: createDriverContactInfo()
                }"
              ></cg-paragraph>
            </div>
            <div class="paragraph-spacing last-child-right">
              <cg-olb-headline
                class="driver-contact-heading"
                type="h2"
                styleType="h3"
                [title]="'appointmentConfirmation.texts.contactData' | transloco"
              ></cg-olb-headline>
              @if (appointmentSummary.email) {
                <cg-paragraph
                  class="driver-contact-email pt-0"
                  data-cy="driver-contact-email"
                  data-cs-mask
                  [content]="{
                    text: appointmentSummary.email
                  }"
                ></cg-paragraph>
              }
              <cg-paragraph
                class="driver-contact-phone pt-0"
                data-cy="driver-contact-phone"
                data-cs-mask
                [content]="{
                  text: appointmentSummary.phone
                }"
              ></cg-paragraph>
            </div>
          </div>
        </div>
        <hr class="appointment-confirmation--divider order-9" />
        <div class="order-10 flex flex-row justify-between gap-4 pt-2.5">
          <cg-ccc-phone-info
            class="w-full"
            phoneInfoTextKey="cccOpeningHours.byPhone.appointmentConfirmation"
            [phoneNumber]="phoneNumber"
          ></cg-ccc-phone-info>
        </div>
        <hr class="appointment-confirmation--divider order-11 mb-6" />
        <cg-qualtrics-teaser
          class="sticky -bottom-[1px] z-20 order-12 d:!static"
          #qualtricsTeaser
        ></cg-qualtrics-teaser>
      </div>
    </form>
  </section>
}
