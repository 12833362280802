import { AsyncPipe, NgClass } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ControlContainer, FormGroup, FormGroupDirective, ReactiveFormsModule } from "@angular/forms";
import { combineLatest, Observable, Subject } from "rxjs";
import { distinctUntilChanged, filter, map, tap } from "rxjs/operators";
import { TranslocoPipe } from "@jsverse/transloco";
import { TrackingService } from "@cg/analytics";
import { ConsentServiceKeys, UserCentricsService } from "@cg/consent-management";
import { Icon } from "@cg/content-api/typescript-interfaces";
import { GoogleMapsService } from "@cg/core/services";
import { AddFormControls } from "@cg/core/types";
import { CtalinkVariation, HeadlineComponent, IconComponent, ParagraphComponent, PLACEHOLDER } from "@cg/core/ui";
import { IS_BROWSER_PLATFORM } from "@cg/core/utils";
import { EnvironmentService } from "@cg/environments";
import { arrowsIcon, calendarNoIcon, pinIcon, pinNoIcon, warningIcon } from "@cg/icon";
import {
  Appointment,
  AppointmentData,
  AppointmentSearchForm,
  AvailableServiceCenter,
  BackendSwitchChannelType,
  Ctalink,
  CtalinkComponent,
  CtaTwoLinesComponent,
  DisplayMode,
  FormatTelLinkPipe,
  LoadingSpinnerComponent,
  OlbSearchForScInputComponent,
  PhoneNumber,
  SetAppointmentPayload
} from "@cg/shared";
import { AppointmentFilterComponent } from "../appointment-filter/appointment-filter.component";
import { AppointmentFilterStaticComponent } from "../appointment-filter-static/appointment-filter-static.component";
import { AppointmentListComponent } from "../appointment-list/appointment-list.component";
@Component({
  selector: "cg-appointment-search",
  templateUrl: "./appointment-search.component.html",
  styleUrls: ["./appointment-search.component.scss"],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    AsyncPipe,
    TranslocoPipe,
    ReactiveFormsModule,
    HeadlineComponent,
    IconComponent,
    ParagraphComponent,
    CtaTwoLinesComponent,
    CtalinkComponent,
    AppointmentFilterStaticComponent,
    AppointmentFilterComponent,
    AppointmentFilterComponent,
    AppointmentListComponent,
    LoadingSpinnerComponent,
    OlbSearchForScInputComponent
  ]
})
export class AppointmentSearchComponent implements OnInit, OnDestroy {
  @Input() public appointmentData: AppointmentData;
  @Input() public skipFormWithExitId: (exitId: string) => void;

  public readonly PhoneNumber = PhoneNumber;
  public readonly plainPhone = FormatTelLinkPipe.getPlainPhoneNumber(PhoneNumber.CS_NO_APPOINTMENT_AVAILABLE);

  private customerCaseId?: string;
  private appointmentId?: string;
  private availableAppointments?: Appointment[];

  @Input()
  public set savedEvent(value: Subject<boolean>) {
    value.subscribe(() => {
      this.saveForm();
    });
  }

  @ViewChild(AppointmentFilterComponent) public appointmentFilter: AppointmentFilterComponent;

  public destroyRef = inject(DestroyRef);
  public form: FormGroup<AddFormControls<AppointmentSearchForm>>;
  public needGoogleApiUnload = false;
  public isBookingWithoutAppointmentAllowed = false;
  public DisplayMode = DisplayMode;
  public displayMode = DisplayMode.INITIAL;
  public hasAppointments$: Observable<boolean>;
  public readonly mobileServiceCta: Ctalink = {
    id: "appointment-mobile-service",
    icon: arrowsIcon,
    link: {
      href: "",
      title: "appointment.appointmentSearch.ctas.mobileServiceTitle",
      text: "appointment.appointmentSearch.ctas.mobileServiceTitle"
    },
    variation: CtalinkVariation.OLB,
    ngTemplate: "cgCtalink"
  };

  public readonly noAppointmentCtaContent = {
    icon: calendarNoIcon,
    title: "appointment.appointmentSearch.ctas.noAppointmentTitle",
    subtitle: "appointment.appointmentSearch.ctas.noAppointmentSubtitle"
  };

  public get searchInput(): string {
    return this.form.controls.searchServiceCenterInput.value;
  }

  public get icon(): Icon {
    const icon: Icon = pinIcon;

    switch (this.displayMode) {
      case DisplayMode.INITIAL:
        return icon;
      case DisplayMode.NO_SCS:
        return pinNoIcon;
      case DisplayMode.NO_APPOINTMENTS:
      case DisplayMode.NO_APPOINTMENTS_FOR_SC:
        return calendarNoIcon;
      default:
        return icon;
    }
  }

  public get text(): string {
    switch (this.displayMode) {
      case DisplayMode.NO_SCS:
        return "appointment.appointmentSearch.texts.noScs";
      case DisplayMode.NO_SCS_WITHOUT_MOBILE_SERVICE:
        return "appointment.appointmentSearch.texts.noScsWithoutMobileService";
      case DisplayMode.NO_APPOINTMENTS:
        return "appointment.appointmentSearch.texts.noAppointment";
      case DisplayMode.NO_APPOINTMENTS_FOR_SC:
        return "appointment.appointmentSearch.texts.noAppointmentForSc";
      default:
        return "appointment.appointmentSearch.texts.searchInfo";
    }
  }

  public get busyIcon(): Icon {
    return warningIcon;
  }

  public get showMobileService(): boolean {
    return this.displayMode === DisplayMode.NO_SCS;
  }

  public get hasAppointmentError(): boolean {
    const { invalid, touched } = this.form.controls.selectedAppointmentId;

    return invalid && touched;
  }

  public get isFormTouchedProgrammatically(): boolean {
    return this.form.pristine && !this.form.dirty;
  }

  private get activeMobileService(): boolean {
    return this.environmentService.env.features.mobileService;
  }

  // eslint-disable-next-line max-params
  public constructor(
    private userCentricsService: UserCentricsService,
    private cdr: ChangeDetectorRef,
    private parent: FormGroupDirective,
    private trackingService: TrackingService,
    @Inject(IS_BROWSER_PLATFORM) private readonly isBrowser: boolean,
    private readonly environmentService: EnvironmentService
  ) {}

  public ngOnInit(): void {
    this.form = this.parent.form as FormGroup<AddFormControls<AppointmentSearchForm>>;
    this.isBookingWithoutAppointmentAllowed = this.appointmentData.isBookingWithoutAppointmentAllowed;
    this.appointmentData.setStatus("FIXED");

    this.userCentricsService.activateService<GoogleMapsService>(ConsentServiceKeys.googleMaps);

    combineLatest([
      this.appointmentData.isLoading$,
      this.appointmentData.availableAppointments$,
      this.appointmentData.availableServiceCenters$,
      this.appointmentData.formattedAddress$,
      this.appointmentData.selectedServiceCenterIds$,
      this.appointmentData.customerCaseId$,
      this.appointmentData.appointmentId$
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        ([
          isLoading,
          appointments,
          serviceCenters,
          formattedAddress,
          selectedServiceCenterIds,
          customerCaseId,
          appointmentId
        ]: [boolean, Appointment[], AvailableServiceCenter[], string, string[], string, string]) => {
          this.customerCaseId = customerCaseId;
          this.appointmentId = appointmentId;
          this.availableAppointments = appointments;

          if (isLoading) {
            this.displayMode = DisplayMode.PENDING;
          } else if (!formattedAddress) {
            this.displayMode = DisplayMode.INITIAL;
          } else if (
            appointments?.length === 0 &&
            !!selectedServiceCenterIds &&
            !selectedServiceCenterIds.includes(PLACEHOLDER)
          ) {
            this.displayMode = DisplayMode.NO_APPOINTMENTS_FOR_SC;
          } else if (serviceCenters?.length === 0 || serviceCenters === null) {
            if (this.activeMobileService) {
              this.displayMode = DisplayMode.NO_SCS;
            } else {
              this.displayMode = DisplayMode.NO_SCS_WITHOUT_MOBILE_SERVICE;
            }
          } else if (appointments?.length === 0 || appointments === null) {
            this.displayMode = DisplayMode.NO_APPOINTMENTS;
          } else if (formattedAddress) {
            this.displayMode = DisplayMode.ADDRESS_ENTERED;
          }
        }
      );

    this.setFormValues();
    this.hasAppointments$ = this.appointmentData.availableAppointments$.pipe(
      map((appointments: Appointment[]) => appointments?.length > 0),
      tap(() => {
        setTimeout(() => {
          this.cdr.detectChanges();
        }, 100);
      })
    );
  }

  public ngOnDestroy(): void {
    // @todo this is never set to true
    if (this.needGoogleApiUnload) {
      window["consentSettings"]["googleMaps"] = false;
    }
  }

  public setFormValues(): void {
    this.appointmentData.formattedAddress$
      .pipe(
        filter((formattedAddress: string) => !!formattedAddress),
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((formattedAddress: string) => {
        const oldValue = this.form.controls.searchServiceCenterInput.value;

        if (oldValue && oldValue !== formattedAddress) {
          this.form.markAsUntouched();
          this.appointmentData.resetStateForForm();
        }

        this.form.controls.searchServiceCenterInput.setValue(formattedAddress);
        this.form.markAsPristine({ onlySelf: true });
      });

    this.appointmentData.appointmentId$
      .pipe(
        distinctUntilChanged(),
        filter((id: string) => this.form.controls.selectedAppointmentId.value !== id),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((appointmentId: string) => {
        this.form.controls.selectedAppointmentId.setValue(appointmentId);
        this.form.markAsPristine({ onlySelf: true });
      });
  }

  public saveForm(): void {
    if (this.form.invalid) {
      return;
    }

    if (this.availableAppointments) {
      if (!this.appointmentId) {
        console.error("No appointment id");
        return;
      }

      const selectedAppointment = this.availableAppointments.find(
        (value: Appointment) => value.appointmentId === this.appointmentId
      );

      if (!selectedAppointment) {
        console.error("No selected appointment");
        return;
      }

      const appointmentPayload: SetAppointmentPayload = {
        appointmentId: this.appointmentId,
        customerCaseId: this.customerCaseId,
        availabilityPeriodStart: selectedAppointment.availabilityPeriodStart,
        availabilityPeriodEnd: selectedAppointment.availabilityPeriodEnd,
        customerAppointmentStart: selectedAppointment.customerAppointmentStart,
        customerAppointmentEnd: selectedAppointment.customerAppointmentEnd,
        serviceCenter: selectedAppointment.serviceCenter,
        street: selectedAppointment.street,
        city: selectedAppointment.city,
        sublocality: selectedAppointment.sublocality,
        postalCode: selectedAppointment.postalCode,
        state: selectedAppointment.state,
        country: selectedAppointment.country,
        fitterId: selectedAppointment.fitterId,
        overallTaskTime: selectedAppointment.overallTaskTime,
        jobStart: selectedAppointment.jobStart,
        jobEnd: selectedAppointment.jobEnd,
        locationType: "INHOUSE",
        status: "FIXED",
        source: "OLB"
      };
      this.appointmentData.confirmAppointment(appointmentPayload);
    }
  }

  public onGoForwardWithoutAppointment(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.resetControlErrors();

    this.appointmentData.setChannelSwitchReason(BackendSwitchChannelType.B2B_WITHOUT_APPOINTMENT);
    this.skipFormWithExitId("channelSwitch");
  }

  public goToMobileService(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.appointmentData.setChannelSwitchReason("MOBILE_SERVICE");
    this.skipFormWithExitId("mobileService");
  }

  public tapToCall(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();

    const tel = FormatTelLinkPipe.getPlainPhoneNumber(PhoneNumber.CS_NO_APPOINTMENT_AVAILABLE);

    if (this.isBrowser) {
      this.trackingService.trackEvent({
        eventAction: "tap-to-call",
        eventLabel: tel
      });
      window.location.href = `tel:${tel}`;
    }
  }

  public filterChanged() {
    this.form.markAsUntouched();
  }

  public listScrolled() {
    if (!this.appointmentFilter) {
      return;
    }
    this.appointmentFilter.collapseFilter();
  }

  /**
   * If we continue in the process without Appointment,
   * the form is invalid because of the required validators.
   * This has the consequence that the "scrollToError" in the next step scrolls to the wrong place.
   * To fix this we need to reset the errors
   * @private
   */
  private resetControlErrors() {
    for (const control of Object.values(this.form.controls)) {
      control.setErrors(null);
    }
  }
}
