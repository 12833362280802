<section
  class="mb-step-xs flex flex-col font-roboto text-base leading-6 text-cg-anthracite m:mb-step-mobile t:mb-step-tablet d:mb-step-desktop w:mb-step-widescreen fhd:mb-step-fullhd [&>cg-split-view>div>div]:pb-8 [&>cg-split-view>div>div]:pt-8"
  data-cy="damage-assessment-result"
>
  <cg-split-view [master]="master" [showBranding]="false"></cg-split-view>
</section>

<ng-template #master>
  <div class="flex h-full w-full flex-col flex-wrap items-start t:flex-row t:flex-nowrap t:items-center">
    <div class="flex">
      <cg-icon class="h-12 w-12 self-start" [content]="successIcon"></cg-icon>
      <div class="ml-3">
        <cg-olb-headline
          class="[&>*]:!m-0"
          [title]="
            ((requiredServiceIsReplace$ | async)
              ? 'damageAssessmentResult.titleReplace'
              : 'damageAssessmentResult.titleRepair'
            ) | transloco
          "
          [subtitle]="'damageAssessmentResult.subTitle' | transloco"
        ></cg-olb-headline>
      </div>
    </div>
    <div
      class="mt-1 flex flex-1 justify-end self-end t:mt-0 t:self-start [&>cg-info-button>button>div]:h-auto [&>cg-info-button>button>div]:p-0"
    >
      <cg-info-button class="" [detailContent]="detailContent"></cg-info-button>
    </div>
  </div>
</ng-template>

<ng-template #detailContent>
  <div data-cy="damage-assessment-result-overlay">
    <cg-olb-headline
      class="[&>*]:!mt-0"
      [title]="
        ((requiredServiceIsReplace$ | async)
          ? 'damageAssessmentResult.prefixDialog.titleReplace'
          : 'damageAssessmentResult.prefixDialog.titleRepair'
        ) | transloco
      "
    ></cg-olb-headline>
    <cg-paragraph
      [content]="{
        text: infoText$ | async | transloco
      }"
    ></cg-paragraph>

    @if (showOriginalWindowText) {
      <div>
        <cg-olb-headline
          [title]="'damageAssessmentResult.prefixDialog.titleOriginalWindow' | transloco"
        ></cg-olb-headline>
        <cg-paragraph
          [content]="{
            text: 'damageAssessmentResult.prefixDialog.textOriginalWindow' | transloco
          }"
        ></cg-paragraph>
      </div>
    }
  </div>
</ng-template>
