import { DecimalPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, input, InputSignal } from "@angular/core";

// Feature.NEW_APPOINTMENT_TILE
@Component({
  selector: "cg-new-appointment-distance-label",
  templateUrl: "./new-appointment-distance-label.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DecimalPipe]
})
export class NewAppointmentDistanceLabelComponent {
  public distance: InputSignal<number> = input.required<number>();
}
