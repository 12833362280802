import { DatePipe, SlicePipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject, input } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { NewAppointmentFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { BadgeComponent } from "@cg/core/ui";
import { BaseButtonComponent, BreakpointService, OverlayService } from "@cg/shared";
import { LocationWithAppointment } from "../../interfaces/location-with-appointment.interface";
import { NewAppointmentCircleComponent } from "../new-appointment-circle/new-appointment-circle.component";
import { NewAppointmentDetailComponent } from "../new-appointment-detail/new-appointment-detail.component";
import { NewAppointmentDistanceLabelComponent } from "../new-appointment-distance-label/new-appointment-distance-label.component";

// Feature.NEW_APPOINTMENT_TILE
@Component({
  selector: "cg-new-appointment-earliest-appointment-card",
  templateUrl: "./new-appointment-earliest-appointment-card.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DatePipe,
    SlicePipe,
    TranslocoPipe,
    BaseButtonComponent,
    BadgeComponent,
    NewAppointmentCircleComponent,
    NewAppointmentDistanceLabelComponent
  ]
})
export class NewAppointmentEarliestAppointmentCardComponent {
  private readonly appointmentFacade = inject(NewAppointmentFacade);
  private readonly overlayService = inject(OverlayService);
  private readonly breakpointService = inject(BreakpointService);

  public isMobile = toSignal(this.breakpointService.isMobile$);
  public locationWithAppointment = input.required<LocationWithAppointment>();

  public bookAppointment(event: Event): void {
    event.stopPropagation();
    event.preventDefault();

    this.appointmentFacade.setSelectedAppointment(
      this.locationWithAppointment().appointment.appointmentId,
      this.locationWithAppointment().serviceCenter.serviceCenterId
    );
    this.overlayService.open(NewAppointmentDetailComponent);
  }
}
